import React, { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Avatar,
  Tabs,
  Tab,
  Grid,
  IconButton,
  useMediaQuery,
  Stack,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import axios from "axios";
import { TiTimes } from 'react-icons/ti';
import SellerDetailsCardConvert from "../../components/SellerDetailsCard/SellerDetailsCardConvert";
import ArtCardFB from "../../components/Skeletons/ArtCardFB";
import CollectionCardFB from "../../components/Skeletons/CollectionCardFB";
import AddIcon from "@mui/icons-material/Add";
import ProfileCover from "../../assets/userProfileCover.png";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import CreateCollection from "../../components/CreateCollection/CreateCollection";
import CollectionCard from "../Collections/CollectionCard/CollectionCard";
import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";
import { getAllNFTS } from '../../service/nftService';
import DataContext from '../Collections/DataContext';
import { ImImage } from 'react-icons/im';

// Tabpanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ConvertXNFT = ({ darkMode, account, blockchain, handleConnectIndexxWallet, indexxWalletEmail, indexxWalletObj, indexxWalletAddress, isIndexxWalletWeb }) => {
  const { t } = useTranslation();
  const { setData } = useContext(DataContext);
  const [sellerData, setSellerData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [tabValue, setTabValue] = useState(0); // setting tab value for changing
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [walletReqAddress, setReqWalletAddress] = useState();
  const [, setLoaderMessage] = useState("");
  const [sendData, setSendData] = useState();
  const [mainTab, setMainTab] = useState(0); // setting tab value for changing
  const [image, setImage] = useState("");

  const handleImageUpload = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const Input = styled('input')({
    display: 'none',
  });


  // Parse the query parameters from the URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  useEffect(() => {
    // const fetchData = async () => {
    //   setIsLoading(true);
    //   setLoaderMessage("Loading...");
    //   const res = await axios.get("/sellerData.json");
    //   const sellerCardData = res.data;
    //   setSellerData(sellerCardData);
    //   setIsLoading(false);
    // };

    const fetchNft = async () => {
      const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
      let userWalletObj = [];
      if (isIndexxWalletWeb) {
        userWalletObj = JSON.parse(localStorage.getItem("indexxWebWallets"));
        const requiredWallet = userWalletObj.find(x => x.coinName === blockchain)
        const res = await getAllNFTS(requiredWallet?.coinWalletAddress, blockchain);
        setReqWalletAddress(requiredWallet?.coinWalletAddress);
        setIsLoading(true);
        setLoaderMessage("Loading...");
        const sellerCardData = res;
        setSellerData(sellerCardData);
        setIsLoading(false);
      } else {
        if (account === null || account === undefined || blockchain === '' || blockchain === undefined) {
          return;
        }
        const res = await getAllNFTS(account, blockchain);
        setReqWalletAddress(account);
        setIsLoading(true);
        setLoaderMessage("Loading...");
        const sellerCardData = res;
        setSellerData(sellerCardData);
        setIsLoading(false);
      }
    }
    //fetchData();
    fetchNft();
  }, [account, blockchain]);

  // Tab handler
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeMainTab = (event, newValue) => {
    setMainTab(newValue);
  };

  const handleNavigate = () => {
  };

  // Modal
  const handleOpenModal = () => {
    setOpenModal(true);
  }
  const handleCloseModal = () => setOpenModal(false);

  const MobileTabs = styled(Tabs)({
    border: 'none',
    backgroundColor: `${darkMode ? '#040404' : '#ffffff'}`,
    '& .MuiTabs-indicator': {
      backgroundColor: 'inherit',
    },
  });

  const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    color: `${darkMode ? '#ffffff' : '#040404'}`,
    backgroundColor: `${darkMode ? '#171c26' : '#EEEEEE'}`,
    borderRadius: '4px',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#0294FE',
      borderRadius: '4px',
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));

  console.log(sellerData, "seller");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        mt: 11,
      }}
    >
      {isLoading && (<div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {darkMode ? (
          <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
        ) : (
          <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
        )}
      </div>)
      }
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                }`,
            }}
          >
            {"Convert XNFT"}
          </Typography>
        </Box>
      )}
      <Box
        style={{
          position: 'fixed',
          height: '100vh',
        }}
      >

      </Box>
      {!isMobile ?
        <Box zIndex={100} color={darkMode ? '#ffffff' : '#121212'}>
          {(isIndexxWalletWeb) ?
            <>
              {sellerData?.length === 0 ? (
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "290px", marginBottom: "250px" }}>
                  <Typography variant='h5'>
                    No NFTs to display
                  </Typography>
                </Box>
              ) :
                <Box style={{ marginTop: 60 }}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${darkMode ? "#040404" : "#e3dcdc"
                        } 100%), url(${ProfileCover})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      p: 5,
                      borderRadius: '10px',
                    }}
                  >
                    <Box>

                      <Stack
                        mt={2}
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                      >

                        <Stack
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          gap={1}
                        >
                          <Box
                            bgcolor={darkMode ? '#171c26a1' : '#f2ecec70'}

                            sx={{
                              borderRadius: "100px",
                              height: "120px",
                              width: "120px",
                              display: "flex",
                              justifyContent: "center",
                              verticalAlign: "middle"
                            }}
                          >

                            {image ?
                              <Box
                                sx={{
                                  position: 'relative',
                                  width: '100px', // Adjust the width and height to make it square
                                  height: '100px',
                                }}
                              >
                                <Box sx={{ position: 'absolute', right: '-26%', top: '-5%', marginBottom: "-20px" }}>
                                  <IconButton onClick={() => setImage(null)}>
                                    <Typography component="span" color="secondary">
                                      <TiTimes />
                                    </Typography>
                                  </IconButton>
                                </Box>
                                <Avatar
                                  src={image}
                                  alt="Nothing"
                                  sx={{ width: 90, height: 90, borderRadius: "50px", my: 1.8, mx: 0.6 }}
                                />
                              </Box>
                              :
                              <label htmlFor="icon-button-file-front">
                                <Input
                                  accept="image/*"
                                  id="icon-button-file-front"
                                  type="file"
                                  onChange={handleImageUpload}
                                />
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                  sx={{ m: 4.5 }}
                                >
                                  <Typography component="span" color="secondary" sx={{ fontSize: "26px", verticalAlign: "middle" }}>
                                    <ImImage />
                                  </Typography>
                                </IconButton>
                              </label>
                            }
                          </Box>
                        </Stack>
                        <Stack
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          gap={1}
                          mt={2}
                        >
                          <Typography color="secondary" variant="h5">
                            {isIndexxWalletWeb ? walletReqAddress : account}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                  <Box
                    bgcolor={darkMode ? '#171C26' : '#EEEEEE'}
                    sx={{
                      borderRadius: "10px",

                      mb: 2,
                    }}
                  >

                    <Tabs
                      textColor='secondary'
                      indicatorColor='secondary'
                      value={mainTab}
                      onChange={handleChangeMainTab}
                      centered
                    >
                      <Tab
                        sx={{
                          color: 'gray',
                          textTransform: 'capitalize',
                          display: 'block',
                          mx: 5,
                          zIndex: "601",
                        }}
                        label={`Created (${sellerData?.filter(fs => fs?.title?.toLowerCase().includes("created")).length
                          })`}
                      />
                      <Tab
                        sx={{
                          color: 'gray',
                          textTransform: 'capitalize',
                          display: 'block',
                          mx: 5,
                          zIndex: "601",
                        }}
                        label={`Owned (${sellerData?.length
                          })`}
                      />
                    </Tabs>
                  </Box>
                  {mainTab === 1 ?

                    <Box
                      bgcolor={darkMode ? '#171C26' : '#EEEEEE'}
                      sx={{
                        borderRadius: "10px",

                        mb: 4,
                      }}
                    >

                      <Tabs
                        textColor='secondary'
                        indicatorColor='secondary'
                        value={tabValue}
                        onChange={handleChange}
                        centered
                      >
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`Bitcoin (${sellerData?.filter(fs => fs?.title?.toLowerCase().includes("bitcoin")).length
                            })`}
                        />
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`Ethereum (${sellerData?.filter(
                            (fs) =>
                              fs?.title?.toLowerCase().includes("ethereum")).length
                            })`}
                        />
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`Indexx(${sellerData?.filter(
                            (fs) =>
                              fs?.title?.toLowerCase().includes("indexx")).length
                            })`}
                        />
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`USD(${sellerData?.filter(
                            (fs) =>
                              fs?.title?.toLowerCase().includes("usd")).length
                            })`}
                        />
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`Others (${sellerData?.filter(
                            (fs) =>
                            (!fs?.title?.toLowerCase().includes("bitcoin") &&
                              !fs?.title?.toLowerCase().includes("ethereum") &&
                              !fs?.title?.toLowerCase().includes("indexx") &&
                              !fs?.title?.toLowerCase().includes("usd")
                            ))?.length
                            })`}
                        />
                      </Tabs>
                    </Box>
                    : null}

                  <Box sx={{ mx: -3, mt: -2 }}>
                    <TabPanel value={mainTab} index={0}>
                      <div
                        style={{
                          position: 'fixed',
                          zIndex: 500,
                        }}
                      >

                      </div>

                      <Grid
                        container
                        spacing={{ xs: 2, md: 4 }}
                        columns={{ xs: 1, sm: 12, md: 12}}
                      >

                        {
                          sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <CollectionCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                ?.filter(fs => fs?.title?.toLowerCase().includes("bitcoin"))
                                .map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    isIndexxWalletWeb={isIndexxWalletWeb}
                                    walletReqAddress={walletReqAddress}
                                    blockchain={blockchain}
                                  />
                                ))}
                            </>
                          )

                        }
                      </Grid>
                    </TabPanel>
                    <TabPanel value={mainTab} index={1}>

                      <TabPanel value={tabValue} index={0}>
                        <div
                          style={{
                            position: 'fixed',
                            zIndex: 500,
                          }}
                        >

                        </div>

                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >

                          {
                            sellerData.length === 0 ? (
                              [1, 2, 3, 4, 5, 6].map((n) => (
                                <CollectionCardFB darkMode={darkMode} key={n} />
                              ))
                            ) : (
                              <>
                                {sellerData
                                  ?.filter(fs => fs?.title?.toLowerCase().includes("bitcoin"))
                                  .map((sdc) => (
                                    <SellerDetailsCardConvert
                                      darkMode={darkMode}
                                      key={sdc.id}
                                      sdc={sdc}
                                      account={account}
                                      blockchain={blockchain}
                                    />
                                  ))}
                              </>
                            )

                          }
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tabValue} index={1}>
                        <div
                          style={{
                            position: 'fixed',
                            zIndex: 500,
                          }}
                        >

                        </div>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >
                          {sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <ArtCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                ?.filter(fs => fs?.title?.toLowerCase().includes("ethereum"))
                                .map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    blockchain={blockchain}
                                  />
                                ))}
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tabValue} index={2}>
                        <div
                          style={{
                            position: "fixed",
                            zIndex: 500,
                          }}
                        >

                        </div>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >
                          {sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <ArtCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                ?.filter(fs => fs?.title?.toLowerCase().includes("indexx"))
                                .map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    blockchain={blockchain}
                                  />
                                )
                                )}
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tabValue} index={3}>
                        <div
                          style={{
                            position: "fixed",
                            zIndex: 500,
                          }}
                        >

                        </div>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >
                          {sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <ArtCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                ?.filter(fs => fs?.title?.toLowerCase().includes("usd"))
                                ?.map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    blockchain={blockchain}
                                  />
                                )
                                )}
                            </>
                          )}
                        </Grid>
                      </TabPanel>

                      <TabPanel value={tabValue} index={4}>
                        <div
                          style={{
                            position: "fixed",
                            zIndex: 500,
                          }}
                        >

                        </div>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >
                          {sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <ArtCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                .filter(fs => (!fs?.title?.toLowerCase().includes("bitcoin") &&
                                  !fs?.title?.toLowerCase().includes("ethereum") &&
                                  !fs?.title?.toLowerCase().includes("indexx") &&
                                  !fs?.title?.toLowerCase().includes("usd")))
                                ?.map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    blockchain={blockchain}
                                  />
                                )
                                )}
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                    </TabPanel>

                  </Box>
                </Box>
              }
            </> : (account === null || account === undefined || blockchain === '' || blockchain === undefined) ?
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "290px", marginBottom: "250px" }}>
                <Typography variant='h5'>
                  Connect wallet to display NFTs
                </Typography>
              </Box>
              :
              <>
                <Box style={{ marginTop: 60 }}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${darkMode ? "#040404" : "#e3dcdc"
                        } 100%), url(${ProfileCover})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      p: 5,
                      borderRadius: '10px',
                    }}
                  >
                    <Box>

                      <Stack
                        mt={2}
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                      >

                        <Stack
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          gap={1}
                        >
                          <Box
                            bgcolor={darkMode ? '#171c26a1' : '#f2ecec70'}

                            sx={{
                              borderRadius: "100px",
                              height: "120px",
                              width: "120px",
                              display: "flex",
                              justifyContent: "center",
                              verticalAlign: "middle"
                            }}
                          >

                            {image ?
                              <Box
                                sx={{
                                  position: 'relative',
                                  width: '100px', // Adjust the width and height to make it square
                                  height: '100px',
                                }}
                              >
                                <Box sx={{ position: 'absolute', right: '-26%', top: '-5%', marginBottom: "-20px" }}>
                                  <IconButton onClick={() => setImage(null)}>
                                    <Typography component="span" color="secondary">
                                      <TiTimes />
                                    </Typography>
                                  </IconButton>
                                </Box>
                                <Avatar
                                  src={image}
                                  alt="Nothing"
                                  sx={{ width: 90, height: 90, borderRadius: "50px", my: 1.8, mx: 0.6 }}
                                />
                              </Box>
                              :
                              <label htmlFor="icon-button-file-front">
                                <Input
                                  accept="image/*"
                                  id="icon-button-file-front"
                                  type="file"
                                  onChange={handleImageUpload}
                                />
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                  sx={{ m: 4.5 }}
                                >
                                  <Typography component="span" color="secondary" sx={{ fontSize: "26px", verticalAlign: "middle" }}>
                                    <ImImage />
                                  </Typography>
                                </IconButton>
                              </label>
                            }
                          </Box>

                        </Stack>
                        <Stack
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          // gap={1}
                          mt={2}
                        >
                          <Typography color="secondary" variant="h5">
                            {isIndexxWalletWeb ? walletReqAddress : account}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                  <Box
                    bgcolor={darkMode ? '#171C26' : '#EEEEEE'}
                    sx={{
                      borderRadius: "10px",

                      mb: 2,
                    }}
                  >

                    <Tabs
                      textColor='secondary'
                      indicatorColor='secondary'
                      value={mainTab}
                      onChange={handleChangeMainTab}
                      centered
                    >
                      <Tab
                        sx={{
                          color: 'gray',
                          textTransform: 'capitalize',
                          display: 'block',
                          mx: 5,
                          zIndex: "601",
                        }}
                        label={`Created (${sellerData.filter(fs => fs?.title?.toLowerCase().includes("created")).length
                          })`}
                      />
                      <Tab
                        sx={{
                          color: 'gray',
                          textTransform: 'capitalize',
                          display: 'block',
                          mx: 5,
                          zIndex: "601",
                        }}
                        label={`Owned (${sellerData.length
                          })`}
                      />
                    </Tabs>
                  </Box>
                  {mainTab === 1 ?

                    <Box
                      bgcolor={darkMode ? '#171C26' : '#EEEEEE'}
                      sx={{
                        borderRadius: "10px",

                        mb: 4,
                      }}
                    >

                      <Tabs
                        textColor='secondary'
                        indicatorColor='secondary'
                        value={tabValue}
                        onChange={handleChange}
                        centered
                      >
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`Bitcoin (${sellerData.filter(fs => fs?.title?.toLowerCase().includes("bitcoin")).length
                            })`}
                        />
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`Ethereum (${sellerData.filter(
                            (fs) =>
                              fs?.title?.toLowerCase().includes("ethereum")).length
                            })`}
                        />
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`Indexx(${sellerData.filter(
                            (fs) =>
                              fs?.title?.toLowerCase().includes("indexx")).length
                            })`}
                        />
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`USD(${sellerData.filter(
                            (fs) =>
                              fs?.title?.toLowerCase().includes("usd")).length
                            })`}
                        />
                        <Tab
                          sx={{
                            color: 'gray',
                            textTransform: 'capitalize',
                            display: 'block',
                            mx: 5,
                            zIndex: "601",
                          }}
                          label={`Others (${sellerData.filter(
                            (fs) =>
                            (!fs?.title?.toLowerCase().includes("bitcoin") &&
                              !fs?.title?.toLowerCase().includes("ethereum") &&
                              !fs?.title?.toLowerCase().includes("indexx") &&
                              !fs?.title?.toLowerCase().includes("usd")
                            ))?.length
                            })`}
                        />
                      </Tabs>
                    </Box>
                    : null}

                  <Box sx={{ mx: -3, mt: -2 }}>

                    <TabPanel value={mainTab} index={0}>
                      <div
                        style={{
                          position: 'fixed',
                          zIndex: 500,
                        }}
                      >

                      </div>

                      <Grid
                        container
                        spacing={{ xs: 2, md: 6 }}
                        columns={{ xs: 1, sm: 12, md: 12 }}
                      >

                        {
                          sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <CollectionCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                .filter(fs => fs?.title?.toLowerCase().includes("bitcoin"))
                                .map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    blockchain={blockchain}
                                  />
                                ))}
                            </>
                          )

                        }
                      </Grid>
                    </TabPanel>
                    <TabPanel value={mainTab} index={1}>

                      <TabPanel value={tabValue} index={0}>
                        <div
                          style={{
                            position: 'fixed',
                            zIndex: 500,
                          }}
                        >

                        </div>

                        <Grid
                          container
                          spacing={{ xs: 2, md: 6 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >

                          {
                            sellerData.length === 0 ? (
                              [1, 2, 3, 4, 5, 6].map((n) => (
                                <CollectionCardFB darkMode={darkMode} key={n} />
                              ))
                            ) : (
                              <>
                                {sellerData
                                  .filter(fs => fs?.title?.toLowerCase().includes("bitcoin"))
                                  .map((sdc) => (
                                    <SellerDetailsCardConvert
                                      darkMode={darkMode}
                                      key={sdc.id}
                                      sdc={sdc}
                                      account={account}
                                      blockchain={blockchain}
                                    />
                                  ))}
                              </>
                            )

                          }
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tabValue} index={1}>
                        <div
                          style={{
                            position: 'fixed',
                            zIndex: 500,
                          }}
                        >

                        </div>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 8 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >
                          {sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <ArtCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                .filter(fs => fs?.title?.toLowerCase().includes("ethereum"))
                                .map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    blockchain={blockchain}
                                  />
                                ))}
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tabValue} index={2}>
                        <div
                          style={{
                            position: "fixed",
                            zIndex: 500,
                          }}
                        >

                        </div>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 8 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >
                          {sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <ArtCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                .filter(fs => fs?.title?.toLowerCase().includes("indexx"))
                                .map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    blockchain={blockchain}
                                  />
                                )
                                )}
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tabValue} index={3}>
                        <div
                          style={{
                            position: "fixed",
                            zIndex: 500,
                          }}
                        >

                        </div>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 8 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >
                          {sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <ArtCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                ?.filter(fs => fs?.title?.toLowerCase().includes("usd"))
                                ?.map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    blockchain={blockchain}
                                  />
                                )
                                )}
                            </>
                          )}
                        </Grid>
                      </TabPanel>

                      <TabPanel value={tabValue} index={4}>
                        <div
                          style={{
                            position: "fixed",
                            zIndex: 500,
                          }}
                        >

                        </div>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 8 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >
                          {sellerData.length === 0 ? (
                            [1, 2, 3, 4, 5, 6].map((n) => (
                              <ArtCardFB darkMode={darkMode} key={n} />
                            ))
                          ) : (
                            <>
                              {sellerData
                                .filter(fs => (!fs?.title?.toLowerCase().includes("bitcoin") &&
                                  !fs?.title?.toLowerCase().includes("ethereum") &&
                                  !fs?.title?.toLowerCase().includes("indexx") &&
                                  !fs?.title?.toLowerCase().includes("usd")))
                                ?.map((sdc) => (
                                  <SellerDetailsCardConvert
                                    darkMode={darkMode}
                                    key={sdc.id}
                                    sdc={sdc}
                                    account={account}
                                    blockchain={blockchain}
                                  />
                                )
                                )}
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                    </TabPanel>
                  </Box>
                </Box>
              </>}
        </Box>
        :
        <>

          <Box
            sx={{ position: "relative", overflowX: "hidden", mt: -10 }}
            color={darkMode ? "#ffffff" : "#121212"}
          >
            {(isIndexxWalletWeb) ?
              <>
                {sellerData?.length === 0 ? (
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "290px", marginBottom: "250px" }}>
                    <Typography variant='h5'>
                      No NFTs to display
                    </Typography>
                  </Box>
                ) :
                  (<>
                    <Box
                      bgcolor={darkMode ? "#121212" : "#EEEEEE"}
                      sx={{
                        borderRadius: "20px",
                        p: 1,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${darkMode ? "#040404" : "#e3dcdc"
                            } 100%), url(${ProfileCover})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          p: 2,
                          borderRadius: "10px",
                        }}
                      >
                        <Box>
                          <Stack
                            mt={2}
                            direction='column'
                            justifyContent='center'
                            alignItems='center'
                          >

                            <Stack
                              direction='column'
                              justifyContent='center'
                              alignItems='center'
                              gap={1}
                            >
                              <Box
                                bgcolor={darkMode ? '#171c26a1' : '#f2ecec70'}

                                sx={{
                                  borderRadius: "100px",
                                  height: "120px",
                                  width: "120px",
                                  display: "flex",
                                  justifyContent: "center",
                                  verticalAlign: "middle"
                                }}
                              >

                                {image ?
                                  <Box
                                    sx={{
                                      position: 'relative',
                                      width: '100px', // Adjust the width and height to make it square
                                      height: '100px',
                                    }}
                                  >
                                    <Box sx={{ position: 'absolute', right: '-26%', top: '-5%', marginBottom: "-20px" }}>
                                      <IconButton onClick={() => setImage(null)}>
                                        <Typography component="span" color="secondary">
                                          <TiTimes />
                                        </Typography>
                                      </IconButton>
                                    </Box>
                                    <Avatar
                                      src={image}
                                      alt="Nothing"
                                      sx={{ width: 90, height: 90, borderRadius: "50px", my: 1.8, mx: 0.6 }}
                                    />
                                  </Box>
                                  :
                                  <label htmlFor="icon-button-file-front">
                                    <Input
                                      accept="image/*"
                                      id="icon-button-file-front"
                                      type="file"
                                      onChange={handleImageUpload}
                                    />
                                    <IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="span"
                                      sx={{ m: 4.5 }}
                                    >
                                      <Typography component="span" color="secondary" sx={{ fontSize: "26px", verticalAlign: "middle" }}>
                                        <ImImage />
                                      </Typography>
                                    </IconButton>
                                  </label>
                                }
                              </Box>
                            </Stack>
                            <Stack
                              direction='column'
                              justifyContent='center'
                              alignItems='center'
                              gap={1}
                              mt={2}
                            >
                              <Box width={"60%"} sx={{ overflowWrap: "break-word" }} mb={2}>
                                {/* 0x86de830a7ce70qqqqq456363102a9734a7f84538 */}
                                {walletReqAddress}
                                {/* {isIndexxWalletWeb ? walletReqAddress : account} */}
                              </Box>
                            </Stack>
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          borderRadius: "10px",
                          mt: 3,
                          mb: 2,
                        }}
                      >
                        <MobileTabs
                          variant="scrollable"
                          textColor="secondary"
                          indicatorColor="secondary"
                          value={mainTab}
                          onChange={handleChangeMainTab}
                        // centered
                        >
                          <MobileTab
                            sx={{
                              textTransform: "capitalize",
                              display: "block",
                              mr: 2,
                            }}
                            label={`Created (${sellerData?.filter(fs => fs?.title?.toLowerCase().includes("created")).length
                              })`}
                          />
                          <MobileTab
                            sx={{
                              textTransform: "capitalize",
                              display: "block",
                              mr: 2,
                            }}
                            label={`Owned (${sellerData?.length
                              })`}
                          />
                        </MobileTabs>
                      </Box>
                      {mainTab === 1 ?

                        <Box
                          sx={{
                            borderRadius: "10px",
                            mt: 2,
                            mb: 1,
                          }}
                        >
                          <MobileTabs
                            variant="scrollable"
                            textColor="secondary"
                            indicatorColor="secondary"
                            value={tabValue}
                            onChange={handleChange}
                          // centered
                          >
                            <MobileTab
                              sx={{
                                textTransform: "capitalize",
                                display: "block",
                                mr: 2,
                              }}
                              label={`Bitcoin (${sellerData?.filter(fs => fs?.title?.toLowerCase().includes("bitcoin")).length
                                })`}
                            />
                            <MobileTab
                              sx={{
                                textTransform: "capitalize",
                                display: "block",
                                mr: 2,
                              }}
                              label={`Ethereum (${sellerData?.filter(
                                (fs) =>
                                  fs?.title?.toLowerCase().includes("ethereum")).length
                                })`}
                            />
                            <MobileTab
                              sx={{
                                textTransform: "capitalize",
                                display: "block",
                                mr: 2,
                              }}
                              label={`Indexx(${sellerData?.filter(
                                (fs) =>
                                  fs?.title?.toLowerCase().includes("indexx")).length
                                })`}
                            />
                            <MobileTab
                              sx={{
                                textTransform: "capitalize",
                                display: "block",
                                mr: 2,
                              }}
                              label={`USD(${sellerData?.filter(
                                (fs) =>
                                  fs?.title?.toLowerCase().includes("usd")).length
                                })`}
                            />
                            <MobileTab
                              sx={{
                                textTransform: "capitalize",
                                display: "block",
                              }}
                              label={`Others (${sellerData?.filter(
                                (fs) =>
                                (!fs?.title?.toLowerCase().includes("bitcoin") &&
                                  !fs?.title?.toLowerCase().includes("ethereum") &&
                                  !fs?.title?.toLowerCase().includes("indexx") &&
                                  !fs?.title?.toLowerCase().includes("usd")
                                ))?.length
                                })`}
                            />
                          </MobileTabs>
                        </Box>
                        : null}

                      <Box sx={{ mx: -3 }}>
                        {/* <TabPanel value={mainTab} index={0}>
                      <div
                        style={{
                          position: "fixed",
                          zIndex: 500,
                        }}
                      >

                      </div>
                      <Grid
                        container
                        spacing={{ xs: 2, md: 6 }}
                        columns={{ xs: 1, sm: 12, md: 12 }}
                      >
                        {sellerData.length === 0 ? (
                          [1, 2, 3, 4, 5, 6].map((n) => (
                            <ArtCardFB darkMode={darkMode} key={n} />
                          ))
                        ) : (
                          <>
                            {sellerData?.map((fs) =>
                              fs.sellerItems.map((sdc) => (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.id}
                                  sellerCollection={sdc}
                                />
                              ))
                            )}
                          </>
                        )}
                      </Grid>
                    </TabPanel> */}
                        <TabPanel value={mainTab} index={1}>
                          <TabPanel value={tabValue} index={0}>
                            <div
                              style={{
                                position: "fixed",
                                zIndex: 500,
                              }}
                            >

                            </div>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 6 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >
                              {sellerData?.length === 0 ? (
                                [1, 2, 3, 4, 5, 6].map((n) => (
                                  <ArtCardFB darkMode={darkMode} key={n} />
                                ))
                              ) : (
                                <>
                                  {sellerData
                                    ?.filter(fs => fs?.title?.toLowerCase().includes("bitcoin"))
                                    .map((sdc) => (
                                      <SellerDetailsCardConvert
                                        darkMode={darkMode}
                                        key={sdc.id}
                                        sdc={sdc}
                                        account={account}
                                        blockchain={blockchain}
                                      />
                                    ))}
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={tabValue} index={1}>
                            <div
                              style={{
                                position: "fixed",
                                zIndex: 500,
                              }}
                            >

                            </div>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 8 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >
                              {sellerData?.length === 0 ? (
                                [1, 2, 3, 4, 5, 6].map((n) => (
                                  <ArtCardFB darkMode={darkMode} key={n} />
                                ))
                              ) : (
                                <>
                                  {sellerData
                                    ?.filter(fs => fs?.title?.toLowerCase().includes("ethereum"))
                                    .map((sdc) => (
                                      <SellerDetailsCardConvert
                                        darkMode={darkMode}
                                        key={sdc.id}
                                        sdc={sdc}
                                        account={account}
                                        blockchain={blockchain}
                                      />
                                    ))}
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={tabValue} index={2}>
                            <div
                              style={{
                                position: 'fixed',
                                zIndex: 500,
                              }}
                            >

                            </div>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 8 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >
                              {sellerData?.length === 0 ? (
                                [1, 2, 3, 4, 5, 6].map((n) => (
                                  <ArtCardFB darkMode={darkMode} key={n} />
                                ))
                              ) : (
                                <>
                                  {sellerData
                                    ?.filter(fs => fs?.title?.toLowerCase().includes("indexx"))
                                    .map((sdc) => (
                                      <SellerDetailsCardConvert
                                        darkMode={darkMode}
                                        key={sdc.id}
                                        sdc={sdc}
                                        account={account}
                                        blockchain={blockchain}
                                      />
                                    )
                                    )}
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={tabValue} index={3}>
                            <div
                              style={{
                                position: 'fixed',
                                zIndex: 500,
                              }}
                            >

                            </div>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 8 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >
                              {sellerData?.length === 0 ? (
                                [1, 2, 3, 4, 5, 6].map((n) => (
                                  <ArtCardFB darkMode={darkMode} key={n} />
                                ))
                              ) : (
                                <>
                                  {sellerData
                                    ?.filter(fs => fs?.title?.toLowerCase().includes("usd"))
                                    ?.map((sdc) => (
                                      <SellerDetailsCardConvert
                                        darkMode={darkMode}
                                        key={sdc.id}
                                        sdc={sdc}
                                        account={account}
                                        blockchain={blockchain}
                                      />
                                    )
                                    )}
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={tabValue} index={4}>
                            <div
                              style={{
                                position: 'fixed',
                                zIndex: 500,
                              }}
                            >

                            </div>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 8 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >
                              {sellerData?.length === 0 ? (
                                [1, 2, 3, 4, 5, 6].map((n) => <ArtCardFB darkMode={darkMode} key={n} />)
                              ) : (
                                <>
                                  {sellerData
                                    ?.filter(fs => (!fs?.title?.toLowerCase().includes("bitcoin") &&
                                      !fs?.title?.toLowerCase().includes("ethereum") &&
                                      !fs?.title?.toLowerCase().includes("indexx") &&
                                      !fs?.title?.toLowerCase().includes("usd")))
                                    ?.map((sdc) => (
                                      <SellerDetailsCardConvert
                                        darkMode={darkMode}
                                        key={sdc.id}
                                        sdc={sdc}
                                        account={account}
                                        blockchain={blockchain}
                                      />
                                    )
                                    )}
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                        </TabPanel>
                      </Box>
                    </Box>
                  </>)
                }
              </> :
              (
                <>
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "290px", marginBottom: "250px" }}>
                    <Typography variant='h5'>
                      Connect wallet to display NFTs
                    </Typography>
                  </Box>
                </>
              )}
          </Box>


        </>

      }
      <CreateCollection
        open={openModal}
        handleClose={handleCloseModal}
        darkMode={darkMode}
      />
    </Box>
  );
};

export default ConvertXNFT;
