/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { AiFillWallet } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
// Material UI components
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import { TiCancel } from "react-icons/ti";
import ConnectWalletPopUp from "../ConnectWalletPopUp/ConnectWalletPopUp";
import IndexxWalletIcon from '../../assets/Icons/darkUIIcons/indexx_wallet_image.svg';

// Icons

import { AiFillIdcard } from "react-icons/ai";
import {
  BsChevronDown,
  BsChevronUp,
  BsFillBookmarkFill,
  BsFillStarFill,
  BsGearFill,
  BsLightbulbFill,
  BsQuestionOctagonFill,
} from "react-icons/bs";
import { CgLoadbarDoc } from "react-icons/cg";
import { GiBugleCall } from "react-icons/gi";
import { IoIosSwitch } from "react-icons/io";
import { IoLanguage, IoPersonSharp } from "react-icons/io5";
import { MdLock } from "react-icons/md";
import { RiInstagramLine, RiTwitterFill, RiDiscordLine } from "react-icons/ri";
import MetaMaskIcon from "../../assets/Icons/darkUIIcons/metaMaskIcon.svg";
import MetaMaskIconLight from "../../assets/Icons/lightUIIcons/metaMaskIcon.svg";

import LoginIcon from "@mui/icons-material/Login";

import GavelIcon from "@mui/icons-material/Gavel";
import LogoutIcon from "@mui/icons-material/Logout";

// Modules
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import DocumentEditIcon from "../../assets/Icons/darkUIIcons/documentEditIconDark.svg";
import KeyIconDark from "../../assets/Icons/darkUIIcons/keyIconDark.svg";
import DocumentEditIconLight from "../../assets/Icons/lightUIIcons/change-email-icon.svg";
import KeyIconLight from "../../assets/Icons/lightUIIcons/change-pass-icon.svg";

// Logos
import SideFooterLogoDark from "../../assets/footerimages/xmarket_dark.svg";
import SideFooterLogoLight from "../../assets/footerimages/xmarket_white.svg";
import NavUserAvatar from '../../assets/avatar transparent 1.svg';
import useAuth from "../../hooks/useAuth";
// Profile sidemenu content

// Drawer width for mobile ui
const drawerWidth = 240;

const SideDrawer = ({ darkMode, mobileOpen, handleDrawerToggle, handleConnectMetamaskWallet,
  marketplace,
  account,
  blockchain,
  setBlockchain,
  setIndexxWalletEmail,
  indexxWalletEmail,
  indexxWalletObj,
  indexxWalletAddress,
  isIndexxWalletWeb,
  handleConnectIndexxWallet,
  connectwall
}) => {
  const profileSideMenu = [
    // {
    //   id: 1,
    //   name: "SETTINGS_USER_PROFILE",
    //   icon: <IoPersonSharp />,
    //   path: "/profile/user-profile",
    // },
    // {
    //   id: 2,
    //   name: "SETTINGS_KYC",
    //   icon: <AiFillIdcard />,
    //   path: "/profile/kyc",
    // },
    {
      id: 3,
      name: "SETTINGS_LANGUAGE",
      icon: <IoLanguage />,
      path: "/profile/language",
    },
    {
      id: 4,
      name: "SETTINGS_THEME",
      icon: <IoIosSwitch />,
      path: "/profile/theme",
    },
    // {
    //   id: 5,
    //   name: "SETTINGS_CHANGE_PASSWORD",
    //   icon: "",
    //   iconMask: darkMode ? KeyIconDark : KeyIconLight,
    //   path: "/profile/change-password",
    // },
    // {
    //   id: 6,
    //   name: "SETTINGS_CHANGE_EMAIL",
    //   icon: "",
    //   iconMask: !darkMode ? DocumentEditIconLight : DocumentEditIcon,
    //   path: "/profile/change-email",
    // },
    {
      id: 7,
      name: "FOOTER_LINK_FAQS",
      icon: <BsQuestionOctagonFill />,
      path: "/frequently-asked-questions",
    },
    {
      id: 8,
      name: "FOOTER_LINK_PRIVACY_POLICY",
      icon: <MdLock />,
      path: "/privacy-policy",
    },
    {
      id: 9,
      name: "FOOTER_LINK_TERMS_CONDITION",
      icon: <CgLoadbarDoc />,
      path: "/terms-and-condition",
    },
    {
      id: 10,
      name: "FOOTER_LINK_CONTACT_US",
      icon: <GiBugleCall />,
      lightIcon: <GiBugleCall />,
      path: "/contact-us",
    },
  ];
  const { user, handleLogOut } = useAuth();

  // Settings toggler
  const [settingsExpanded, setSettingsExpanded] = React.useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [openModal, setOpenModal] = React.useState(false);

  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleConnectWallet = async () => {
    handleCloseModal();
    const result = window.ethereum;
    // Access properties using dot notation
    const isBlockWallet = result.isBlockWallet;
    const isMetaMask = result.isMetaMask;
    if (typeof window.ethereum !== "undefined") {
      if (isMetaMask) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
      }
    }
  };

  // const handleConnectIndexxWallet = async () => {
  //   handleCloseModal();
  //   console.log(window.ethereum)
  //   const result = window.ethereum;
  //   // Access properties using dot notation
  //   const isBlockWallet = result.isBlockWallet;
  //   const isMetaMask = result.isMetaMask;
  //   console.log(isBlockWallet)
  //   console.log(isMetaMask)
  //   if (typeof window.ethereum !== "undefined") {
  //     if (isBlockWallet) {
  //       const accounts = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //       });
  //       setWalletAddress(accounts[0]);
  //     }
  //   }
  // };


  const handleDisconnectWallet = () => {
    console.log('I am side d  ', 456, isIndexxWalletWeb, indexxWalletAddress)
    if (isIndexxWalletWeb) {
      localStorage.removeItem('isIndexxWebWallet');
      localStorage.removeItem('indexxWebWallets');
      localStorage.removeItem('indexxWebWalletEmail');
      localStorage.removeItem('returnUrl');
      localStorage.removeItem('blockchainFromUrl');
      if(window.location.hostname.includes("localhost")) {
        window.location.reload();
        //added below if we are on page which extracts data from data context for that
        // particular page context will removed on refresh/reload 
        const redirectUrl = new URL(`http://localhost:${window.location.port}`);
        window.open(redirectUrl.toString(), "_self")
      }
      else if (account || isIndexxWalletWeb) {
        // if (walletAddress) {
        window.location.reload();
        //added below if we are on page which extracts data from data context for that
        // particular page context will removed on refresh/reload 
        const redirectUrl = new URL("https://test.xnftmarketplace.indexx.ai");
        window.open(redirectUrl.toString(), "_self")
      }
    }
  };

  const handleLogoClick = () => {
    window.location.href = "https://test.indexx.ai";
  };

  const handleTwitterClick = () => {
    window.location.href = "https://twitter.com/Indexx_ai";
  };

  const handleDiscordClick = () => {
    window.location.href = "https://discord.gg/fjTs6s6u";
  };

  const openAvatarMenu = Boolean(avatarAnchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [walletExpanded, setWalletExpanded] = React.useState(false);
  const handleWalletExpand = () => {
    setWalletExpanded(!walletExpanded);
  };
  const [, setAnchorEl] = React.useState(null);

  const handleOpenAvatarMenu = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const onDisconnect = () => {
    handleDisconnectWallet();
    localStorage.setItem("isWalletConnected", false);
    localStorage.setItem("walletType", "");
    handleCloseMenu();
  };
  const handleClickTrigger = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Settings expand toggler
  const handleSettingsExpand = () => {
    setSettingsExpanded(!settingsExpanded);
  };

  // Click handler
  const handleReRouting = (path) => {
    navigate(path);
    handleDrawerToggle();
  };

  const drawer = (
    <Box
      bgcolor={darkMode ? "#0A0407" : "#EEEEEE"}
      sx={{ overflowX: "hidden", height: "100vh" }}
    >
      {/* App Toolbar */}
      <Toolbar />
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* {user?.email ? ( */}
        <>
          <ListItem
            disablePadding
            sx={{
              display: "flex",
              position: "relative",
              mt: -3,
              mb: 2,
            }}
          >
            <Stack width="100%">
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Avatar
                  onClick={handleOpenAvatarMenu}
                  alt={user?.userName}
                  src={NavUserAvatar}
                  sx={{
                    height: 60,
                    width: 60,
                    mt: 0.7,
                    cursor: "pointer",
                    display: "block",
                  }}
                />
              </Stack>
            </Stack>
          </ListItem>
          {/* <ListItem
              disablePadding
              sx={{
                my: 1,
                display: "flex",
                position: "relative",
                ml: 3,
              }}
            >
              <IconButton
                sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
                onClick={() => handleReRouting("/mybids")}
              >
                <Typography component="span" color="secondary">
                  <GavelIcon />
                </Typography>
                <Typography color="secondary" variant="body2" component="span">
                  {t("ALL_BIDS")}
                </Typography>
              </IconButton>
            </ListItem> */}
          <ListItem
            disablePadding
            sx={{
              my: 1,
              display: "flex",
              position: "relative",
              ml: 3,
            }}
          >
            <IconButton
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                pl: 2,
              }}
              onClick={handleWalletExpand}
            >
              <Typography color="secondary" component="span">
                {walletAddress ? (
                  darkMode ? (
                    <img
                      onClick={handleClickTrigger}
                      style={{ width: "20px", height: "20px" }}
                      src={MetaMaskIcon}
                      alt="Meta Mask Icon"
                    />
                  ) : (
                    <img
                      onClick={handleClickTrigger}
                      style={{ width: "20px", height: "20px" }}
                      src={MetaMaskIconLight}
                      alt="Meta Mask Icon"
                    />
                  )
                ) : isIndexxWalletWeb ? <>
                </> : (
                  <Typography
                    onClick={handleOpenModal}
                    color="secondary"
                    component="span"
                  >
                    <FaWallet />
                  </Typography>
                )}
              </Typography>
              <Typography color="secondary" variant="body2" component="span">
                {isIndexxWalletWeb ? (
                  <>
                    <Typography
                      color="secondary"
                      variant="body2"
                      component="span"
                      onClick={() => handleWalletExpand()}
                      sx={{ display: "flex", alignItems: "center", gap: 2 }}
                    >
                      <img
                        style={{ width: '20px', height: '25px' }}
                        src={IndexxWalletIcon}
                        alt="Meta Mask Icon"
                      />

                      {walletExpanded ? (
                        <MenuItem
                          style={{
                            width: "200px",
                            backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                            // color: `${darkMode ? "#ffffff" : "#040404"}`,
                            gap: 15,
                            borderRadius: "10px",
                            marginBottom: "10px",
                          }}
                          onClick={handleDisconnectWallet}
                        >
                          <Typography component="span" color="secondary">
                            <TiCancel />
                          </Typography>
                          <Typography color="secondary">{t("DISCONNECT")}</Typography>
                        </MenuItem>
                      ) : (
                        <Typography
                          component="span"
                          color="secondary"
                          sx={{
                            mt: 1,
                          }}
                        >
                          {indexxWalletAddress.slice(0, 10)}
                          <BsChevronDown />
                        </Typography>
                      )}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    color="secondary"
                    variant="body2"
                    component="span"
                    onClick={handleOpenModal}
                    ref={connectwall}
                  >
                    {t("NAV_CONNECT_WALLET")}
                  </Typography>
                )}
              </Typography>
            </IconButton>
          </ListItem>
          {walletExpanded && walletAddress && (
            <>
              {
                <List>
                  <ListItem
                    disablePadding
                    sx={{
                      ml: 9,
                    }}
                  >
                    <IconButton
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                      }}
                    >
                      <Typography component="span" color="secondary">
                        <AiFillWallet />
                      </Typography>

                      <Typography
                        variant="body2"
                        color="secondary"
                        sx={{ fontSize: "11px" }}
                      >
                        {indexxWalletAddress.slice(0, 10)}
                      </Typography>
                    </IconButton>
                  </ListItem>
                  <ListItem
                    onClick={() => navigate("/user/profile")}
                    disablePadding
                    sx={{
                      ml: 9,
                    }}
                  >
                    <IconButton
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                      }}
                    >
                      <Typography component="span" color="secondary">
                        <AiFillWallet />
                      </Typography>

                      <Typography
                        variant="body2"
                        color="secondary"
                        sx={{ fontSize: "11px" }}
                      >
                        {t("MY_PROFILE")}
                      </Typography>
                    </IconButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      ml: 9,
                    }}
                  >
                    <IconButton
                      onClick={onDisconnect}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                      }}
                    >
                      <Typography component="span" color="secondary">
                        <TiCancel />
                      </Typography>

                      <Typography
                        variant="body2"
                        color="secondary"
                        sx={{ fontSize: "11px" }}
                      >
                        {t("DISCONNECT")}
                      </Typography>
                    </IconButton>
                  </ListItem>
                </List>
              }
            </>
          )}
        </>
        {/* ) : (
          <ListItem
            disablePadding
            sx={{
              my: 1,
              display: "flex",
              position: "relative",
              ml: 2,
            }}
          >
            <IconButton
              sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
              onClick={() => handleReRouting("/authentication/sign-in")}
            >
              <Typography component="span" color="secondary">
                <LoginIcon />
              </Typography>
              <Typography color="secondary" variant="body2" component="span">
                {t("SIGNUP_LOGIN")}
              </Typography>
            </IconButton>
          </ListItem>
        )} */}

        <ConnectWalletPopUp
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          darkMode={darkMode}
          handleConnectWallet={handleConnectWallet}
          handleConnectIndexxWallet={handleConnectIndexxWallet}
          handleDisconnectWallet={handleDisconnectWallet}
          walletAddress={walletAddress}
        />
        <ListItem
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          {location.pathname ===
            `/trending-sellers${location.pathname.slice(17)}` ? (
            <div
              style={{
                background:"#0294FE",
                borderRadius: "4px",
                width: "100%",
                height: "50px",
                position: "absolute",
              }}
            ></div>
          ) : null}
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
            onClick={() => handleReRouting("/trending-sellers")}
          >
            {location.pathname === "/trending-sellers" ? (
              <Typography component="span" color="#ffffff">
                <BsFillStarFill />
              </Typography>
            ) : (
              <Typography component="span" color="secondary">
                <BsFillStarFill />
              </Typography>
            )}

            <Typography color="secondary" variant="body2" component="span">
              {t("TRENDING_SELLERS")}
            </Typography>
          </IconButton>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          {location.pathname === "/trending-creators" ? (
            <div
              style={{
                background:"#0294FE",
                borderRadius: "4px",
                width: "100%",
                height: "50px",
                position: "absolute",
              }}
            ></div>
          ) : null}
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
            onClick={() => handleReRouting("/trending-creators")}
          >
            {location.pathname === "/trending-creators" ? (
              <Typography component="span" color="#ffffff">
                <BsLightbulbFill />
              </Typography>
            ) : (
              <Typography component="span" color="secondary">
                <BsLightbulbFill />
              </Typography>
            )}
            <Typography color="secondary" variant="body2" component="span">
              {t("TRENDING_CREATORS")}
            </Typography>
          </IconButton>
        </ListItem>
        {/* Favourites */}
        {/* <ListItem
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          {location.pathname === "/favourites" ? (
            <div
              style={{
                background:
                  "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)",
                borderRadius: "4px",
                width: "100%",
                height: "50px",
                position: "absolute",
              }}
            ></div>
          ) : null}
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
            onClick={() => handleReRouting("/favourites?type=nft-bookmark")}
          >
            {location.pathname === "/favourites" ? (
              <Typography component="span" color="#ffffff">
                <BsFillBookmarkFill />
              </Typography>
            ) : (
              <Typography component="span" color="secondary">
                <BsFillBookmarkFill />
              </Typography>
            )}
            <Typography color="secondary" variant="body2" component="span">
              {t("FAVOURITES")}
            </Typography>
          </IconButton>
        </ListItem> */}
        <ListItem
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          {location.pathname === "/user/profile" ? (
            <div
              style={{
                background:"#0294FE",
                borderRadius: "4px",
                width: "100%",
                height: "50px",
                position: "absolute",
              }}
            ></div>
          ) : null}
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
            onClick={() => handleReRouting("/user/profile")}
          >
            {location.pathname === "/user/profile" ? (
              <Typography component="span" color="#ffffff">
                <IoPersonSharp />
              </Typography>
            ) : (
              <Typography component="span" color="secondary">
                <IoPersonSharp />
              </Typography>
            )}
            <Typography color="secondary" variant="body2" component="span">
              {t("MY_PROFILE")}
            </Typography>
          </IconButton>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            my: 1,
            display: "flex",
            position: "relative",
            ml: 3,
          }}
        >
          {location.pathname === `/profile${location.pathname?.slice(8)}` ? (
            <div
              style={{
                background:"#0294FE",
                borderRadius: "4px",
                width: "100%",
                height: "50px",
                position: "absolute",
              }}
            ></div>
          ) : null}
          <IconButton
            sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
            onClick={handleSettingsExpand}
          >
            {location.pathname === `/profile${location.pathname?.slice(8)}` ? (
              <Typography component="span" color="#ffffff">
                <BsGearFill />
              </Typography>
            ) : (
              <Typography component="span" color="secondary">
                <BsGearFill />
              </Typography>
            )}
            <Typography
              color="secondary"
              variant="body2"
              component="span"
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
            >
              {t("SETTINGS")}
              {settingsExpanded ? (
                <Typography component="span" color="secondary">
                  <BsChevronDown />
                </Typography>
              ) : (
                <Typography component="span" color="secondary">
                  <BsChevronUp />
                </Typography>
              )}
            </Typography>
          </IconButton>
        </ListItem>
        {settingsExpanded && (
          <>
            {profileSideMenu.map((pfs) => (
              <ListItem
                disablePadding
                sx={{
                  ml: 9,
                  mt: 2,
                }}
                key={pfs.id}
              >
                {location.pathname === pfs.path ? (
                  <div
                    style={{
                      background:"#0294FE",
                      borderRadius: "4px",
                      width: "100%",
                      height: "50px",
                      position: "absolute",
                    }}
                  ></div>
                ) : null}
                {pfs.icon ? (
                  <IconButton
                    onClick={() => handleReRouting(`${pfs.path}`)}
                    sx={{ display: "flex", alignItems: "center", gap: 3 }}
                  >
                    {location.pathname === pfs.path ? (
                      <Typography component="span" color="#ffffff">
                        {pfs.icon}
                      </Typography>
                    ) : (
                      <Typography component="span" color="secondary">
                        {pfs.icon}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      color="secondary"
                      sx={{ fontSize: "11px" }}
                    >
                      {t(`${pfs.name}`)}
                    </Typography>
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleReRouting(`${pfs.path}`)}
                    sx={{ display: "flex", alignItems: "center", gap: 3 }}
                  >
                    <img src={pfs.iconMask} alt={pfs.name} />
                    <Typography
                      variant="body2"
                      color="secondary"
                      sx={{ fontSize: "11px" }}
                    >
                      {t(`${pfs.name}`)}
                    </Typography>
                  </IconButton>
                )}
              </ListItem>
            ))}
          </>
        )}
        {/* {user?.email && (
          <ListItem
            disablePadding
            sx={{
              my: 1,
              display: "flex",
              position: "relative",
              ml: 3,
            }}
          >
            <IconButton
              sx={{ display: "flex", alignItems: "center", gap: 2, pl: 2 }}
              onClick={() => {
                handleLogOut(navigate);
              }}
            >
              <Typography component="span" color="secondary">
                <LogoutIcon />
              </Typography>
              <Typography color="secondary" variant="body2" component="span">
                {t("LOGOUT")}
              </Typography>
            </IconButton>
          </ListItem>
        )} */}
      </List>
      <Box sx={{ px: 2, mt: 17 }}>
        <>
          {darkMode ? (
            <img src={SideFooterLogoDark} alt="Side Footer Main Minto Logo" width={"100px"} onClick={handleLogoClick} />
          ) : (
            <img src={SideFooterLogoLight} alt="Side Footer Main Minto Logo" width={"100px"} onClick={handleLogoClick} />
          )}

          <Typography
            sx={{ color: "#D71869", my: 1 }}
            variant="subtitle2"
            component="h6"
          >
            {t("FOOTER_TITLE_MAIN")}
          </Typography>
          <Typography sx={{ color: "gray" }} variant="caption" component="p">
            {t("FOOTER_DETAILS")}
          </Typography>
        </>
        <IconButton sx={{ ml: -0.8, mt: 2 }} onClick={handleTwitterClick}>
          <Typography component="span" color="secondary">
            <RiTwitterFill />
          </Typography>
        </IconButton>
        <IconButton sx={{ mt: 2 }} onClick={handleDiscordClick}>
          <Typography component="span" color="secondary">
            <RiDiscordLine />
          </Typography>
        </IconButton>
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "gray", textAlign: "left", fontSize: "7px", mt: 1 }}
        >
          &copy; 2023 {t("FOOTER_COPYRIGHT")}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {/* The Drawer Component */}
      <Drawer
        anchor="right"
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          zIndex: 200000,
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default SideDrawer;
