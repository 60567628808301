import React from 'react';
import { Box } from '@mui/system';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideShadow from '../../assets/sideNavigationShadow.svg';
import AuthStatic from './AuthStatic';

// Logo
import MainLogo from '../../assets/mainLogo.png';
import MainLogoLight from '../../assets/mainLogoLight.png';

// styles
import styles from './AuthStyles.module.css';
import { useTranslation } from 'react-i18next';
const AuthInterface = ({ darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {!isMobile ? (
        <Box sx={{ width: '1200px', margin: '0 auto' }}>
          <Box
            bgcolor={darkMode ? '#040404' : '#ffffff'}
            color={darkMode ? '#ffffff' : '#121212'}
          >
            <Box  position={'relative'}>
          
              <Box>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  height='100vh'
                  gap={5}
                >
                  <Box width={'50%'}>
                    <AuthStatic darkMode={darkMode} />
                  </Box>
                  <Box
                    zIndex={10}
                    minHeight='100vh'
                    sx={{ overflowY: 'scroll' }}
                    width={'50%'}
                    className={styles.mainBoxStyle}
                  >
                    <Outlet />
                  </Box>
                </Stack>
              </Box>
         
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            py={3}
            bgcolor={darkMode ? '#040404' : '#ffffff'}
            color={darkMode ? '#ffffff' : '#121212'}
          >
            <Box className={styles.parentBox} position={'relative'}>
          
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
              >
                <img
                  src={darkMode ? MainLogo : MainLogoLight}
                  alt='Main Logo'
                  style={{ display: 'block', height: '90px', width: '90px' }}
                />
                <Typography variant='subtitle1' fontWeight={300} mt={2}>
                  {t('NFT_MARKETPLACE')}
                </Typography>
              </Box>
              <Box zIndex={10000} sx={{ overflowY: 'scroll' }}>
                <Outlet />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default AuthInterface;
