import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  TextField,
  Button,
  Grow,
  IconButton,
  Tabs,
  Tab,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";

import styles from "./SingleArtWork.module.css";
import { FiChevronLeft } from "react-icons/fi";
import whale from "../../assets/Wales-04 1 2.svg";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import {
  GradientButtonBlue,
} from "../../Utils/GradientButtons/GradientButtons";
import { getRedeemValue } from "../../service/api";
import RedeemView from "./RedeemView.js";

import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";
import SwalNotification from "../../components/Notification/SwalNotification";

// Tabpanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Redeem = ({ darkMode, account, blockchain, handleConnectIndexxWallet, indexxWalletEmail, indexxWalletObj, indexxWalletAddress, isIndexxWalletWeb }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigationRef = useRef(null);

  const [redcode, setRedcode] = useState("");
  const [redcodetoken, setRedcodetoken] = useState("");

  const [tabValue, setTabValue] = useState(0); // setting tab value for changing

  const [isvalidredeem, setIsvalidRedeem] = useState(false);
  const [isValidtoken, setIsValidtoken] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [selectedCategory, setSelectedCategory] = useState("");
  const [voucherData, setVoucherData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const [number, setNumber] = useState("");

  const handleChangeNumber = (event) => {
    const inputNumber = event.target.value;
    if (inputNumber >= 1 && inputNumber <= 100) {
      setNumber(inputNumber);
    }
  };

  // Parse the query parameters from the URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    console.log(handleConnectIndexxWallet);
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        console.log('I am here')
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        console.log('else if am here')
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  const validateRedeem = async () => {
    let res;
    try {
      console.log(redcode);
      res = await getRedeemValue(redcode);
      console.log("res:", res);
      console.log("res:", res?.data.data);
      if (res?.data?.status === 200) {
        console.log('I am here');
        setVoucherData(res.data.data);
        setIsvalidRedeem(!isvalidredeem);
      } else {
        SwalNotification(darkMode, "Invalid Voucher", res.error.error);
        setIsvalidRedeem(isvalidredeem);
      }
    } catch (err) {
      setIsvalidRedeem(isvalidredeem);
      // alert("Invalid Voucher")
      SwalNotification(darkMode, "Invalid Voucher", res.error.error);
    }
  }

  const fullPageOverlayStyles = {
    display: 'flex', // Using flexbox
    justifyContent: 'center', // Center items horizontally
    alignItems: 'center', // Center items vertically
    position: 'fixed', // Fixed position
    top: 0, // From top edge
    left: 0, // From left edge
    height: '100vh', // Full height
    width: '100vw', // Full width
    zIndex: 500000, // Overlay should be on top of all other elements
    backgroundColor: 'rgba(0,0,0,0.5)' // Semi-transparent
  };

  return (

    <>
      {/* {isLoading && (<div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >

        {darkMode ? (
          <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
        ) : (
          <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
        )}
      </div>)
      } */}
      {isLoading && (
        <div style={fullPageOverlayStyles}>
          {darkMode ? (
            <Stack direction={"column"}>
              <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
              <Typography
                variant="h6"
                component="p"
                sx={{ cursor: "pointer", textAlign: "center" }}
                color={`${darkMode ? "#ffffff" : "#121212"} !important`}
              >
                Processing Transaction ...
              </Typography>
            </Stack>
          ) : (
            <Stack>
              <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
              <Typography
                variant="h6"
                component="p"
                sx={{ cursor: "pointer", textAlign: "center" }}
                color={`${darkMode ? "#ffffff" : "#121212"} !important`}
              >
                Processing Transaction ...
              </Typography>
            </Stack>
          )}
        </div>
      )}

      <Box
        color={darkMode ? "#ffffff" : "#121212"}
        sx={{
          position: "relative",
        }}
      // mt={!isMobile ? 11.5 : 2}
      >
        <Box sx={!isMobile ? { mt: 11.5 } : { mt: 1 }}>
          {!isMobile ? (
            <Box className={styles.topNavigationBox}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} />
              </Box>
              <Typography className={styles.topNavigationTypo} component="div">
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ cursor: "pointer" }}
                  zIndex={2}
                  color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                  onClick={() => window.history.back()}
                >
                  Redeem
                </Typography>
              </Typography>
            </Box>
          ) : null}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              my: !isMobile ? 2 : 3,
              flexDirection: "row",
            }}
          >
            <Box
              // width={isMobile ? "100%" : "60%"}
              width="100%"
            >
              <Typography
                variant="span"
                fontWeight={300}
                fontSize={"30.2px"}
                color="secondary"
                ml={3}
              >
                Redeem Gift Card
              </Typography>
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "space-between",
                  flexDirection: `${isMobile ? "column" : "row"}`,
                }}
              >
                <Tabs
                  orientation={isMobile ? "vertical" : "horizontal"}
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                  centered
                  sx={{
                    width: `${isMobile ? "85%" : "100%"}`,
                    display: "flex",
                    gap: "1",
                    p: 3,
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                    "& .MuiTabs-flexContainer": {
                      justifyContent: "space-between",
                    },
                    "& .MuiTab-root": {
                      color: "blue",
                      "&.Mui-selected": {
                        outline: "none",
                        backgroundColor: "#0294FE",
                      },
                      "&:not(.Mui-selected)": {
                        outline: "1px solid #0294FE",
                        outlineOffset: "-1px",
                      },
                    },
                  }}
                >
                  <Tab
                    sx={{
                      color: "secondary",
                      textTransform: "capitalize",
                      display: "flex",
                      zIndex: "601",
                      width: `${isMobile ? "100%" : "524px"}`,
                      maxWidth: `${isMobile ? "100%" : "540px"}`,
                      // width: "100%",
                      fontSize: "18px",
                    }}
                    label={`Redeem XNFT`}
                  />
                  <Tab
                    sx={{
                      color: "secondary",
                      textTransform: "capitalize",
                      display: "flex",
                      zIndex: "601",
                      width: `${isMobile ? "100%" : "524px"}`,
                      maxWidth: `${isMobile ? "100%" : "540px"}`,
                      // width: "100%",
                      fontSize: "18px",
                    }}
                    disabled
                    label={`Redeem Indexx Tokens`}
                  />
                </Tabs>
              </Box>
              <Box>
                <TabPanel value={tabValue} index={0}>
                  {isvalidredeem === false ? (
                    <Box>
                      <Typography
                        variant="span"
                        fontWeight={300}
                        fontSize={"20px"}
                        color="secondary"
                      >
                        Gift Card Redemption Code
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Redemption Code"
                        variant="outlined"
                        color="secondary"
                        placeholder="Enter Gift Card redemption code"
                        value={redcode}
                        sx={{ my: 3, width: `${isMobile ? "100%" : "100%"}` }}
                        onChange={(e) => {
                          setRedcode(e.target.value);
                        }}
                      />
                      <Typography
                        variant="span"
                        fontWeight={200}
                        fontSize={"12px"}
                        color="secondary"
                      >
                        The redemption code consists of 16 characters comprising
                        both letters and digits. For instance, it may look like
                        this: A1BC23D4EFG78H56.
                        <br />
                        <br />
                      </Typography>
                      <Typography
                        variant="span"
                        fontWeight={200}
                        fontSize={"12px"}
                        color="secondary"
                      >
                        Binance disclaims any responsibility and liability for any
                        illegal activities or fraudulent actions carried out by
                        any third party linked to a Gift Card, and this applies to
                        you as well.
                      </Typography>
                      <GradientButtonBlue
                        variant="contained"
                        disableElevation
                        disabled={redcode.trim().length === 0}
                        onClick={() =>
                          validateRedeem()
                        }
                        sx={{
                          fontSize: "16px",
                          textTransform: "none",
                          width: "100%",
                          padding: "10px",
                          my: 3,
                        }}
                      >
                        <Typography
                          variant="p"
                          fontWeight={300}
                          color="secondary"
                        >
                          Redeem
                        </Typography>
                      </GradientButtonBlue>
                    </Box>
                  ) : (
                    // <Box>
                    //   <Typography
                    //     variant="span"
                    //     fontWeight={200}
                    //     fontSize={"12px"}
                    //     color="secondary"
                    //   >
                    //     The redemption code consists of 16 characters comprising
                    //     both letters and digits. For instance, it may look like
                    //     this: A1BC23D4EFG78H56.
                    //     <br />
                    //     <br />
                    //   </Typography>
                    //   <Typography
                    //     variant="span"
                    //     fontWeight={200}
                    //     fontSize={"12px"}
                    //     color="secondary"
                    //   >
                    //     Binance disclaims any responsibility and liability for any
                    //     illegal activities or fraudulent actions carried out by
                    //     any third party linked to a Gift Card, and this applies to
                    //     you as well.
                    //   </Typography>
                    //   <Stack
                    //     direction={"row"}
                    //     justifyContent={"space-between"}
                    //     mt={3}
                    //   >
                    //     <Select
                    //       label="Select XNFT"
                    //       value={selectedCategory}
                    //       onChange={handleCategoryChange}
                    //       variant="outlined"
                    //       width="100%"
                    //       sx={{ minWidth: "45%" }}
                    //     >
                    //       {/* <MenuItem value="">Select XNFT</MenuItem> */}
                    //       <MenuItem value="category1">Value1</MenuItem>
                    //       <MenuItem value="category2">Value2</MenuItem>
                    //       <MenuItem value="category3">Value3</MenuItem>
                    //     </Select>

                    //     <TextField
                    //       type="number"
                    //       label="Number of XNFTs"
                    //       variant="outlined"
                    //       inputProps={{
                    //         min: 1,
                    //         max: 100,
                    //         step: 1,
                    //       }}
                    //       value={number}
                    //       onChange={handleChangeNumber}
                    //       sx={{
                    //         minWidth: "40%",
                    //       }}
                    //     />
                    //   </Stack>
                    //   <Stack
                    //     direction={"row"}
                    //     justifyContent={"space-between"}
                    //     mt={3}
                    //   >
                    //     <Typography
                    //       variant="span"
                    //       fontWeight={300}
                    //       fontSize={"20px"}
                    //       color="secondary"
                    //       sx={{
                    //         py: 1,
                    //         my:1
                    //       }}
                    //     >
                    //       Remaining Balance
                    //     </Typography>
                    //     <Typography
                    //       variant="span"
                    //       fontWeight={300}
                    //       fontSize={"20px"}
                    //       color="secondary"
                    //       width={"37%"}
                    //       sx={{
                    //         backgroundColor: `${darkMode ? "#171C26" : "#eeeeee"}`,
                    //         textAlign: "center",
                    //         p: 1,
                    //         my:"auto"
                    //       }}
                    //     >
                    //       20 INEX
                    //     </Typography>
                    //   </Stack>
                    //   <GradientButtonBlue
                    //     variant="contained"
                    //     disableElevation
                    //     // disabled={redcode.trim().length === 0}
                    //     // onClick={saveDetails}
                    //     sx={{
                    //       fontSize: "16px",
                    //       textTransform: "none",
                    //       width: "100%",
                    //       padding: "10px",
                    //       my: 3,
                    //     }}
                    //   >
                    //     <Typography
                    //       variant="p"
                    //       fontWeight={300}
                    //       color="secondary"
                    //     >
                    //       Complete Transaction
                    //     </Typography>
                    //   </GradientButtonBlue>
                    // </Box>
                    <>
                      <RedeemView data={voucherData} darkMode={darkMode} account={account}
                        blockchain={blockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail}
                        indexxWalletObj={indexxWalletObj} indexxWalletAddress={indexxWalletAddress} isIndexxWalletWeb={isIndexxWalletWeb} setIsLoading={setIsLoading}></RedeemView>
                    </>
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  {isValidtoken === false ? (
                    <Box>
                      <Typography
                        variant="span"
                        fontWeight={300}
                        fontSize={"20px"}
                        color="secondary"
                      >
                        Gift Card Redemption Code
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Redemption Code"
                        variant="outlined"
                        color="secondary"
                        placeholder="Enter Gift Card redemption code"
                        value={redcodetoken}
                        sx={{ my: 3, width: `${isMobile ? "100%" : "100%"}` }}
                        onChange={(e) => {
                          setRedcodetoken(e.target.value);
                        }}
                      // InputProps={{
                      // style: { fontSize: 50, fontWeight: 500 },
                      // }}
                      />
                      <Typography
                        variant="span"
                        fontWeight={200}
                        fontSize={"12px"}
                        color="secondary"
                      >
                        The redemption code consists of 16 characters comprising
                        both letters and digits. For instance, it may look like
                        this: A1BC23D4EFG78H56.
                        <br />
                        <br />
                      </Typography>
                      <Typography
                        variant="span"
                        fontWeight={200}
                        fontSize={"12px"}
                        color="secondary"
                      >
                        Binance disclaims any responsibility and liability for any
                        illegal activities or fraudulent actions carried out by any
                        third party linked to a Gift Card, and this applies to you
                        as well.
                      </Typography>
                      <GradientButtonBlue
                        variant="contained"
                        disableElevation
                        disabled={redcode.trim().length === 0}
                        onClick={() => {
                          setIsValidtoken(!isValidtoken);
                        }}
                        sx={{
                          fontSize: "16px",
                          textTransform: "none",
                          width: "100%",
                          padding: "10px",
                          my: 3,
                        }}
                      >
                        <Typography variant="p" fontWeight={300} color="secondary">
                          Redeem
                        </Typography>
                      </GradientButtonBlue>
                    </Box>
                  ) : (
                    <Box>
                      <Typography
                        variant="span"
                        fontWeight={200}
                        fontSize={"12px"}
                        color="secondary"
                      >
                        The redemption code consists of 16 characters comprising
                        both letters and digits. For instance, it may look like
                        this: A1BC23D4EFG78H56.
                        <br />
                        <br />
                      </Typography>
                      <Typography
                        variant="span"
                        fontWeight={200}
                        fontSize={"12px"}
                        color="secondary"
                      >
                        Binance disclaims any responsibility and liability for any
                        illegal activities or fraudulent actions carried out by
                        any third party linked to a Gift Card, and this applies to
                        you as well.
                      </Typography>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        mt={3}
                      >
                        <Typography
                          variant="span"
                          fontWeight={300}
                          fontSize={"20px"}
                          color="secondary"
                          sx={{
                            py: 1,
                            my: 1
                          }}
                        >
                          Available Balance
                        </Typography>
                        <Typography
                          variant="span"
                          fontWeight={300}
                          fontSize={"20px"}
                          color="secondary"
                          width={"37%"}
                          sx={{
                            backgroundColor: `${darkMode ? "#171C26" : "#eeeeee"}`,
                            textAlign: "center",
                            p: 1,
                            my: "auto"
                          }}
                        >
                          20 INEX
                        </Typography>
                      </Stack>
                      <GradientButtonBlue
                        variant="contained"
                        disableElevation
                        // disabled={redcode.trim().length === 0}
                        // onClick={saveDetails}
                        sx={{
                          fontSize: "16px",
                          textTransform: "none",
                          width: "100%",
                          padding: "10px",
                          my: 3,
                        }}
                      >
                        <Typography
                          variant="p"
                          fontWeight={300}
                          color="secondary"
                        >
                          Complete Transaction
                        </Typography>
                      </GradientButtonBlue>
                    </Box>
                  )}
                </TabPanel>
              </Box>
            </Box>
            {/* {!isMobile ? (
              <Box width={"35%"} mt={12}>
                <img
                  src={whale}
                  alt="redeem" */}
            {/* // width={isMobile ? "100%" : "75%"} */}
            {/* // width={"75%"} */}
            {/* height={"auto"}
                />
              </Box>
            ) : null} */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Redeem;
