import React, { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Avatar,
  Tabs,
  Tab,
  Grid,
  useMediaQuery,
  Stack,
  Button,
} from "@mui/material";
import { useParams } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import axios from "axios";
import SellerDetailsCard from "../../components/SellerDetailsCard/SellerDetailsCard";
import ArtCardFB from "../../components/Skeletons/ArtCardFB";
import CollectionCardFB from "../../components/Skeletons/CollectionCardFB";
import AddIcon from "@mui/icons-material/Add";
import ProfileCover from "../../assets/userProfileCover.png";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import CreateCollection from "../../components/CreateCollection/CreateCollection";
import CollectionCard from "../Collections/CollectionCard/CollectionCard";
import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";
import { getAllNFTS } from '../../service/nftService';
import DataContext from '../Collections/DataContext';
import { Network } from "alchemy-sdk";

const ethers = require('ethers');


// Tabpanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Profile = ({ darkMode, account, blockchain, handleConnectIndexxWallet }) => {
  const { t } = useTranslation();
  const { setData } = useContext(DataContext);
  const [sellerData, setSellerData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [tabValue, setTabValue] = useState(0); // setting tab value for changing
  const [mainTab, setMainTab] = useState(0); // setting tab value for changing
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [, setLoaderMessage] = useState("");
  const [sendData, setSendData] = useState();
  const { id } = useParams();

  const isContract = async (address) => {
    const network = blockchain === "Ethereum" ? Network.ETH_SEPOLIA : Network.MATIC_MUMBAI;
    const apiKey = blockchain === "Ethereum" ? "2DsflcbXsCueCqyNzHCOoMWEQgmgax6v" : "0ht_aGipiqE8ADgcLaegCWlIKXhv8W0m";
    const provider = new ethers.providers.AlchemyProvider(network, apiKey)
    return ((await provider.getCode(address)) !== "0x")
  }

  useEffect(() => {
    const fetchNft = async () => {
      if (id === null || id === undefined || blockchain === '' || blockchain === undefined) {
        console.log('no wallet')
      } else {
        const res = await getAllNFTS(id, blockchain);
        setIsLoading(true);
        setLoaderMessage("Loading...");
        const sellerCardData = res;
        setSellerData(sellerCardData);
        setIsLoading(false);
      }
    }
    fetchNft();
  }, [account, blockchain]);


  // Parse the query parameters from the URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    console.log(handleConnectIndexxWallet);
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        console.log('I am here')
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        console.log('else if am here')
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  // Tab handler
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeMainTab = (event, newValue) => {
    setMainTab(newValue);
  };

  const handleNavigate = () => {
    // //const largeData = sellerCollection;
    // console.log('clicked', largeData)

    // setData(largeData);
    // setSendData(largeData)
    // navigate(`${sellerCollection?.name}/${id}`)
    // setIsClicked(true)
  };

  // Modal
  const handleOpenModal = () => {
    console.log('I AM HERE')
    setOpenModal(true);
  }
  const handleCloseModal = () => setOpenModal(false);

  // This will be deleted when the API endpoint added
  const filteredSeller = sellerData;

  const MobileTabs = styled(Tabs)({
    border: 'none',
    backgroundColor: `${darkMode ? '#040404' : '#ffffff'}`,
    '& .MuiTabs-indicator': {
      backgroundColor: 'inherit',
    },
  });

  const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    color: `${darkMode ? '#ffffff' : '#040404'}`,
    backgroundColor: `${darkMode ? '#171c26' : '#EEEEEE'}`,
    borderRadius: '4px',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#0294FE',
      borderRadius: '4px',
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));

  const creatorImage = "";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <Box
      sx={{
        mt: 11,
      }}
    >
      {isLoading && (<div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {darkMode ? (
          <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
        ) : (
          <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
        )}
      </div>)
      }
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                }`,
            }}
          >
            {"View User"}
          </Typography>
        </Box>
      )}
      <Box
        style={{
          position: 'fixed',
          height: '100vh',
        }}
      >

      </Box>
      {!isMobile ?
        <Box zIndex={100} color={darkMode ? '#ffffff' : '#121212'}>
          <Box
            sx={{
              backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${darkMode ? "#040404" : "#e3dcdc"
                } 100%), url(${ProfileCover})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              p: 5,
              borderRadius: '10px',
            }}
          >
            <Box>

              <Stack
                mt={2}
                direction='column'
                justifyContent='center'
                alignItems='center'
              >

                <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  gap={1}
                >
                  <Avatar
                    src={creatorImage}
                    alt="Nothing"
                    sx={{ width: 120, height: 120, marginBottom: -10 }}
                  />
                </Stack>
                <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  gap={1}
                  mt={12}
                >
                  <Typography color="secondary" variant="h5">
                    {id}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Box>
          {/* {(account === null || account === undefined || blockchain === '' || blockchain === undefined) ?
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop:"290px", marginBottom:"250px"}}>
              <Typography variant='h5'>
              Connect wallet to display NFTs
              </Typography>
            </Box>
            : */}
          <>
            <Box>
              <Box
                bgcolor={darkMode ? '#171C26' : '#EEEEEE'}
                sx={{
                  borderRadius: "10px",
                }}
              >

                <Tabs
                  textColor='secondary'
                  indicatorColor='secondary'
                  value={mainTab}
                  onChange={handleChangeMainTab}
                  centered
                >
                  <Tab
                    sx={{
                      color: 'gray',
                      textTransform: 'capitalize',
                      display: 'block',
                      mx: 5,
                      zIndex: "601",
                    }}
                    label={`Created (${filteredSeller.filter(fs => fs?.title?.toLowerCase().includes("bitcoin")).length
                      })`}
                  />
                  <Tab
                    sx={{
                      color: 'gray',
                      textTransform: 'capitalize',
                      display: 'block',
                      mx: 5,
                      zIndex: "601",
                    }}
                    label={`Owned (${filteredSeller.length
                      })`}
                  />
                </Tabs>
              </Box>
              {mainTab === 1 ?
                <Box
                  bgcolor={darkMode ? '#171C26' : '#EEEEEE'}
                  sx={{
                    borderRadius: "10px",

                    mt: 2,
                  }}
                >

                  <Tabs
                    textColor='secondary'
                    indicatorColor='secondary'
                    value={tabValue}
                    onChange={handleChange}
                    centered
                  >
                    <Tab
                      sx={{
                        color: 'gray',
                        textTransform: 'capitalize',
                        display: 'block',
                        mx: 5,
                        zIndex: "601",
                      }}
                      label={`Bitcoin (${filteredSeller.filter(fs => fs?.title?.toLowerCase().includes("bitcoin")).length
                        })`}
                    />
                    <Tab
                      sx={{
                        color: 'gray',
                        textTransform: 'capitalize',
                        display: 'block',
                        mx: 5,
                        zIndex: "601",
                      }}
                      label={`Ethereum (${filteredSeller.filter(
                        (fs) =>
                          fs?.title?.toLowerCase().includes("ethereum")).length
                        })`}
                    />
                    <Tab
                      sx={{
                        color: 'gray',
                        textTransform: 'capitalize',
                        display: 'block',
                        mx: 5,
                        zIndex: "601",
                      }}
                      label={`Indexx(${filteredSeller.filter(
                        (fs) =>
                          fs?.title?.toLowerCase().includes("indexx")).length
                        })`}
                    />
                    <Tab
                      sx={{
                        color: 'gray',
                        textTransform: 'capitalize',
                        display: 'block',
                        mx: 5,
                        zIndex: "601",
                      }}
                      label={`USD(${filteredSeller.filter(
                        (fs) =>
                          fs?.title?.toLowerCase().includes("usd")).length
                        })`}
                    />
                    <Tab
                      sx={{
                        color: 'gray',
                        textTransform: 'capitalize',
                        display: 'block',
                        mx: 5,
                        zIndex: "601",
                      }}
                      label={`Others (${filteredSeller.filter(
                        (fs) =>
                        (!fs?.title?.toLowerCase().includes("bitcoin") &&
                          !fs?.title?.toLowerCase().includes("ethereum") &&
                          !fs?.title?.toLowerCase().includes("indexx") &&
                          !fs?.title?.toLowerCase().includes("usd")
                        ))?.length
                        })`}
                    />
                  </Tabs>
                </Box>
                : null}

              <Box sx={{ mx: -3, mt: 4 }}>
                <TabPanel value={mainTab} index={0}>
                  <div
                    style={{
                      position: 'fixed',
                      zIndex: 500,
                    }}
                  >

                  </div>

                  <Grid
                    container
                    spacing={{ xs: 2, md: 6 }}
                    columns={{ xs: 1, sm: 12, md: 12 }}
                  >

                    {
                      filteredSeller.length === 0 ? (
                        [1, 2, 3, 4, 5, 6].map((n) => (
                          <CollectionCardFB darkMode={darkMode} key={n} />
                        ))
                      ) : (
                        <>
                          {filteredSeller
                            .filter(fs => fs?.title?.toLowerCase().includes("bitcoin"))
                            .map((sdc) => (
                              <SellerDetailsCard
                                darkMode={darkMode}
                                key={sdc.id}
                                sdc={sdc}
                                account={account}
                                blockchain={blockchain}
                              />
                            ))}
                        </>
                      )

                    }
                  </Grid>
                </TabPanel>
                <TabPanel value={mainTab} index={1}>
                  <TabPanel value={tabValue} index={0}>
                    <div
                      style={{
                        position: 'fixed',
                        zIndex: 500,
                      }}
                    >

                    </div>

                    <Grid
                      container
                      spacing={{ xs: 2, md: 8 }}
                      columns={{ xs: 1, sm: 12, md: 12 }}
                    >

                      {
                        filteredSeller.length === 0 ? (
                          [1, 2, 3, 4, 5, 6].map((n) => (
                            <ArtCardFB darkMode={darkMode} key={n} />
                          ))
                        ) : (
                          <>
                            {filteredSeller
                              .filter(fs => fs?.title?.toLowerCase().includes("bitcoin"))
                              .map((sdc) => (
                                <SellerDetailsCard
                                  darkMode={darkMode}
                                  key={sdc.id}
                                  sdc={sdc}
                                  account={account}
                                  blockchain={blockchain}
                                />
                              ))}
                          </>
                        )

                      }
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <div
                      style={{
                        position: 'fixed',
                        zIndex: 500,
                      }}
                    >

                    </div>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 8 }}
                      columns={{ xs: 1, sm: 12, md: 12 }}
                    >
                      {filteredSeller.length === 0 ? (
                        [1, 2, 3, 4, 5, 6].map((n) => (
                          <ArtCardFB darkMode={darkMode} key={n} />
                        ))
                      ) : (
                        <>
                          {filteredSeller
                            .filter(fs => fs?.title?.toLowerCase().includes("ethereum"))
                            .map((sdc) => (
                              <SellerDetailsCard
                                darkMode={darkMode}
                                key={sdc.id}
                                sdc={sdc}
                                account={account}
                                blockchain={blockchain}
                              />
                            ))}
                        </>
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <div
                      style={{
                        position: "fixed",
                        zIndex: 500,
                      }}
                    >

                    </div>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 8 }}
                      columns={{ xs: 1, sm: 12, md: 12 }}
                    >
                      {filteredSeller.length === 0 ? (
                        [1, 2, 3, 4, 5, 6].map((n) => (
                          <ArtCardFB darkMode={darkMode} key={n} />
                        ))
                      ) : (
                        <>
                          {filteredSeller
                            .filter(fs => fs?.title?.toLowerCase().includes("indexx"))
                            .map((sdc) => (
                              <SellerDetailsCard
                                darkMode={darkMode}
                                key={sdc.id}
                                sdc={sdc}
                                account={account}
                                blockchain={blockchain}
                              />
                            )
                            )}
                        </>
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <div
                      style={{
                        position: "fixed",
                        zIndex: 500,
                      }}
                    >

                    </div>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 8 }}
                      columns={{ xs: 1, sm: 12, md: 12 }}
                    >
                      {filteredSeller.length === 0 ? (
                        [1, 2, 3, 4, 5, 6].map((n) => (
                          <ArtCardFB darkMode={darkMode} key={n} />
                        ))
                      ) : (
                        <>
                          {filteredSeller
                            ?.filter(fs => fs?.title?.toLowerCase().includes("usd"))
                            ?.map((sdc) => (
                              <SellerDetailsCard
                                darkMode={darkMode}
                                key={sdc.id}
                                sdc={sdc}
                                account={account}
                                blockchain={blockchain}
                              />
                            )
                            )}
                        </>
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    <div
                      style={{
                        position: "fixed",
                        zIndex: 500,
                      }}
                    >

                    </div>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 8 }}
                      columns={{ xs: 1, sm: 12, md: 12 }}
                    >
                      {filteredSeller.length === 0 ? (
                        [1, 2, 3, 4, 5, 6].map((n) => (
                          <ArtCardFB darkMode={darkMode} key={n} />
                        ))
                      ) : (
                        <>
                          {filteredSeller
                            .filter(fs => (!fs?.title?.toLowerCase().includes("bitcoin") &&
                              !fs?.title?.toLowerCase().includes("ethereum") &&
                              !fs?.title?.toLowerCase().includes("indexx") &&
                              !fs?.title?.toLowerCase().includes("usd")))
                            ?.map((sdc) => (
                              <SellerDetailsCard
                                darkMode={darkMode}
                                key={sdc.id}
                                sdc={sdc}
                                account={account}
                                blockchain={blockchain}
                              />
                            )
                            )}
                        </>
                      )}
                    </Grid>
                  </TabPanel>
                </TabPanel>
              </Box>
            </Box>
          </>
          {/* } */}
        </Box>
        :
        <Box
          sx={{ position: "relative", overflowX: "hidden", mt: -10 }}
          color={darkMode ? "#ffffff" : "#121212"}
        >
          <Box
            bgcolor={darkMode ? "#121212" : "#EEEEEE"}
            sx={{
              borderRadius: "20px",
              // p: 1,
            }}
          >
            <Box
              sx={{
                backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${darkMode ? "#040404" : "#e3dcdc"
                  } 100%), url(${ProfileCover})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                p: 2,
                borderRadius: "10px",
              }}
            >
              <Box>
                <Stack
                  mt={1}
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                >

                  <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    gap={1}
                  >
                    <Avatar
                      src={creatorImage}
                      alt="Nothing"
                      sx={{ width: 90, height: 90 }}
                    />
                  </Stack>
                  <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    gap={1}
                    mt={2}
                  >
                    <Box width={"60%"} sx={{ overflowWrap: "break-word" }} mb={2}>
                      {id}
                    </Box>
                  </Stack>
                </Stack>
              </Box>

            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                borderRadius: "10px",
                mt: 1,
                mb: 3,
              }}
            >
              <MobileTabs
                variant="scrollable"
                textColor="secondary"
                indicatorColor="secondary"
                value={mainTab}
                onChange={handleChangeMainTab}
              // centered
              >
                <MobileTab
                  sx={{
                    textTransform: "capitalize",
                    display: "block",
                    mr: 2,
                  }}
                  label={`Created (${filteredSeller.filter(fs => fs?.title?.toLowerCase().includes("bitcoin")).length
                    })`}
                />
                <MobileTab
                  sx={{
                    textTransform: "capitalize",
                    display: "block",
                    // mr: 2,
                  }}
                  label={`Owned (${filteredSeller.length
                    })`}
                />
              </MobileTabs>
            </Box>
            {mainTab === 1 ?
              <Box
                sx={{
                  borderRadius: "10px",
                  mt: 3,
                  mb: 1,
                }}
              >
                <MobileTabs
                  variant="scrollable"
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                // centered
                >
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={`Bitcoin (${filteredSeller.filter(fs => fs?.title?.toLowerCase().includes("bitcoin")).length
                      })`}
                  />
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={`Ethereum (${filteredSeller.filter(
                      (fs) =>
                        fs?.title?.toLowerCase().includes("ethereum")).length
                      })`}
                  />
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={`Indexx(${filteredSeller.filter(
                      (fs) =>
                        fs?.title?.toLowerCase().includes("indexx")).length
                      })`}
                  />
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={`USD(${filteredSeller.filter(
                      (fs) =>
                        fs?.title?.toLowerCase().includes("usd")).length
                      })`}
                  />
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                    }}
                    label={`Others (${filteredSeller.filter(
                      (fs) =>
                      (!fs?.title?.toLowerCase().includes("bitcoin") &&
                        !fs?.title?.toLowerCase().includes("ethereum") &&
                        !fs?.title?.toLowerCase().includes("indexx") &&
                        !fs?.title?.toLowerCase().includes("usd")
                      ))?.length
                      })`}
                  />
                </MobileTabs>
              </Box>
              : null}
            <Box sx={{ mx: -3 }}>

              <TabPanel value={mainTab} index={0}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >

                </div>
                <Grid
                  container
                  spacing={{ xs: 2, md: 6 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <CollectionCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {filteredSeller
                        .filter(fs => fs?.title?.toLowerCase().includes("bitcoin"))
                        .map((sdc) => (
                          <SellerDetailsCard
                            darkMode={darkMode}
                            key={sdc.id}
                            sdc={sdc}
                            account={account}
                            blockchain={blockchain}
                          />
                        ))}
                    </>
                  )}
                </Grid>
              </TabPanel>

              <TabPanel value={mainTab} index={1}>

                <TabPanel value={tabValue} index={0}>
                  <div
                    style={{
                      position: "fixed",
                      zIndex: 500,
                    }}
                  >

                  </div>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 6 }}
                    columns={{ xs: 1, sm: 12, md: 12 }}
                  >
                    {filteredSeller.length === 0 ? (
                      [1, 2, 3, 4, 5, 6].map((n) => (
                        <ArtCardFB darkMode={darkMode} key={n} />
                      ))
                    ) : (
                      <>
                        {filteredSeller
                          .filter(fs => fs?.title?.toLowerCase().includes("bitcoin"))
                          .map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                              account={account}
                              blockchain={blockchain}
                            />
                          ))}
                      </>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <div
                    style={{
                      position: "fixed",
                      zIndex: 500,
                    }}
                  >

                  </div>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 8 }}
                    columns={{ xs: 1, sm: 12, md: 12 }}
                  >
                    {filteredSeller.length === 0 ? (
                      [1, 2, 3, 4, 5, 6].map((n) => (
                        <ArtCardFB darkMode={darkMode} key={n} />
                      ))
                    ) : (
                      <>
                        {filteredSeller
                          .filter(fs => fs?.title?.toLowerCase().includes("ethereum"))
                          .map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                              account={account}
                              blockchain={blockchain}
                            />
                          ))}
                      </>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <div
                    style={{
                      position: 'fixed',
                      zIndex: 500,
                    }}
                  >

                  </div>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 8 }}
                    columns={{ xs: 1, sm: 12, md: 12 }}
                  >
                    {filteredSeller.length === 0 ? (
                      [1, 2, 3, 4, 5, 6].map((n) => (
                        <ArtCardFB darkMode={darkMode} key={n} />
                      ))
                    ) : (
                      <>
                        {filteredSeller
                          .filter(fs => fs?.title?.toLowerCase().includes("indexx"))
                          .map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                              account={account}
                              blockchain={blockchain}
                            />
                          )
                          )}
                      </>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <div
                    style={{
                      position: 'fixed',
                      zIndex: 500,
                    }}
                  >

                  </div>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 8 }}
                    columns={{ xs: 1, sm: 12, md: 12 }}
                  >
                    {filteredSeller.length === 0 ? (
                      [1, 2, 3, 4, 5, 6].map((n) => (
                        <ArtCardFB darkMode={darkMode} key={n} />
                      ))
                    ) : (
                      <>
                        {filteredSeller
                          ?.filter(fs => fs?.title?.toLowerCase().includes("usd"))
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                              account={account}
                              blockchain={blockchain}
                            />
                          )
                          )}
                      </>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                  <div
                    style={{
                      position: 'fixed',
                      zIndex: 500,
                    }}
                  >

                  </div>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 8 }}
                    columns={{ xs: 1, sm: 12, md: 12 }}
                  >
                    {filteredSeller.length === 0 ? (
                      [1, 2, 3, 4, 5, 6].map((n) => <ArtCardFB darkMode={darkMode} key={n} />)
                    ) : (
                      <>
                        {filteredSeller
                          .filter(fs => (!fs?.title?.toLowerCase().includes("bitcoin") &&
                            !fs?.title?.toLowerCase().includes("ethereum") &&
                            !fs?.title?.toLowerCase().includes("indexx") &&
                            !fs?.title?.toLowerCase().includes("usd")))
                          ?.map((sdc) => (
                            <SellerDetailsCard
                              darkMode={darkMode}
                              key={sdc.id}
                              sdc={sdc}
                              account={account}
                              blockchain={blockchain}
                            />
                          )
                          )}
                      </>
                    )}
                  </Grid>
                </TabPanel>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      }
      <CreateCollection
        open={openModal}
        handleClose={handleCloseModal}
        darkMode={darkMode}
      />
    </Box>
  );
};

export default Profile;
