import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Button,
  Grow,
  IconButton,
  TextField,
  FormControl,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { TiTimes } from "react-icons/ti";
import styles from "./SingleArtWork.module.css";
import { FiChevronLeft } from "react-icons/fi";

import { GradientButtonPrimary, GradientButtonBlue } from "../../Utils/GradientButtons/GradientButtons";


const Addbrand = ({ darkMode }) => {
  const { t } = useTranslation();
  const [brandName, setBrandName] = useState("");
  const [brandInfo, setBrandInfo] = useState(
    "XYZ Company is a dynamic and innovative organization that specializes in providing cutting-edge solutions and services to meet the evolving needs of its clients. With a strong focus on customer satisfaction, XYZ Company strives to deliver exceptional results and exceed expectations. "
  );

  const [whower, setWhower] = useState(
    "As a company, Indexx recognizes the transformative potential of cryptocurrencies. It offers a robust platform that allows users to store, trade, and interact with various cryptocurrencies. From well-established cryptocurrencies like Bitcoin and Ethereum to emerging tokens, Indexx provides a user-friendly interface for seamless transactions and portfolio management."
  );

  const [Mission, setMission] = useState(
    "At indexx.ai, our mission is to revolutionize the crypto industry by providing cutting-edge solutions that empower individuals and businesses to harness the full potential of decentralized technologies. We are committed to creating innovative tools, services, and platforms that simplify and enhance the crypto experience, making it accessible, secure, and efficient for all. Through our dedication to research, development, and collaboration, we strive to drive widespread adoption, foster trust, and shape the future of the crypto ecosystem, ultimately empowering individuals to take control of their financial destinies in a decentralized world."
  );
  const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [brands, setbrands] = useState([]);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     axios.get("/brandData.json").then((res) => {
  //       setbrands(res.data);
  //     });
  //   }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const saveDetails = () => {
    //code for posting data
    navigate(`/brands`);
  };

  const handleCreateAsset = () => {
    //code for posting data
    navigate(`/create-asset`);
  };

  const inputStyle = {
    "& .MuiInputBase-input": {
      //   fontSize: '1.5rem',
      //   fontWeight: 'bold',
      fontFamily: "'Poppins', sans-serif",
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
  };

  const handleImageUpload = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleLogoUpload = (e) => {
    setLogo(URL.createObjectURL(e.target.files[0]));
  };

  const Input = styled("input")({
    display: "none",
  });

  return (
    <Box
      color={darkMode ? "#ffffff" : "#121212"}
      sx={{
        position: "relative",
      }}
    >
      <Box sx={!isMobile ? { mt: 11.5 } : { mt: 3 }}>
        <Box className={styles.topNavigationBox}>
          <Box sx={{ cursor: "pointer" }} onClick={() => window.history.back()}>
            <FiChevronLeft fontSize={"1.5rem"} />
          </Box>
          <Typography className={styles.topNavigationTypo} component="div">
            <Typography
              variant="h6"
              component="p"
              sx={{ cursor: "pointer" }}
              zIndex={2}
              color={`${darkMode ? "#ffffff" : "#121212"} !important`}
              onClick={() => window.history.back()}
            >
              Create Brand
            </Typography>
          </Typography>
        </Box>
        {/* {!isMobile ? (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              gap: 4,
              alignItems: "center",
            }}
          >
            <Typography
              component="div"
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ cursor: "pointer" }}
                variant="subtitle1"
                      component="span"
                      fontWeight={500}
              >
                Add Company Brand
              </Typography>
        
            </Typography>
           
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "fixed",
                top: "3%",
                zIndex: "10000",
                width: "75%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box pb={2} ml={6}>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  component="div"
                  sx={{
                    borderBottom: `${
                      darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                    }`,
                  }}
                >
                </Typography>
              </Box>
            </Box>
          </Box>
        )} */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // maxWidth: `${isMobile ? "100%" : "75%" }`,
            width: "100%",
            objectFit: "scale-down",
            // maxHeight: "auto"
            my: 12,
            flexDirection: "col",
          }}
        >
          <Box width={!isMobile ? "50%" : "100%"} color={darkMode ? "#ffffff" : "#121212"}>
            {/* <Typography variant="h2" fontWeight={500} color="secondary" > */}
            {/* Company name */}

            <Box
              sx={{
                display: "flex",
                justifyContent: `${isMobile ? "center" : "left" }`,
                alignItems: `${isMobile ? "center" : "left" }`,
                // alignItems: "center",
                // maxWidth: `${isMobile ? "100%" : "75%" }`,
                width: "100%",
                objectFit: "scale-down",
                // maxHeight: "auto"
                flexDirection: "column",
              }}
            >
              <Box
                // width={"15%"}
                // width={isMobile ? "26%" : "15%"}
                width={"86px"}
                // bgcolor={darkMode ? "#121212" : "#ffffff"}
                // color={darkMode ? "#ffffff" : "#121212"}
              >
                {logo ? (
                  <>
                    {/* <img src={image} alt="brand image" sx={{width:"100%", height:"100%", objectFit: 'contain' }} /> */}
                    <Box
                      sx={{ position: "absolute", left: "8%", top: "5.5%" }}
                    >
                      <IconButton onClick={() => setLogo(null)}>
                        <Typography component="span" color="secondary">
                          <TiTimes />
                        </Typography>
                      </IconButton>
                    </Box>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain", // Ensure the image stays within the boundaries
                        padding: "5px",
                      }}
                      src={logo}
                      alt="Uploaded"
                    />
                  </>
                ) : (
                  <Box
                    // bgcolor={darkMode ? "#121212" : "#f7faf8"}
                    //   width={"50%"}
                    //   bgcolor={darkMode ? "#121212" : "#ffffff"}
                    //   color={darkMode ? "#ffffff" : "#121212"}
                    sx={{
                      display: "flex",
                      // gap: 1,
                      height: "70px",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      border: "1px dashed",
                      borderRadius: "2px",
                    }}
                  >
                    <label htmlFor="icon-button-file-front">
                      <Input
                        accept="image/*"
                        id="icon-button-file-front"
                        type="file"
                        onChange={handleLogoUpload}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="p"
                      >
                        <Typography
                          component="p"
                          color="secondary"
                          variant="p"
                          sx={{ fontSize: "x-small", padding: "0" }}
                        >
                          <AddIcon />
                        </Typography>
                      </IconButton>
                    </label>
                    <Typography
                      component="span"
                      color="secondary"
                      variant="p"
                      sx={{ fontSize: "x-small" }}
                    >
                      Add logo
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box my={3}>
                {/* <TextField
                  id="outlined-basic"
                  label="Company Name"
                  // placeholder="Company Name"
                  value={brandName}
                  onChange={(e) => {
                    setBrandName(e.target.value);
                  }}
                  variant="outlined"
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    // fontSize: "xxx-large",
                  }}
                  InputProps={{
                    // disableUnderline: true,
                    style: { fontSize: 50, fontWeight: 500 },
                  }} 
                /> */}
                <TextField
                  id="outlined-basic"
                  label="Company Name"
                  variant="outlined"
                  color="secondary"
                  placeholder="Company Name"
                  value={brandName}
                  sx={{ mb: 2, width:`${isMobile ? "100%" : "90%" }` }}
                  onChange={(e) => {
                    setBrandName(e.target.value);
                  }}
                  InputProps={{
                    style: { fontSize: 50, fontWeight: 500 },
                  }}
                />
              </Box>
              <Box my={3} width={!isMobile ? "90%" : "100%"}>
                <TextField
                  id="outlined-basic"
                  label="Brand Info"
                  variant="outlined"
                  color="secondary"
                  value={brandInfo}
                  onChange={(e) => {
                    setBrandInfo(e.target.value);
                  }}
                  // fontWeight={500}
                  // fontSize="large"
                  multiline
                  sx={{
                    // color: `${darkMode ? "#ffffff" : "#040404"}`,
                    // backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    width:"100%"
                  }}
                  // InputProps={{ disableUnderline: true, }} // Remove the outlines
                />
              </Box>
            </Box>
            {/* </Typography> */}
          </Box>
          {!isMobile ? (
          <Box
            width={"50%"}
            // bgcolor={darkMode ? "#121212" : "#ffffff"}
            color={darkMode ? "#ffffff" : "#121212"}
          >
            {image ? (
              <>
                {/* <img src={image} alt="brand image" sx={{width:"100%", height:"100%", objectFit: 'contain' }} /> */}
                <Box sx={{ position: "absolute", right: "4%", top: "4%" }}>
                  <IconButton onClick={() => setImage(null)}>
                    <Typography component="span" color="secondary">
                      <TiTimes />
                    </Typography>
                  </IconButton>
                </Box>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain", // Ensure the image stays within the boundaries
                    padding: "5px",
                  }}
                  src={image}
                  alt="Uploaded"
                />
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  height: "490px",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  border: "1px dashed",
                  borderRadius: "20px",
                }}
              >
                <label htmlFor="icon-button-file-front">
                  <Input
                    accept="image/*"
                    id="icon-button-file-front"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <Typography component="span" color="secondary">
                      <AddIcon />
                    </Typography>
                  </IconButton>
                </label>
                <Typography component="span" color="secondary">
                  Add photo
                </Typography>
              </Box>
            )}
          </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // maxWidth: `${isMobile ? "100%" : "75%" }`,
            width: "100%",
            objectFit: "scale-down",
            // maxHeight: "auto"
            alignContent: "center",
            alignItems: "center",
            my: 15,
            flexDirection: "col",
          }}
        >
          <Box width={!isMobile ? "75%" : "100%"} color={darkMode ? "#ffffff" : "#121212"}>
            {/* <Typography variant="h2" fontWeight={500} color="secondary" > */}
            {/* Company name */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                // maxWidth: `${isMobile ? "100%" : "75%" }`,
                width: "100%",
                objectFit: "scale-down",
                // maxHeight: "auto"
                flexDirection: "column",
              }}
            >
              <Box
                width={"100%"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box 
                // width={"12%"} 
                // width={isMobile ? "26%" : "12%"}
                width={"86px"}
                >
                  {logo ? (
                    <>
                      {/* <img src={image} alt="brand image" sx={{width:"100%", height:"100%", objectFit: 'contain' }} /> */}
                      <Box
                        sx={{ position: "absolute", right: "4%", top: "4%" }}
                      >
                        {/* <IconButton onClick={() => setLogo(null)}>
                          <Typography component="span" color="secondary">
                            <TiTimes />
                          </Typography>
                        </IconButton> */}
                      </Box>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain", // Ensure the image stays within the boundaries
                          padding: "5px",
                        }}
                        src={logo}
                        alt="Uploaded"
                      />
                    </>
                  ) : (
                    <Box
                      // bgcolor={darkMode ? "#121212" : "#f7faf8"}
                      //   width={"50%"}
                      //   bgcolor={darkMode ? "#121212" : "#ffffff"}
                      //   color={darkMode ? "#ffffff" : "#121212"}
                      sx={{
                        display: "flex",
                        // gap: 1,
                        height: "80px",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        border: "1px dashed",
                        borderRadius: "2px",
                      }}
                    >
                      <label htmlFor="icon-button-file-front">
                        <Input
                          accept="image/*"
                          id="icon-button-file-front"
                          type="file"
                          onChange={handleLogoUpload}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="p"
                        >
                          <Typography
                            component="p"
                            color="secondary"
                            variant="p"
                            sx={{ fontSize: "x-small", padding: "0" }}
                          >
                            <AddIcon />
                          </Typography>
                        </IconButton>
                      </label>
                      <Typography
                        component="span"
                        color="secondary"
                        variant="p"
                        sx={{ fontSize: "x-small" }}
                      >
                        Add logo
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                my={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {/* <TextField
                  id="standard-basic"
                  // label="Brand Name"
                  value={brandName}
                  onChange={(e) => {
                    setBrandName(e.target.value);
                  }}
                  variant="standard"
                  // fontWeight={500}
                  // fontSize="50"
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    fontSize: "xxx-large",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: 50, fontWeight: 500 },
                  }} // Remove the outlines
                /> */}

                <Typography variant={isMobile ? "h3" : "h2"} fontWeight={400} color="secondary">
                  Who we are?
                </Typography>
              </Box>
              <Box
                my={1}
                sx={{
                  display: "flex",
                  textAlign: "center",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Who we are"
                  placeholder="Who we are"
                  value={whower}
                  onChange={(e) => {
                    setWhower(e.target.value);
                  }}
                  variant="outlined"
                  // fontSize="large"
                  multiline
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    width: "100%",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontWeight: "100" },
                    inputProps: { style: { textAlign: "center" } },
                  }} // Remove the outlines
                />
              </Box>
            </Box>
            {/* </Typography> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // maxWidth: `${isMobile ? "100%" : "75%" }`,
            width: "100%",
            objectFit: "scale-down",
            // maxHeight: "auto"
            alignContent: "center",
            alignItems: "center",
            my: 12,
            flexDirection: "col",
          }}
        >
          <Box width={!isMobile ? "75%" : "100%"} color={darkMode ? "#ffffff" : "#121212"}>
            {/* <Typography variant="h2" fontWeight={500} color="secondary" > */}
            {/* Company name */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                // maxWidth: `${isMobile ? "100%" : "75%" }`,
                width: "100%",
                objectFit: "scale-down",
                // maxHeight: "auto"
                flexDirection: "column",
              }}
            >
              <Box
                width={"100%"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box 
                // width={"12%"}
                // width={isMobile ? "26%" : "12%"}
                width={"86px"}
                >
                  {logo ? (
                    <>
                      {/* <img src={image} alt="brand image" sx={{width:"100%", height:"100%", objectFit: 'contain' }} /> */}
                      <Box
                        sx={{ position: "absolute", right: "4%", top: "4%" }}
                      >
                        {/* <IconButton onClick={() => setLogo(null)}>
                          <Typography component="span" color="secondary">
                            <TiTimes />
                          </Typography>
                        </IconButton> */}
                      </Box>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain", // Ensure the image stays within the boundaries
                          padding: "5px",
                        }}
                        src={logo}
                        alt="Uploaded"
                      />
                    </>
                  ) : (
                    <Box
                      // bgcolor={darkMode ? "#121212" : "#f7faf8"}
                      //   width={"50%"}
                      //   bgcolor={darkMode ? "#121212" : "#ffffff"}
                      //   color={darkMode ? "#ffffff" : "#121212"}
                      sx={{
                        display: "flex",
                        // gap: 1,
                        height: "80px",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        border: "1px dashed",
                        borderRadius: "2px",
                      }}
                    >
                      <label htmlFor="icon-button-file-front">
                        <Input
                          accept="image/*"
                          id="icon-button-file-front"
                          type="file"
                          onChange={handleLogoUpload}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="p"
                        >
                          <Typography
                            component="p"
                            color="secondary"
                            variant="p"
                            sx={{ fontSize: "x-small", padding: "0" }}
                          >
                            <AddIcon />
                          </Typography>
                        </IconButton>
                      </label>
                      <Typography
                        component="span"
                        color="secondary"
                        variant="p"
                        sx={{ fontSize: "x-small" }}
                      >
                        Add logo
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                my={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {/* <TextField
                  id="standard-basic"
                  // label="Brand Name"
                  value={brandName}
                  onChange={(e) => {
                    setBrandName(e.target.value);
                  }}
                  variant="standard"
                  // fontWeight={500}
                  // fontSize="50"
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    fontSize: "xxx-large",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: 50, fontWeight: 500 },
                  }} // Remove the outlines
                /> */}

                <Typography variant={isMobile ? "h3" : "h2"} fontWeight={400} color="secondary">
                  Our Mission
                </Typography>
              </Box>
              <Box
                my={1}
                sx={{
                  display: "flex",
                  textAlign: "center",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Our mission"
                  placeholder="Our mission"
                  value={Mission}
                  onChange={(e) => {
                    setMission(e.target.value);
                  }}
                  variant="outlined"
                  // fontSize="large"
                  multiline
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    width: "100%",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontWeight: "100" },
                    inputProps: { style: { textAlign: "center" } },
                  }} // Remove the outlines
                />
              </Box>
            </Box>
            {/* </Typography> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // maxWidth: `${isMobile ? "100%" : "75%" }`,
            width: "100%",
            objectFit: "scale-down",
            // maxHeight: "auto"
            alignContent: "center",
            alignItems: "center",
            my: 12,
            flexDirection: "col",
          }}
        >
          <Box width={!isMobile ? "75%" : "100%"} color={darkMode ? "#ffffff" : "#121212"}>
            {/* <Typography variant="h2" fontWeight={500} color="secondary" > */}
            {/* Company name */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                // maxWidth: `${isMobile ? "100%" : "75%" }`,
                width: "100%",
                objectFit: "scale-down",
                // maxHeight: "auto"
                flexDirection: "column",
              }}
            >
              <Box
                my={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Typography variant={isMobile ? "h3" : "h3"} fontWeight={400} color="secondary">
                  Our Products
                </Typography>
              </Box>
              {/* <Box
                // bgcolor={darkMode ? "#121212" : "#ffffff"}
                onClick={handleCreateAsset}
                sx={{
                  p: 2,
                  cursor: "pointer",
                }}
              > */}
              <Box
                color={darkMode ? "#ffffff" : "#121212"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                }}
              >
                <Box
                  bgcolor={darkMode ? "#171C26" : "#ffffff"}
                  sx={{
                    // width:`${isMobile?"60%":"28%"}`,
                    width:"200px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    height: "180px",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: "5px",
                  }}
                  mt={3}
                  onClick={handleCreateAsset}
                >
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <Typography component="div" color="secondary">
                      <AddIcon />
                    </Typography>
                  </IconButton>
                  <Typography component="div" color="secondary">
                    Create Asset
                  </Typography>
                </Box>
              </Box>
              <Box
                my={8}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <GradientButtonBlue
                  variant="contained"
                  disableElevation
                  onClick={saveDetails}
                  sx={{
                    fontSize: "18px",
                    textTransform: "none",
                    width: "300px",
                    padding: "10px",
                  }}
                  // sx={{bgcolor:"#9E00FF"}}
                >
                  <Typography variant="p" fontWeight={500} color="secondary">
                    Save
                  </Typography>
                </GradientButtonBlue>
              </Box>
              {/* </Box> */}
            </Box>
            {/* </Typography> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Addbrand;
