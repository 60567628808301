import React from "react";
import Swal from "sweetalert2";

function SwalNotification(darkMode, message, text) {
  return Swal.fire({
    title: message,
    text: text,
    // icon: icon,
    imageUrl : "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/info.png",
    color : darkMode ? "#fff" : "#000",
    background: darkMode ? "#171C26" : "#fff",
    confirmButtonColor : "#0294FE",
    customClass: {
      confirmButton: 'swal2-confirm',
    },
  });
}

export default SwalNotification;