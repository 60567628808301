import {
  Button, Stack, Typography,
  Zoom,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import { MdAddToPhotos, MdGavel } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiPackage } from 'react-icons/fi';
import { StyledMenu, StyledMenu2 } from '../StyledMenu/StyledMenu';
import bino from '../../assets/headerimages/bino.png';
import col from '../../assets/headerimages/col.png';
import brandicon from "../../assets/headerimages/brand.svg"
import mallet from '../../assets/headerimages/auction.png';
import swap from '../../assets/headerimages/swap.svg';
import ex from '../../assets/headerimages/ex.svg';
import eth from '../../assets/ethlogo.svg';
import matic from '../../assets/MATIC.png';
import redeem from '../../assets/headerimages/RDM.svg';
import store from '../../assets/headerimages/store.svg';
import greeting from '../../assets/headerimages/Greetingcard.svg';
import gift_card from '../../assets/headerimages/giftcard.svg';

import home from "../../assets/mobilebottomimages/home.svg"
import home_dark from "../../assets/mobilebottomimages/home_dark.svg"
import blockchain_white from "../../assets/mobilebottomimages/blockchain.svg"
import blockchain_dark from "../../assets/mobilebottomimages/blockchain_dark.svg"
import trade from "../../assets/mobilebottomimages/trade.svg"
import trade_dark from "../../assets/mobilebottomimages/trade_dark.svg"
import xnft from "../../assets/mobilebottomimages/X_small.svg"
import xnft_dark from "../../assets/mobilebottomimages/X_small_dark.svg"
import pen from "../../assets/mobilebottomimages/pen_white.svg"
import pen_dark from "../../assets/mobilebottomimages/pen_dark.svg"
import gift from "../../assets/mobilebottomimages/gift black.svg"
import gift_dark from "../../assets/mobilebottomimages/gift white.svg"

import create from '../../assets/headerimages/create.svg';
import upload from '../../assets/headerimages/upload.svg';
import plus from '../../assets/headerimages/plus.svg';

import Link from '@mui/material/Link';


// Metamask Icon
import MetaMaskIcon from '../../assets/Icons/darkUIIcons/metaMaskIcon.svg';
import MetaMaskIconLight from '../../assets/Icons/lightUIIcons/metaMaskIcon.svg';

const MobileNavigation = ({
  darkMode,
  walletAddress,
  openMenu,
  handleClickTrigger,
  handleOpenModal,
  handleConnectMetamaskWallet,
  marketplace,
  account,
  blockchain,
  setBlockchain,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [blockchainMenuAnchor, setBlockchainMenuAnchor] = useState(null);
  const [isBlockchainMenuOpen, setIsBlockchainMenuOpen] = useState(false);

  const setMaticChain = () => {
    setBlockchain('Polygon');
    setIsBlockchainMenuOpen(false);
    setBlockchainMenuAnchor(null);
  };

  const setEthereumChain = () => {
    setBlockchain('Ethereum');
    setIsBlockchainMenuOpen(false);
    setBlockchainMenuAnchor(null);
  };

  const [XNFTAnchorEl, setXNFTAnchorEl] = useState(null);
  const openXNFTMenu = Boolean(XNFTAnchorEl);
  const handleOpenXNFTMenu = (event) => {
    setXNFTAnchorEl(event.currentTarget);
  };

  const handleCloseXNFTMenu = () => {
    setXNFTAnchorEl(null);
  };
  const XNFTMenu = (
    <StyledMenu
      elevation={1}
      TransitionComponent={Zoom}
      anchorEl={XNFTAnchorEl}
      open={openXNFTMenu}
      onClose={handleCloseXNFTMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{
        zIndex: `${location.pathname === '/create-asset' ? 400000 : 400000}`,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        onClick={() => {
          navigate('/collections?type=all');
          handleCloseXNFTMenu();
        }}
        sx={{
          alignItems: 'center',
          gap: 2,
          textTransform: 'capitalize',
          color: location.pathname === '/collections' ? '#0294FE' : 'inherit',
        }}
      >
        {/* <Stack direction="row" gap={1} alignItems="center"> */}
        <img src={col} alt="Traded Icon" width={25}></img>
        <Typography variant="subtitle1" fontWeight={400}>
          Collection
        </Typography>
        {/* </Stack> */}
      </MenuItem>

      <MenuItem
        onClick={() => {
          navigate('/explore?type=all');
          handleCloseXNFTMenu();
        }}
        sx={{
          alignItems: 'center',
          gap: 2,
          textTransform: 'capitalize',
          color: location.pathname === '/explore' ? '#0294FE' : 'inherit',
        }}
      >
        {/* <Stack direction="row" gap={1} alignItems="center"> */}
        <img src={bino} alt="Traded Icon" width={25}></img>
        <Typography variant="subtitle1" fontWeight={400}>
          Explore
        </Typography>
        {/* </Stack> */}
      </MenuItem>

      <MenuItem
        onClick={() => {
          navigate('/auction?type=live');
          handleCloseXNFTMenu();
        }}
        sx={{
          alignItems: 'center',
          gap: 2,
          textTransform: 'capitalize',
          color: location.pathname === '/auction' ? '#0294FE' : 'inherit',
        }}
      >
        {/* <Stack direction="row" gap={1} alignItems="center"> */}
        <img src={mallet} alt="Traded Icon" width={25}></img>
        <Typography variant="subtitle1" fontWeight={400}>
          Auction
        </Typography>
        {/* </Stack> */}
      </MenuItem>

      <MenuItem
        onClick={() => {
          navigate("/brands");
          handleCloseXNFTMenu();
        }}
        sx={{
          alignItems: "center",
          gap: 2,
          textTransform: "capitalize",
          color: location.pathname === '/brands' ? '#0294FE' : 'inherit',
        }}
      >
        {/* <Stack direction="row" gap={1} alignItems="center"> */}
        <img src={brandicon} alt="Brand Icon" width={25}></img>
        <Typography variant="subtitle1" fontWeight={400}>
          Brands
        </Typography>
        {/* </Stack> */}
      </MenuItem>
    </StyledMenu>
  );


  // Trade Menu
  const [tradeAnchorEl, settradeAnchorEl] = useState(null);
  const opentradeMenu = Boolean(tradeAnchorEl);
  const handleOpentradeMenu = (event) => {
    settradeAnchorEl(event.currentTarget);
  };

  const handleClosetradeMenu = () => {
    settradeAnchorEl(null);
  };

  // // Gift Card Menu
  // const [giftAnchorEl, setgiftAnchorEl] = useState(null);
  // const opengiftMenu = Boolean(giftAnchorEl);
  // const handleOpengiftMenu = (event) => {
  //   setgiftAnchorEl(event.currentTarget);
  // };

  // const handleClosegiftMenu = () => {
  //   setgiftAnchorEl(null);
  // };

  // Blockchain Menu
  const [blockchainAnchorEl, setblockchainAnchorEl] = useState(null);
  const openblockchainMenu = Boolean(blockchainAnchorEl);
  const handleOpenblockchainMenu = (event) => {
    setblockchainAnchorEl(event.currentTarget);
  };

  const handleCloseblockchainMenu = () => {
    setblockchainAnchorEl(null);
  };

  //Create Menu
  const [createAnchorEl, setCreateAnchorEl] = useState(null);
  const openCreateMenu = Boolean(createAnchorEl);

  const handleOpenCreateMenu = (event) => {
    setCreateAnchorEl(event.currentTarget);
  };

  const handleCloseCreateMenu = () => {
    setCreateAnchorEl(null);
  };

  const tradeMenu = <StyledMenu
    elevation={1}
    TransitionComponent={Zoom}
    anchorEl={tradeAnchorEl}
    open={opentradeMenu}
    onClose={handleClosetradeMenu}
    MenuListProps={{
      "aria-labelledby": "basic-button",
    }}
    sx={{
      zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
    }}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
  >
    <MenuItem
      onClick={handleClosetradeMenu}
      sx={{
        alignItems: "center",
        gap: 2,
        textTransform: "capitalize",
      }}
      href="https://test.swap.indexx.ai"
      target="_blank"
      component={Link}
    >
      {/* <Stack direction="row" gap={1} alignItems="center"> */}
      <img src={swap} alt="Traded Icon" width={25}></img>
      <Typography variant="subtitle1" fontWeight={400}>
        Swap
      </Typography>
      {/* </Stack> */}
    </MenuItem>

    <MenuItem
      onClick={handleClosetradeMenu}
      sx={{
        alignItems: 'center',
        gap: 2,
        textTransform: 'capitalize',
      }}
      href="https://test.cex.indexx.ai"
      target="_blank"
      component={Link}
    >
      {/* <Stack direction="row" gap={1} alignItems="center"> */}
      <img src={ex} alt="Traded Icon" width={25}></img>
      <Typography variant="subtitle1" fontWeight={400}>
        Exchange
      </Typography>
      {/* </Stack> */}
    </MenuItem>

  </StyledMenu>

  // const giftMenu = <StyledMenu
  //   elevation={1}
  //   TransitionComponent={Zoom}
  //   anchorEl={giftAnchorEl}
  //   open={opengiftMenu}
  //   onClose={handleClosegiftMenu}
  //   MenuListProps={{
  //     "aria-labelledby": "basic-button",
  //   }}
  //   sx={{
  //     zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
  //   }}
  //   anchorOrigin={{
  //     vertical: "bottom",
  //     horizontal: "left",
  //   }}
  //   transformOrigin={{
  //     vertical: "top",
  //     horizontal: "left",
  //   }}
  // >

  //   <MenuItem
  //     onClick={() => {
  //       navigate('/redeem');
  //       handleClosegiftMenu();
  //     }}
  //     sx={{
  //       alignItems: 'center',
  //       gap: 2,
  //       textTransform: 'capitalize',
  //       color: location.pathname === '/redeem' ? '#0294FE' : 'inherit',
  //     }}
  //   >
  //     {/* <Stack direction="row" gap={1} alignItems="center"> */}
  //     <img src={redeem} alt="Traded Icon" width={25}></img>
  //     <Typography variant="subtitle1" fontWeight={400}>
  //       Redeem
  //     </Typography>
  //     {/* </Stack> */}
  //   </MenuItem>

  //   <MenuItem
  //     onClick={() => {
  //       navigate('/store');
  //       handleClosegiftMenu();
  //     }}
  //     sx={{
  //       alignItems: 'center',
  //       gap: 2,
  //       textTransform: 'capitalize',
  //       color: location.pathname === '/store' ? '#0294FE' : 'inherit',
  //     }}
  //   >
  //     {/* <Stack direction="row" gap={1} alignItems="center"> */}
  //     <img src={store} alt="Traded Icon" width={25}></img>
  //     <Typography variant="subtitle1" fontWeight={400}>
  //       Store
  //     </Typography>
  //     {/* </Stack> */}
  //   </MenuItem>
  // </StyledMenu>

  const [giftAnchorEl, setgiftAnchorEl] = useState(null);
const [submenu1Open, setSubmenu1Open] = useState(false);
const [submenu2Open, setSubmenu2Open] = useState(false);

const handleOpenGiftMenu = (event) => {
  setgiftAnchorEl(event.currentTarget);
};

const handleCloseGiftMenu = () => {
  setgiftAnchorEl(null);
  setSubmenu1Open(false);
  setSubmenu2Open(false);
};

const handleSubmenu1Toggle = () => {
  setSubmenu1Open(!submenu1Open);
};

    const handleSubmenu2Toggle = () => {
      setSubmenu2Open(!submenu2Open);
    };

    const giftMenu = <StyledMenu2
      anchorEl={giftAnchorEl}
      open={Boolean(giftAnchorEl)}
      onClose={handleCloseGiftMenu}
      MenuListProps={{ onMouseLeave: handleCloseGiftMenu }}
      sx={{
        zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
        // ml:18
      }}
    >
      <MenuItem onClick={handleSubmenu1Toggle}>
        <ListItemIcon>
          <img src={gift_card} alt="Traded Icon" width={25}></img>
        </ListItemIcon>
        <ListItemText primary="Gift Cards" />
        {submenu1Open && (
          <StyledMenu
            open={submenu1Open}
            onClose={handleCloseGiftMenu}
            anchorReference="giftAnchorEl"
            // anchorPosition={{ top: 10, left: '100%' }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            sx={{
              zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
              // mt: 10,
              ml: 2
            }}
          >
            <MenuItem
              onClick={() => {
                navigate('/redeem-gift');
                handleCloseGiftMenu();
              }}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
                color: location.pathname === '/redeem-gift' ? '#0294FE' : 'inherit',
              }}
            >
              <img src={redeem} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Redeem
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={handleCloseGiftMenu}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
              }}
              href="https://shop.indexx.ai/collections/gift-cards-1"
              target="_blank"
              component={Link}
            >
              <img src={store} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Store
              </Typography>
            </MenuItem>
          </StyledMenu>
        )}
      </MenuItem>
      <MenuItem onClick={handleSubmenu2Toggle}>
        <ListItemIcon>
          <img src={greeting} alt="Traded Icon" width={25}></img>
        </ListItemIcon>
        <ListItemText primary="Greeting Cards" />
        {submenu2Open && (
          <StyledMenu
            open={submenu2Open}
            onClose={handleCloseGiftMenu}
            anchorReference="giftAnchorEl"
            // anchorPosition={{ top: 0, left: '100%' }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            sx={{
              zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
              // mt: 15,
              ml: 2
            }}
          >
            <MenuItem
              onClick={() => {
                navigate('/redeem-greeting');
                handleCloseGiftMenu();
              }}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
                color: location.pathname === '/redeem-greeting' ? '#0294FE' : 'inherit',
              }}
            >
              <img src={redeem} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Redeem
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={handleCloseGiftMenu}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
              }}
              href="https://shop.indexx.ai/collections/greeting-cards"
              target="_blank"
              component={Link}
            >
              <img src={store} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Store
              </Typography>
            </MenuItem>
          </StyledMenu>
        )}
      </MenuItem>
    </StyledMenu2>


  const blockchainMenu = (
    <StyledMenu
      elevation={1}
      TransitionComponent={Zoom}
      anchorEl={blockchainAnchorEl}
      open={openblockchainMenu}
      onClose={handleCloseblockchainMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{
        zIndex: `${location.pathname === '/create-asset' ? 400000 : 400000}`,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        onClick={() => {
          // navigate("/collections");
          setEthereumChain();
          handleCloseblockchainMenu();
        }}
        sx={{
          alignItems: 'center',
          gap: 2,
          textTransform: 'capitalize',
        }}
      >
        {/* <Stack direction="row" gap={1} alignItems="center"> */}
        <img src={eth} alt="Ethereum" width={25}></img>
        <Typography variant="subtitle1" fontWeight={400}>
          Ethereum
        </Typography>
        {/* </Stack> */}
      </MenuItem>

      <MenuItem
        onClick={() => {
          // navigate("/explore?type=all");
          setMaticChain();
          handleCloseblockchainMenu();
        }}
        sx={{
          alignItems: 'center',
          gap: 2,
          textTransform: 'capitalize',
        }}
      >
        {/* <Stack direction="row" gap={1} alignItems="center"> */}
        <img src={matic} alt="Polygon" width={25}></img>
        <Typography variant="subtitle1" fontWeight={400}>
          Polygon
        </Typography>
        {/* </Stack> */}
      </MenuItem>
    </StyledMenu>
  );

  const createMenu = (
    <StyledMenu
      elevation={1}
      TransitionComponent={Zoom}
      anchorEl={createAnchorEl}
      open={openCreateMenu}
      onClose={handleCloseCreateMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{
        zIndex: `${location.pathname === '/create-asset' ? 400000 : 400000}`,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        onClick={() => {
          navigate('/create-asset');
          handleCloseCreateMenu();
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <img src={create} alt="create" width={25}></img>
          <Typography variant="subtitle1" fontWeight={400}>
            Create XNFT
          </Typography>
        </Stack>
      </MenuItem>
      {/* <Box my={0.5} px={2}></Box>

        <MenuItem
          onClick={() => {
            navigate('/mybids');
            handleCloseCreateMenu();
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <img src={upload} alt="upload" width={25}></img>
            <Typography variant="subtitle1" fontWeight={400}>
              Upload XNFT
            </Typography>
          </Stack>
        </MenuItem> */}
      <Box my={0.5} px={2}></Box>

      <MenuItem
        onClick={() => {
          navigate('/add-brand');
          handleCloseCreateMenu();
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <img src={plus} alt="upload" width={25}></img>
          <Typography variant="subtitle1" fontWeight={400}>
            Add Brand
          </Typography>
        </Stack>
      </MenuItem>
    </StyledMenu>
  );

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 30000,
        bottom: 0,
        backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
        left: 0,
        right: 0,
      }}
    >
      <Box pt={1}>
        <Stack
          direction='row'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={() => navigate('/home')}
              variant='text'
              color='secondary'
            >

              {darkMode ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={home_dark}
                  alt="Home"
                />
              ) : (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={home}
                  alt="Home"
                />
              )}
              <Typography
                component='p'
                sx={
                  location.pathname === '/home' || location.pathname === '/'
                    ? {
                      borderRadius: '50%',
                      height: '5px',
                      width: '5px',
                      backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                    }
                    : null
                }
              ></Typography>
            </Button>
          </Box>
          <Box>
            {/* <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={() => navigate('/explore?type=all')}
              variant='text'
              color='secondary'
            >
              <Typography color='secondary' component='span'>
                <HiCubeTransparent />
              </Typography>
              <Typography
                component='p'
                sx={
                  location.pathname === '/explore'
                    ? {
                        borderRadius: '50%',
                        height: '5px',
                        width: '5px',
                        backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                      }
                    : null
                }
              ></Typography>
            </Button> */}

            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                flexDirection: 'column',
                // textTransform: 'capitalize',
              }}
              onClick={handleOpenXNFTMenu}
              variant="text"
              color="secondary"
            >


              {darkMode ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={xnft_dark}
                  alt="xnft"
                />
              ) : (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={xnft}
                  alt="xnft"
                />
              )}
              <Typography
                component='p'
                sx={
                  location.pathname === '/collections' || location.pathname === '/explore' || location.pathname === '/brands' || location.pathname === '/auction'
                    ? {
                      borderRadius: '50%',
                      height: '5px',
                      width: '5px',
                      backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                    }
                    : null
                }
              ></Typography>
            </Button>

            {XNFTMenu}

          </Box>
          <Box>
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                flexDirection: 'column',
                // textTransform: 'capitalize',
              }}
              onClick={handleOpenGiftMenu}
              variant="text"
              color="secondary"
            >


              {darkMode ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={gift_dark}
                  alt="xnft"
                />
              ) : (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={gift}
                  alt="xnft"
                />
              )}
              <Typography
                component='p'
                sx={
                  location.pathname === '/redeem-gift' ||
                   location.pathname === '/redeem-greeting'
                    ? {
                      borderRadius: '50%',
                      height: '5px',
                      width: '5px',
                      backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                    }
                    : null
                }
              ></Typography>
            </Button>

            {giftMenu}

          </Box>

          <Box>
            {/* <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={() => navigate('/auction?type=live')}
              variant='text'
              color='secondary'
            >
              <Typography color='secondary' component='span'>
                <MdGavel />
              </Typography>
              <Typography
                component='p'
                sx={
                  location.pathname === '/auction'
                    ? {
                        borderRadius: '50%',
                        height: '5px',
                        width: '5px',
                        backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                      }
                    : null
                }
              ></Typography>
            </Button> */}
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                flexDirection: 'column',
                // textTransform: 'capitalize',
              }}
              onClick={handleOpentradeMenu}
              variant="text"
              color="secondary"
            >


              {darkMode ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={trade_dark}
                  alt="Trade"
                />
              ) : (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={trade}
                  alt="Trade"
                />
              )}
              <Typography
                component='p'
              // sx={
              // location.pathname ===  '/auction' ? {
              //     borderRadius: '50%',
              //     height: '5px',
              //     width: '5px',
              //     backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
              //   }
              //   : null
              // }
              ></Typography>
            </Button>

            {tradeMenu}
          </Box>

          <Box>
            {/* <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={() => navigate('/collections')}
              variant='text'
              color='secondary'
            >
              <Typography color='secondary' component='span'>
                <FiPackage />
              </Typography>
              <Typography
                component='p'
                sx={
                  location.pathname === '/collections'
                    ? {
                        borderRadius: '50%',
                        height: '5px',
                        width: '5px',
                        backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                      }
                    : null
                }
              ></Typography>
            </Button> */}
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                // textTransform: 'capitalize',
              }}
              onClick={handleOpenblockchainMenu}
              variant="text"
              color="secondary"
            >
              {blockchain === "" ?
                (<>
                  {darkMode ? (
                    <img
                      style={{ height: "20px", width: "20px" }}
                      src={blockchain_dark}
                      alt="Blockchain"
                    />
                  ) : (
                    <img
                      style={{ height: "20px", width: "20px" }}
                      src={blockchain_white}
                      alt="Blockchain"
                    />
                  )}
                </>)

                :
                (blockchain === "Polygon" ? <><img src={matic} alt="Polygon" style={{ height: "20px", width: "20px" }} />
                </>
                  : <><img src={eth} alt="Ethereum" style={{ height: "20px", width: "20px" }} />
                  </>)
              }
              <Typography
                component='p'
                sx={
                  // location.pathname ===  '/auction' ? {
                  //     borderRadius: '50%',
                  //     height: '5px',
                  //     width: '5px',
                  //     backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                  //   }
                  //   : null
                  null
                }
              ></Typography>
            </Button>

            {blockchainMenu}
          </Box>
          <Box>
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
              onClick={handleOpenCreateMenu}
              variant='text'
              color='secondary'
            >
              {/* <Typography color='secondary' component='span'>
                <MdAddToPhotos />
              </Typography> */}
              {darkMode ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={pen}
                  alt="Trade"
                />
              ) : (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={pen_dark}
                  alt="Trade"
                />
              )}
              <Typography
                component='p'
                sx={
                  location.pathname === '/create-asset' || location.pathname === '/mybids'
                    ? {
                      borderRadius: '50%',
                      height: '5px',
                      width: '5px',
                      backgroundColor: `${darkMode ? '#ffffff' : '#040708'}`,
                    }
                    : null
                }
              ></Typography>
            </Button>
            {createMenu}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default MobileNavigation;
