/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';

// Material UI components
import {
  Backdrop,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  ListItemText,
  Modal,
  Stack,
  Typography,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
  Zoom,
  Fade,
  IconButton,
  ListItemIcon,
} from '@mui/material';
import { Popover } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Gradient buttons
import {
  GradientButtonBlue,
  GradientButtonPrimary,
  GradientButtonSecondary,
} from '../../Utils/GradientButtons/GradientButtons';

import ConnectWalletPopUp from '../ConnectWalletPopUp/ConnectWalletPopUp';

import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Icons
import mallet from '../../assets/headerimages/auction.png';
import swap from '../../assets/headerimages/swap.svg';
import ex from '../../assets/headerimages/ex.svg';
import eth from '../../assets/ethlogo.svg';
import matic from '../../assets/MATIC.png';
import bino from '../../assets/headerimages/bino.png';
import col from '../../assets/headerimages/col.png';
import brandicon from "../../assets/headerimages/brand.svg"
import create from '../../assets/headerimages/create.svg';
import redeem from '../../assets/headerimages/RDM.svg';
import store from '../../assets/headerimages/store.svg';
import plus from '../../assets/headerimages/plus.svg';
import greeting from '../../assets/headerimages/Greetingcard.svg';
import gift from '../../assets/headerimages/giftcard.svg';
import xnft_card from "../../assets/headerimages/xnftcards.png"
import SettingsIcon from '@mui/icons-material/Settings';
import { BsChevronDown } from 'react-icons/bs';
import { IoPersonSharp } from 'react-icons/io5';
import { TiCancel } from 'react-icons/ti';

// import NavUserAvatar from '../../assets/navUserAvatar.png';
import NavUserAvatar from '../../assets/avatar transparent 1.svg';



import selectBlockchain from '../../assets/selectBlockchainGif.gif';
// Authentication
import useAuth from '../../hooks/useAuth';

import MetaMaskIcon from '../../assets/Icons/darkUIIcons/metaMaskIcon.svg';
import IndexxWalletIcon from '../../assets/Icons/darkUIIcons/indexx_wallet_image.svg';
import MobileNavigation from './MobileNavigation';

// Styled Menu
import { Person } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { StyledMenu, StyledMenu2 } from '../StyledMenu/StyledMenu';
import { useImperativeHandle } from 'react';
import { getUserWallets } from '../../service/api';
import styles from './PopUp.module.css';

const Navigation = React.forwardRef(
  (
    {
      darkMode,
      handleConnectMetamaskWallet,
      marketplace,
      account,
      blockchain,
      setBlockchain,
      setIndexxWalletEmail,
      indexxWalletEmail,
      indexxWalletObj,
      indexxWalletAddress,
      isIndexxWalletWeb,
      connectwall },
    ref
  ) => {
    const { user, handleLogOut } = useAuth();
    const [walletAddress, setWalletAddress] = useState('');
    //const [blockchain, setBlockchain] = useState("");
    const [openModal, setOpenModal] = React.useState(false);
    const [openBcModal, setBcOpenModal] = React.useState(false);
    const [isIndexxWallet, setIsIndexxWallet] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
    const [createAnchorEl, setCreateAnchorEl] = useState(null);

    const [tradeMenuAnchor, setTradeMenuAnchor] = useState(null);
    const [blockchainMenuAnchor, setBlockchainMenuAnchor] = useState(null);
    const [extensions, setExtensions] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isBlockchainMenuOpen, setIsBlockchainMenuOpen] = useState(false);
    const [secondTradeMenuAnchor, setSecondTradeMenuAnchor] = useState(null);
    const [isSecondMenuOpen, setIsSecondMenuOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { t } = useTranslation();
    const selectblockchain = useRef();
    const handleClick = () => {
      console.log("inside");
      selectblockchain.current.click();
    }

    const openMenu = Boolean(anchorEl);
    const openAvatarMenu = Boolean(avatarAnchorEl);
    const openCreateMenu = Boolean(createAnchorEl);

    // XNFT Menu
    const [XNFTAnchorEl, setXNFTAnchorEl] = useState(null);
    const openXNFTMenu = Boolean(XNFTAnchorEl);
    const handleOpenXNFTMenu = (event) => {
      setXNFTAnchorEl(event.currentTarget);
    };

    const handleCloseXNFTMenu = () => {
      setXNFTAnchorEl(null);
    };

    // Trade Menu
    const [tradeAnchorEl, settradeAnchorEl] = useState(null);
    const opentradeMenu = Boolean(tradeAnchorEl);
    const handleOpentradeMenu = (event) => {
      settradeAnchorEl(event.currentTarget);
    };

    const handleClosetradeMenu = () => {
      settradeAnchorEl(null);
    };

    // Blockchain Menu
    const [blockchainAnchorEl, setblockchainAnchorEl] = useState(null);
    const openblockchainMenu = Boolean(blockchainAnchorEl);
    const handleOpenblockchainMenu = (event) => {
      setblockchainAnchorEl(event.currentTarget);
    };

    const handleCloseblockchainMenu = () => {
      setblockchainAnchorEl(null);
    };

    const handleClickTrigger = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const handleOpenAvatarMenu = (event) => {
      setAvatarAnchorEl(event.currentTarget);
    };

    const handleCloseAvatarMenu = () => {
      setAvatarAnchorEl(null);
    };

    const handleOpenCreateMenu = (event) => {
      setCreateAnchorEl(event.currentTarget);
    };

    const handleCloseCreateMenu = () => {
      setCreateAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
      setTradeMenuAnchor(event.currentTarget);
      setIsMenuOpen(true);
    };

    const setEthereumChain = () => {
      setBlockchain('Ethereum');
      setIsBlockchainMenuOpen(false);
      setBlockchainMenuAnchor(null);
    };

    const setMaticChain = () => {
      setBlockchain('Polygon');
      setIsBlockchainMenuOpen(false);
      setBlockchainMenuAnchor(null);
    };

    const handleBlockchainOpen = (event) => {
      setBlockchainMenuAnchor(event.currentTarget);
      setIsBlockchainMenuOpen(true);
    };

    const handleMenuClose = () => {
      setTradeMenuAnchor(null);
    };

    const handleBlockchainMenuClose = () => {
      setBlockchainMenuAnchor(null);
    };
    const handleSecondMenuOpen = (event) => {
      setSecondTradeMenuAnchor(event.currentTarget);
      setIsSecondMenuOpen(true);
    };

    const handleSecondMenuClose = () => {
      setSecondTradeMenuAnchor(null);
      setIsSecondMenuOpen(false);
    };

    const handleOpenModal = async () => {
      if (blockchain === '') {
        //alert("Select Blockchain First");
        setBcOpenModal(true);
        return;
      } else {
        // handleConnectMetamaskWallet();
        console.log(handleConnectMetamaskWallet);
        // todo switch to matic

        //todo switch to ethereum
      }
      setOpenModal(true);
    };
    const handleCloseModal = () => setOpenModal(false);

    const handleBcCloseModal = () => setBcOpenModal(false);

    const navigate = useNavigate();
    const location = useLocation();

    // const isCollectionsOrExplorePage =
    // location.pathname === '/collections' ||
    // location.pathname === '/explore';

    // const isTradePage =
    // location.pathname === '/auction'

    // const handleConnectWallet = async () => {
    //   if (blockchain !== '') {
    //     handleCloseModal();
    //     await getExtensions();
    //     console.log(window.ethereum, window.web3);
    //     const result = window.ethereum;
    //     console.log(result._events);
    //     console.log(result);
    //     console.log(result._eventsCount);
    //     const providerOrigin = window.ethereum.currentProvider.origin;
    //     const expectedOrigins = ['chrome-extension:', 'moz-extension:'];
    //     console.log(providerOrigin);
    //     console.log(expectedOrigins);
    //     // Access properties using dot notation
    //     const isBlockWallet = result.isBlockWallet;
    //     const isMetaMask = result.isMetaMask;
    //     console.log(isBlockWallet);
    //     console.log(isMetaMask);
    //     console.log(extensions);
    //     if (typeof window.ethereum !== 'undefined') {
    //       if (isMetaMask) {
    //         const accounts = await window.ethereum.request({
    //           method: 'eth_requestAccounts',
    //         });
    //         setWalletAddress(accounts[0]);
    //       }
    //     }
    //     setOpenModal(false);
    //   } else {
    //     alert('Select Blockchain First');
    //   }
    // };

    const getExtensions = async () => {
      if (
        typeof window.chrome !== 'undefined' &&
        typeof window.chrome.runtime !== 'undefined'
      ) {
        // Chrome-based browsers
        window.chrome.runtime.sendMessage({ type: 'GET_ALL' }, (response) => {
          if (response && response.extensions) {
            setExtensions(response.extensions);
          }
        });
      } else if (
        typeof window.browser !== 'undefined' &&
        typeof window.browser.extension !== 'undefined'
      ) {
        // Firefox browser
        const manifest = window.browser.runtime.getManifest();
        const allExtensions = manifest.browser_specific_settings.gecko.id;
        setExtensions(allExtensions);
      } else if (typeof window.msBrowser !== 'undefined') {
        // Edge browser
        // Edge does not provide an API to retrieve installed extensions programmatically
        // You may need to handle this case differently or provide a fallback option
      }
    };

    const handleDisconnectWallet = () => {
      localStorage.removeItem('isIndexxWebWallet');
      localStorage.removeItem('indexxWebWallets');
      localStorage.removeItem('indexxWebWalletEmail');
      localStorage.removeItem('returnUrl');
      localStorage.removeItem('blockchainFromUrl');
      console.log('I am here', 123)
      if (window.location.hostname.includes("localhost")) {
        window.location.reload();
        //added below if we are on page which extracts data from data context for that
        // particular page context will removed on refresh/reload 
        const redirectUrl = new URL(`http://localhost:${window.location.port}`);
        window.open(redirectUrl.toString(), "_self")
      }
      else if (account || isIndexxWalletWeb) {
        // if (walletAddress) {
        window.location.reload();
        //added below if we are on page which extracts data from data context for that
        // particular page context will removed on refresh/reload 
        const redirectUrl = new URL("https://test.xnftmarketplace.indexx.ai");
        window.open(redirectUrl.toString(), "_self")
      }
    };

    const handleConnectIndexxWallet = async (
      email = '',
      returnUrl = '',
      blockchainFromUrl = ''
    ) => {
      if (email === '' || returnUrl === '') {
        handleCloseModal();
        let currentUrl = window.location.href;
        window.open(
          'https://test.wallet2.indexx.ai/login/sign-in?referrer=xnftmarketplace&blockchain=' +
          blockchain + '&currentUrl=' +
          currentUrl +
          '?blockchain=' +
          blockchain,
          '_self'
        );
      } else {
        const res = await getUserWallets(email);
        localStorage.setItem('isIndexxWebWallet', true);
        localStorage.setItem('indexxWebWallets', res.data);
        localStorage.setItem('indexxWebWalletEmail', email);
        localStorage.setItem('returnUrl', returnUrl);
        localStorage.setItem('blockchainFromUrl', blockchain);
        const requiredWallet = res.data.find((x) => x.coinName === 'Polygon');
        console.log('i am here in nav')
        if (requiredWallet) {
          setWalletAddress(requiredWallet.coinWalletAddress);
          setIsIndexxWallet(true);
          setBlockchain(blockchainFromUrl);
          setIndexxWalletEmail(email);
        } else {
          console.log('Not registered on indexx wallet');
        }
      }
    };

    // Expose the handleConnectIndexxWallet function through the ref
    useImperativeHandle(ref, () => ({
      handleConnectIndexxWallet,
    }));

    const XNFTMenu = (
      <StyledMenu
        elevation={1}
        TransitionComponent={Zoom}
        anchorEl={XNFTAnchorEl}
        open={openXNFTMenu}
        onClose={handleCloseXNFTMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          zIndex: `${location.pathname === '/create-asset' ? 400000 : 400000}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/collections?type=all');
            handleCloseXNFTMenu();
          }}
          sx={{
            alignItems: 'center',
            gap: 2,
            textTransform: 'capitalize',
            color: location.pathname === '/collections' ? '#0294FE' : 'inherit',
          }}
        >
          {/* <Stack direction="row" gap={1} alignItems="center"> */}
          <img src={col} alt="Traded Icon" width={25}></img>
          <Typography variant="subtitle1" fontWeight={400}>
            Collection
          </Typography>
          {/* </Stack> */}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/explore?type=all');
            handleCloseXNFTMenu();
          }}
          sx={{
            alignItems: 'center',
            gap: 2,
            textTransform: 'capitalize',
            color: location.pathname === '/explore' ? '#0294FE' : 'inherit',
          }}
        >
          {/* <Stack direction="row" gap={1} alignItems="center"> */}
          <img src={bino} alt="Traded Icon" width={25}></img>
          <Typography variant="subtitle1" fontWeight={400}>
            Explore
          </Typography>
          {/* </Stack> */}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/auction?type=live');
            handleCloseXNFTMenu();
          }}
          sx={{
            alignItems: 'center',
            gap: 2,
            textTransform: 'capitalize',
            color: location.pathname === '/auction?type=live' ? '#0294FE' : 'inherit',
          }}
        >
          {/* <Stack direction="row" gap={1} alignItems="center"> */}
          <img src={mallet} alt="Traded Icon" width={25}></img>
          <Typography variant="subtitle1" fontWeight={400}>
            Auction
          </Typography>
          {/* </Stack> */}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate("/brands");
            handleCloseXNFTMenu();
          }}
          sx={{
            alignItems: "center",
            gap: 2,
            textTransform: "capitalize",
            color: location.pathname === '/brands' ? '#0294FE' : 'inherit',
          }}
        >
          {/* <Stack direction="row" gap={1} alignItems="center"> */}
          <img src={brandicon} alt="Brand Icon" width={25}></img>
          <Typography variant="subtitle1" fontWeight={400}>
            Brands
          </Typography>
          {/* </Stack> */}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate("/convert-xnft");
            handleCloseXNFTMenu();
          }}
          sx={{
            alignItems: "center",
            gap: 2,
            textTransform: "capitalize",
            color: location.pathname === '/brands' ? '#0294FE' : 'inherit',
          }}
        >
          {/* <Stack direction="row" gap={1} alignItems="center"> */}
          <img src={swap} alt="Traded Icon" width={25}></img>
          <Typography variant="subtitle1" fontWeight={400}>
            Convert XNFT
          </Typography>
          {/* </Stack> */}
        </MenuItem>
      </StyledMenu>
    );



    const tradeMenu = <StyledMenu
      elevation={1}
      TransitionComponent={Zoom}
      anchorEl={tradeAnchorEl}
      open={opentradeMenu}
      onClose={handleClosetradeMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{
        zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuItem
        onClick={handleClosetradeMenu}
        sx={{
          alignItems: "center",
          gap: 2,
          textTransform: "capitalize",
        }}
        href="https://test.swap.indexx.ai"
        target="_blank"
        component={Link}
      >
        {/* <Stack direction="row" gap={1} alignItems="center"> */}
        <img src={swap} alt="Traded Icon" width={25}></img>
        <Typography variant="subtitle1" fontWeight={400}>
          Swap
        </Typography>
        {/* </Stack> */}
      </MenuItem>

      <MenuItem
        onClick={handleClosetradeMenu}
        sx={{
          alignItems: 'center',
          gap: 2,
          textTransform: 'capitalize',
        }}
        href="https://test.cex.indexx.ai"
        target="_blank"
        component={Link}
      >
        {/* <Stack direction="row" gap={1} alignItems="center"> */}
        <img src={ex} alt="Traded Icon" width={25}></img>
        <Typography variant="subtitle1" fontWeight={400}>
          Exchange
        </Typography>
        {/* </Stack> */}
      </MenuItem>

    </StyledMenu>


    // Gift Card Menu
    // const [giftAnchorEl, setgiftAnchorEl] = useState(null);
    // const opengiftMenu = Boolean(giftAnchorEl);
    // const handleOpengiftMenu = (event) => {
    //   setgiftAnchorEl(event.currentTarget);
    // };

    // const handleClosegiftMenu = () => {
    //   setgiftAnchorEl(null);
    // };

    const [giftAnchorEl, setgiftAnchorEl] = useState(null);
    const [submenu1Open, setSubmenu1Open] = useState(false);
    const [submenu2Open, setSubmenu2Open] = useState(false);
    const [submenu3Open, setSubmenu3Open] = useState(false);

    const handleOpenGiftMenu = (event) => {
      setgiftAnchorEl(event.currentTarget);
    };

    const handleCloseGiftMenu = () => {
      setgiftAnchorEl(null);
      setSubmenu1Open(false);
      setSubmenu2Open(false);
    };

    const handleSubmenu1Toggle = () => {
      setSubmenu1Open(!submenu1Open);
    };

    const handleSubmenu2Toggle = () => {
      setSubmenu2Open(!submenu2Open);
    };

    const handleSubmenu3Toggle = () => {
      setSubmenu3Open(!submenu3Open);
    };
    const giftMenu = <StyledMenu2
      anchorEl={giftAnchorEl}
      open={Boolean(giftAnchorEl)}
      onClose={handleCloseGiftMenu}
      MenuListProps={{ onMouseLeave: handleCloseGiftMenu }}
      sx={{
        zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
        // ml:18
      }}
    >
      <MenuItem onClick={handleSubmenu1Toggle}>
        <ListItemIcon>
          <img src={gift} alt="Traded Icon" width={25}></img>
        </ListItemIcon>
        <ListItemText primary="Gift Cards" />
        {submenu1Open && (
          <StyledMenu
            open={submenu1Open}
            onClose={handleCloseGiftMenu}
            anchorReference="giftAnchorEl"
            // anchorPosition={{ top: 10, left: '100%' }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            sx={{
              zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
              mt: 10,
              ml: -25.5
            }}
          >
            <MenuItem
              onClick={() => {
                navigate('/redeem-gift');
                handleCloseGiftMenu();
              }}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
                color: location.pathname === '/redeem-gift' ? '#0294FE' : 'inherit',
              }}
            >
              <img src={redeem} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Redeem
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={handleCloseGiftMenu}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
              }}
              href="https://shop.indexx.ai/collections/gift-cards-1"
              target="_blank"
              component={Link}
            >
              <img src={store} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Store
              </Typography>
            </MenuItem>
          </StyledMenu>
        )}
      </MenuItem>
      <MenuItem onClick={handleSubmenu2Toggle}>
        <ListItemIcon>
          <img src={greeting} alt="Traded Icon" width={25}></img>
        </ListItemIcon>
        <ListItemText primary="Greeting Cards" />
        {submenu2Open && (
          <StyledMenu
            open={submenu2Open}
            onClose={handleCloseGiftMenu}
            anchorReference="giftAnchorEl"
            // anchorPosition={{ top: 0, left: '100%' }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            sx={{
              zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
              mt: 15,
              ml: -25.5
            }}
          >
            <MenuItem
              onClick={() => {
                navigate('/redeem-greeting');
                handleCloseGiftMenu();
              }}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
                color: location.pathname === '/redeem-greeting' ? '#0294FE' : 'inherit',
              }}
            >
              <img src={redeem} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Redeem
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={handleCloseGiftMenu}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
              }}
              href="https://shop.indexx.ai/collections/greeting-cards"
              target="_blank"
              component={Link}
            >
              <img src={store} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Store
              </Typography>
            </MenuItem>
          </StyledMenu>
        )}
      </MenuItem>
      <MenuItem onClick={handleSubmenu3Toggle}>
        <ListItemIcon>
          <img src={xnft_card} alt="Traded Icon" width={25}></img>
        </ListItemIcon>
        <ListItemText primary="XNFT Gift Cards" />
        {submenu3Open && (
          <StyledMenu
            open={submenu3Open}
            onClose={handleCloseGiftMenu}
            anchorReference="giftAnchorEl"
            // anchorPosition={{ top: 0, left: '100%' }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            sx={{
              zIndex: `${location.pathname === "/create-asset" ? 400000 : 400000}`,
              mt: 18,
              ml: -25.5
            }}
          >
            <MenuItem
              onClick={() => {
                navigate('/redeem-xnft');
                handleCloseGiftMenu();
              }}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
                color: location.pathname === '/redeem-greeting' ? '#0294FE' : 'inherit',
              }}
            >
              <img src={redeem} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Redeem
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={handleCloseGiftMenu}
              sx={{
                alignItems: 'center',
                gap: 2,
                textTransform: 'capitalize',
              }}
              href="https://shop.indexx.ai/collections/xnft"
              target="_blank"
              component={Link}
            >
              <img src={store} alt="Traded Icon" width={25}></img>
              <Typography variant="subtitle1" fontWeight={400}>
                Store
              </Typography>
            </MenuItem>
          </StyledMenu>
        )}
      </MenuItem>
    </StyledMenu2>


    const blockchainMenu = (
      <StyledMenu
        elevation={1}
        TransitionComponent={Zoom}
        anchorEl={blockchainAnchorEl}
        open={openblockchainMenu}
        onClose={handleCloseblockchainMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          zIndex: `${location.pathname === '/create-asset' ? 400000 : 400000}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            // navigate("/collections");
            setEthereumChain();
            handleCloseblockchainMenu();
          }}
          sx={{
            alignItems: 'center',
            gap: 2,
            textTransform: 'capitalize',
          }}
        >
          {/* <Stack direction="row" gap={1} alignItems="center"> */}
          <img src={eth} alt="Ethereum" width={25}></img>
          <Typography variant="subtitle1" fontWeight={400}>
            Ethereum
          </Typography>
          {/* </Stack> */}
        </MenuItem>

        <MenuItem
          onClick={() => {
            // navigate("/explore?type=all");
            setMaticChain();
            handleCloseblockchainMenu();
          }}
          sx={{
            alignItems: 'center',
            gap: 2,
            textTransform: 'capitalize',
          }}
        >
          {/* <Stack direction="row" gap={1} alignItems="center"> */}
          <img src={matic} alt="Polygon" width={25}></img>
          <Typography variant="subtitle1" fontWeight={400}>
            Polygon
          </Typography>
          {/* </Stack> */}
        </MenuItem>
      </StyledMenu>
    );

    const createMenu = (
      <StyledMenu
        elevation={1}
        TransitionComponent={Zoom}
        anchorEl={createAnchorEl}
        open={openCreateMenu}
        onClose={handleCloseCreateMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          zIndex: `${location.pathname === '/create-asset' ? 400000 : 400000}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/create-asset');
            handleCloseCreateMenu();
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <img src={create} alt="create" width={25}></img>
            <Typography variant="subtitle1" fontWeight={400}>
              Create XNFT
            </Typography>
          </Stack>
        </MenuItem>
        <Box my={0.5} px={2}></Box>

        {/* <MenuItem
          onClick={() => {
            navigate('/mybids');
            handleCloseCreateMenu();
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <img src={upload} alt="upload" width={25}></img>
            <Typography variant="subtitle1" fontWeight={400}>
              Upload XNFT
            </Typography>
          </Stack>
        </MenuItem> */}

        <Box my={0.5} px={2}></Box>

        <MenuItem
          onClick={() => {
            navigate('/add-brand');
            handleCloseCreateMenu();
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <img src={plus} alt="upload" width={25}></img>
            <Typography variant="subtitle1" fontWeight={400}>
              Add Brand
            </Typography>
          </Stack>
        </MenuItem>
      </StyledMenu>
    );

    const avatarMenu = (
      <StyledMenu
        elevation={1}
        TransitionComponent={Zoom}
        anchorEl={avatarAnchorEl}
        open={openAvatarMenu}
        onClose={handleCloseAvatarMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          zIndex: `${location.pathname === '/create-asset' ? 400000 : 400000}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/user/profile');
            handleCloseAvatarMenu();
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Person />
            <Typography variant="subtitle1" fontWeight={400}>
              {t('MY_PROFILE')}
            </Typography>
          </Stack>
        </MenuItem>
        {/* <Box my={0.5} px={2}>
          <Divider />
        </Box>

        <MenuItem
          onClick={() => {
            navigate('/mybids');
            handleCloseAvatarMenu();
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <GavelIcon />
            <Typography variant="subtitle1" fontWeight={400}>
              {t('ALL_BIDS')}
            </Typography>
          </Stack>
        </MenuItem> */}
        {/* <Box my={0.5} px={2}>
          <Divider />
        </Box> */}
        <MenuItem
          onClick={() => {
            navigate('/profile/theme');
            handleCloseAvatarMenu();
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <SettingsIcon />
            <Typography variant="subtitle1" fontWeight={400}>
              {t('SETTINGS')}
            </Typography>
          </Stack>
        </MenuItem>
        {/* <Box my={0.5} px={2}>
          <Divider />
        </Box>
        <MenuItem onClick={handleLogOut}>
          <Stack direction="row" gap={1} alignItems="center">
            <LogoutIcon />
            <Typography variant="subtitle1" fontWeight={400}>
              {t('LOGOUT')}
            </Typography>
          </Stack>
          <Divider />
        </MenuItem> */}
      </StyledMenu>
    );

    return (
      <div
        style={{
          position: 'relative',
          paddingTop: `${!isMobile ? '6rem' : '0'}`,
        }}
      >
        {!isMobile ? (
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              zIndex: `${location.pathname === '/create-asset' ? 30000 : 300000
                }`,
              position: 'fixed',
              top: '0%',
              backgroundColor: `${darkMode ? '#040404' : '#ffffff'}`,
              pt: 3,
              pb: 2,
            }}
          >
            <Box>
              <Stack direction="row" spacing={2} marginLeft={-5}>
                <Button
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => navigate('/home')}
                  variant="text"
                  color="secondary"
                >
                  {location.pathname === '/home' ||
                    location.pathname === '/' ? (
                    <Typography
                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                      color="#0294FE"
                    >
                      {t('NAV_HOME')}
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                    >
                      {t('NAV_HOME')}
                    </Typography>
                  )}
                </Button>

                {/* <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/auction?type=live")}
                variant="text"
                color="secondary"
              >
                {
                  location.pathname === "/auction" ?

                    <Typography

                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                      color="#0294FE"
                    >
                      {t("NAV_AUCTION")}
                    </Typography> :
                    <Typography

                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                    >
                      {t("NAV_AUCTION")}
                    </Typography>
                }

              </Button> */}

                <Button
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    textTransform: 'capitalize',
                    // color: isCollectionsOrExplorePage ? '#0294FE' : '#FFFFFF',
                  }}
                  onClick={handleOpenXNFTMenu}
                  variant="text"
                  color="secondary"
                >
                  {location.pathname === '/collections' ||
                    location.pathname === '/explore' || location.pathname === '/brands'
                    || location.pathname === '/auction' ? (
                    <Typography
                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                      color="#0294FE"
                    >
                      XNFT
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                    >
                      XNFT
                    </Typography>
                  )}
                </Button>

                {XNFTMenu}

                <Button
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    textTransform: 'capitalize',
                    // color: isCollectionsOrExplorePage ? '#0294FE' : '#FFFFFF',
                  }}
                  onClick={handleOpenGiftMenu}
                  variant="text"
                  color="secondary"
                >
                  {location.pathname === '/redeem-gift' ||
                    location.pathname === '/redeem-greeting' ? (
                    <Typography
                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                      color="#0294FE"
                    >
                      Shop
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                    >
                      Shop
                    </Typography>
                  )}
                </Button>

                {giftMenu}

                <Button
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    textTransform: 'capitalize',
                    // color: isTradePage ? '#0294FE' : '#FFFFFF',
                  }}
                  onClick={handleOpentradeMenu}
                  variant="text"
                  color="secondary"
                >
                  {/* {location.pathname === '/auction' ? (
                    <Typography
                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                      color="#0294FE"
                    >
                      Trade
                    </Typography>
                  ) : ( */}
                  <Typography
                    variant="subtitle1"
                    component="span"
                    fontWeight={500}
                  >
                    Trade
                  </Typography>
                  {/* )} */}
                </Button>

                {tradeMenu}

                {/* <Button
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                      textTransform: 'capitalize',
                      // color: isTradePage ? '#0294FE' : '#FFFFFF',
                    }}
                    // onClick={}
                    variant="text"
                    color="secondary"
                  >
                  {location.pathname === '/brands' ?
                    <Typography

                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                      color="#0294FE"

                    >
                      Brands
                    </Typography>
                    :
                    <Typography

                      variant="subtitle1"
                      component="span"
                      fontWeight={500}
                    >
                      Brands
                    </Typography>
                }
                  </Button> */}


                {/* <Button
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    textTransform: 'capitalize',
                  }}
                  href="https://indexxgiftcards.myshopify.com/"
                  variant="text"
                  color="secondary"
                >
                  <Typography
                    variant="subtitle1"
                    component="span"
                    fontWeight={500}
                  >
                    Gift Cards
                  </Typography>
                </Button> */}





                <Button
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    textTransform: 'capitalize',
                  }}
                  onClick={handleOpenblockchainMenu}
                  variant="text"
                  color="secondary"
                  ref={selectblockchain}
                >
                  {blockchain === "" ?
                    <Typography variant="subtitle1"
                      component="span"
                      fontWeight={500} >
                      Select blockchain
                    </Typography>
                    :
                    (blockchain === "Polygon" ? <><img src={matic} alt="Polygon" width={20} />
                      <Typography variant="subtitle1"
                        component="span"
                        fontWeight={500} >
                        Polygon
                      </Typography>
                    </>
                      : <><img src={eth} alt="Ethereum" width={20} />
                        <Typography variant="subtitle1"
                          component="span"
                          fontWeight={500} >
                          Ethereum
                        </Typography>
                      </>)
                  }
                </Button>

                {blockchainMenu}
              </Stack>
            </Box>

            {
              <React.Fragment>
                <GradientButtonPrimary
                  onClick={handleOpenCreateMenu}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    marginRight: -7,
                    height: 45.59,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    component="span"
                    pr={1}
                    pl={1}
                  >
                    {t('NAV_CREATE_ASSET')}
                    {/* Create IndexXNFT */}
                  </Typography>
                </GradientButtonPrimary>

                {createMenu}
              </React.Fragment>
            }

            <Box style={{ display: 'flex', gap: '5px', verticalAlign: "middle" }}>
              {
                <React.Fragment>
                  {account ? (
                    <GradientButtonBlue
                      id="basic-button"
                      aria-controls={openMenu ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                      onClick={handleClickTrigger}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      {isIndexxWallet ? (
                        <img
                          style={{ width: '20px', height: '25px' }}
                          src={IndexxWalletIcon}
                          alt="Meta Mask Icon"
                        />
                      ) : (
                        <img
                          style={{ width: '20px', height: '20px' }}
                          src={MetaMaskIcon}
                          alt="Meta Mask Icon"
                        />
                      )}
                      <Typography
                        variant="subtitle1"
                        component="span"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        {account?.slice(0, 10)} <BsChevronDown />
                      </Typography>
                    </GradientButtonBlue>
                  ) : isIndexxWalletWeb ? (
                    <GradientButtonBlue
                      id="basic-button"
                      aria-controls={openMenu ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                      onClick={handleClickTrigger}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      {isIndexxWalletWeb ? (
                        <img
                          style={{ width: '20px', height: '25px' }}
                          src={IndexxWalletIcon}
                          alt="Meta Mask Icon"
                        />
                      ) : (
                        <img
                          style={{ width: '20px', height: '20px' }}
                          src={MetaMaskIcon}
                          alt="Meta Mask Icon"
                        />
                      )}
                      <Typography
                        variant="subtitle1"
                        component="span"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        {indexxWalletAddress.slice(0, 10)} <BsChevronDown />
                      </Typography>
                    </GradientButtonBlue>
                  ) :
                    blockchain === '' ?
                      <GradientButtonBlue
                        onClick={handleClick}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 2,
                        }}
                        ref={connectwall}
                      >
                        <Typography
                          variant="subtitle1"
                          component="span"
                          pr={1}
                          pl={1}
                        >
                          {t('NAV_CONNECT_WALLET')}
                        </Typography>
                      </GradientButtonBlue>
                      :
                      <GradientButtonBlue
                        onClick={handleOpenModal}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 2,
                        }}
                        ref={connectwall}
                      >
                        <Typography
                          variant="subtitle1"
                          component="span"
                          pr={1}
                          pl={1}
                        >
                          {t('NAV_CONNECT_WALLET')}
                        </Typography>
                      </GradientButtonBlue>

                  }
                </React.Fragment>
              }
              {
                <React.Fragment>
                  <Avatar
                    onClick={handleOpenAvatarMenu}
                    alt={user?.userName}
                    src={NavUserAvatar}
                    sx={{
                      ml: 2, mr: -3.7,
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.5)',
                        // bgcolor: 'white',
                        // outline: 'none', // Remove outline on hover
                      },
                    }}
                  />

                  {avatarMenu}
                </React.Fragment>
              }
            </Box>
          </Container>
        ) : (
          <MobileNavigation
            darkMode={darkMode}
            walletAddress={account}
            openMenu={openMenu}
            handleClickTrigger={handleClickTrigger}
            handleOpenModal={handleOpenModal}
            handleConnectMetamaskWallet={handleConnectMetamaskWallet}
            marketplace={marketplace}
            account={account}
            blockchain={blockchain}
            setBlockchain={setBlockchain}
            connectwall={connectwall}
          />
        )}

        {/* <Modal
          sx={{ zIndex: 500000 }}
          open={openBcModal}
          onClose={handleBcCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openBcModal}>
            <Box
              bgcolor={darkMode ? '#171c26' : '#ffffff'}
              // color={darkMode ? "#EEEEEE" : "#171c26"}
              className={styles.modalStyle}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="h6"
                  component="span"
                  pb={'5px'}
                  color="secondary"
                >
                  {'Follow the instructions to select Blockchain First'}
                </Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleBcCloseModal}
                  aria-label="close"
                >
                  <CloseIcon style={{ color: 'white' }} />
                </IconButton>
              </Box>
              <img
                src={selectBlockchain}
                alt="Selectblockchain"
                style={{ width: '-webkit-fill-available' }}
              />
            </Box>
          </Fade>
        </Modal> */}

        <ConnectWalletPopUp
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          darkMode={darkMode}
          handleConnectWallet={handleConnectMetamaskWallet}
          handleConnectIndexxWallet={handleConnectIndexxWallet}
          handleDisconnectWallet={handleDisconnectWallet}
          walletAddress={account}
          blockchain={blockchain}
        />
        {!isMobile ? (
          <Menu
            id="basic-menu"
            PaperProps={{
              style: {
                backgroundColor: `${darkMode ? "#171c26" : "#ffffff"}`,
                // color: `${darkMode ? "#EEEEEE" : "#171c26"}`,
                padding: "0 10px",
                borderRadius: "10px",
              },
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            style={{ marginTop: "12px" }}
          >
            <MenuItem
              style={{
                width: "200px",
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                // color: `${darkMode ? "#ffffff" : "#040404"}`,
                paddingLeft: "2rem",
                gap: 15,
                borderRadius: "10px",
                marginBottom: "10px",
              }}
              onClick={handleDisconnectWallet}
            >
              <Typography component="span" color="secondary">
                <TiCancel />
              </Typography>
              <Typography color="secondary">{t("DISCONNECT")}</Typography>
            </MenuItem>
          </Menu>
        ) : (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                backgroundColor: `${darkMode ? "#171c26" : "#ffffff"}`,
                borderRadius: "10px",
                padding: "0 10px",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            style={{ marginTop: "-2rem" }}
          >
            <MenuItem
              style={{
                width: "130px",
                gap: 10,
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              {darkMode ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={MetaMaskIcon}
                  alt="Metamask"
                />
              ) : (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={MetaMaskIcon}
                  alt="Metamask"
                />
              )}
              <Typography
                variant="body1"
                color="secondary"
                sx={{ fontSize: "12px" }}
              >
                {account ? account.slice(0, 10) : null}
              </Typography>
            </MenuItem>
            <MenuItem
              style={{
                width: "130px",
                gap: 10,
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                borderRadius: "10px",
                marginBottom: "10px",
              }}
              onClick={() => {
                navigate("/user/profile");
                handleCloseMenu();
              }}
            >
              <Typography component="span" color="secondary">
                <IoPersonSharp />
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                sx={{ fontSize: "12px" }}
              >
                {t("MY_PROFILE")}
              </Typography>
            </MenuItem>
            <MenuItem
              style={{
                width: "130px",
                gap: 10,
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                borderRadius: "10px",
              }}
              onClick={handleDisconnectWallet}
            >
              <Typography component="span" color="secondary">
                <TiCancel />
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                sx={{ fontSize: "12px" }}
              >
                {t("DISCONNECT")}
              </Typography>
            </MenuItem>
          </Menu>
        )}
      </div>
    );
  });

export default Navigation;
