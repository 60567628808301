import { useTheme } from "@emotion/react";
import { Container, useMediaQuery } from "@mui/material";
import React from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import SideBar from "../SideBar/SideBar";

const Layout = ({ children, darkMode, handleConnectMetamaskWallet, marketplace, account, blockchain, setBlockchain, setIndexxWalletEmail, indexxWalletEmail, isIndexxWalletWeb, indexxWalletObj, indexxWalletAddress, handleConnectIndexxWallet, connectwall }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <React.Fragment>
      <Container
        sx={
          darkMode
            ? { backgroundColor: "#040404" }
            : { backgroundColor: "#ffffff" }
        }
      >
        <Navigation connectwall={connectwall} darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={indexxWalletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
        <SideBar connectwall={connectwall} darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={indexxWalletAddress} isIndexxWalletWeb={isIndexxWalletWeb} handleConnectIndexxWallet={handleConnectIndexxWallet}/>
        <Container sx={isMobile ? { py: 10 } : { mt: -10 }}>
          {children}
        </Container>
      </Container>
      {!isMobile && <Footer darkMode={darkMode} />}
    </React.Fragment>
  );
};

export default Layout;
