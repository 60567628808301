/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Grow,
  useMediaQuery,
  IconButton,
  Zoom,
  MenuItem,
} from "@mui/material";
import { HiOutlineClock } from "react-icons/hi";
import { AiOutlineHeart } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import CountDownBoard from "../CountDownBoard/CountDownBoard";
import { add } from "date-fns";
import AddToCollectionModal from "../SellerDetailsOwnedModals/AddToCollectionModal";
import DeleteFromCollection from "../SellerDetailsOwnedModals/DeleteFromCollection";
import RemoveFromCollection from "../SellerDetailsOwnedModals/RemoveFromCollection";

// Styles
import styles from "./SellerDetailsCard.module.css";
import { GradientButtonPrimary, GradientButtonBlue } from "../../Utils/GradientButtons/GradientButtons";
import { FaThumbsUp } from "react-icons/fa";
import { KeyboardArrowDown } from "@mui/icons-material";
import { StyledMenu } from "../StyledMenu/StyledMenu";
import { useLocation, useNavigate } from "react-router-dom";
import DataContext from "../../Pages/Collections/DataContext";
import { useContext } from "react";
import noImageICon from "../../assets/no_image-mint.svg";

// This should come from the backend API
const futureDate = add(new Date(), {
  days: 7,
  hours: 12,
  minutes: 21,
});

const SellerDetailsCardConvert = ({ sdc, darkMode, account, blockchain }) => {
  const { setData } = useContext(DataContext);
  const [sendData, setSendData] = useState();
  const [isClicked, setIsClicked] = useState(false);

  const { t } = useTranslation();
  const {
    id,
    artworkImage,
    artworkTitle,
    artworkPrice,
    creator,
    creatorImage,
    owner,
    ownerImage,
    uploaded,
    likes,
    tag,
  } = sdc;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const location = useLocation();
  let navigate = useNavigate();
  var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Add to collection modal
  const [openAddToCollectionModal, setOpenAddToCollectionModal] =
    useState(false);

  // Delete from collection modal
  const [openDeleteFromCollectionModal, setOpenDeleteFromCollectionModal] =
    useState(false);

  // Remove from collection modal
  const [openRemoveFromCollectionModal, setOpenRemoveFromCollectionModal] =
    useState(false);

  const parsedId = parseInt(id) * 100;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleNavigate = () => {
    const largeData = sdc;
    largeData.dataType = "userCollection";
    largeData.account = account;
    largeData.blockchain = blockchain;
    console.log(largeData.blockchain, "largeData.blockchain")
    setData(largeData);
    setSendData(largeData)
    navigate(`${sdc?.contract?.address}/${sdc?.tokenId}`)
    setIsClicked(true)
  };

  const convertNow = async () => {
  }
  return (
    //
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(true ? { timeout: 1000 + parsedId } : {})}
    >

      {sdc &&
        <Grid
          className={styles.sellerCard}
          item
          xs={1}
          sm={6}
          md={3}
          sx={{ zIndex: 600 }}
        >
          <div
            className={styles.sellerCard}
            onClick={handleNavigate}
          //   onClick={() => navigate(`/explore/${1}`)
          // }
          >
            <Box
              className={styles.sellerCardGradient}
              sx={{ padding: "1px", borderRadius: "20px" }}
            >
              <Box
                bgcolor={darkMode ? "#121212" : "#ffffff"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "20px",
                  padding: "0.4rem",
                  zIndex: "10",
                  cursor: "pointer",
                  position: "relative",
                  boxShadow: `${!darkMode && "0px 4px 4px rgba(0, 0, 0, 0.25)"}`,
                }}
              >
                <Box style={{ position: "relative" }}>
                  <img
                    style={{ width: "100%", borderRadius: "20px" }}
                    src={sdc?.media[0]?.gateway ? sdc?.media[0]?.gateway : noImageICon}
                    alt={artworkTitle}
                  />
                </Box>
                <Box sx={{ mt: 1 }}>
                  {tag === "ownedItems" ? (
                    <React.Fragment>
                      {location.pathname === "/user/profile" && (
                        <Stack
                          mb={1}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="subtitle1"
                            component="h2"
                            color="secondary.main"
                          >
                            {artworkTitle !== "" ? artworkTitle : "No Title"}
                          </Typography>
                          <IconButton onClick={handleOpenMenu}>
                            <KeyboardArrowDown />
                          </IconButton>
                          <StyledMenu
                            elevation={1}
                            TransitionComponent={Zoom}
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            PaperProps={{
                              style: {
                                borderRadius: "10px",
                                backgroundColor: `${darkMode ? "#121212" : "#ffffff"
                                  }`,
                                color: `${darkMode ? "#ffffff" : "#121212"}`,
                              },
                            }}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setOpenAddToCollectionModal(true);
                                handleCloseMenu();
                              }}
                            >
                              <Typography variant="subtitle1" fontWeight={400}>
                                {t("ADD_TO_COLLECTION")}
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setOpenRemoveFromCollectionModal(true);
                                handleCloseMenu();
                              }}
                            >
                              <Typography variant="subtitle1" fontWeight={400}>
                                {t("BURN_THE_NFT")}
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setOpenDeleteFromCollectionModal(true);
                                handleCloseMenu();
                              }}
                            >
                              <Typography variant="subtitle1" fontWeight={400}>
                                {t("REMOVE_FROM_SALE")}
                              </Typography>
                            </MenuItem>
                          </StyledMenu>
                        </Stack>
                      )}
                    </React.Fragment>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      component="h2"
                      color="secondary.main"
                      mb={1}
                    >
                      {artworkTitle}
                    </Typography>
                  )}
                  {/* <Typography variant="subtitle2" component="p" color="gray">
                    {"Name"}
                  </Typography> */}
                  <Typography
                    variant="subtitle1"
                    component="p"
                    color="secondary.main"
                    mb={1}
                  >
                    {sdc?.title !== "" ? sdc?.title : "No Title For this NFT"}
                  </Typography>
                  {tag === "auctionItems" ? (
                    <Box>
                      {!isMobile ? (
                        <Box>
                          <CountDownBoard
                            darkMode={darkMode}
                            futureDate={futureDate}
                            isCard={true}
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{ position: "absolute", top: "45%", left: "26%" }}
                        >
                          <CountDownBoard
                            darkMode={darkMode}
                            futureDate={futureDate}
                          />
                        </Box>
                      )}
                    </Box>
                  ) : null}
                  {/* <Divider
                    style={{ backgroundColor: "#8E8E8E", margin: "10px 0" }}
                  />
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Box>
                        <Avatar
                          sx={{ border: "2px solid #ffffff" }}
                          src={creatorImage}
                          alt={creator}
                        />
                      </Box>
                      <Stack direction="column" alignItems="center">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("CREATOR")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                        >
                          {creator}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Box>
                        <Avatar
                          sx={{ border: "2px solid #ffffff" }}
                          src={ownerImage}
                          alt={owner}
                        />
                      </Box>
                      <Stack direction="column" alignItems="center">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("OWNER")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                        >
                          {owner}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box> */}
                  {tag === "auctionItems" ? null : (
                    <Box>
                      <Divider
                        style={{ backgroundColor: "#8E8E8E", margin: "10px 0" }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Stack direction="row" gap={2} alignItems="center">
                          <HiOutlineClock
                            style={{
                              color: `${darkMode ? "#ffffff" : "#121212"}`,
                            }}
                          />
                          <Typography
                            variant="caption"
                            component="span"
                            color="gray"
                          >
                            {sdc?.timeLastUpdated?.toLocaleString('en-US', options) + " UTC"}
                          </Typography>
                        </Stack>
                        {/* <Stack direction="row" gap={2} alignItems="center">
                          <AiOutlineHeart
                            style={{
                              color: `${darkMode ? "#ffffff" : "#121212"}`,
                            }}
                          />
                          <Typography
                            variant="caption"
                            component="span"
                            color="gray"
                          >
                            {likes}
                          </Typography>
                        </Stack> */}
                      </Box>
                      <Divider
                        style={{ backgroundColor: "#8E8E8E", margin: "10px 0" }}
                      />
                      <Box className={styles.footerButtonContainer} mb={1}>
                        <GradientButtonBlue
                          className={styles.gradientButtonClass}
                          variant="contained"
                          fullWidth={true}
                          onClick={() => convertNow()}
                        >
                          {/* <Typography
                          color="#ffffff"
                          component="span"
                          fontSize={20}
                          mt={0.5}
                        >
                          <IoCart />
                        </Typography> */}
                          <Typography variant="body1" component="span">
                            {t("CONVERT_NOW")}
                          </Typography>
                        </GradientButtonBlue>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </div>
          {tag === "auctionItems" && (
            <div className={styles.auctionCardButton}>
              <Box className={styles.auctionCardButtonBox}>
                <GradientButtonBlue className={styles.gradientButton} fullWidth={true}>

                  {!isMobile ? (
                    <Typography variant="body1" component="span">
                      {t("PLACE_YOUR_BID")}
                    </Typography>
                  ) : (
                    <Typography variant="body2" component="span" fontSize="14px">
                      {t("PLACE_YOUR_BID")}
                    </Typography>
                  )}
                </GradientButtonBlue>
              </Box>
            </div>
          )}
          <AddToCollectionModal
            open={openAddToCollectionModal}
            handleClose={() => setOpenAddToCollectionModal(false)}
            darkMode={darkMode}
          />
          <DeleteFromCollection
            open={openDeleteFromCollectionModal}
            handleClose={() => setOpenDeleteFromCollectionModal(false)}
            darkMode={darkMode}
          />
          <RemoveFromCollection
            open={openRemoveFromCollectionModal}
            handleClose={() => setOpenRemoveFromCollectionModal(false)}
            darkMode={darkMode}
          />
        </Grid>
      }
    </Grow>
  );
};

export default SellerDetailsCardConvert;
