/* eslint-disable no-unused-vars */
import DataContext from './DataContext';
import React, { useEffect, useState, useContext } from "react";
// From Material UI
import {
    GradientButtonPrimary,
    GradientButtonSecondary,
} from "../../Utils/GradientButtons/GradientButtons";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Backdrop,
    Button,
    Divider,
    Fade,
    IconButton,
    Modal,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
// Custom Gradient button

// Icons
import LinkIcon from "@mui/icons-material/Link";
import { FiChevronLeft } from "react-icons/fi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { HiOutlineClock } from "react-icons/hi";
import { BsFillBookmarkFill, BsBookmark, BsChevronDown } from "react-icons/bs";
import { HiTemplate } from "react-icons/hi";
import { IoCart } from "react-icons/io5";
import PolygonIcon from "../../assets/Icons/darkUIIcons/polygon.svg";
import OpenseaIcon from "../../assets/opensea-logo.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { biddingData } from "./biddingData";
import noImageICon from "../../assets/no_image-mint.svg";

// Tabpanel
import { TabPanel } from "./TabPanel";

// Styles
import styles from "./SingleArtWork.module.css";
import { etherscanAddressURL, maticcanAddressURL } from '../../service/api';
function UserProfileCollection({ fa, darkMode }) {
    const { largeData } = useContext(DataContext);
    const artworkImage = largeData?.media[0]?.gateway ? largeData?.media[0]?.gateway : noImageICon;
    const artworkTitle = largeData?.title ? largeData?.title : "No Title For this NFT";
    const artworkDetails = largeData?.description;
    const artworkPrice = "";
    const creator = largeData?.contract?.contractDeployer;
    const creatorImage = "";
    const owner = largeData?.account;
    const ownerImage = "";
    const uploaded = 5;
    const likes = 10;
    const contractAddress = largeData?.contract?.address
    const chain = largeData?.blockchain === "Polygon" ? "mumbai" : "sepolia"
    const scanURL = largeData?.blockchain === "Polygon" ? maticcanAddressURL : etherscanAddressURL;
    const currentMint = "";
    const timeStamp = largeData?.timeLastUpdated;
    // States
    const [openModal, setOpenModal] = React.useState(false);
    const [likeState, setLikeState] = useState(false);
    const [bookmarkState, setBookmarkState] = useState(false);
    const [tabValue, setTabValue] = useState(0); // setting tab value for changing
    const [userCollection, setUserCollection] = useState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const navigate = useNavigate();


    const convertPriceToUSD = (artworkPrice) => {
        // Define static conversion rates for BTC and ETH
        const btcToUsdRate = 26501; // Conversion rate for BTC to USD
        const ethToUsdRate = 1728; // Conversion rate for ETH to USD

        // Extract the currency and amount from the artworkPrice string
        const [amount, currency] = artworkPrice.split(" ");

        // Convert the price to USD based on the currency
        let usdPrice;
        if (currency === "BTC") {
            usdPrice = parseFloat(amount) * btcToUsdRate;
        } else if (currency === "ETH") {
            usdPrice = parseFloat(amount) * ethToUsdRate;
        } else {
            // Invalid or unsupported currency
            return null;
        }

        // Return the price in USD
        return Math.floor(usdPrice);
    };





    useEffect(() => {
        setUserCollection(largeData)
    }, [largeData])




    // Tab handler
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [loading, setLoading] = useState(true);
    // Custom Mobile Tabs
    const MobileTabs = styled(Tabs)({
        border: "none",
        backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
        "& .MuiTabs-indicator": {
            backgroundColor: "inherit",
        },
    });

    const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        "&.Mui-selected": {
            color: "#ffffff",
            backgroundColor: "#0294FE",
            borderRadius: "4px",
            fontWeight: theme.typography.fontWeightMedium,
        },
    }));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        // Artwork details information
        <>
            {
                // userCollection?.dataType === "userCollection" ?
                //     (<> user profile collection</>) :
                (<>
                    <Modal
                        sx={{ zIndex: 500000 }}
                        open={openModal}
                        onClose={handleCloseModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openModal}>
                            <Box
                                bgcolor={darkMode ? "#171c26" : "#EEEEEE"}
                                className={
                                    !isMobile ? styles.modalStyleWeb : styles.modalStyleMobile
                                }
                            >
                                <Typography
                                    className={styles.itemDetailsModalTitle}
                                    color="secondary"
                                    variant="h6"
                                    component="div"
                                >
                                    <Typography component="span" color="secondary" sx={{ mt: 0.3 }}>
                                        <HiTemplate fontSize={"1.5rem"} />
                                    </Typography>
                                    <Typography variant="h6" component="span" mt={-0.2}>
                                        {t("ITEM_DETAILS")}
                                    </Typography>
                                </Typography>
                                <Typography
                                    // This is global styles
                                    className={
                                        !isMobile
                                            ? styles.readMoreModalText
                                            : styles.readMoreModalTextMobile
                                    }
                                    variant="body2"
                                    component="p"
                                    color="secondary"
                                    lineHeight={2}
                                    height={250}
                                    pr={2.5}
                                >
                                    {artworkDetails}
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>
                    <Box mt={11} className={styles.detailsContainerBox}>
                        <Box>
                            {/* Top navigation */}
                            {!isMobile ? (
                                <Box className={styles.topNavigationBox}>
                                    <Box
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => window.history.back()}
                                    >
                                        <FiChevronLeft fontSize={"1.5rem"} />
                                    </Box>
                                    <Typography
                                        className={styles.topNavigationTypo}
                                        component="div"

                                    >
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            sx={{ cursor: "pointer" }}
                                            zIndex={2}
                                            color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                                        >
                                            {t("ITEM_DETAILS")}
                                        </Typography>

                                    </Typography>
                                </Box>
                            ) : (
                                <Box className={styles.topNavigationBoxMobile}>
                                    <Box
                                        width={"70px"}
                                        height={"60px"}
                                        mt={-1}
                                        bgcolor={`${darkMode ? "#040404" : "#ffffff"}`}
                                    >
                                        <FiChevronLeft
                                            onClick={() => window.history.back()}
                                            fontSize={"1.5rem"}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            position: "fixed",
                                            top: "3%",
                                            zIndex: "10000",
                                            width: "70%",
                                            display: "flex",
                                            justifyContent: "center",
                                            ml: 4,
                                        }}
                                    >
                                        <Typography
                                            component="div"

                                            position="relative"
                                            display="flex"
                                            alignItems="center"
                                            ml={4}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                component="p"
                                                sx={{ zIndex: 2 }}
                                            >
                                                {t("ITEM_DETAILS")}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {/* Details container */}
                            {!isMobile ? (
                                <Box className={styles.detailsContainer}>
                                    <Box zIndex={10} height={"100%"}>
                                        <Box
                                            sx={{
                                                position: "relative",
                                            }}
                                        >
                                            <img
                                                className={styles.artDisplayImage}
                                                src={artworkImage}
                                                alt={artworkTitle}
                                                onLoad={() => setLoading(false)}
                                                loading="eager"
                                            />
                                        </Box>
                                        <Box
                                            className={styles.artDisplayDetails}
                                            bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                                            sx={{
                                                // mt: 3,
                                                marginTop:"25%",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    p: 3,
                                                }}
                                            >
                                                <Box gap={1}>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("CONTRACT_ADDRESS")}
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="p"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                fontWeight={500}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {contractAddress.substring(0, 6) + "..." + contractAddress.substring(contractAddress.length - 3)}
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                ml={1}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <ContentCopyIcon />
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TOKEN_ID")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                              {largeData?.tokenId.length > 15 ? largeData?.tokenId?.substring(0, 6) + "..." + largeData?.tokenId.substring(largeData?.tokenId.length - 3) : largeData?.tokenId}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TOKEN_STANDARD")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.tokenType}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("BLOCKCHAIN")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                           {largeData?.blockchain === "Polygon" ? "Polygon" : "Ethereum"}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("ROYALTY_FEE")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {5 + "%"}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TRANSACTION_FEE")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            fontWeight={500}
                                                        >
                                                            5%
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            className={styles.artDisplayDetails}
                                            sx={{
                                                mt: 2,
                                            }}
                                        >
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Button
                                                    variant="contained"
                                                    className={styles.artDisplayDetailsButton}
                                                    sx={{
                                                        p: 0,
                                                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                                    }}
                                                    onClick={() => window.open(`https://testnets.opensea.io/assets/${chain}/${contractAddress}/${largeData?.tokenId}`, "_blank")}
                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <img
                                                            src={OpenseaIcon}
                                                            alt="Opnesea Icon"
                                                            width="20"
                                                            height={"20"}
                                                        />
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        View on Opensea
                                                    </Typography>
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        align="right"
                                                    >
                                                        <LinkIcon />
                                                    </Typography>
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    className={styles.artDisplayDetailsButton}
                                                    sx={{
                                                        p: 0,
                                                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                                    }}
                                                    onClick={() => window.open(`${scanURL}/${contractAddress}`, "_blank")}

                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <img
                                                            src={PolygonIcon}
                                                            alt="Polygon Icon"
                                                            width="20"
                                                            height={"20"}
                                                        />
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        PolygonScan
                                                    </Typography>
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <LinkIcon />
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        className={styles.detailsContainerContentBox}
                                        bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                                        sx={{
                                            position: "relative",
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                component="h2"
                                                color="secondary.main"
                                                mb={4}
                                                fontWeight={500}
                                            >
                                                {artworkTitle}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                component="div"
                                                lineHeight={1.5}
                                                color="secondary.main"
                                                mb={2}
                                                textAlign="justify"
                                            >
                                                {artworkDetails?.length > 200
                                                    ? artworkDetails.slice(0, 200)
                                                    : artworkDetails}
                                                {artworkDetails.length > 200 && (
                                                    <Button
                                                        variant="text"
                                                        onClick={handleOpenModal}
                                                        sx={{ color: "#02C7FE", textTransform: "lowercase" }}
                                                    >
                                                        ...{t("READ_MORE")}
                                                    </Button>
                                                )}
                                            </Typography>

                                            <Accordion
                                                disableGutters
                                                elevation={0}
                                                sx={{
                                                    backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                                                    mb: 3,
                                                    border: "none",
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <Typography component="span" color="secondary">
                                                            <BsChevronDown
                                                                fontSize={"1rem"}
                                                                style={{ marginTop: "5px" }}
                                                            />
                                                        </Typography>
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        color="secondary"
                                                        ml={-2}
                                                        fontWeight={500}
                                                    >
                                                        {t("PROPERTIES")}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ border: "none", ml: -2 }}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        {largeData?.properties &&
                                                            largeData?.properties.length > 0 &&
                                                            largeData?.properties[0].key !== "" ? (
                                                            <>
                                                                {largeData.properties?.map((property) => (
                                                                    <Box
                                                                        className={styles.propBox}
                                                                        bgcolor={darkMode ? "#040404" : "#ffffff"}
                                                                    >
                                                                        <Typography
                                                                            variant="button"
                                                                            component="p"
                                                                            color="#02C7FE"
                                                                        >
                                                                            {property.key}
                                                                        </Typography>
                                                                        <Typography variant="body2" color="secondary">
                                                                            {property.value}
                                                                        </Typography>
                                                                    </Box>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography variant="body2" color="secondary">
                                                                    {t("NO_PROPERTIES_FOUND")}
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>

                                            {/* <>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {t(`PRICE`)}
                                                </Typography>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    color="secondary.main"
                                                    mb={3}
                                                    fontWeight={500}
                                                >
                                                    {artworkPrice}

                                                </Typography>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    color="secondary.main"
                                                    mb={3}
                                                    fontWeight={500}
                                                >
                                                    {convertPriceToUSD(artworkPrice)} USD

                                                </Typography>
                                            </> */}

                                            <Divider className={styles.dividerBox} />
                                            <Box className={styles.singleArtCardInfo}>
                                                <Box className={styles.avatarBox}>
                                                    <Box>
                                                        <Avatar src={creatorImage} alt={creator} />
                                                    </Box>
                                                    <Stack direction="column">
                                                        <Typography variant="caption" gutterBottom color="gray">
                                                            {t("CREATOR")}
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            gutterBottom
                                                            color="secondary.main"
                                                            sx={{
                                                                textTransform: "lowercase",
                                                                maxWidth: "90px",
                                                            }}
                                                            noWrap={true}
                                                        >
                                                            {creator}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                                <Box className={styles.avatarBox}>
                                                    <Box>
                                                        <Avatar src={ownerImage} alt={owner} />
                                                    </Box>
                                                    <Stack direction="column">
                                                        <Typography variant="caption" gutterBottom color="gray">
                                                            {t("OWNER")}
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            gutterBottom
                                                            color="secondary.main"
                                                            sx={{
                                                                textTransform: "lowercase",
                                                                maxWidth: "90px",
                                                            }}
                                                            noWrap={true}
                                                        >
                                                            {owner}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                            <Divider className={styles.dividerBox} />
                                            <Box className={styles.cardFooter}>
                                                <Stack direction="row" alignItems="center">
                                                    <IconButton>
                                                        <Typography component="span" color="secondary">
                                                            <HiOutlineClock className={styles.footerIcons} />
                                                        </Typography>
                                                    </IconButton>
                                                    <Typography
                                                        variant="caption"
                                                        component="span"
                                                        color={darkMode ? "#ffffff" : "#121212"}
                                                    >
                                                        {timeStamp}
                                                    </Typography>
                                                </Stack>
                                                {/* <Stack direction="row" alignItems="center" gap={2}>
                                                    <Box className={styles.userInteractionBox}>
                                                        <IconButton onClick={() => setLikeState(!likeState)}>
                                                            <Typography color="#e23e58" component="span">
                                                                <AiOutlineHeart className={styles.footerIcons} />
                                                            </Typography>
                                                        </IconButton>
                                                        <Typography
                                                            variant="caption"
                                                            component="span"
                                                            color={darkMode ? "#ffffff" : "#121212"}
                                                        >
                                                            {likes}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <IconButton>
                                                            <Typography component="span" color="secondary">
                                                                <BsBookmark className={styles.footerIcons} />
                                                            </Typography>
                                                        </IconButton>
                                                    </Box>
                                                </Stack> */}
                                            </Box>
                                        </Box>

                                        {/* <>
                                            <Box className={styles.footerButtonContainer}>
                                                <GradientButtonPrimary
                                                    className={styles.gradientButtonClass}
                                                    variant="contained"
                                                    fullWidth={true}
                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={0.5}
                                                    >
                                                        <IoCart />
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        {t("BUY_NOW")}
                                                    </Typography>
                                                </GradientButtonPrimary>
                                            </Box>
                                        </> */}
                                    </Box>
                                </Box>
                            ) : (
                                <Box className={styles.detailsContainerMobile}>
                                    <Box zIndex={10}>
                                        <img
                                            className={styles.artDisplayImageMobile}
                                            src={artworkImage}
                                            alt={artworkTitle}
                                            loading="eager"
                                        />
                                    </Box>
                                    <Box className={styles.userInteractionBoxMobile}>
                                        <Stack direction="row" alignItems="center">
                                            <IconButton>
                                                <Typography component="span" color="secondary">
                                                    <HiOutlineClock className={styles.footerIcons} />
                                                </Typography>
                                            </IconButton>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                color={darkMode ? "#ffffff" : "#121212"}
                                            >
                                                {uploaded} {t("HOURS_AGO")}
                                            </Typography>
                                        </Stack>
                                        {/* <Stack direction="row" alignItems="center" gap={2}>
                                            <Box className={styles.userInteractionBoxMobile}>
                                                <IconButton onClick={() => setLikeState(!likeState)}>
                                                    <Typography component="span" color="secondary">
                                                        <AiOutlineHeart className={styles.footerIcons} />
                                                    </Typography>
                                                </IconButton>
                                                <Typography
                                                    variant="caption"
                                                    component="span"
                                                    color={darkMode ? "#ffffff" : "#121212"}
                                                >
                                                    {likes}
                                                </Typography>
                                            </Box>
                                            <Box mt={-0.5}>
                                                <IconButton>
                                                    <Typography component="span" color="secondary">
                                                        <BsBookmark className={styles.footerIcons} />
                                                    </Typography>
                                                </IconButton>
                                            </Box>
                                        </Stack> */}
                                    </Box>
                                    <Box>
                                        <Box className={styles.cardFooterMobile}>
                                            <Box sx={{ display: "flex", gap: 2 }}>
                                                <Box>
                                                    <Avatar src={creatorImage} alt={creator} />
                                                </Box>
                                                <Stack direction="column" alignItems="left">
                                                    <Typography variant="caption" gutterBottom color="gray">
                                                        {t("CREATOR")}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        gutterBottom
                                                        color="secondary.main"
                                                        sx={{
                                                            textTransform: "lowercase",
                                                            maxWidth: "90px",
                                                        }}
                                                        noWrap={true}
                                                    >
                                                        {creator}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                            <Box sx={{ display: "flex", gap: 2 }}>
                                                <Box>
                                                    <Avatar src={ownerImage} alt={owner} />
                                                </Box>
                                                <Stack direction="column" alignItems="left">
                                                    <Typography variant="caption" gutterBottom color="gray">
                                                        {t("OWNER")}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        gutterBottom
                                                        color="secondary.main"
                                                        sx={{
                                                            textTransform: "lowercase",
                                                            maxWidth: "90px",
                                                        }}
                                                        noWrap={true}
                                                    >
                                                        {owner}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography
                                                variant="subtitle2"
                                                component="h2"
                                                color="secondary.main"
                                                mb={2}
                                            >
                                                {artworkTitle}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                lineHeight={1.8}
                                                mb={2}
                                                color="secondary.main"
                                                textAlign="left"
                                            >
                                                {artworkDetails?.length > 200
                                                    ? artworkDetails?.slice(0, 200)
                                                    : artworkDetails}
                                                {artworkDetails.length > 200 && (
                                                    <Button
                                                        variant="text"
                                                        onClick={handleOpenModal}
                                                        sx={{ fontSize: "11px", color: "#02C7FE" }}
                                                    >
                                                        ...{t("READ_MORE")}
                                                    </Button>
                                                )}
                                            </Typography>

                                            {/* <>
                                                <Typography
                                                    variant="subtitle2"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={1}
                                                >
                                                    {t("PRICE")}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    component="p"
                                                    color="secondary.main"
                                                    mb={1}
                                                    fontWeight={500}
                                                >
                                                    {artworkPrice}
                                                </Typography>
                                            </> */}
                                        </Box>
                                        <Box
                                            className={styles.artDisplayDetailsMobile}
                                            sx={{
                                                mt: 2,
                                            }}
                                        >
                                            <Box className={styles.artDisplayDetailsContentMob} gap={2}>
                                                <Button
                                                    variant="contained"
                                                    className={styles.artDisplayDetailsButtonMob}
                                                    sx={{
                                                        p: 0,
                                                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                                    }}
                                                    onClick={() => {
                                                        window.open(largeData.file, "_blank");
                                                    }}
                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <img
                                                            src={OpenseaIcon}
                                                            alt="Opensea Icon"
                                                            width="20"
                                                            height={"20"}
                                                        />
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        View on Opensea
                                                    </Typography>
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        align="right"
                                                    >
                                                        <LinkIcon />
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className={styles.artDisplayDetailsButtonMob}
                                                    sx={{
                                                        p: 0,
                                                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                                    }}
                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <img
                                                            src={PolygonIcon}
                                                            alt="Polygon Icon"
                                                            width="20"
                                                            height={"20"}
                                                        />
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        View on PolygonScan
                                                    </Typography>
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <LinkIcon />
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Box
                                            className={styles.artDisplayDetailsMobile}
                                            bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                                            sx={{
                                                mt: 3,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    p: 3,
                                                }}
                                            >
                                                <Box gap={1}>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            Contract Address
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="p"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                fontWeight={350}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {contractAddress.substring(0, 6) + "..." + contractAddress.substring(contractAddress.length - 3)}
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                ml={1}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <ContentCopyIcon />
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TOKEN_ID")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.tokenId.length > 15 ? largeData?.tokenId?.substring(0, 6) + "..." + largeData?.tokenId.substring(largeData?.tokenId.length - 3) : largeData?.tokenId}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TOKEN_STANDARD")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.tokenType}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("BLOCKCHAIN")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.blockchain === "Polygon" ? "Polygon" : "Ethereum"}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("ROYALTY_FEE")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {5 + "%"}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            Transaction Fee
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            fontWeight={500}
                                                        >
                                                            5%
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* <Box className={styles.footerButtonContainer}>
                                            <GradientButtonPrimary
                                                className={styles.gradientButtonClass}
                                                variant="contained"
                                                fullWidth={true}
                                            >
                                                <Typography
                                                    color="#ffffff"
                                                    component="span"
                                                    fontSize={20}
                                                    mt={0.5}
                                                >

                                                </Typography>
                                                <Typography variant="body1" component="span">
                                                    {t("BUY_NOW")}
                                                </Typography>
                                            </GradientButtonPrimary>
                                        </Box> */}
                                    </Box>
                                </Box>
                            )}
                        </Box>

                    </Box>

                </>)}
        </>
    );
};

export default UserProfileCollection;
