import {
  Backdrop,
  Box,
  Fade,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

// Icons
import BinanceWalletIcon from "../../assets/Icons/darkUIIcons/binanceWalletIcon.svg";
import MetaMaskIcon from "../../assets/Icons/darkUIIcons/metaMaskIcon.svg";
import IndexxWalletIcon from "../../assets/Icons/darkUIIcons/indexx_wallet_image.svg";
import TrustwalletIcon from "../../assets/Icons/darkUIIcons/trustWalletIcon.svg";
import MetaMaskIconLight from "../../assets/Icons/lightUIIcons/metaMaskIcon.svg";
import Indexxgreen from "../../assets/Icons/darkUIIcons/Wallet-45-20-1.png";
import Indexxorange from "../../assets/Icons/darkUIIcons/Wallet-45-21-1.png";


import { FaWallet } from "react-icons/fa";

import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

import styles from "./PopUp.module.css";

const ConnectWalletPopUp = ({
  openModal,
  handleCloseModal,
  darkMode,
  handleConnectWallet,
  handleConnectIndexxWallet,
  handleDisconnectWallet,
  walletAddress,
  blockchain
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Modal
      sx={{ zIndex: 500000 }}
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        
        {!isMobile? (
          <Box
            bgcolor={darkMode ? "#171c26" : "#ffffff"}
            className={styles.modalStyle}
          >
            <Box display="flex" alignItems="center" gap={2}>

              <Typography
                variant="h6"
                component="span"
                pb={"5px"}
                color="secondary"
              >
                {t("CONNECT_TO_WALLET")}
              </Typography>
            </Box>

            <Box mt={4}>
              <Grid
                container
                columns={{ xs: 12, md: 12 }}
                spacing={{ xs: 2, md: 2 }}
              >
                 <Grid item xs={12} md={12}>
                  <Box
                    backgroundColor={darkMode ? "#171C26" : "#ffffff"}
                    onClick={() => {handleConnectIndexxWallet();
                      handleCloseModal();
                    }}
                    disableElevation
                    color="accent"
                    variant="contained"
                    sx={{  '&:hover': {
                        backgroundColor: `${darkMode ? "#000" : "#e0e0e0"}`,
                        color:"red"
                      },}}
                    className={styles.buttonStyle}
                  >
                    <img
                      style={{ width: "60px" }}
                      src={Indexxgreen}
                      alt="XWallet"
                    />
                    <Typography
                      variant="span"
                      color="secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Indexx Wallet Web
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
              

                  <Box
                    bgcolor={darkMode ? "#171C26" : "#ffffff"}
                    // onClick={handleConnectIndexxWallet}
                    disableElevation
                    color="accent"
                    variant="contained"
                    sx={{  '&:hover': {
                        backgroundColor: `${darkMode ? "#000" : "#e0e0e0"}`,
                        color:"red"
                      },}}
                    className={styles.buttonStyle}
                    href="https://chrome.google.com/webstore/detail/indexx-wallet/fpibioaihcagphbidhodidjbnclocgll"
                  >
                    <img
                      style={{ width: "60px" }}
                      src={Indexxorange}
                      alt="XWallet"
                    />
                    <Typography
                      variant="span"
                      color="secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Indexx Wallet Extension
                    </Typography>
                  </Box>
                
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    bgcolor={darkMode ? "#171C26" : "#ffffff"}
                    onClick={() => {handleConnectWallet();
                    handleCloseModal();
                    }}
                    sx={{  '&:hover': {
                        backgroundColor: `${darkMode ? "#000" : "#e0e0e0"}`,
                        color:"red"
                      },}}
                    className={styles.buttonStyle}
                  >
                    {darkMode ? (
                      <img src={MetaMaskIcon} width={60} alt="Meta Mask" />
                    ) : (
                      <img src={MetaMaskIconLight} width={60} alt="Meta Mask" />
                    )}
                    <Typography
                      variant="span"
                      color="secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      MetaMask
                    </Typography>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} md={12}>
                  <Box
                    bgcolor={darkMode ? "#000000" : "#EEEEEE"}
                    // onClick={handleDisconnectWallet}
                    disableElevation
                    color="accent"
                    variant="contained"
                    className={styles.buttonStyle}
                  >
                    <img
                      style={{ width: "50px", height: "50px" }}
                      src={BinanceWalletIcon}
                      alt="Binance Wallet"
                    />
                    <Typography
                      variant="span"
                      color="secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Binance
                    </Typography>
                  </Box>
                </Grid> */}
                {/* <Grid item xs={12} md={12}>
                  <Box
                    bgcolor={darkMode ? "#000000" : "#EEEEEE"}
                    // onClick={handleDisconnectWallet}
                    disableElevation
                    color="accent"
                    variant="contained"
                    className={styles.buttonStyle}
                  >
                    <img src={TrustwalletIcon} alt="Trust Wallet" />
                    <Typography
                      variant="span"
                      color="secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Trust Wallet
                    </Typography>
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box
            className={styles.modalStyleMobile}
            bgcolor={darkMode ? "#171c26" : "#ffffff"}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              justifyContect="center"
            >
              <Typography component="span" color="secondary">
                <FaWallet />
              </Typography>
              <Typography
                variant="subtitle1"
                color="secondary"
                pb={"5px"}
                borderBottom={`2px solid ${darkMode ? "#ffffff" : "#121212"}`}
              >
                {t("CONNECT_TO_WALLET")}
              </Typography>
            </Box>
            <Box mt={4}>
              <Grid
                container
                columns={{ xs: 1, sm: 12 }}
                spacing={{ xs: 2, sm: 4 }}
              >
                <Grid item xs={6} sm={6}>
                  <Box
                    bgcolor={darkMode ? "#171c26" : "#ffffff"}
                    onClick={handleConnectIndexxWallet}
                    disableElevation
                    color="accent"
                    variant="contained"
                    sx={{  '&:hover': {
                        backgroundColor: `${darkMode ? "#000" : "#e0e0e0"}`,
                        color:"red"
                      },}}
                    className={styles.buttonStyleMobile}
                  >
                    {/* {darkMode ? (
                      <img
                        style={{ width: "30px" }}
                        src={MetaMaskIcon}
                        alt="Meta Mask"
                      />
                    ) : (
                      <img
                        style={{ width: "30px" }}
                        src={MetaMaskIconLight}
                        alt="Meta Mask"
                      />
                    )} */}
                    <img
                      style={{ width: "30px" }}
                      src={IndexxWalletIcon}
                      alt="XWallet"
                    />
                    <Typography
                      variant="span"
                      color="secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Indexx Wallet Web
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item xs={6} sm={6}>
                  <Box
                    bgcolor={darkMode ? "#000000" : "#ffffff"}
                    // onClick={handleDisconnectWallet}
                    disableElevation
                    color="accent"
                    variant="contained"
                    className={styles.buttonStyleMobile}
                  >
                    <img
                      style={{ width: "30px" }}
                      src={BinanceWalletIcon}
                      alt="Binance Wallet"
                    />
                    <Typography
                      variant="span"
                      color="secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Binance
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box
                    bgcolor={darkMode ? "#000000" : "#ffffff"}
                    // onClick={handleDisconnectWallet}
                    disableElevation
                    color="accent"
                    variant="contained"
                    className={styles.buttonStyleMobile}
                  >
                    <img
                      style={{ width: "30px" }}
                      src={TrustwalletIcon}
                      alt="Trust Wallet"
                    />
                    <Typography
                      variant="span"
                      color="secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Trust Wallet
                    </Typography>
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        )}
      </Fade>
    </Modal>
  );
};

export default ConnectWalletPopUp;