import { useTheme } from "@emotion/react";
import { Divider, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronLeft } from "react-icons/fi";

const TermsAndCondition = ({ darkMode }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!isMobile ? (
        <Box>
          <Box
            color={darkMode ? "#ffffff" : "#121212"}
            sx={{
              position: "relative",
              display: "flex",
              gap: 4,
              alignItems: "center",
              mt: 11,
              mb: 5,
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <FiChevronLeft fontSize={"1.5rem"} />
            </Box>
            <Typography
              component="div"
              sx={{
                // borderBottom: `2px solid ${darkMode ? "#ffffff" : "#121212"}`,
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="p"
                sx={{ zIndex: 2, cursor: "pointer" }}
              >
                {t("FOOTER_LINK_TERMS_CONDITION")}
              </Typography>
            </Typography>
          </Box>
          <Box
            bgcolor={darkMode ? "#171c26" : "#EEEEEE"}
            color={darkMode ? "#ffffff" : "#121212"}
            sx={{
              borderRadius: "16px",
              p: 6,
            }}
          >
            <Typography variant="h6" component="h6" sx={{ mb: 3 }}>
              {t("LAST_UPDATED")}: 24 May 2023
            </Typography>
            <Divider sx={{ backgroundColor: "#8E8E8E", opacity: "0.7" }} />
            <Box>
              <Typography variant="h6" component="h6" sx={{ my: 2 }}>
                {t("ACCEPTING_THE_TERMS")}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                sx={{
                  lineHeight: 2,
                  textAlign: "justify",
                  color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                }}
              >
                {/* {t('TERMS_CONDITION_DESC')} */}
                <p>
                  Please read these Terms and Conditions ("Terms") carefully
                  before accessing or using the XNFT services. These Terms
                  govern your access to and use of the XNFT website and related
                  services. By accessing or using our services, you agree to be
                  bound by these Terms. If you do not agree with any part of
                  these Terms, you may not access or use the services.
                </p>
                <p>Definitions</p>
                <p>
                  1.1. "XNFT" refers to the company providing the services and
                  may be referred to as "we," "us," or "our."
                </p>
                <p>
                  1.2. "Services" refer to the XNFT website and any related
                  features, tools, or applications provided by XNFT.
                </p>
                <p>User Eligibility</p>
                <p>
                  2.1. By accessing or using our services, you represent and
                  warrant that you are at least 18 years old or have the legal
                  capacity to enter into binding agreements. If you are
                  accessing or using the services on behalf of an entity, you
                  represent and warrant that you have the necessary authority to
                  bind that entity to these Terms.
                </p>
                <p>User Account</p>
                <p>
                  3.1. In order to access certain features of our services, you
                  may need to create a user account. You are responsible for
                  maintaining the confidentiality of your account credentials
                  and for all activities that occur under your account.
                </p>
                <p>
                  3.2. You agree to provide accurate, complete, and up-to-date
                  information when creating your account and to promptly update
                  any changes.
                </p>
                <p>
                  3.3. You are solely responsible for any actions taken through
                  your account, and you must notify us immediately of any
                  unauthorized use or security breach.
                </p>
                <p>Intellectual Property</p>
                <p>
                  4.1. The XNFT services and all related content, including but
                  not limited to text, graphics, images, logos, icons, designs,
                  software, and audio/video clips, are the property of XNFT or
                  its licensors and are protected by copyright, trademark, and
                  other intellectual property laws.
                </p>
                <p>
                  4.2. You are granted a limited, non-exclusive, and
                  non-transferable license to access and use the services for
                  personal, non-commercial purposes, subject to these Terms.
                </p>
                <p>
                  4.3. You may not reproduce, modify, distribute, transmit,
                  display, perform, publish, license, create derivative works
                  from, or sell any information, software, products, or services
                  obtained from the XNFT services without our prior written
                  consent.
                </p>
                <p>Prohibited Activities</p>
                <p>5.1. When using our services, you agree not to:</p>
                <p>
                  5.1.1. Violate any applicable laws, regulations, or
                  third-party rights.
                </p>
                <p>
                  5.1.2. Engage in fraudulent, deceptive, or illegal activities.
                </p>
                <p>
                  5.1.3. Use automated systems or software to extract data from
                  our services without our prior written consent.
                </p>
                <p>5.1.4. Introduce viruses, malware, or other harmful code.</p>
                <p>
                  5.1.5. Attempt to gain unauthorized access to our systems or
                  interfere with the proper functioning of the services.
                </p>
                <p>
                  5.1.6. Engage in any activity that could damage, disable,
                  overburden, or impair our servers or networks.
                </p>
                <p>Limitation of Liability</p>
                <p>
                  6.1. XNFT and its affiliates, officers, directors, employees,
                  agents, and suppliers shall not be liable for any direct,
                  indirect, incidental, special, consequential, or punitive
                  damages arising out of or in connection with your use of the
                  services.
                </p>
                <p>
                  6.2. XNFT does not guarantee the accuracy, completeness, or
                  reliability of any content or information provided through the
                  services. You acknowledge and agree to use the services at
                  your own risk.
                </p>
                <p>Indemnification</p>
                <p>
                  7.1. You agree to indemnify, defend, and hold harmless XNFT
                  and its affiliates, officers, directors, employees, agents,
                  and suppliers from and against any claims, liabilities,
                  damages, losses, costs, or expenses arising out of or in
                  connection with your use of the services or any violation of
                  these Terms.
                </p>
                <p>Modifications and Termination</p>
                <p>
                  8.1. XNFT reserves the right, at its sole discretion, to
                  modify or terminate the services, or to modify these Terms, at
                  any time without prior notice. Updated Terms will be effective
                  upon posting on the XNFT website. Your continued use of the
                  services after any modifications constitutes your acceptance
                  of the updated Terms.
                </p>
                <p>Governing Law and Jurisdiction</p>
                <p>
                  9.1. These Terms shall be governed by and construed in
                  accordance with the laws of [Insert applicable jurisdiction].
                  Any disputes arising out of or in connection with these Terms
                  shall be subject to the exclusive jurisdiction of the courts
                  located in [Insert applicable jurisdiction].
                </p>
                <p>Entire Agreement</p>
                <p>
                  10.1. These Terms constitute the entire agreement between you
                  and XNFT regarding the services and supersede all prior or
                  contemporaneous agreements, understandings, and
                  representations.
                </p>
                <p>Contact Us</p>
                <p>
                  11.1. If you have any questions, concerns, or inquiries about
                  these Terms, please contact us at{" "}
                  <a href="https://indexx.ai/" color="inherit">
                    {" "}
                    www.indexx.ai{" "}
                  </a>
                </p>
                <p>
                  Please note that these Terms & Conditions are subject to
                  change, and it is your responsibility to review them
                  periodically to stay informed of any updates.
                </p>
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              position: "fixed",
              top: "0%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10000",
              mt: 5,
            }}
          >
            <Typography
              variant="subtitle1"
              color="secondary"
              component="div"
              sx={{
                borderBottom: `${
                  darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                }`,
              }}
            >
              {t("FOOTER_LINK_TERMS_CONDITION")}
            </Typography>
          </Box>
          <Box
            color={darkMode ? "#ffffff" : "#121212"}
            sx={{
              borderRadius: "16px",
              mt: 5,
            }}
          >
            <Box>
              <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                {t("ACCEPTING_THE_TERMS")}
              </Typography>
              <Divider sx={{ backgroundColor: "#8E8E8E", opacity: "0.4" }} />
              <Typography
                variant="body2"
                component="p"
                sx={{
                  lineHeight: 2,
                  textAlign: "justify",
                  mt: 2,
                  color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                }}
              >
                {/* {t("TERMS_CONDITION_DESC")} */}
                <p>
                  Please read these Terms and Conditions ("Terms") carefully
                  before accessing or using the XNFT services. These Terms
                  govern your access to and use of the XNFT website and related
                  services. By accessing or using our services, you agree to be
                  bound by these Terms. If you do not agree with any part of
                  these Terms, you may not access or use the services.
                </p>
                <p>Definitions</p>
                <p>
                  1.1. "XNFT" refers to the company providing the services and
                  may be referred to as "we," "us," or "our."
                </p>
                <p>
                  1.2. "Services" refer to the XNFT website and any related
                  features, tools, or applications provided by XNFT.
                </p>
                <p>User Eligibility</p>
                <p>
                  2.1. By accessing or using our services, you represent and
                  warrant that you are at least 18 years old or have the legal
                  capacity to enter into binding agreements. If you are
                  accessing or using the services on behalf of an entity, you
                  represent and warrant that you have the necessary authority to
                  bind that entity to these Terms.
                </p>
                <p>User Account</p>
                <p>
                  3.1. In order to access certain features of our services, you
                  may need to create a user account. You are responsible for
                  maintaining the confidentiality of your account credentials
                  and for all activities that occur under your account.
                </p>
                <p>
                  3.2. You agree to provide accurate, complete, and up-to-date
                  information when creating your account and to promptly update
                  any changes.
                </p>
                <p>
                  3.3. You are solely responsible for any actions taken through
                  your account, and you must notify us immediately of any
                  unauthorized use or security breach.
                </p>
                <p>Intellectual Property</p>
                <p>
                  4.1. The XNFT services and all related content, including but
                  not limited to text, graphics, images, logos, icons, designs,
                  software, and audio/video clips, are the property of XNFT or
                  its licensors and are protected by copyright, trademark, and
                  other intellectual property laws.
                </p>
                <p>
                  4.2. You are granted a limited, non-exclusive, and
                  non-transferable license to access and use the services for
                  personal, non-commercial purposes, subject to these Terms.
                </p>
                <p>
                  4.3. You may not reproduce, modify, distribute, transmit,
                  display, perform, publish, license, create derivative works
                  from, or sell any information, software, products, or services
                  obtained from the XNFT services without our prior written
                  consent.
                </p>
                <p>Prohibited Activities</p>
                <p>5.1. When using our services, you agree not to:</p>
                <p>
                  5.1.1. Violate any applicable laws, regulations, or
                  third-party rights.
                </p>
                <p>
                  5.1.2. Engage in fraudulent, deceptive, or illegal activities.
                </p>
                <p>
                  5.1.3. Use automated systems or software to extract data from
                  our services without our prior written consent.
                </p>
                <p>5.1.4. Introduce viruses, malware, or other harmful code.</p>
                <p>
                  5.1.5. Attempt to gain unauthorized access to our systems or
                  interfere with the proper functioning of the services.
                </p>
                <p>
                  5.1.6. Engage in any activity that could damage, disable,
                  overburden, or impair our servers or networks.
                </p>
                <p>Limitation of Liability</p>
                <p>
                  6.1. XNFT and its affiliates, officers, directors, employees,
                  agents, and suppliers shall not be liable for any direct,
                  indirect, incidental, special, consequential, or punitive
                  damages arising out of or in connection with your use of the
                  services.
                </p>
                <p>
                  6.2. XNFT does not guarantee the accuracy, completeness, or
                  reliability of any content or information provided through the
                  services. You acknowledge and agree to use the services at
                  your own risk.
                </p>
                <p>Indemnification</p>
                <p>
                  7.1. You agree to indemnify, defend, and hold harmless XNFT
                  and its affiliates, officers, directors, employees, agents,
                  and suppliers from and against any claims, liabilities,
                  damages, losses, costs, or expenses arising out of or in
                  connection with your use of the services or any violation of
                  these Terms.
                </p>
                <p>Modifications and Termination</p>
                <p>
                  8.1. XNFT reserves the right, at its sole discretion, to
                  modify or terminate the services, or to modify these Terms, at
                  any time without prior notice. Updated Terms will be effective
                  upon posting on the XNFT website. Your continued use of the
                  services after any modifications constitutes your acceptance
                  of the updated Terms.
                </p>
                <p>Governing Law and Jurisdiction</p>
                <p>
                  9.1. These Terms shall be governed by and construed in
                  accordance with the laws of [Insert applicable jurisdiction].
                  Any disputes arising out of or in connection with these Terms
                  shall be subject to the exclusive jurisdiction of the courts
                  located in [Insert applicable jurisdiction].
                </p>
                <p>Entire Agreement</p>
                <p>
                  10.1. These Terms constitute the entire agreement between you
                  and XNFT regarding the services and supersede all prior or
                  contemporaneous agreements, understandings, and
                  representations.
                </p>
                <p>Contact Us</p>
                <p>
                  11.1. If you have any questions, concerns, or inquiries about
                  these Terms, please contact us at{" "}
                  <a href="https://indexx.ai/" color="inherit">
                    {" "}
                    www.indexx.ai{" "}
                  </a>
                </p>
                <p>
                  Please note that these Terms & Conditions are subject to
                  change, and it is your responsibility to review them
                  periodically to stay informed of any updates.
                </p>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TermsAndCondition;
