import { useState, useEffect } from "react";

const useAuth = () => {
  const [user, setUser] = useState({});

  // Sign Up the user
  const handleSignUpUser = (email, fullName, userName, password, navigate) => {
    setUser({
      email: email,
      fullName: fullName,
      userName: userName,
      password: password,
    });
    navigate("/");
  };

  // Sign in the user
  const handleSignInUser = (email, password, navigate, location) => {
    setUser({ email: email, password: password });
    const destination = location?.state?.from || "/";
    navigate(destination);
  };

  useEffect(() => {
    if (user?.email) {
      localStorage.setItem("users", JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("users"));

    if (localUser?.email) {
      setUser(localUser);
    } else {
      setUser({});
    }
  }, []);

  // Log out the user
  const handleLogOut = () => {
    setUser({});
    localStorage.removeItem("users");
  };

  return { user, handleSignUpUser, handleSignInUser, handleLogOut };
};

export default useAuth;
