/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import xnftlogo from './ftop.png';
import bottomlogo from './bottomm.png';
import btccoin from './btccoin.png';
import dummychart from './dummychart.png';
import styles from './SingleArtWork.module.css';
import circle from '../../assets/circle.svg';
import tokentop from './tokentop.svg';
import BTC from '../../assets/tokenImages/BTC.svg';
import ETH from '../../assets/tokenImages/ETH.svg';
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  Select,
  TextField,
  useMediaQuery,
  MenuItem,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@emotion/react';

import { Box } from '@mui/system';
import { useState } from 'react';

import { styled } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Popover } from '@mui/material';
import { ChromePicker } from 'react-color';
// Icons
import { TiTimes } from 'react-icons/ti';
import { MdAddToPhotos } from 'react-icons/md';
import { RiAddBoxFill } from 'react-icons/ri';
import { ImImage } from 'react-icons/im';
// import { BiMenuAltLeft } from "react-icons/bi";
import sampleimage from '../../assets/collectionImages/BTClogo.png';

import AssetProperModal from '../../components/AssetPropertiesModal/AssetProperModal';

import { useTranslation } from 'react-i18next';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';

import { uploadFile } from 'react-s3';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'green', // Change this to the desired outline color when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'gray', // Change this to the desired outline color when focused
      },
      '&:not(.Mui-focused) fieldset': {
        borderColor: 'white', // Change this to the desired outline color when not focused
      },
      '& input': {
        color: 'white', // Change this to the desired color of the entered input
      },
    },
    '& .MuiInputLabel-root': {
      color: 'white', // Change this to the desired color of the label
    },
  },
});

const TitleTextField = ({ titleName, handleTitleNameChange }) => {
  return (
    <div className={styles.textFieldContainer}>
      <Stack direction="row">
        <Typography
          color="secondary"
          sx={{ textAlign: 'left' }}
          variant="body2"
          component="p"
          fontSize={14}
        >
          {' '}
          <br />
          Title:
        </Typography>
        <TextField
          value={titleName}
          onChange={handleTitleNameChange}
          variant="outlined"
          sx={{ marginLeft: 'auto' }}
        />
      </Stack>
    </div>
  );
};

// const SerialNumberTextField = ({ serialNumber, handleSerialNumberChange }) => {
//   return (
//     <div className={styles.textFieldContainer}>
//       <Stack direction="row">
//         <Typography
//           color="secondary"
//           sx={{ textAlign: "left" }}
//           variant="body2"
//           component="p"
//           fontSize={14}
//         >
//           {" "}
//           <br />
//           Serial Number:
//         </Typography>
//         <TextField
//           value={serialNumber}
//           onChange={handleSerialNumberChange}
//           variant="outlined"
//           sx={{ marginLeft: "auto" }}
//           type="number"
//         />
//       </Stack>
//     </div>
//   );
// };

const Input = styled('input')({
  display: 'none',
});

const CustomTokenCover = ({
  selectedToken,
  companyName,
  websiteName,
  tokenPrice,
  handleTokenPrice,
  serialNumber,
  parentImage,
  handleTitleChangeParent,
  handleDescriptionChangeParent,
  handleColorChangeParent
}) => {
  const [selectedType, setSelectedType] = useState('');
  const [image, setImage] = useState(null);
  const [tokenColor, setTokenColor] = useState('#5A5A5A'); // Initial color state
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [fixedButtonToggler, setFixedButtonToggler] = useState(true);
  const [openButtonToggler, setOpenButtonToggler] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dateValueFrom, setDateValueFrom] = useState(new Date());
  const [dateValueTo, setDateValueTo] = useState(new Date());

  const [xnftName, setxnftName] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [propertiesDataState, setPropertiesDataState] = useState([
    { key: '', value: '' },
  ]);
  const [savedProperties, setSavedProperties] = useState([]);
  const [nftImage, setNftImage] = useState('');
  const [price, setPrice] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [generatedImageURL, setGeneratedImageURL] = useState(
    Array(10).fill(sampleimage)
  );

  const open = Boolean(anchorEl);
  const id = open ? "color-popover" : undefined;
  const [titleName, setTitleName] = useState("Enter Title Name");

  const classes = useStyles();

  // const [serialNumber, setSerialNumber] = useState("Enter Serial");

  // console.log('selected inside cover:',selectedToken)
  // const handleSerialNumberChange = (event) => {
  //   setSerialNumber(event.target.value);
  // };

  const handleTokenPriceChangeInternal = (event) => {
    const enteredTokenPrice = event.target.value;
    handleTokenPrice(enteredTokenPrice); // Call the handlePriceChange function with the entered price
  };
  const handleTitleNameChange = (event) => {
    setTitleName(event.target.value);
    handleTitleChangeParent(event);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    handleDescriptionChangeParent(event);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeTokenColor = (newColor) => {
    setTokenColor(newColor.hex);
    handleColorChangeParent(newColor)
  };

  // const handleImageUpload = (e) => {
  //   setImage(URL.createObjectURL(e.target.files[0]));
  //   setNftImage(e.target.files[0]);
  // };

  return (
    <>
      <Stack direction="row" spacing={6}
      sx={{
        width: `${!isMobile ? 'auto' : "326px" }`,
        // height: `${!isMobile ? 'auto' : "846px" }`,
      }}
      >
        <div>
          <Stack direction="column">
            <Box
              sx={{
                width: `${!isMobile ? '360px' : "326px" }`,
                height: '600px',
                backgroundColor: tokenColor,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '30px',
              }}
            >
              <br />
              <br />
              <div
                style={{
                  // backgroundImage: `url(${tokentop})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: `${!isMobile ? '360px' : "326px" }`,
                  height: "60px",
                  position: "relative",
                  marginTop: "-140px",
                  textAlign: 'center',
                  paddingBottom: '10px',
                  paddingTop: '20px',
                  borderRadius:"30px 30px 0px 0px"
                }}
              >

                <img
                  style={{
                    width: '70px',
                    objectFit: 'contain', // Ensure the image stays within the boundaries
                    padding: '5px',

                  }}
                  src={parentImage}
                  alt="Uploaded"
                />
              </div>


              <img
                src={
                  selectedToken === ''
                    ? circle
                    : selectedToken === 'btc'
                      ? BTC
                      : ETH
                }
                alt="IndexXNFT Logo"
                style={{ width: '200px', marginTop: 120, marginBottom: 20 }}
              />

              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'center',
                  color: 'white',
                }}
              >
                {titleName}
              </Typography>

              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                  marginBottom: -3,
                }}
              >
                {`${serialNumber}`.padStart(7, '0')}
              </Typography>
              <br />
              <Typography
                sx={{
                  fontWeight: "light",
                  fontSize: 14,
                  textAlign: "center",
                  color: "white",
                  marginTop: "50px",
                  marginBottom: "-40px",
                }}
              >
                {websiteName}
              </Typography>
            </Box>

            <br />
{isMobile ? 
  <div>
            <Typography
              color="secondary"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Combo Cover
            </Typography>

            <Typography
              color="secondary"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Description:
            </Typography>
            <TextField
              label="Description"
              variant="outlined"
              color="secondary"
              multiline
              rows={4}
              minWith="400px"
              sx={{ mb: 2, width: '100%' }}
              size="small" // Make the input box smaller
              onChange={handleDescriptionChange}
              // className={classes.root}
            />
            <Typography
              color="secondary"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Item Price:
            </Typography>
            <TextField
              label="Token Price in MATIC"
              variant="outlined"
              color="secondary"
              rows={1}
              type="number"
              sx={{ mb: 2, width: '100%' }}
              size="small"
              value={price} // Set the value of the TextField to the price state
              onChange={handleTokenPriceChangeInternal} // Update the price state when the user enters a value
              // className={classes.root}
            />
          </div>
:null}

            <div>
              <br />
              <TitleTextField
                titleName={titleName}
                handleTitleNameChange={handleTitleNameChange}
              />
              {/* <br />
              <SerialNumberTextField
                serialNumber={serialNumber}
                handleSerialNumberChange={handleSerialNumberChange}
              /> */}
              <br />
              <div>
                <Button
                  variant="contained"
                  onClick={handleClick}
                  // variant={fixedButtonToggler ? "contained" : "outlined"}
                  style={{
                    backgroundColor: '#040404',
                    border: '1px solid #c4c4c4',
                  }}
                  fullWidth={true}
                >
                <Typography color="white">
                  Select Background Color
                </Typography>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '50%',
                      backgroundColor: tokenColor,
                      marginLeft: 5,
                      display: 'inline-block',
                    }}
                  />
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box p={2}>
                    <ChromePicker
                      color={tokenColor}
                      onChange={handleChangeTokenColor}
                    />
                  </Box>
                </Popover>
              </div>
            </div>
          </Stack>
        </div>
        {!isMobile ?         
        <Box
          sx={{
            width: "430px",
            height: "270px",
            backgroundColor: "#171C26",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start", // Align to the left
            justifyContent: "center",
            borderRadius: "15px",
            padding: "20px", // Add some padding for better spacing
            paddingBottom: "480px"
          }}
        >
          <div>
            <Typography
              color="white"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Combo Cover
            </Typography>

            <Typography
              color="white"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Description:
            </Typography>
            <TextField
              label="Description"
              variant="outlined"
              // color="secondary"
              multiline
              rows={4}
              minWith="400px"
              sx={{ mb: 2, minWidth: '420px' }}
              size="small" // Make the input box smaller
              onChange={handleDescriptionChange}
              className={classes.root}
            />
            <Typography
              color="white"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Item Price:
            </Typography>
            <TextField
              label="Token Price in MATIC"
              variant="outlined"
              // color="secondary"
              rows={1}
              type="number"
              sx={{ mb: 2, width: '100%' }}
              size="small"
              value={price} // Set the value of the TextField to the price state
              onChange={handleTokenPriceChangeInternal} // Update the price state when the user enters a value
              className={classes.root}
            />
          </div>
        </Box>
        : null}
      </Stack>
    </>
  );
};

export default CustomTokenCover;
