/* eslint-disable no-unused-vars */
import DataContext from './DataContext';
import React, { useEffect, useState, useContext } from "react";
// From Material UI
import {
    GradientButtonPrimary,
    GradientButtonSecondary,
    GradientButtonBlue
} from "../../Utils/GradientButtons/GradientButtons";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Backdrop,
    Button,
    Divider,
    Fade,
    IconButton,
    Modal,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
// Custom Gradient button

// Icons
import LinkIcon from "@mui/icons-material/Link";
import { FiChevronLeft } from "react-icons/fi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { HiOutlineClock } from "react-icons/hi";
import { BsFillBookmarkFill, BsBookmark, BsChevronDown } from "react-icons/bs";
import { HiTemplate } from "react-icons/hi";
import { IoCart } from "react-icons/io5";
import PolygonIcon from "../../assets/Icons/darkUIIcons/polygon.svg";
import OpenseaIcon from "../../assets/opensea-logo.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { biddingData } from "./biddingData";
import noImageICon from "../../assets/no_image-mint.svg";
import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";
import BTC from '../../assets/tokenImages/BTC.svg';
import ETH from '../../assets/tokenImages/ETH.svg';
import USDlogo from "../../assets/collectionImages/USDlogo.png";
import inexlogo from "../../assets/inex new copy.png";
import { getAllNFTS } from '../../service/nftService';
import SwalNotificationForConvert from '../../components/Notification/SwalNotificationForConvert';
import Indexx from '../../contractData/Indexx.json';

// Tabpanel
import { TabPanel } from "./TabPanel";

// Styles
import styles from "./SingleArtWork.module.css";
import { etherscanAddressURL, getCoinPriceByName, getCryptoPrice, getDecryptedKey, inexAddressEth, inexAddressPolygon, maticcanAddressURL, validateAndConvert, validateAndConvertInex, validateInex } from '../../service/api';
const ethers = require('ethers');

function ConvertXnftCollection({ fa, darkMode, account, blockchain, handleConnectIndexxWallet, indexxWalletEmail, indexxWalletObj, indexxWalletAddress, isIndexxWalletWeb }) {
    const { largeData } = useContext(DataContext);
    const artworkImage = largeData?.media[0]?.gateway ? largeData?.media[0]?.gateway : noImageICon;
    const artworkTitle = largeData?.title ? largeData?.title : "No Title For this NFT";
    const artworkDetails = largeData?.description;
    let artworkPrice = largeData?.metadata?.name?.includes("Blue") ? 0.25 :
        (largeData?.metadata?.name?.includes("Purple") ? 0.5 :
            (largeData?.metadata?.name?.includes("Green") ? 0.1 :
                (largeData?.metadata?.name?.includes("Pink") ? 0.01 :
                    (largeData?.metadata?.name?.includes("Orange") ? 0.001 : 1))));;
    let artPriceDenomation = largeData?.name?.includes("Bitcoin") ? "BTC" : (largeData?.name?.includes("Ethereum") ? "ETH" : "USD");
    const creator = largeData?.contract?.contractDeployer;
    const creatorImage = "";
    const owner = largeData?.account;
    const ownerImage = "";
    const uploaded = 5;
    const likes = 10;
    const contractAddress = largeData?.contract?.address
    //const blockchain = largeData?.blockchain === "Polygon" ? "mumbai" : "sepolia";
    const chain = largeData?.blockchain === "Polygon" ? "mumbai" : "sepolia";
    const scanURL = largeData?.blockchain === "Polygon" ? maticcanAddressURL : etherscanAddressURL;
    const currentMint = "";
    const timeStamp = largeData?.timeLastUpdated;
    // States
    const [openModal, setOpenModal] = React.useState(false);
    const [likeState, setLikeState] = useState(false);
    const [bookmarkState, setBookmarkState] = useState(false);
    const [tabValue, setTabValue] = useState(0); // setting tab value for changing
    const [userCollection, setUserCollection] = useState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [receiveAmount, setReceiveAmount] = useState();
    const [artPriceInUsd, setArtPriceInUsd] = useState(0);
    const [priceInex, setPriceInex] = useState(0);
    const [inexInMATIC, setInexInMATIC] = useState(0);
    const [inexInETH, setInexInETH] = useState(0);
    const [artPriceInMatic, setArtPriceInMatic] = useState(0);
    const [artPriceInEth, setArtPriceInEth] = useState(0);
    const [artPriceInBtc, setArtPriceInBtc] = useState(0);
    const [contractAddr, setContractAddr] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [walletReqAddress, setReqWalletAddress] = useState();
    const [, setLoaderMessage] = useState("");

    const { t } = useTranslation();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const navigate = useNavigate();


    const convertPriceToUSD = (artworkPrice) => {
        // Define static conversion rates for BTC and ETH
        const btcToUsdRate = 26501; // Conversion rate for BTC to USD
        const ethToUsdRate = 1728; // Conversion rate for ETH to USD

        // Extract the currency and amount from the artworkPrice string
        const [amount, currency] = artworkPrice.split(" ");

        // Convert the price to USD based on the currency
        let usdPrice;
        if (currency === "BTC") {
            usdPrice = parseFloat(amount) * btcToUsdRate;
        } else if (currency === "ETH") {
            usdPrice = parseFloat(amount) * ethToUsdRate;
        } else {
            // Invalid or unsupported currency
            return null;
        }

        // Return the price in USD
        return Math.floor(usdPrice);
    };


    useEffect(() => {
        // const fetchData = async () => {
        //   setIsLoading(true);
        //   setLoaderMessage("Loading...");
        //   const res = await axios.get("/sellerData.json");
        //   const sellerCardData = res.data;
        //   setSellerData(sellerCardData);
        //   setIsLoading(false);
        // };

        const fetchNft = async () => {
            const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
            let userWalletObj = [];
            if (isIndexxWalletWeb) {
                userWalletObj = JSON.parse(localStorage.getItem("indexxWebWallets"));
                const requiredWallet = userWalletObj.find(x => x.coinName === blockchain)
                const res = await getAllNFTS(requiredWallet?.coinWalletAddress, blockchain);
                setReqWalletAddress(requiredWallet?.coinWalletAddress);
                setIsLoading(true);
                setLoaderMessage("Loading...");
                const sellerCardData = res;
                //setSellerData(sellerCardData);
                setIsLoading(false);
            } else {
                if (largeData?.account === null || largeData?.account === undefined || blockchain === '' || blockchain === undefined) {
                    console.log('here no account', largeData?.account);
                    return;
                }
                const res = await getAllNFTS(largeData?.account, blockchain);
                console.log(largeData?.account);
                setReqWalletAddress(largeData?.account);
                setIsLoading(true);
                setLoaderMessage("Loading...");
                const sellerCardData = res;
                //setSellerData(sellerCardData);
                setIsLoading(false);
            }
        }
        //fetchData();
        fetchNft();
    }, [account, blockchain]);


    function isCurrentDateGreaterThanBusinessDays(dateTime, days) {
        function addBusinessDays(date, days) {
            while (days > 0) {
                date.setDate(date.getDate() + 1);
                if (date.getDay() > 0 && date.getDay() < 6) {  // 0 is Sunday, 6 is Saturday
                    days--;
                }
            }
            return date;
        }

        let givenDate = new Date(dateTime);
        let currentDate = new Date();

        let businessDate = addBusinessDays(givenDate, days);

        if (currentDate > businessDate) {
            return currentDate;
        } else {
            let remainingDays = Math.round((businessDate - currentDate) / (1000 * 60 * 60 * 24));
            return "Your inex is currently being mined, please come back in " + remainingDays + " days.";
        }
    }

    function isCurrentDateGreaterThanSixMonths(dateTime) {
        function addSixMonths(date) {
            let newDate = new Date(date);
            let currentMonth = newDate.getMonth();
            newDate.setMonth(currentMonth + 6);

            // If we land in an invalid date (e.g., adding 6 months to August 31st lands on Feb 31st which is invalid)
            // JavaScript will automatically adjust it, but it could mean we have added slightly more than 6 months.
            // So, we adjust it back to the last day of the intended month if this happens.
            if (newDate.getMonth() !== (currentMonth + 6) % 12) {
                newDate.setMonth(currentMonth + 6, 0); // The 0 sets it to the last day of the previous month
            }

            return newDate;
        }

        let givenDate = new Date(dateTime);
        let currentDate = new Date();

        let targetDate = addSixMonths(givenDate);

        if (currentDate > targetDate) {
            return currentDate;
        } else {
            let remainingDays = Math.round((targetDate - currentDate) / (1000 * 60 * 60 * 24));
            return "Your crypto is currently being mined, please come back in " + remainingDays + " days.";
        }
    }


    useEffect(() => {
        setUserCollection(largeData);
        console.log('largeData contract', largeData?.contract);
        console.log('largeData', largeData);
    }, [largeData])

    const fullPageOverlayStyles = {
        display: 'flex', // Using flexbox
        justifyContent: 'center', // Center items horizontally
        alignItems: 'center', // Center items vertically
        position: 'fixed', // Fixed position
        top: 0, // From top edge
        left: 0, // From left edge
        height: '100vh', // Full height
        width: '100vw', // Full width
        zIndex: 500000, // Overlay should be on top of all other elements
        backgroundColor: 'rgba(0,0,0,0.5)' // Semi-transparent
    };

    useEffect(() => {

        async function lastPrice() {

            // Define static conversion rates for BTC and ETH
            const btcToUsdRate = await getCryptoPrice('BTC'); // Conversion rate for BTC to USD
            const ethToUsdRate = await getCryptoPrice('ETH'); // Conversion rate for ETH to USD
            const maticToUsdRate = await getCryptoPrice('MATIC');
            const inexPrice = await getCoinPriceByName("INEX");

            console.log('largeData?.metadata?.name', largeData?.contract?.name, (largeData?.contract?.name?.includes("Pink")))
            // Extract the currency and amount from the artworkPrice string
            //const [amount, currency] = artworkPrice.split(" ");
            const amount = largeData?.contract?.name?.includes("Blue") ? 0.25 :
                (largeData?.contract?.name?.includes("Purple") ? 0.5 :
                    (largeData?.contract?.name?.includes("Green") ? 0.1 :
                        (largeData?.contract?.name?.includes("Pink") ? 0.01 :
                            (largeData?.contract?.name?.includes("Orange") ? 0.001 : 1))));

            artworkPrice = amount;
            // Convert the price to USD based on the currency
            let usdPrice;
            let maticPrice;
            let ethPrice;
            let btcPrice;
            console.log('artworkPrice', artworkPrice)
            if (largeData?.contract?.name?.includes("Bitcoin")) {
                console.log('here');
                usdPrice = parseFloat(amount) * btcToUsdRate.data.lastPrice;
                artPriceDenomation = "BTC";
                maticPrice = usdPrice / maticToUsdRate.data.lastPrice;
                btcPrice = artworkPrice;
            } else if (largeData?.contract?.name?.includes("Ethereum")) {
                usdPrice = parseFloat(amount) * ethToUsdRate.data.lastPrice;
                artPriceDenomation = "ETH";
                maticPrice = usdPrice / maticToUsdRate.data.lastPrice;
                ethPrice = artworkPrice;
                btcPrice = ethToUsdRate.data.lastPrice / btcToUsdRate.data.lastPrice;
            } else {
                // Invalid or for USD currency
                usdPrice = 1;
                artPriceDenomation = "USD";
                maticPrice = usdPrice / maticToUsdRate.data.lastPrice;
                ethPrice = usdPrice / ethToUsdRate.data.lastPrice;
                btcPrice = usdPrice / btcToUsdRate.data.lastPrice;
            }

            setContractAddr(largeData?.token_address)

            const inexAmount = 0.15 * usdPrice;
            console.log(inexPrice.data.results.data)
            setPriceInex(inexPrice.data.results.data);
            console.log('matic', maticToUsdRate.data.lastPrice)
            console.log(inexAmount * (inexPrice.data.results.data / maticToUsdRate.data.lastPrice))
            console.log(inexAmount * (inexPrice.data.results.data / ethToUsdRate.data.lastPrice))
            setInexInMATIC(inexAmount * (inexPrice.data.results.data / maticToUsdRate.data.lastPrice))
            setInexInETH(inexAmount * (inexPrice.data.results.data / ethToUsdRate.data.lastPrice))
            // Add commas to the USD price
            // const formattedPrice = usdPrice.toLocaleString();

            // Return the price in USD with commas
            // setArtPriceInUsd(formattedPrice);
            console.log(usdPrice)
            console.log(maticPrice)
            console.log(ethPrice)
            console.log(btcPrice)
            setArtPriceInUsd(usdPrice);
            setArtPriceInMatic(maticPrice);
            setArtPriceInEth(ethPrice);
            setArtPriceInBtc(btcPrice);
        }
        lastPrice();
    }, []);



    // Tab handler
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [loading, setLoading] = useState(true);
    // Custom Mobile Tabs
    const MobileTabs = styled(Tabs)({
        border: "none",
        backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
        "& .MuiTabs-indicator": {
            backgroundColor: "inherit",
        },
    });

    const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        "&.Mui-selected": {
            color: "#ffffff",
            backgroundColor: "#0294FE",
            borderRadius: "4px",
            fontWeight: theme.typography.fontWeightMedium,
        },
    }));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const convertNow = async () => {
        setIsLoading(true);
        try {
            let dateCheck = isCurrentDateGreaterThanBusinessDays(largeData?.timeLastUpdated, 5); // 5 Business day
            if (typeof dateCheck === "string") {
                setIsLoading(false);
                SwalNotificationForConvert(darkMode, dateCheck, "");
                return;
            }
            // setIsLoading(false);
            console.log('I am here', largeData);
            let nftData = {
                blockchain: largeData.blockchain, //Polygon or Ethereum
                type: largeData?.contract?.name.includes("Bitcoin") ? "Bitcoin" : "Ethereum", // Bitcoin or Ethereum
                tokenId: largeData?.tokenId,
                contractAddress: largeData?.contract?.address,
                isINEXConvert: false
            }
            let walletAddress;
            if (isIndexxWalletWeb) {
                if (nftData?.type === "Ethereum")
                    walletAddress = walletReqAddress;
                else if (nftData?.type === "Bitcoin") {
                    walletAddress = walletReqAddress;  // still polygon or ethereum to be sent to check the nft is linked with this wallet
                    let userWalletObj = JSON.parse(localStorage.getItem("indexxWebWallets"));
                    const requiredWallet = userWalletObj.find(x => x.coinName === nftData?.type);
                    console.log(requiredWallet, 'Bitcoin')
                    nftData.bitcoinWalletAddress = requiredWallet?.coinWalletAddress;
                }
            } else {
                if (nftData?.type === "Ethereum") {
                    walletAddress = walletReqAddress;
                }
                else if (nftData?.type === "Bitcoin") {
                    console.log('err', "User need to use indexx wallet web")
                    SwalNotificationForConvert(darkMode, "You need to use Indexx web wallet to convert bitcoin xnft", "Metamask doesnot support bitcoin");
                    setIsLoading(false);
                    return;
                }
            }
            console.log(nftData, walletAddress)
            let res = await validateAndConvert(nftData, walletAddress)
            console.log(res)
            if (res) {
                setIsLoading(false);
                if (res.data.status === 500) {
                    SwalNotificationForConvert(darkMode, res.data.data.message, "");
                    setIsLoading(false);
                }
                else if (res.data.status === 200) {
                    SwalNotificationForConvert(darkMode, res.data.data.message, res.data.data.tx, nftData?.type);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
            SwalNotificationForConvert(darkMode, err, "");
        }
    }

    const convertInex = async () => {
        setIsLoading(true);
        try {
            let dateCheck = isCurrentDateGreaterThanSixMonths(largeData?.timeLastUpdated);
            console.log(dateCheck, typeof dateCheck, typeof dateCheck === "string")
            let inexPrice = await getCoinPriceByName("INEX");
            console.log(inexPrice)
            let inexTokens = artPriceInUsd * 0.15;
            let inexAddress = blockchain === "Ethereum" ? inexAddressEth : inexAddressPolygon;
            console.log(inexTokens)
            if (typeof dateCheck === "string") {
                setIsLoading(false);
                SwalNotificationForConvert(darkMode, dateCheck, "");
                return;
            }
            console.log('I am here', largeData);
            //First get the inex tokens from user to company account 
            let nftData = {
                blockchain: largeData.blockchain, //Polygon or Ethereum
                type: largeData?.contract?.name.includes("Bitcoin") ? "Bitcoin" : "Ethereum", // Bitcoin or Ethereum
                tokenId: largeData?.tokenId,
                contractAddress: largeData?.contract?.address,
                inexTransferred: inexTokens,
                isINEXConvert: true
            }
            let walletAddress;
            let inexTxStatus = false;
            if (isIndexxWalletWeb) {
                walletAddress = walletReqAddress;
                let res = await validateInex(nftData, walletAddress)
                console.log(res)
                if (res) {
                    if (res.data.status === 500) {
                        SwalNotificationForConvert(darkMode, res.data.data.message, "");
                        setIsLoading(false);
                        return;
                    }
                    else if (res.data.status === 200) {
                        setIsLoading(false);
                        SwalNotificationForConvert(darkMode, res.data.data.message, "", res.data.data.tx, nftData?.type);
                    }
                } else {
                    setIsLoading(false);
                    return;
                }
                const providerURL = blockchain === "Ethereum" ? "https://eth-sepolia.g.alchemy.com/v2/9Hh6pgUeB9vYdDs2a_k-onYczSP8AeYN" : blockchain === "Polygon" ? "https://polygon-mumbai.g.alchemy.com/v2/NTVLlKPYGVR2mdmlpb0epJ_pgJaa3t0u" : "";
                const provider = new ethers.providers.JsonRpcProvider(providerURL);
                let userWalletObj = JSON.parse(localStorage.getItem("indexxWebWallets"));
                const requiredWallet = userWalletObj.find(x => x.coinName === nftData?.type);
                const privateKey = requiredWallet.coinPrivateKey;
                console.log('privateKey', privateKey)
                const wallet = new ethers.Wallet(privateKey, provider);
                var indexxContract = new ethers.Contract(inexAddress, Indexx.abi, wallet);
                let data = await (await indexxContract.transfer("0x35EAd16cbd74AB6380aB9ad91266dc407149652f", ethers.utils.parseEther(String(inexTokens)))).wait();
                console.log('dta', data, walletReqAddress);
                inexTxStatus = data?.status === 1 ? true : false;
                console.log(requiredWallet, 'Bitcoin')
            } else {
                walletAddress = largeData?.account;
                let res = await validateInex(nftData, walletAddress)
                console.log(res)
                if (res) {
                    if (res.data.status === 500) {
                        setIsLoading(false);
                        SwalNotificationForConvert(darkMode, res.data.data.message, "");
                        return;
                    }
                    else if (res.data.status === 200) {
                        console.log('Continue to transfer ixex');
                    }
                } else {
                    setIsLoading(false);
                    return;
                }
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                var indexxContract = new ethers.Contract(inexAddress, Indexx.abi, provider.getSigner());
                let data = await (await indexxContract.transfer("0x35EAd16cbd74AB6380aB9ad91266dc407149652f", ethers.utils.parseEther(String(inexTokens)))).wait();
                console.log('dta', data);
                inexTxStatus = data?.status === 1 ? true : false;
            }
            console.log(nftData, walletAddress, inexTxStatus)
            if (inexTxStatus) {
                let res = await validateAndConvertInex(nftData, walletAddress)
                console.log(res)
                if (res) {
                    setIsLoading(false);
                    if (res.data.status === 500)
                        SwalNotificationForConvert(darkMode, res.data.data.message, "");
                    else if (res.data.status === 200) {
                        // SwalNotificationForConvert(darkMode, res.data.data.message, "", res.data.data.tx, nftData?.type);
                        console.log('Continue to transfer ixex');
                    }
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                SwalNotificationForConvert(darkMode, "Inex transaction failed to transfer. Please try again with sufficient" + blockchain === "Ethereum" ? "Ether" : "Matic", "");
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
            SwalNotificationForConvert(darkMode, err, "");
        }
    }

    const handleProfileClick = (id) => {
        navigate(`/view-profile/${id}`, { replace: true });
    };

    return (
        // Artwork details information
        <>
            {isLoading && (
                <div style={fullPageOverlayStyles}>
                    {darkMode ? (
                        <Stack direction={"column"}>
                            <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
                            <Typography
                                variant="h6"
                                component="p"
                                sx={{ cursor: "pointer", textAlign: "center" }}
                                color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                            >
                                Processing Transaction ...
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack>
                            <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
                            <Typography
                                variant="h6"
                                component="p"
                                sx={{ cursor: "pointer", textAlign: "center" }}
                                color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                            >
                                Processing Transaction ...
                            </Typography>
                        </Stack>
                    )}
                </div>
            )}
            {
                // userCollection?.dataType === "userCollection" ?
                //     (<> user profile collection</>) :
                (<>
                    <Modal
                        sx={{ zIndex: 500000 }}
                        open={openModal}
                        onClose={handleCloseModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openModal}>
                            <Box
                                bgcolor={darkMode ? "#171c26" : "#EEEEEE"}
                                className={
                                    !isMobile ? styles.modalStyleWeb : styles.modalStyleMobile
                                }
                            >
                                <Typography
                                    className={styles.itemDetailsModalTitle}
                                    color="secondary"
                                    variant="h6"
                                    component="div"
                                >
                                    <Typography component="span" color="secondary" sx={{ mt: 0.3 }}>
                                        <HiTemplate fontSize={"1.5rem"} />
                                    </Typography>
                                    <Typography variant="h6" component="span" mt={-0.2}>
                                        {t("ITEM_DETAILS")}
                                    </Typography>
                                </Typography>
                                <Typography
                                    // This is global styles
                                    className={
                                        !isMobile
                                            ? styles.readMoreModalText
                                            : styles.readMoreModalTextMobile
                                    }
                                    variant="body2"
                                    component="p"
                                    color="secondary"
                                    lineHeight={2}
                                    height={250}
                                    pr={2.5}
                                >
                                    {artworkDetails}
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>
                    <Box mt={11} className={styles.detailsContainerBox}>
                        <Box>
                            {/* Top navigation */}
                            {!isMobile ? (
                                <Box className={styles.topNavigationBox}>
                                    <Box
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => window.history.back()}
                                        bgcolor={`${darkMode ? "#040404" : "#ffffff"}`}
                                    >
                                        <FiChevronLeft fontSize={"1.5rem"} color={darkMode ? "#ffffff" : "#121212"} />
                                    </Box>
                                    <Typography
                                        className={styles.topNavigationTypo}
                                        component="div"

                                    >
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            sx={{ cursor: "pointer" }}
                                            zIndex={2}
                                            color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                                        >
                                            {t("ITEM_DETAILS")}
                                        </Typography>

                                    </Typography>
                                </Box>
                            ) : (
                                <Box className={styles.topNavigationBoxMobile}>
                                    <Box
                                        width={"70px"}
                                        height={"60px"}
                                        mt={-1}
                                        bgcolor={`${darkMode ? "#040404" : "#ffffff"}`}
                                    >
                                        <FiChevronLeft
                                            onClick={() => window.history.back()}
                                            fontSize={"1.5rem"}
                                            color={darkMode ? "#ffffff" : "#121212"}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            position: "fixed",
                                            top: "3%",
                                            zIndex: "10000",
                                            width: "70%",
                                            display: "flex",
                                            justifyContent: "center",
                                            ml: 4,
                                        }}
                                    >
                                        <Typography
                                            component="div"

                                            position="relative"
                                            display="flex"
                                            alignItems="center"
                                            ml={4}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                component="p"
                                                sx={{ zIndex: 2 }}
                                            >
                                                {t("ITEM_DETAILS")}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {/* Details container */}
                            {!isMobile ? (
                                <Box className={styles.detailsContainer}>
                                    <Box zIndex={10} height={"100%"}>
                                        <Box
                                            sx={{
                                                position: "relative",
                                            }}
                                        >
                                            <img
                                                className={styles.artDisplayImage}
                                                src={artworkImage}
                                                alt={artworkTitle}
                                                onLoad={() => setLoading(false)}
                                                loading="eager"
                                            />
                                        </Box>
                                        <Box
                                            className={styles.artDisplayDetails}
                                            bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                                            sx={{
                                                // mt: 3,
                                                marginTop: "25%",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    p: 3,
                                                }}
                                            >
                                                <Box gap={1}>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("CONTRACT_ADDRESS")}
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="p"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                fontWeight={500}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {contractAddress?.substring(0, 6) + "..." + contractAddress?.substring(contractAddress.length - 3)}
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                ml={1}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <ContentCopyIcon />
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TOKEN_ID")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.tokenId.length > 15 ? largeData?.tokenId?.substring(0, 6) + "..." + largeData?.tokenId.substring(largeData?.tokenId.length - 3) : largeData?.tokenId}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TOKEN_STANDARD")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.tokenType}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("BLOCKCHAIN")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.blockchain === "Polygon" ? "Polygon" : "Ethereum"}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("ROYALTY_FEE")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {5 + "%"}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TRANSACTION_FEE")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            fontWeight={500}
                                                        >
                                                            5%
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            className={styles.artDisplayDetails}
                                            sx={{
                                                mt: 2,
                                            }}
                                        >
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Button
                                                    variant="contained"
                                                    className={styles.artDisplayDetailsButton}
                                                    sx={{
                                                        p: 0,
                                                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                                    }}
                                                    onClick={() => window.open(`https://testnets.opensea.io/assets/${chain}/${contractAddress}/${largeData?.tokenId}`, "_blank")}
                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <img
                                                            src={OpenseaIcon}
                                                            alt="Opnesea Icon"
                                                            width="20"
                                                            height={"20"}
                                                        />
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        View on Opensea
                                                    </Typography>
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        align="right"
                                                    >
                                                        <LinkIcon />
                                                    </Typography>
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    className={styles.artDisplayDetailsButton}
                                                    sx={{
                                                        p: 0,
                                                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                                    }}
                                                    onClick={() => window.open(`${scanURL}/${contractAddress}`, "_blank")}

                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <img
                                                            src={PolygonIcon}
                                                            alt="Polygon Icon"
                                                            width="20"
                                                            height={"20"}
                                                        />
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        PolygonScan
                                                    </Typography>
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <LinkIcon />
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        className={styles.detailsContainerContentBox}
                                        bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                                        sx={{
                                            position: "relative",
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                component="h2"
                                                color="secondary.main"
                                                mb={4}
                                                fontWeight={500}
                                            >
                                                {artworkTitle}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                component="div"
                                                lineHeight={1.5}
                                                color="secondary.main"
                                                mb={2}
                                                textAlign="justify"
                                            >
                                                {artworkDetails?.length > 200
                                                    ? artworkDetails.slice(0, 200)
                                                    : artworkDetails}
                                                {artworkDetails.length > 200 && (
                                                    <Button
                                                        variant="text"
                                                        onClick={handleOpenModal}
                                                        sx={{ color: "#02C7FE", textTransform: "lowercase" }}
                                                    >
                                                        ...{t("READ_MORE")}
                                                    </Button>
                                                )}
                                            </Typography>

                                            <Accordion
                                                disableGutters
                                                elevation={0}
                                                sx={{
                                                    backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                                                    mb: 3,
                                                    border: "none",
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <Typography component="span" color="secondary">
                                                            <BsChevronDown
                                                                fontSize={"1rem"}
                                                                style={{ marginTop: "5px" }}
                                                            />
                                                        </Typography>
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        color="secondary"
                                                        ml={-2}
                                                        fontWeight={500}
                                                    >
                                                        {t("PROPERTIES")}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ border: "none", ml: -2 }}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        {largeData?.properties &&
                                                            largeData?.properties.length > 0 &&
                                                            largeData?.properties[0].key !== "" ? (
                                                            <>
                                                                {largeData.properties?.map((property) => (
                                                                    <Box
                                                                        className={styles.propBox}
                                                                        bgcolor={darkMode ? "#040404" : "#ffffff"}
                                                                    >
                                                                        <Typography
                                                                            variant="button"
                                                                            component="p"
                                                                            color="#02C7FE"
                                                                        >
                                                                            {property.key}
                                                                        </Typography>
                                                                        <Typography variant="body2" color="secondary">
                                                                            {property.value}
                                                                        </Typography>
                                                                    </Box>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography variant="body2" color="secondary">
                                                                    {t("NO_PROPERTIES_FOUND")}
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>

                                            {/* <>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {t(`PRICE`)}
                                                </Typography>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    color="secondary.main"
                                                    mb={3}
                                                    fontWeight={500}
                                                >
                                                    {artworkPrice}

                                                </Typography>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    color="secondary.main"
                                                    mb={3}
                                                    fontWeight={500}
                                                >
                                                    {convertPriceToUSD(artworkPrice)} USD

                                                </Typography>
                                            </> */}

                                            <>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {t(`PRICE`)}
                                                </Typography>
                                                {/* <Typography
                                            variant="h5"
                                            component="p"
                                            color="secondary.main"
                                            mb={3}
                                            fontWeight={500}
                                        >
                                            {artworkPrice} {" "} {artPriceDenomation}
                                        </Typography> */}
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    color="secondary.main"
                                                    mb={3}
                                                    fontWeight={500}
                                                >
                                                    {artPriceInUsd.toLocaleString()} USD
                                                    ({largeData?.contract?.name.includes("Bitcoin") ? `${Math.floor(artPriceInBtc * 1000000) / 1000000} BTC` : `${Math.floor(artPriceInEth * 1000000) / 1000000} ETH`})

                                                </Typography>
                                            </>
                                            <>
                                                {/* <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {"Price to be paid"}
                                                </Typography> */}
                                                {blockchain === 'Ethereum' ?
                                                    <>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {"You will receive"}
                                                        </Typography>
                                                        <Typography
                                                            variant="h5"
                                                            component="p"
                                                            color="secondary.main"
                                                            mb={3}
                                                            fontWeight={500}
                                                        >
                                                            {/* {artPriceDenomation === "USD" ? `${artPriceInEth} ETH` : `${artworkPrice} ETH`} */}
                                                            {"80% | "} {" XNFT Value"} {" : "} {" ~ "} {largeData?.contract?.name.includes("Bitcoin") ? `${Math.floor(0.8 * artPriceInBtc * 1000000) / 1000000} BTC` : `${Math.floor(0.8 * artPriceInEth * 1000000) / 1000000} ETH`}

                                                        </Typography>
                                                    </>
                                                    :
                                                    blockchain === 'Polygon' ?
                                                        <>
                                                            <Typography
                                                                variant="h6"
                                                                component="p"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                fontWeight={500}
                                                            >
                                                                {"You will receive"}
                                                            </Typography>
                                                            <Typography
                                                                variant="h5"
                                                                component="p"
                                                                color="secondary.main"
                                                                mb={3}
                                                                fontWeight={500}
                                                            >
                                                                {/* {`${(artPriceInMatic).toLocaleString()}`} {" "} {"MATIC"} */}
                                                                {"80% | "} {" XNFT Value"} {" : "} {" ~ "} {largeData?.contract?.name.includes("Bitcoin") ? `${Math.floor(0.8 * artPriceInBtc * 1000000) / 1000000} BTC` : `${Math.floor(0.8 * artPriceInEth * 1000000) / 1000000} ETH`}
                                                            </Typography>
                                                        </>
                                                        :
                                                        <>
                                                            {/* <Typography
                                                                variant="h5"
                                                                component="p"
                                                                color="secondary.main"
                                                                mb={3}
                                                                fontWeight={500}
                                                            >
                                                                {artPriceDenomation === "USD" ? `${artPriceInEth} ETH` : `${artworkPrice} ETH`}
                                                            </Typography>
                                                            <Typography
                                                                variant="h5"
                                                                component="p"
                                                                color="secondary.main"
                                                                mb={3}
                                                                fontWeight={500}
                                                            >
                                                                {`${(artPriceInMatic).toLocaleString()}`} {" "} {"MATIC"}
                                                            </Typography> */}

                                                            <Typography
                                                                variant="h6"
                                                                component="p"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                fontWeight={500}
                                                            >
                                                                {"You will receive"}
                                                            </Typography>

                                                            <Typography variant="h5"
                                                                component="p"
                                                                color="secondary.main"
                                                                mb={3}
                                                                fontWeight={500}>
                                                                {"80% | "} {" XNFT Value"} {" : "} {" ~ "} {`${(0.8 * artPriceInUsd).toLocaleString()} USD`} <br></br>
                                                                {/* {"80% | "} {" XNFT Value"} {" : "} {" ~ "} {`${(0.8 * artPriceInMatic).toLocaleString()} MATIC`} <br></br> */}
                                                                {"80% | "} {" XNFT Value"} {" : "} {" ~ "} {largeData?.contract?.name.includes("Bitcoin") ? `${Math.floor(0.8 * artPriceInBtc * 1000000) / 1000000} BTC` : `${Math.floor(0.8 * artPriceInEth * 1000000) / 1000000} ETH`}
                                                                {/* // {"80% | "} {" XNFT Value"} {" : "} {" ~ "} {`${Math.floor(0.8 * artPriceInBtc * 1000000) / 1000000} BTC`} */}

                                                                {/* {blockchain === 'Polygon' ? `80% | XNFT Value : ~ ${(0.8 * artPriceInMatic).toLocaleString()} MATIC` :
                                                                    blockchain === 'Ethereum' ? `80% | XNFT Value : ~ ${Math.floor(0.8 * artPriceInEth * 1000000) / 1000000} ETH` :
                                                                        `80% | XNFT Value : ~ ${(0.8 * artPriceInUsd).toLocaleString()} USD`} */}
                                                            </Typography>
                                                        </>
                                                }


                                            </>
                                            {/* <Accordion
                                                disableGutters
                                                elevation={0}
                                                sx={{
                                                    backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                                                    mb: 3,
                                                    border: "none",
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <Typography component="span" color="secondary">
                                                            <BsChevronDown
                                                                fontSize={"1rem"}
                                                                style={{ marginTop: "5px" }}
                                                            />
                                                        </Typography>
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        color="secondary"
                                                        ml={-2}
                                                        fontWeight={500}
                                                    >
                                                        Breakdown
                                                    </Typography>{" "}

                                                    <img src={artPriceDenomation === "BTC" ? BTC : artPriceDenomation === "ETH" ? ETH : USDlogo} alt="currency" width={"25px"} height={"25px"}
                                                        style={{ marginLeft: "5px", marginRight: "8px", marginTop: "3px", borderRadius: "15px" }} />{" "}
                                                    <img src={inexlogo} alt="currency" width={"25px"} height={"25px"} style={{ marginTop: "3px" }} onClick={() => { window.location.href = "https://test.indexx.ai/indexx-exchange/token-details/inex" }} />

                                                </AccordionSummary>
                                                <AccordionDetails sx={{ border: "none", ml: -2 }}>
                                                    <Stack direction="column" spacing={2}>

                                                        <Typography variant="body2" color="secondary" display={"block"}>
                                                            {"80% | "} {artPriceDenomation} {" XNFT Value"} {" : "} {`${(0.8 * artworkPrice).toFixed(3)} `}{artPriceDenomation}
                                                            {" ~ "} {`${(0.8 * artPriceInUsd).toLocaleString()} USD`}
                                                        </Typography>
                                                        <Typography variant="body2" color="secondary" display={"block"}>
                                                            {"20% | "} {"INEX Value"} {" : "} {`${((0.2 * artPriceInUsd) / 0.2).toLocaleString()} INEX`}
                                                            {" ~ "} {`${(0.2 * artPriceInUsd).toLocaleString()} USD`}
                                                        </Typography>
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion> */}
                                            <Divider className={styles.dividerBox} />
                                            <Box className={styles.singleArtCardInfo}>
                                                <Box className={styles.avatarBox}>
                                                    <Box>
                                                        <Avatar src={creatorImage} alt={creator} />
                                                    </Box>
                                                    <Stack direction="column">
                                                        <Typography variant="caption" gutterBottom color="gray">
                                                            {t("CREATOR")}
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            gutterBottom
                                                            color="secondary.main"
                                                            sx={{
                                                                textTransform: "lowercase",
                                                                maxWidth: "90px",
                                                            }}
                                                            noWrap={true}
                                                            onClick={() => handleProfileClick(creator)}
                                                        >
                                                            {creator}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                                <Box className={styles.avatarBox}>
                                                    <Box>
                                                        <Avatar src={ownerImage} alt={owner} />
                                                    </Box>
                                                    <Stack direction="column">
                                                        <Typography variant="caption" gutterBottom color="gray">
                                                            {t("OWNER")}
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            gutterBottom
                                                            color="secondary.main"
                                                            sx={{
                                                                textTransform: "lowercase",
                                                                maxWidth: "90px",
                                                            }}
                                                            noWrap={true}
                                                            onClick={() => handleProfileClick(owner)}
                                                        >
                                                            {owner}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                            <Divider className={styles.dividerBox} />
                                            <Box className={styles.cardFooter}>
                                                <Stack direction="row" alignItems="center">
                                                    <IconButton>
                                                        <Typography component="span" color="secondary">
                                                            <HiOutlineClock className={styles.footerIcons} />
                                                        </Typography>
                                                    </IconButton>
                                                    <Typography
                                                        variant="caption"
                                                        component="span"
                                                        color={darkMode ? "#ffffff" : "#121212"}
                                                    >
                                                        {timeStamp}
                                                    </Typography>
                                                </Stack>
                                                {/* <Stack direction="row" alignItems="center" gap={2}>
                                                    <Box className={styles.userInteractionBox}>
                                                        <IconButton onClick={() => setLikeState(!likeState)}>
                                                            <Typography color="#e23e58" component="span">
                                                                <AiOutlineHeart className={styles.footerIcons} />
                                                            </Typography>
                                                        </IconButton>
                                                        <Typography
                                                            variant="caption"
                                                            component="span"
                                                            color={darkMode ? "#ffffff" : "#121212"}
                                                        >
                                                            {likes}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <IconButton>
                                                            <Typography component="span" color="secondary">
                                                                <BsBookmark className={styles.footerIcons} />
                                                            </Typography>
                                                        </IconButton>
                                                    </Box>
                                                </Stack> */}
                                            </Box>
                                        </Box>

                                        <>
                                            <Box className={styles.footerButtonContainer}>
                                                <GradientButtonBlue
                                                    className={styles.gradientButtonClass}
                                                    variant="contained"
                                                    fullWidth={true}
                                                    onClick={() => convertNow()}
                                                >
                                                    {/* <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={0.5}
                                                    >
                                                        <IoCart />
                                                    </Typography> */}
                                                    <Typography variant="body1" component="span">
                                                        {t("CONVERT_NOW")}
                                                    </Typography>
                                                </GradientButtonBlue>
                                            </Box>
                                        </>
                                        <>
                                            <br></br>
                                            <Typography
                                                variant="h6"
                                                component="p"
                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                mb={2}
                                                fontWeight={500}
                                            >
                                                {"You will receive"}
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                component="p"
                                                color="secondary.main"
                                                mb={3}
                                                fontWeight={500}
                                            >
                                                {/* {`${(artPriceInMatic).toLocaleString()}`} {" "} {"MATIC"} */}
                                                {"15% | "} {" XNFT Value"} {" : "} {" ~ "} {largeData?.blockchain?.includes("Ethereum") ? `${Math.floor(inexInETH * 1000000) / 1000000} ETH` : `${Math.floor(inexInMATIC * 1000000) / 1000000} MATIC`}
                                            </Typography>

                                            <Box className={styles.footerButtonContainer}>
                                                <GradientButtonBlue
                                                    className={styles.gradientButtonClass}
                                                    variant="contained"
                                                    fullWidth={true}
                                                    onClick={() => convertInex()}
                                                >
                                                    {/* <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={0.5}
                                                    >
                                                        <IoCart />
                                                    </Typography> */}
                                                    <Typography variant="body1" component="span">
                                                        {t("CONVERT_INEX")}
                                                    </Typography>
                                                </GradientButtonBlue>

                                            </Box>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                color={darkMode ? "#ffffff" : "#121212"}
                                            >
                                                {`Before you click on "Convert INEX" button make sure you have enough ${blockchain === "Ethereum" ? "Ether" : "Matic"} is avaliable in your wallet. Else convert INEX to crypto will fail.`}
                                            </Typography>
                                        </>
                                    </Box>
                                </Box>
                            ) : (
                                <Box className={styles.detailsContainerMobile}>
                                    <Box zIndex={10}>
                                        <img
                                            className={styles.artDisplayImageMobile}
                                            src={artworkImage}
                                            alt={artworkTitle}
                                            loading="eager"
                                        />
                                    </Box>
                                    <Box className={styles.userInteractionBoxMobile}>
                                        <Stack direction="row" alignItems="center">
                                            <IconButton>
                                                <Typography component="span" color="secondary">
                                                    <HiOutlineClock className={styles.footerIcons} />
                                                </Typography>
                                            </IconButton>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                color={darkMode ? "#ffffff" : "#121212"}
                                            >
                                                {uploaded} {t("HOURS_AGO")}
                                            </Typography>
                                        </Stack>
                                        {/* <Stack direction="row" alignItems="center" gap={2}>
                                            <Box className={styles.userInteractionBoxMobile}>
                                                <IconButton onClick={() => setLikeState(!likeState)}>
                                                    <Typography component="span" color="secondary">
                                                        <AiOutlineHeart className={styles.footerIcons} />
                                                    </Typography>
                                                </IconButton>
                                                <Typography
                                                    variant="caption"
                                                    component="span"
                                                    color={darkMode ? "#ffffff" : "#121212"}
                                                >
                                                    {likes}
                                                </Typography>
                                            </Box>
                                            <Box mt={-0.5}>
                                                <IconButton>
                                                    <Typography component="span" color="secondary">
                                                        <BsBookmark className={styles.footerIcons} />
                                                    </Typography>
                                                </IconButton>
                                            </Box>
                                        </Stack> */}
                                    </Box>
                                    <Box>
                                        <Box className={styles.cardFooterMobile}>
                                            <Box sx={{ display: "flex", gap: 2 }}>
                                                <Box>
                                                    <Avatar src={creatorImage} alt={creator} />
                                                </Box>
                                                <Stack direction="column" alignItems="left">
                                                    <Typography variant="caption" gutterBottom color="gray">
                                                        {t("CREATOR")}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        gutterBottom
                                                        color="secondary.main"
                                                        sx={{
                                                            textTransform: "lowercase",
                                                            maxWidth: "90px",
                                                        }}
                                                        noWrap={true}
                                                        onClick={() => handleProfileClick(creator)}
                                                    >
                                                        {creator}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                            <Box sx={{ display: "flex", gap: 2 }}>
                                                <Box>
                                                    <Avatar src={ownerImage} alt={owner} />
                                                </Box>
                                                <Stack direction="column" alignItems="left">
                                                    <Typography variant="caption" gutterBottom color="gray">
                                                        {t("OWNER")}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        gutterBottom
                                                        color="secondary.main"
                                                        sx={{
                                                            textTransform: "lowercase",
                                                            maxWidth: "90px",
                                                        }}
                                                        noWrap={true}
                                                        onClick={() => handleProfileClick(owner)}
                                                    >
                                                        {owner}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography
                                                variant="subtitle2"
                                                component="h2"
                                                color="secondary.main"
                                                mb={2}
                                            >
                                                {artworkTitle}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                lineHeight={1.8}
                                                mb={2}
                                                color="secondary.main"
                                                textAlign="left"
                                            >
                                                {artworkDetails?.length > 200
                                                    ? artworkDetails?.slice(0, 200)
                                                    : artworkDetails}
                                                {artworkDetails.length > 200 && (
                                                    <Button
                                                        variant="text"
                                                        onClick={handleOpenModal}
                                                        sx={{ fontSize: "11px", color: "#02C7FE" }}
                                                    >
                                                        ...{t("READ_MORE")}
                                                    </Button>
                                                )}
                                            </Typography>

                                            {/* <>
                                                <Typography
                                                    variant="subtitle2"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={1}
                                                >
                                                    {t("PRICE")}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    component="p"
                                                    color="secondary.main"
                                                    mb={1}
                                                    fontWeight={500}
                                                >
                                                    {artworkPrice}
                                                </Typography>
                                            </> */}
                                        </Box>
                                        <Box
                                            className={styles.artDisplayDetailsMobile}
                                            sx={{
                                                mt: 2,
                                            }}
                                        >
                                            <Box className={styles.artDisplayDetailsContentMob} gap={2}>
                                                <Button
                                                    variant="contained"
                                                    className={styles.artDisplayDetailsButtonMob}
                                                    sx={{
                                                        p: 0,
                                                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                                    }}
                                                    onClick={() => {
                                                        window.open(largeData.file, "_blank");
                                                    }}
                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <img
                                                            src={OpenseaIcon}
                                                            alt="Opensea Icon"
                                                            width="20"
                                                            height={"20"}
                                                        />
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        View on Opensea
                                                    </Typography>
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        align="right"
                                                    >
                                                        <LinkIcon />
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className={styles.artDisplayDetailsButtonMob}
                                                    sx={{
                                                        p: 0,
                                                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                                    }}
                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <img
                                                            src={PolygonIcon}
                                                            alt="Polygon Icon"
                                                            width="20"
                                                            height={"20"}
                                                        />
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        View on PolygonScan
                                                    </Typography>
                                                    <Typography
                                                        color="#ffffff"
                                                        component="span"
                                                        fontSize={20}
                                                        mt={1}
                                                        sx={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <LinkIcon />
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Box
                                            className={styles.artDisplayDetailsMobile}
                                            bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                                            sx={{
                                                mt: 3,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    p: 3,
                                                }}
                                            >
                                                <Box gap={1}>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            Contract Address
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="p"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                fontWeight={350}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {contractAddress.substring(0, 6) + "..." + contractAddress.substring(contractAddress.length - 3)}
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                color={darkMode ? "#FFFFFF" : "#121212"}
                                                                mb={2}
                                                                ml={1}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <ContentCopyIcon />
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TOKEN_ID")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.tokenId.length > 15 ? largeData?.tokenId?.substring(0, 6) + "..." + largeData?.tokenId.substring(largeData?.tokenId.length - 3) : largeData?.tokenId}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("TOKEN_STANDARD")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.tokenType}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("BLOCKCHAIN")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {largeData?.blockchain === "Polygon" ? "Polygon" : "Ethereum"}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            {t("ROYALTY_FEE")}
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            mb={2}
                                                            fontWeight={500}
                                                        >
                                                            {5 + "%"}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={styles.artDisplayDetailsContent}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            color="gray"
                                                        >
                                                            Transaction Fee
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            component="p"
                                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                                            fontWeight={500}
                                                        >
                                                            5%
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* <Box className={styles.footerButtonContainer}>
                                            <GradientButtonPrimary
                                                className={styles.gradientButtonClass}
                                                variant="contained"
                                                fullWidth={true}
                                            >
                                                <Typography
                                                    color="#ffffff"
                                                    component="span"
                                                    fontSize={20}
                                                    mt={0.5}
                                                >

                                                </Typography>
                                                <Typography variant="body1" component="span">
                                                    {t("BUY_NOW")}
                                                </Typography>
                                            </GradientButtonPrimary>
                                        </Box> */}
                                    </Box>
                                </Box>
                            )}
                        </Box>

                    </Box>

                </>)}
        </>
    );
};

export default ConvertXnftCollection;
