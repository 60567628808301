import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Button,
  Grow,
  IconButton,
  TextField,
  FormControl,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import styles from "./SingleArtWork.module.css";
import { FiChevronLeft } from "react-icons/fi";
import { brandData } from "./brandData";
import { useParams } from 'react-router-dom';
import { GradientButtonPrimary, GradientButtonBlue } from "../../Utils/GradientButtons/GradientButtons";


const Viewbrand = ({ darkMode, blockchain, handleConnectIndexxWallet }) => {

  const { id } = useParams();

  // const [brand, setBrand] = useState([brandData]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // useEffect(() => {
  // const fetchData = async () => {
  //   const res = await axios.get('/sellerData.json');
  //   const sellerCardData = res.data;
  //   setSellerData(sellerCardData);
  // };
  // fetchData();
  //   setBrand(brandData);
  // }, []);

  // const filteredbrand = brand.filter((sd) => sd.id === id);

  const filteredbrand = brandData.filter((sd) => sd.id === id);

  // const [brands, setbrands] = useState([]);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     axios.get("/brandData.json").then((res) => {
  //       setbrands(res.data);
  //     });
  //   }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Parse the query parameters from the URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  const saveDetails = () => {
    //code for posting data
    navigate(`/brands`);
  };

  const viewCollection = () => {
    navigate(`/collections?type=all`, { replace: true });
  };


  return (
    <Box
      color={darkMode ? "#ffffff" : "#121212"}
      sx={{
        position: "relative",
      }}
    >
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                }`,
            }}
          >
            {/* {"My Profile"} */}
            {filteredbrand[0].brandName}
          </Typography>
        </Box>
      )}
      <Box sx={!isMobile ? { mt: 11.5 } : { mt: 0 }}>
        <Box className={styles.topNavigationBox}>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => window.history.back()}
          >
            <FiChevronLeft fontSize={"1.5rem"} />
          </Box>
          <Typography
            className={styles.topNavigationTypo}
            component="div"

          >
            <Typography
              variant="h6"
              component="p"
              sx={{ cursor: "pointer" }}
              zIndex={2}
              color={`${darkMode ? "#ffffff" : "#121212"} !important`}
              onClick={() => window.history.back()}
            >
              Brand
            </Typography>

          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            objectFit: "scale-down",
            my: 12,
            flexDirection: "col",
          }}
        >
          <Box width={!isMobile ? "50%" : "100%"} color={darkMode ? "#ffffff" : "#121212"}>


            <Box
              sx={{
                display: "flex",
                justifyContent: `${isMobile ? "center" : "left"}`,
                alignItems: `${isMobile ? "center" : "left"}`,
                // maxWidth: `${isMobile ? "100%" : "75%" }`,
                width: "100%",
                objectFit: "scale-down",
                // maxHeight: "auto"
                flexDirection: "column",
              }}
            >
              <Box
                // width={"15%"}
                width={"86px"}
              >
                <Box
                  sx={{ position: "absolute", left: "7%", top: "-0.5%" }}
                >
                </Box>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain", // Ensure the image stays within the boundaries
                    padding: "5px",
                  }}
                  src={filteredbrand[0].brandLogo}
                  alt="Uploaded"
                />
              </Box>
              <Box my={3}>
                <Typography variant="h2" fontWeight={500} color="secondary" >
                  {filteredbrand[0].brandName}
                </Typography>
              </Box>
              <Box my={3}>
                <Typography variant="text" fontWeight={500} color="secondary" >
                  {filteredbrand[0].brandInfo}
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isMobile ? (
            <Box
              width={"50%"}
              // bgcolor={darkMode ? "#121212" : "#ffffff"}
              color={darkMode ? "#ffffff" : "#121212"}
            >

              <Box sx={{ position: "absolute", right: "4%", top: "4%" }}>
              </Box>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain", // Ensure the image stays within the boundaries
                  padding: "5px",
                }}
                src={filteredbrand[0].brandImage}
                alt="Uploaded"
              />
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            objectFit: "scale-down",
            alignContent: "center",
            alignItems: "center",
            my: 15,
            flexDirection: "col",
          }}
        >
          <Box width={!isMobile ? "75%" : "100%"} color={darkMode ? "#ffffff" : "#121212"}>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                objectFit: "scale-down",
                flexDirection: "column",
              }}
            >
              <Box
                width={"100%"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  // width={"12%"}
                  width={"86px"}
                >
                  <Box
                    sx={{ position: "absolute", right: "4%", top: "4%" }}
                  >
                  </Box>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain", // Ensure the image stays within the boundaries
                      padding: "5px",
                    }}
                    src={filteredbrand[0].brandLogo}
                    alt="Uploaded"
                  />
                </Box>
              </Box>
              <Box
                my={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >

                <Typography variant={isMobile ? "h3" : "h2"} fontWeight={400} color="secondary">
                  Who we are?
                </Typography>
              </Box>
              <Box
                my={1}
                sx={{
                  display: "flex",
                  textAlign: "center",
                }}
              >
                <Typography variant="text" fontWeight={500} color="secondary" >
                  {filteredbrand[0].brandWhoweare}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            objectFit: "scale-down",
            alignContent: "center",
            alignItems: "center",
            my: 12,
            flexDirection: "col",
          }}
        >
          <Box width={!isMobile ? "75%" : "100%"} color={darkMode ? "#ffffff" : "#121212"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                objectFit: "scale-down",
                flexDirection: "column",
              }}
            >
              <Box
                width={"100%"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  // width={"12%"}
                  width={"86px"}
                >
                  <Box
                    sx={{ position: "absolute", right: "4%", top: "4%" }}
                  >
                  </Box>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain", // Ensure the image stays within the boundaries
                      padding: "5px",
                    }}
                    src={filteredbrand[0].brandLogo}
                    alt="Uploaded"
                  />
                </Box>
              </Box>
              <Box
                my={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >

                <Typography variant={isMobile ? "h3" : "h2"} fontWeight={400} color="secondary">
                  Our Mission
                </Typography>
              </Box>
              <Box
                my={1}
                sx={{
                  display: "flex",
                  textAlign: "center",
                }}
              >
                <Typography variant="text" fontWeight={500} color="secondary" >
                  {filteredbrand[0].brandMission}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            objectFit: "scale-down",
            alignContent: "center",
            alignItems: "center",
            my: 12,
            flexDirection: "col",
          }}
        >
          <Box width={!isMobile ? "75%" : "100%"} color={darkMode ? "#ffffff" : "#121212"}>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                objectFit: "scale-down",
                flexDirection: "column",
              }}
            >
              <Box
                my={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Typography variant="h3" fontWeight={400} color="secondary">
                  XNFT's
                </Typography>
              </Box>

              <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" my={1}>
                {filteredbrand[0].xnftimages.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Brand XNFT ${index}`} // Update the alt attribute here
                    style={{ width: "200px" }}
                    width="200px"
                  />

                ))}
              </Stack>


              <Box
                color={darkMode ? "#ffffff" : "#121212"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                }}
              >
                {/* <Box
                  bgcolor={darkMode ? "#171C26" : "#ffffff"}
                  sx={{
                    // width: "28%",
                    width:"200px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    height: "180px",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                  mt={3}
                >
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <Typography component="div" color="secondary">
                      <AddIcon />
                    </Typography>
                  </IconButton>
                  <Typography component="div" color="secondary">
                    Create Asset
                  </Typography>
                </Box> */}
              </Box>
              <Box
                my={8}
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  flexDirection: `${isMobile ? "column" : "row"}`,
                }}
              >
                <GradientButtonBlue
                  variant="contained"
                  disableElevation
                  onClick={saveDetails}
                  sx={{
                    fontSize: "16px",
                    textTransform: "none",
                    width: "250px",
                    padding: "10px",
                  }}
                >
                  <Typography variant="p" fontWeight={500} color="secondary">
                    Save
                  </Typography>
                </GradientButtonBlue>
                <GradientButtonBlue
                  variant="contained"
                  disableElevation
                  onClick={viewCollection}
                  sx={{
                    fontSize: "16px",
                    textTransform: "none",
                    width: "250px",
                    padding: "10px",
                  }}
                >
                  <Typography variant="p" fontWeight={500} color="secondary">
                    View XNFT Collection
                  </Typography>
                </GradientButtonBlue>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Viewbrand;
