import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';


// import Backdrop from '../../../assets/exploreBackDropCircle.svg';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import { CollectionData } from '../CollectionData';
import CollectionCard from '../CollectionCard/CollectionCard';
import { Link } from 'react-router-dom';


const UtilityCollection = ({ queryName, darkMode, view, searchText }) => {

    
  const filteredData = CollectionData.filter(
    (ac) => ac.tags === "utility"
);      
//   const [collData, setCollData] = useState(filteredData);
  const [searched, setSearched] = useState(filteredData);


//     useEffect(() => {
    
//       setCollData(filteredData);
//       setSearched(filteredData);
//   },[])

  useEffect(() => {
    if(searchText === "" || searchText === undefined) {
        setSearched(filteredData);
    } else {
      const filteredItems = filteredData.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );
      setSearched(filteredItems)
    }
  }, [searchText])

  return (
    <>

    {queryName === "utility" ? (
        <>
          {view === "container" ? (
            <Box>
      {/* <Box
        style={{
          color: `${darkMode ? "#ffffff" : "#121212"}`,
          position: 'fixed',
          height: '100vh',
        }}
      >

      </Box> */}
      <Box mt={8}>
        <Grid
          container
          spacing={{ xs: 4, md: 6 }}
          columns={{ xs: 1, sm: 12, md: 12 }}
        >
          <>
            {searched.map((collection) => (
              <CollectionCard
                darkMode={darkMode}
                key={collection.id}
                id={collection.id}
                collection={collection}
              />
            ))}
          </>
        </Grid>
      </Box>


            </Box>
           ) : (
        <TableContainer component={Paper} 
    style={{
      marginTop:40,
      backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`
    }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Title</TableCell>
           
            <TableCell>Created By</TableCell>
            <TableCell>Total NFT</TableCell>
            <TableCell>Timestamp</TableCell>
            <TableCell>Likes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searched.map((collection) => (
            <TableRow
              key={collection.id}
              component={Link}
              to={collection.link}
              style={{ textDecoration: 'none' }}
            >
              <TableCell>{collection.id}</TableCell>
              <TableCell>
                <Avatar alt="Collection Image" src={collection.image} sx={{ width: 60, height: 60, borderRadius: '50%',
                    "& .MuiAvatar-img": {
                      objectFit:"contain"
                    }
                 }} />
              </TableCell>
              <TableCell>{collection.title}</TableCell>
             
              <TableCell>{collection.createdBy}</TableCell>
              <TableCell>{collection.totalNFT}</TableCell>
              <TableCell>{collection.timeStamp}</TableCell>
              <TableCell>{collection.likes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          )}
        </>
    ) : null}
    </>

  );
};

export default UtilityCollection;
