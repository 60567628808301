import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Grow,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Box } from '@mui/system';

// Icons
import SearchIconExplore from '../../assets/Icons/darkUIIcons/searchIconExplore.svg';
import { FiChevronLeft } from "react-icons/fi";
import { RiLayoutGridLine, RiListUnordered } from "react-icons/ri"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TuneIcon from '@mui/icons-material/Tune';
import FilterListIcon from '@mui/icons-material/FilterList';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';
import { BiSort } from 'react-icons/bi';
import FilterTab from "../../components/FilterTab/FilterTab";

import { StyledMenu } from '../../components/StyledMenu/StyledMenu';
import CollectionContainer from './CollectionContainer/CollectionContainer';
import { useTranslation } from 'react-i18next';
import styles from "./SingleArtWork.module.css";
import Navigation from "../../components/Navigation/Navigation";
import CollectionsTable from './CollectionContainer/CollectionsTable';
const Collections = ({ darkMode, blockchain, setBlockchain, handleConnectIndexxWallet }) => {
  const { t } = useTranslation();
  const [sortOptions, setSorOptions] = useState(t('DATE_CREATED'));
  const [orderOptions, setOrderOptions] = useState(t('NEWEST'));
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState('container');
  const navigationRef = useRef(null);

  const openSortMenu = Boolean(sortAnchorEl);
  const openOrderMenu = Boolean(orderAnchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Parse the query parameters from the URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };
  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };
  const handleCloseSortMenu = () => {
    setSortAnchorEl(null);
  };
  const handleOpenOrderMenu = (event) => {
    setOrderAnchorEl(event.currentTarget);
  };

  const handleCloseOrderMenu = () => {
    setOrderAnchorEl(null);
  };

  const searchResults = (event) => {
    setSearchText(event.target.value)
  }

  return (
    <Box
      color={darkMode ? '#ffffff' : '#121212'}
      mt={!isMobile ? 11.5 : 2}
      mb={3}
    >
      {/* <Navigation ref={navigationRef} darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain}/> */}
      <Box className={styles.topNavigationBox}>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => window.history.back()}
        >
          <FiChevronLeft fontSize={"1.5rem"} />
        </Box>
        <Typography
          className={styles.topNavigationTypo}
          component="div"

        >
          <Typography
            variant="h6"
            component="p"
            sx={{ cursor: "pointer" }}
            zIndex={2}
            color={`${darkMode ? "#ffffff" : "#121212"} !important`}
            onClick={() => window.history.back()}
          >
            Collections
          </Typography>

        </Typography>
      </Box>
      {!isMobile ? (
        <Stack direction='row' alignItems='center' gap={3}>
          <Box width='35%'>
            <Input
              disableUnderline
              fullWidth
              placeholder="Search in Collections"
              sx={{
                background: `${darkMode ? '#171c26' : '#EEEEEE'}`,
                border: 'none',
                py: 1.6,
                px: 2,
                borderRadius: '5px',
              }}
              onChange={searchResults}
              startAdornment={
                <InputAdornment position='start'>
                  <img src={SearchIconExplore} alt='Search' />
                </InputAdornment>
              }
            />
          </Box>
          <Box>
            <Button
              onClick={handleOpenSortMenu}
              sx={{
                py: 0.7,
                width: 350,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
              variant='contained'
              color='accent'
              fullWidth
              endIcon={
                <Box color='secondary' mt={1.5}>
                  {openSortMenu ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </Box>
              }
            >
              <Typography
                sx={{ textTransform: 'capitalize' }}
                variant='subtitle1'
                color='secondary'
              >
                {t('SORT_BY')}
              </Typography>
              <Typography sx={{ color: 'gray' }} variant='subtitle1'>
                {sortOptions}
              </Typography>
            </Button>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  width: 330,
                },
              }}
              TransitionComponent={Grow}
              anchorEl={sortAnchorEl}
              open={openSortMenu}
              onClose={handleCloseSortMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setSorOptions(t('DATE_CREATED'));
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {t('DATE_CREATED')}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions(t('POPULARITY'));
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <BiSort style={{ height: 25, width: 25 }} />
                  <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                    {t('POPULARITY')}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box>
          {/* Order */}
          <Box>
            <Button
              onClick={handleOpenOrderMenu}
              sx={{
                py: 0.7,
                width: 350,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
              variant='contained'
              color='accent'
              fullWidth
              endIcon={
                <Box color='secondary' mt={1.5}>
                  {openOrderMenu ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </Box>
              }
            >
              <Typography
                sx={{ textTransform: 'capitalize' }}
                variant='subtitle1'
                color='secondary'
              >
                {t('ORDER_BY')}
              </Typography>
              <Typography sx={{ color: 'gray' }} variant='subtitle1'>
                {orderOptions}
              </Typography>
            </Button>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  width: 330,
                },
              }}
              TransitionComponent={Grow}
              anchorEl={orderAnchorEl}
              open={openOrderMenu}
              onClose={handleCloseOrderMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setOrderOptions(t('NEWEST'));
                  handleCloseOrderMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {t('NEWEST')}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOrderOptions(t('OLDEST'));
                  handleCloseOrderMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <UpdateIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {t('OLDEST')}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box>

          <ToggleButtonGroup
      value={view}
      exclusive
      onChange={handleViewChange}
      style={{  backgroundColor: `${darkMode ? '#171C26' : '#eeeeee'}` }} // Set the background color here
    >
      <ToggleButton value="container" style={{padding:"16px", border:"none"}}>
        <RiLayoutGridLine style={{fontSize:"22px"}}/>
      </ToggleButton>
      <ToggleButton value="table" style={{padding:"16px", border:"none"}}>
        <RiListUnordered style={{fontSize:"22px"}}/>
      </ToggleButton>
    </ToggleButtonGroup>
        </Stack>
      ) : (
        <Stack direction='row' alignItems='center' gap={3}>
          <Box>
            <OutlinedInput
              size='small'
              fullWidth
              onChange={searchResults}
              startAdornment={
                <InputAdornment position='start'>
                  <img
                    height={15}
                    width={15}
                    src={SearchIconExplore}
                    alt='Search'
                  />
                </InputAdornment>
              }
            />
          </Box>
          {/* Sort */}
          <Box>
            <IconButton
              onClick={handleOpenSortMenu}
              variant='contained'
              fullWidth
            >
              <TuneIcon />
            </IconButton>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`,
                },
              }}
              anchorEl={sortAnchorEl}
              open={openSortMenu}
              onClose={handleCloseSortMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setSorOptions(t('DATE_CREATED'));
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {t('DATE_CREATED')}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions(t('POPULARITY'));
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <BiSort style={{ height: 25, width: 25 }} />
                  <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                    {t('POPULARITY')}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box>
          {/* Order */}
          <Box>
            <IconButton
              onClick={handleOpenOrderMenu}
              variant='contained'
              fullWidth
            >
              <FilterListIcon />
            </IconButton>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`,
                },
              }}
              TransitionComponent={Grow}
              anchorEl={orderAnchorEl}
              open={openOrderMenu}
              onClose={handleCloseOrderMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setOrderOptions(t('NEWEST'));
                  handleCloseOrderMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {t('NEWEST')}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOrderOptions(t('OLDEST'));
                  handleCloseOrderMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <UpdateIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {t('OLDEST')}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            sx={{
              backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`,
            }}
          >
            <ToggleButton value="container">
              <RiLayoutGridLine style={{fontSize:"22px"}}/>
            </ToggleButton>
            <ToggleButton value="table">
              <RiListUnordered style={{fontSize:"22px"}}/>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      )}
      {/* <CollectionContainer darkMode={darkMode} />
      <CollectionsTable/> */}
      <Box mb={3} mt={2}>

        <FilterTab darkMode={darkMode} />
      </Box>

      {/* {view === 'container' ? ( */}
      <CollectionContainer darkMode={darkMode} searchText={searchText} view={view} />
      {/* ) : (
        <CollectionsTable darkMode={darkMode} searchText={searchText}/>
      )} */}

    </Box>
  );
};

export default Collections;
