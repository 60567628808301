import bluebull from "../../assets/brandImages/bul_edited 1.svg"
import x_logo from "../../assets/brandImages/x_logo.svg"
import brand_image from "../../assets/brandImages/indexx_brand.svg"
import black from "../../assets/brandImages/black.svg"
import blue from "../../assets/brandImages/blue.svg"
import doll from "../../assets/brandImages/doll.svg"
import bl from "../../assets/brandImages/bl.svg";


export const brandData = 
[
  {
    "id": "1",
    "brandName": "indexx.ai",
    "brandInfo":"Indexx is a cutting-edge company at the forefront of the digital asset revolution, specializing in cryptocurrency, swaps, XNFTs, and NFTs. With a strong focus on blockchain technology, Indexx aims to provide users with innovative solutions for managing and trading their digital assets securely and efficiently.",
    "brandImage": brand_image,
    "brandLogo": x_logo,
    "xnftimages": [black, blue, doll],

    "brandMission":"At indexx.ai, our mission is to revolutionize the crypto industry by providing cutting-edge solutions that empower individuals and businesses to harness the full potential of decentralized technologies. We are committed to creating innovative tools, services, and platforms that simplify and enhance the crypto experience, making it accessible, secure, and efficient for all. Through our dedication to research, development, and collaboration, we strive to drive widespread adoption, foster trust, and shape the future of the crypto ecosystem, ultimately empowering individuals to take control of their financial destinies in a decentralized world.",
    "brandWhoweare":"As a company, Indexx recognizes the transformative potential of cryptocurrencies. It offers a robust platform that allows users to store, trade, and interact with various cryptocurrencies. From well-established cryptocurrencies like Bitcoin and Ethereum to emerging tokens, Indexx provides a user-friendly interface for seamless transactions and portfolio management.",
    "brandProducts": [
      {
        "id": "1",
        "artworkImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642268554/Minto%20NFT%20Marketplace/NFT%20Images/canvas_and_the_bloddy_lady_etdlfl.jpg",
        "artworkTitle": "Canvas and the bloddy lady",
        "artworkDetails": "Introducing IndexXNFTs: where art meets innovation in stunning masterpieces. Explore breathtaking digital artworks that redefine creativity and captivate with their beauty. Join the revolution and experience the magic of IndexXNFTs today",
        "artworkPrice": "141 USD",
        "creator": "@michail",
        "creatorImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642264443/Minto%20NFT%20Marketplace/avatars/michail.jpg",
        "owner": "@jWest",
        "ownerImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642264443/Minto%20NFT%20Marketplace/avatars/joe_west.jpg",
        "uploaded": "12 hours ago",
        "likes": 40,
        "tag": "createdItems"
      },
      {
        "id": "2",
        "artworkImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642268554/Minto%20NFT%20Marketplace/NFT%20Images/two_fried_one_bycycle_ljbfhn.jpg",
        "artworkTitle": "Two Fried One Bycycle",
        "artworkDetails": "Introducing IndexXNFTs: where art meets innovation in stunning masterpieces. Explore breathtaking digital artworks that redefine creativity and captivate with their beauty. Join the revolution and experience the magic of IndexXNFTs today",
        "artworkPrice": " (161 USD)",
        "creator": "@fFeroz",
        "creatorImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642264442/Minto%20NFT%20Marketplace/avatars/fatima_feroz.jpg",
        "owner": "@juhi",
        "ownerImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642264442/Minto%20NFT%20Marketplace/avatars/juhi.jpg",
        "uploaded": "12 hours ago",
        "likes": 40,
        "tag": "createdItems"
      }
    ]
  },
  {
    "id": "2",
    "brandName": "Bluebull",
    "brandInfo":"BlueBull is a dynamic and innovative company specializing in technology solutions for the finance industry. With a focus on providing cutting-edge software and services, we empower businesses to streamline operations, enhance customer experiences, and drive growth. ",
    "brandImage": bluebull,
    "brandLogo": bluebull,
    "xnftimages": [bl],
    "brandMission":"BlueBull's mission is to revolutionize the finance industry through innovative technology solutions. We strive to empower businesses with cutting-edge software, streamline operations, and enhance customer experiences. By combining our deep industry knowledge and technical expertise, we aim to drive growth and help financial institutions thrive in the digital age.",
    "brandWhoweare":"BlueBull is a technology company at the forefront of the finance industry, delivering innovative solutions. Our advanced software and services enable businesses to optimize operations, elevate customer experiences, and fuel growth. With a commitment to excellence and a customer-centric mindset, BlueBull is committed to reshaping the operations and success of financial institutions in the digital era.",
    "brandProducts": [
      {
        "id": "1",
        "artworkImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642268554/Minto%20NFT%20Marketplace/NFT%20Images/canvas_and_the_bloddy_lady_etdlfl.jpg",
        "artworkTitle": "Canvas and the bloddy lady",
        "artworkDetails": "Introducing IndexXNFTs: where art meets innovation in stunning masterpieces. Explore breathtaking digital artworks that redefine creativity and captivate with their beauty. Join the revolution and experience the magic of IndexXNFTs today",
        "artworkPrice": "141 USD",
        "creator": "@michail",
        "creatorImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642264443/Minto%20NFT%20Marketplace/avatars/michail.jpg",
        "owner": "@jWest",
        "ownerImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642264443/Minto%20NFT%20Marketplace/avatars/joe_west.jpg",
        "uploaded": "12 hours ago",
        "likes": 40,
        "tag": "createdItems"
      },
      {
        "id": "2",
        "artworkImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642268554/Minto%20NFT%20Marketplace/NFT%20Images/two_fried_one_bycycle_ljbfhn.jpg",
        "artworkTitle": "Two Fried One Bycycle",
        "artworkDetails": "Introducing IndexXNFTs: where art meets innovation in stunning masterpieces. Explore breathtaking digital artworks that redefine creativity and captivate with their beauty. Join the revolution and experience the magic of IndexXNFTs today",
        "artworkPrice": " (161 USD)",
        "creator": "@fFeroz",
        "creatorImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642264442/Minto%20NFT%20Marketplace/avatars/fatima_feroz.jpg",
        "owner": "@juhi",
        "ownerImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642264442/Minto%20NFT%20Marketplace/avatars/juhi.jpg",
        "uploaded": "12 hours ago",
        "likes": 40,
        "tag": "createdItems"
      }
    ]
  }
]
