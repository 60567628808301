import React, { useEffect, useRef, forwardRef } from "react";
import { Box, useMediaQuery } from "@mui/material";

import LiveAuctionContainer from "./LiveAuctionContainer";
import TrendingNFTContainer from "./TrendingNFTContainer";
import TrendingSellerContainer from "./TrendingSellersContainer";
import { useTheme } from "@emotion/react";
import HeroBanner from "./HeroBanner";
import StatsComponent from "../../Utils/StatsComponent/StatsComponent";
import Navigation from "../../components/Navigation/Navigation";

const Home = ({ darkMode, blockchain, setBlockchain, handleConnectIndexxWallet }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigationRef = useRef(null);
  const appRef = useRef();


  useEffect(() => {
    console.log(appRef);
    // if (appRef.current) {
    //   appRef.current.handleConnectIndexxWallet();
    // }
  }, []);

  // Parse the query parameters from the URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    console.log(handleConnectIndexxWallet);
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        console.log('I am here')
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        console.log('else if am here')
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  return (
    <>
      {!isMobile ? (
        <Box>
          {/* <Navigation ref={navigationRef} darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain}/> */}
          <Box sx={{ position: "relative" }}>
            <Box
              style={{
                position: "fixed",
                height: "100vh",
              }}
            >

            </Box>
            <Box sx={{ mt: 15 }}>
              <HeroBanner isMobile={isMobile} darkMode={darkMode} />
            </Box>
            {/* Stats */}
            <Box sx={{ mt: 10, mb: 20 }}>
              <StatsComponent darkMode={darkMode} />
            </Box>
            {/* Live Auctions */}
            {/* <LiveAuctionContainer darkMode={darkMode} /> */}

            {/* Trending IndexXNFT */}
            <TrendingNFTContainer darkMode={darkMode} />

            {/* Trending Sellers */}
            <TrendingSellerContainer darkMode={darkMode} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ overflowX: "hidden" }}>
          <Box
            style={{
              position: "relative",
            }}
          >
            <Box
              style={{
                position: "fixed",
                height: "100vh",
              }}
            >

            </Box>
            {/* Hero Banner */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HeroBanner isMobile={isMobile} darkMode={darkMode} />
            </Box>
            {/* Stats */}
            <Box sx={{ mt: 10, mb: 20 }}>
              <StatsComponent darkMode={darkMode} isMobile={isMobile} />
            </Box>
            {/* Live Auctions */}
            {/* <LiveAuctionContainer darkMode={darkMode} /> */}

            {/* Trending IndexXNFT */}
            <TrendingNFTContainer darkMode={darkMode} />

            {/* Trending Sellers */}
            <TrendingSellerContainer darkMode={darkMode} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Home;
