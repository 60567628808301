import React from "react";

// Material components
import {
  Container,
  IconButton,
  List,
  ListItem,
  Typography,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";

// Main logo
import MainLogo from "../../assets/mainLogo.png";
import MainLogoLight from "../../assets/mainLogoLight.png";
import market from "../../assets/footerimages/market.png";
import mask from "../../assets/footerimages/mask.png";
import xnft from "../../assets/footerimages/xnft.png";

import market_dark from "../../assets/footerimages/xmarket_dark.svg";
import wallet_web_dark from "../../assets/footerimages/wallet web green.svg";
import wallet_ext_dark from "../../assets/footerimages/wallet extension ornage.svg";
import xnft_dark from "../../assets/footerimages/xnft_dark.svg";

import market_white from "../../assets/footerimages/xmarket_white.svg";
import wallet_web_white from "../../assets/footerimages/wallet web black-.svg";
import wallet_ext_white from "../../assets/footerimages/wallet extension black.svg";
import xnft_white from "../../assets/footerimages/xnft_white.svg";

import { styled } from '@mui/system';


// Icons
import { RiTwitterFill, RiInstagramLine, RiDiscordLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = ({ darkMode }) => {
  const { t } = useTranslation();

  const HoverIconButton = styled(IconButton)({
    transition: 'transform 0.2s ease',
    backgroundColor: 'transparent !important', 
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: 'transparent', 
      // bgcolor: 'white',
      outline: 'none', // Remove outline on hover
    },
    '&:focus': {
      outline: 'none', // Remove outline on focus
      backgroundColor: 'transparent', 
    },
  });;
  
  const handleMarketClick = () => {
    window.location.href = "https://test.xnftmarketplace.indexx.ai/";
  };

  const handleWalletWebClick = () => {
    window.location.href = "https://test.wallet2.indexx.ai/";
  };

  const handleWalletExtClick = () => {
    window.location.href = "https://chrome.google.com/webstore/detail/indexx-wallet/fpibioaihcagphbidhodidjbnclocgll?hl=en";
  };


  const handleXnftClick = () => {
    window.location.href = "https://test.xnft.indexx.ai/";
  };

  const handleTwitterClick = () => {
    window.location.href = "https://twitter.com/Indexx_ai";
  };

  const handleDiscordClick = () => {
    window.location.href = "https://discord.gg/fjTs6s6u";
  };

  return (
    <Container sx={{ mt: 20, py: 6 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ maxWidth: "430px" }}>
          {/* <Box>
            {darkMode ? (
              <img src={MainLogo} width={100} alt="Minto" />
            ) : (
              <img src={MainLogoLight} width={100} alt="Minto" />
            )}
          </Box> */}

         <Stack direction="row" spacing={4}>
            {/* <img src={market} width={110} alt="Minto" />
            <img src={xnft} width={80} alt="Minto" /> */}
            {/* <a href="https://test.wallet2.indexx.ai/"> <img src={mask} width={110} alt="Minto" /></a> */}

            {darkMode ? (
              <HoverIconButton onClick={handleMarketClick} >
                  <img src={market_dark} width={110} alt="Minto" />
              </HoverIconButton>

            ) : (
              <HoverIconButton onClick={handleMarketClick} >
                <img src={market_white} width={110} alt="Minto" />
              </HoverIconButton>
            )}

            {darkMode ? (
              <HoverIconButton onClick={handleXnftClick} >
                  <img src={xnft_dark} width={110} alt="Minto" />
              </HoverIconButton>
            ) : (
              <HoverIconButton onClick={handleXnftClick} >
                <img src={xnft_white} width={110} alt="Minto" />
              </HoverIconButton>
            )}

            {darkMode ? (
              <HoverIconButton onClick={handleWalletWebClick} >
                  <img src={wallet_web_dark} width={150} alt="Minto" />
              </HoverIconButton>
            ) : (
              <HoverIconButton onClick={handleWalletWebClick}>
                <img src={wallet_web_white} width={150} alt="Minto" />
              </HoverIconButton>
            )}

            {darkMode ? (
              <HoverIconButton onClick={handleWalletExtClick} >
                  <img src={wallet_ext_dark} width={210} alt="Minto" />
              </HoverIconButton>
            ) : (
              <HoverIconButton onClick={handleWalletExtClick}>
                <img src={wallet_ext_white} width={210} alt="Minto" />
              </HoverIconButton>
            )}
       
      </Stack>

          <Box sx={{ my: 2 }}>
            <Typography
              sx={{ color: "#D71869", mb: 1, fontWeight: "bold" }}
              variant="subtitle1"
              component="h4"
            >
              {t("FOOTER_TITLE_MAIN")}
            </Typography>
            <Typography
              sx={{ color: "secondary.main" }}
              variant="body2"
              component="p"
            >
              Prepare to embark on an extraordinary voyage into the enchanting realm of IndexXNFT marketplace, where the vibrant pulse of digital art beats with boundless creativity and endless possibilities. 
            </Typography>
          </Box>
          <Box sx={{ ml: -1.3 }}>
          <HoverIconButton onClick={handleTwitterClick} >
            <IconButton>
              <RiTwitterFill
                style={{ color: `${darkMode ? "#ffffff" : "#171c26"}` }}
              />
            </IconButton>
          </HoverIconButton>
          <HoverIconButton onClick={handleDiscordClick} >
            <IconButton>
              <RiDiscordLine
                style={{ color: `${darkMode ? "#ffffff" : "#171c26"}` }}
              />
            </IconButton>
          </HoverIconButton>
            {/* <IconButton>
              <RiInstagramLine
                style={{ color: `${darkMode ? "#ffffff" : "#171c26"}` }}
              />
            </IconButton> */}
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Box zIndex={1000}>
            <Typography
              color="secondary"
              sx={{ mb: 1, fontWeight: 700 }}
              variant="subtitle1"
              component="h4"
            >
              {t("FOOTER_TITLE_MARKETPLACE")}
            </Typography>
            <List sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/explore?type=all"
                >
                  {t("FOOTER_LINK_EXPLORE")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/create-asset"
                >
                  {t("FOOTER_LINK_CREATE")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/trending-sellers"
                >
                  {t("FOOTER_LINK_SELLERS")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/trending-creators"
                >
                  {t("FOOTER_LINK_CREATORS")}
                </Link>
              </ListItem>
            </List>
          </Box>
          <Box>
            <Typography
              color="secondary"
              sx={{ mb: 1, fontWeight: 700 }}
              variant="subtitle1"
              component="h4"
            >
              {t("FOOTER_TITLE_COMPANY")}
            </Typography>
            <List sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <ListItem disablePadding>
                <a
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  href="https://indexx-ai.gitbook.io/indexx.ai-documentation/"
                  target="_blank"
                  rel="noreferrer"
                >
                Documentation
                </a>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/privacy-policy"
                >
                  {t("FOOTER_LINK_PRIVACY_POLICY")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/terms-and-condition"
                >
                  {t("FOOTER_LINK_TERMS_CONDITION")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/frequently-asked-questions"
                >
                  {t("FOOTER_LINK_FAQS")}
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  style={darkMode ? { color: "#ffffff" } : { color: "#171c26" }}
                  to="/contact-us"
                >
                  {t("FOOTER_LINK_CONTACT_US")}
                </Link>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
      <Typography
        variant="caption"
        component="div"
        sx={{ color: "gray", textAlign: "center", mt: 2 }}
      >
        &copy; 2023 {t("FOOTER_COPYRIGHT")}
      </Typography>
    </Container>
  );
};

export default Footer;
