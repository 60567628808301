import React from 'react';
import { Box } from '@mui/system';

// Logo
import MainLogo from '../../assets/mainLogo.png';
import MainLogoDark from '../../assets/mainLogoLight.png';
import { Stack, Typography } from '@mui/material';

// styles
import styles from './AuthStyles.module.css';

import { useTranslation } from 'react-i18next';

const AuthStatic = ({ darkMode }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
          >
            <img
              src={darkMode ? MainLogo : MainLogoDark}
              alt='Main Logo'
              style={{ display: 'block',width: '200px' }}
            />
            <Typography variant='h5' fontWeight={300} mt={2}>
              {t('IndexXNFT Market')}
            </Typography>
          </Box>
          <Box mt={10}>
            <Typography variant='h2' textAlign='center' fontWeight={500}>
            <span style={{color:'#AD18C7'}}>IndexXNFT </span>
            
              is the <span style={{color:'#AD18C7'}}>FUTURE</span>   <br />  
              {/* NFT was the PAST */}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default AuthStatic;
