import React, { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PolygonIcon from "../../assets/Icons/darkUIIcons/polygon.svg";
import IPFSIcon from "../../assets/Icons/darkUIIcons/ipfs.svg";
// From Material UI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Button,
  Divider,
  Fade,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";

import { add } from "date-fns";

// Custom Gradient button
import { GradientButtonBlue, GradientButtonPrimary } from "../../Utils/GradientButtons/GradientButtons";
import { IoCart } from "react-icons/io5";
// Icon
import { FiChevronLeft } from "react-icons/fi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { HiOutlineClock, HiTemplate } from "react-icons/hi";
import { BsFillBookmarkFill, BsBookmark, BsChevronDown } from "react-icons/bs";
import { FaThumbsUp } from "react-icons/fa";

import CountDownBoard from "../CountDownBoard/CountDownBoard";

import { useTranslation } from "react-i18next";

import { useTheme } from "@emotion/react";

// Styles
import styles from "./SingleAuctionCard.module.css";
import { GradientButtonSecondary } from "../../Utils/GradientButtons/GradientButtons";
import LinkIcon from "@mui/icons-material/Link";
// bidding data information
import { biddingData } from "./biddingData";

// Tabpanel
import { TabPanel } from "./TabPanel";
//import { ethers } from "ethers";
const ethers = require("ethers");


// will come form api
const futureDate = add(new Date(), {
  days: 7,
  hours: 12,
  minutes: 21,
});

const SingleAuctionCard = ({ fa, darkMode }) => {
  const {
    artworkImage,
    artworkTitle,
    artworkDetails,
    artworkPrice,
    creator,
    creatorImage,
    owner,
    ownerImage,
    uploaded,
    likes,
    currentBid,
    highestBid,
    auctionStatus,
    basePrice,
    boughtAt,
    contractAddress,
    abi,
    currentMint,
    tokenUrl
  } = fa;

  const [openModal, setOpenModal] = React.useState(false);

  const [likeState, setLikeState] = useState(false);
  const [bookmarkState, setBookmarkState] = useState(false);
  const [account, setAccount] = useState();
  const [provider, setProvider] = useState();
  const [showBidInput, setShowBidInput] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [tabValue, setTabValue] = useState(0); // setting tab value for changing

  // Tab handler
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const MobileTabs = styled(Tabs)({
    border: "none",
    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
    "& .MuiTabs-indicator": {
      backgroundColor: "inherit",
    },
  });

  const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    "&.Mui-selected": {
      color: "#ffffff",
      backgroundColor: "#0294FE",
      borderRadius: "4px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));


  const buyNFT = async () => {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    setAccount(accounts[0]);
    // Get provider from Metamask
    const maticNetworkRpcUrl = "https://polygon-mumbai.g.alchemy.com/v2/NTVLlKPYGVR2mdmlpb0epJ_pgJaa3t0u";

    // Create the provider using the Matic network RPC URL
    const provider = new ethers.providers.JsonRpcProvider(maticNetworkRpcUrl);

    // Set the provider
    ethers.providers = provider;

    // Set the signer
    const signer = provider.getSigner();
    var indexxContract = new ethers.Contract(contractAddress, abi, signer);
    let res = await indexxContract.mint(tokenUrl);
    provider
      .waitForTransaction(res.hash)
      .then(async function (mintTxn) {
        try {
        } catch (e) {

        }
      })

  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // Artwork details information
    <>
      <Modal
        sx={{ zIndex: 500000 }}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            bgcolor={darkMode ? "#171c26" : "#EEEEEE"}
            className={
              !isMobile ? styles.modalStyleWeb : styles.modalStyleMobile
            }
          >
            <Box className={styles.modalBox}>
              <Typography component="span" color="secondary" mt={0.5}>
                <HiTemplate fontSize={"1.5rem"} />
              </Typography>
              <Typography color="secondary" variant="h6" mt={-0.2}>
                {t("ITEM_DETAILS")}
              </Typography>
            </Box>
            <Typography
              // Global classes
              className={
                !isMobile ? "readMoreModalText" : "readMoreModalTextMobile"
              }
              variant="body2"
              color="secondary"
              lineHeight={2}
              height={"250px"}
              pr={2.5}
              sx={{
                overflowY: "auto",
              }}
            >
              {artworkDetails}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Box mt={11} className={styles.detailsContainerBox}>
        <Box>
          {/* Top navigation */}
          {!isMobile ? (
            <Box className={styles.topNavigationBox}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} />
              </Box>
              <Typography
                className={styles.topNavigationTypo}
                component="div"

              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ cursor: "pointer" }}
                  zIndex={2}
                  color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                >
                  {t("ITEM_DETAILS")}
                </Typography>

              </Typography>
            </Box>
          ) : (
            <Box className={styles.topNavigationBoxMobile}>
              <Box
                width={"70px"}
                height={"60px"}
                mt={-1}
                bgcolor={`${darkMode ? "#040404" : "#ffffff"}`}
              >
                <FiChevronLeft
                  onClick={() => window.history.back()}
                  fontSize={"1.5rem"}
                />
              </Box>
              <Box
                sx={{
                  position: "fixed",
                  top: "3%",
                  zIndex: "10000",
                  width: "70%",
                  display: "flex",
                  justifyContent: "center",
                  ml: 4,
                }}
              >
                <Typography
                  component="div"

                  position="relative"
                  display="flex"
                  alignItems="center"
                  ml={4}
                >
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ zIndex: 2 }}
                  >
                    {t("ITEM_DETAILS")}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          )}

          {!isMobile ? (
            <Box className={styles.detailsContainer}>
              <Box zIndex={10}  height={"100%"}>
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <img
                    className={styles.artDisplayImage}
                    src={artworkImage}
                    alt={artworkTitle}
                    onLoad={() => setLoading(false)}
                    loading="eager"
                  />
                  {auctionStatus === "live" && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "0px",
                        left: "10px",
                        right: "10px",
                      }}
                    >
                      <Divider className={styles.dividerBox} />
                      <CountDownBoard
                        darkMode={darkMode}
                        futureDate={futureDate}
                        isCard={true}
                      />
                      <Divider className={styles.dividerBox} />
                    </Box>
                  )}
                </Box>
                <Box
                  className={styles.artDisplayDetails}
                  bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                  sx={{
                    // mt: 3,
                    marginTop:"25%",
                  }}
                >
                  <Box
                    sx={{
                      p: 3,
                    }}
                  >
                    <Box gap={1}>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("CONTRACT_ADDRESS")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="p"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            fontWeight={500}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {contractAddress.substring(0, 6) + "..." + contractAddress.substring(contractAddress.length - 3)}
                          </Typography>
                          <Typography
                            component="span"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            ml={1}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <ContentCopyIcon />
                          </Typography>
                        </div>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("TOKEN_ID")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {currentMint}
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("TOKEN_STANDARD")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {"ERC721"}
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("BLOCKCHAIN")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          Polygon
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("ROYALTY_FEE")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {5 + "%"}
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("TRANSACTION_FEE")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          fontWeight={500}
                        >
                          5%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={styles.artDisplayDetails}
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box className={styles.artDisplayDetailsContent}>
                    <Button
                      variant="contained"
                      className={styles.artDisplayDetailsButton}
                      sx={{
                        p: 0,
                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={IPFSIcon}
                          alt="IPFS Icon"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                        {t("VIEW_ON_IPFS")}
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        align="right"
                      >
                        <LinkIcon />
                      </Typography>
                    </Button>

                    <Button
                      variant="contained"
                      className={styles.artDisplayDetailsButton}
                      sx={{
                        p: 0,
                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={PolygonIcon}
                          alt="Polygon Icon"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                        PolygonScan
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <LinkIcon />
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                className={styles.detailsContainerContentBox}
                bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                sx={{
                  position: "relative",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    component="h2"
                    color="secondary.main"
                    mb={4}
                    fontWeight={500}
                  >
                    {artworkTitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    lineHeight={1.5}
                    color="secondary.main"
                    mb={2}
                    textAlign="justify"
                  >
                    {artworkDetails?.length > 200
                      ? artworkDetails.slice(0, 200)
                      : artworkDetails}
                    {artworkDetails.length > 200 && (
                      <Button
                        variant="text"
                        onClick={handleOpenModal}
                        sx={{ color: "#02C7FE", textTransform: "lowercase" }}
                      >
                        ...{t("READ_MORE")}
                      </Button>
                    )}
                  </Typography>

                  <Accordion
                    disableGutters
                    elevation={0}
                    sx={{
                      backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                      mb: 3,
                      border: "none",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <Typography component="span" color="secondary">
                          <BsChevronDown
                            fontSize={"1rem"}
                            style={{ marginTop: "5px" }}
                          />
                        </Typography>
                      }
                    >
                      <Typography
                        variant="h6"
                        color="secondary"
                        ml={-2}
                        fontWeight={500}
                      >
                        {t("PROPERTIES")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ border: "none", ml: -2 }}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {fa?.properties &&
                          fa?.properties.length > 0 &&
                          fa?.properties[0].key != "" ? (
                          <>
                            {fa.properties?.map((property) => (
                              <Box
                                className={styles.propBox}
                                bgcolor={darkMode ? "#040404" : "#ffffff"}
                              >
                                <Typography
                                  variant="button"
                                  component="p"
                                  color="#02C7FE"
                                >
                                  {property.key}
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                  {property.value}
                                </Typography>
                              </Box>
                            ))}
                          </>
                        ) : (
                          <>
                            <Typography variant="body2" color="secondary">
                              {t("NO_PROPERTIES_FOUND")}
                            </Typography>
                          </>
                        )}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("BASE_PRICE")}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {basePrice}
                      </Typography>
                    </Box>
                    {/* <Box textAlign="right">
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("HIGHEST_BID")}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {highestBid}
                      </Typography>
                    </Box> */}
                  </div>

                  <Divider className={styles.dividerBox} />
                  {/* <Box className={styles.singleArtCardInfo}>
                    <Box className={styles.avatarBox}>
                      <Box>
                        <Avatar src={creatorImage} alt={creator} />
                      </Box>
                      <Stack direction="column">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("CREATOR")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                          sx={{
                            textTransform: "lowercase",
                            maxWidth: "90px",
                          }}
                          noWrap={true}
                        >
                          {creator}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box className={styles.avatarBox}>
                      <Box>
                        <Avatar src={ownerImage} alt={owner} />
                      </Box>
                      <Stack direction="column">
                        <Typography variant="caption" gutterBottom color="gray">
                          {t("OWNER")}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="secondary.main"
                          sx={{
                            textTransform: "lowercase",
                            maxWidth: "90px",
                          }}
                          noWrap={true}
                        >
                          {owner}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box> */}
                  <Divider className={styles.dividerBox} />
                  <Box className={styles.cardFooter}>
                    <Stack direction="row" alignItems="center">
                      <IconButton>
                        <Typography component="span" color="secondary">
                          <HiOutlineClock className={styles.footerIcons} />
                        </Typography>
                      </IconButton>
                      <Typography
                        variant="caption"
                        component="span"
                        color={darkMode ? "#ffffff" : "#121212"}
                      >
                        {uploaded + "hr ago"}
                      </Typography>
                    </Stack>
                    {/* <Stack direction="row" alignItems="center" gap={2}>
                      <Box className={styles.userInteractionBox}>
                        <IconButton onClick={() => setLikeState(!likeState)}>
                          <Typography color="#e23e58" component="span">
                            <AiOutlineHeart className={styles.footerIcons} />
                          </Typography>
                        </IconButton>
                        <Typography
                          variant="caption"
                          component="span"
                          color={darkMode ? "#ffffff" : "#121212"}
                        >
                          {likes}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton>
                          <Typography component="span" color="secondary">
                            <BsBookmark className={styles.footerIcons} />
                          </Typography>
                        </IconButton>
                      </Box>
                    </Stack> */}
                  </Box>
                </Box>
                {auctionStatus === "live" ? (
                  <>
                    <Stack direction="row" alignItems="center" gap={2}>
                      {/* <GradientButtonBlue
                        className={styles.gradientButtonClass}
                        variant="contained"
                        onClick={() => setShowBidInput(true)}
                        fullWidth={true}
                      >

                        <Typography variant="body1" component="span">
                          {t("PLACE_YOUR_BID")}
                        </Typography>
                      </GradientButtonBlue> */}
                      <GradientButtonSecondary
                        className={styles.gradientButtonClass}
                        variant="contained"
                        fullWidth={true}
                        onClick={() => buyNFT()
                        }

                      >
                        {/* <Typography
                          color="#ffffff"
                          component="span"
                          fontSize={20}
                          mt={0.5}
                        >
                          <IoCart />
                        </Typography> */}
                        <Typography variant="body1" component="span">
                          {t(`Buy for ${artworkPrice} USD`)}
                        </Typography>
                      </GradientButtonSecondary>

                    </Stack>
                  </>
                ) : (
                  <Box className={styles.footerButtonContainer}>
                    <GradientButtonBlue
                      className={styles.gradientButtonClass}
                      variant="contained"
                    >
                      <Typography variant="body1" component="span">
                        {t("Auction Ended")}
                      </Typography>
                    </GradientButtonBlue>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box className={styles.detailsContainerMobile}>
              <Box zIndex={10}>
                <img
                  className={styles.artDisplayImageMobile}
                  src={artworkImage}
                  alt={artworkTitle}
                  loading="eager"
                />
              </Box>
              <Box className={styles.userInteractionBoxMobile}>
                <Stack direction="row" alignItems="center">
                  <IconButton>
                    <Typography component="span" color="secondary">
                      <HiOutlineClock className={styles.footerIcons} />
                    </Typography>
                  </IconButton>
                  <Typography
                    variant="caption"
                    component="span"
                    color={darkMode ? "#ffffff" : "#121212"}
                  >
                    {uploaded} {t("HOURS_AGO")}
                  </Typography>
                </Stack>
                {/* <Stack direction="row" alignItems="center" gap={2}>
                  <Box className={styles.userInteractionBoxMobile}>
                    <IconButton onClick={() => setLikeState(!likeState)}>
                      <Typography component="span" color="secondary">
                        <AiOutlineHeart className={styles.footerIcons} />
                      </Typography>
                    </IconButton>
                    <Typography
                      variant="caption"
                      component="span"
                      color={darkMode ? "#ffffff" : "#121212"}
                    >
                      {likes}
                    </Typography>
                  </Box>
                  <Box mt={-0.5}>
                    <IconButton>
                      <Typography component="span" color="secondary">
                        <BsBookmark className={styles.footerIcons} />
                      </Typography>
                    </IconButton>
                  </Box>
                </Stack> */}
              </Box>
              <Box>
                {/* <Box className={styles.cardFooterMobile}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box>
                      <Avatar src={creatorImage} alt={creator} />
                    </Box>
                    <Stack direction="column" alignItems="left">
                      <Typography variant="caption" gutterBottom color="gray">
                        {t("CREATOR")}
                      </Typography>
                      <Typography
                        variant="caption"
                        gutterBottom
                        color="secondary.main"
                        sx={{
                          textTransform: "lowercase",
                          maxWidth: "90px",
                        }}
                        noWrap={true}
                      >
                        {creator}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box>
                      <Avatar src={ownerImage} alt={owner} />
                    </Box>
                    <Stack direction="column" alignItems="left">
                      <Typography variant="caption" gutterBottom color="gray">
                        {t("OWNER")}
                      </Typography>
                      <Typography
                        variant="caption"
                        gutterBottom
                        color="secondary.main"
                        sx={{
                          textTransform: "lowercase",
                          maxWidth: "90px",
                        }}
                        noWrap={true}
                      >
                        {owner}
                      </Typography>
                    </Stack>
                  </Box>
                </Box> */}
                <Box>
                  <Typography
                    variant="subtitle2"
                    component="h2"
                    color="secondary.main"
                    mb={2}
                  >
                    {artworkTitle}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    lineHeight={1.8}
                    mb={2}
                    color="secondary.main"
                    textAlign="left"
                  >
                    {artworkDetails?.length > 200
                      ? artworkDetails?.slice(0, 200)
                      : artworkDetails}
                    {artworkDetails.length > 200 && (
                      <Button
                        variant="text"
                        onClick={handleOpenModal}
                        sx={{ fontSize: "11px", color: "#02C7FE" }}
                      >
                        ...{t("READ_MORE")}
                      </Button>
                    )}
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("BASE_PRICE")}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {basePrice}
                      </Typography>
                    </Box>
                    {/* <Box textAlign="right">
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={darkMode ? "#FFFFFF" : "#121212"}
                        mb={1}
                      >
                        {t("HIGHEST_BID")}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="secondary.main"
                        mb={1}
                        fontWeight={500}
                      >
                        {highestBid}
                      </Typography>
                    </Box> */}
                  </div>
                </Box>
                {auctionStatus === "live" && (
                  <Box>
                    <Divider className={styles.dividerBox} />
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "20px",
                      }}
                    >
                      <CountDownBoard
                        darkMode={darkMode}
                        futureDate={futureDate}
                        isCard={true}
                      />
                    </Box>
                    <Divider className={styles.dividerBox} />
                  </Box>
                )}

                <Box
                  className={styles.artDisplayDetailsMobile}
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box className={styles.artDisplayDetailsContentMob} gap={2}>
                    <Button
                      variant="contained"
                      className={styles.artDisplayDetailsButtonMob}
                      sx={{
                        p: 0,
                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={IPFSIcon}
                          alt="IPFS Icon"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                        {t("VIEW_ON_IPFS")}
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        align="right"
                      >
                        <LinkIcon />
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      className={styles.artDisplayDetailsButtonMob}
                      sx={{
                        p: 0,
                        backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                      }}
                    >
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={PolygonIcon}
                          alt="Polygon Icon"
                          width="20"
                          height={"20"}
                        />
                      </Typography>
                      <Typography variant="body1" component="span">
                        View on PolygonScan
                      </Typography>
                      <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={1}
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <LinkIcon />
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <Box
                  className={styles.artDisplayDetailsMobile}
                  bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                  sx={{
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      p: 3,
                    }}
                  >
                    <Box gap={1}>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("CONTRACT_ADDRESS")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="p"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            fontWeight={500}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {contractAddress.substring(0, 6) + "..." + contractAddress.substring(contractAddress.length - 3)}
                          </Typography>
                          <Typography
                            component="span"
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            ml={1}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <ContentCopyIcon />
                          </Typography>
                        </div>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          Token ID
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {currentMint}
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          Token Standard
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {"ERC721"}
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("BLOCKCHAIN")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          Polygon
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("ROYALTY_FEE")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          mb={2}
                          fontWeight={500}
                        >
                          {5 + "%"}
                        </Typography>
                      </Box>
                      <Box className={styles.artDisplayDetailsContent}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="gray"
                        >
                          {t("TRANSACTION_FEE")}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="p"
                          color={darkMode ? "#FFFFFF" : "#121212"}
                          fontWeight={500}
                        >
                          5%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {auctionStatus === "live" ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      {/* <GradientButtonBlue
                        className={styles.gradientButtonClass}
                        variant="contained"
                        onClick={() => setShowBidInput(true)}
                        sx={{
                          width: "100%",
                          marginTop: "1rem",
                        }}
                        fullWidth={true}
                      >


                        <Typography variant="body1" component="span">
                          {t("PLACE_YOUR_BID")}
                        </Typography>
                      </GradientButtonBlue> */}
                    </Box>
                    <Box>
                      {" "}
                      <GradientButtonSecondary
                        className={styles.gradientButtonClass}
                        variant="contained"
                        sx={{
                          width: "100%",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography
                          color="#ffffff"
                          component="span"
                          fontSize={20}
                          mt={0.5}
                        >
                          <IoCart />
                        </Typography>
                        <Typography variant="body1" component="span">
                          {t(`Buy for ${artworkPrice}`)}
                        </Typography>
                      </GradientButtonSecondary>
                    </Box>
                  </>
                ) : (
                  <Box className={styles.footerButtonContainer}>
                    <GradientButtonBlue
                      className={styles.gradientButtonClass}
                      variant="contained"
                    >
                      <Typography variant="body1" component="span">
                        {t("AUCTION_ENDED")}
                      </Typography>
                    </GradientButtonBlue>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>

        {/* Tabs and panels*/}
        {!isMobile ? (
          <Box className={styles.tabBox}>
            <Box sx={{ width: "100%", my: 16 }}>
              <Box
                bgcolor={`${darkMode ? "#171c26" : "#EEEEEE"}`}
                borderRadius="10px"
              >
                <Tabs
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                >
                  <Tab
                    sx={{ color: "gray", ml: 5, textTransform: "capitalize" }}
                    label={t("RECENT_BID")}
                  />
                  <Tab
                    sx={{ color: "gray", ml: 12, textTransform: "capitalize" }}
                    label={t("HISTORY")}
                  />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                {/* Global tab styles */}
                <div className="tabStyles">
                  {biddingData[0].bidder.map((bd) => (
                    <Box mb={2} key={bd.id}>
                      <Typography
                        variant="body1"
                        className={styles.tabBoxContent}
                      >
                        <Avatar
                          // sx={{
                          //   border: `2px solid ${darkMode ? "#ffffff" : "#02C7FE"
                          //     }`,
                          // }}
                          src={bd.avatar}
                          alt={bd.name}
                        />
                        <Typography variant="body2" component="p">
                          {t("BIDDING_PRICE")}{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {bd.amount}
                          </span>{" "}
                          {t("BIDDING_BY")}{" "}
                          <span style={{ fontWeight: "bold" }}>{bd.name}</span>{" "}
                          {t("BIDDING_AT")} {bd.date} {bd.time}
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div className="tabStyles">
                  {biddingData[0].acceptee.map((bd) => (
                    <Box mb={2} key={bd.id}>
                      <Typography className={styles.tabBoxContent}>
                        <Avatar
                          // sx={{
                          //   border: `2px solid ${darkMode ? "#ffffff" : "#02C7FE"
                          //     }`,
                          // }}
                          src={bd.avatar}
                          alt={bd.name}
                        />
                        <Typography variant="body2" component="p">
                          {t("BIDDING_PRICE")}{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {bd.amount}
                          </span>{" "}
                          {t("BIDDING_BY")}{" "}
                          <span style={{ fontWeight: "bold" }}>{bd.name}</span>{" "}
                          {t("BIDDING_AT")} {bd.date} {bd.time}
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </div>
              </TabPanel>
            </Box>
          </Box>
        ) : (
          <Box width="90%" className={styles.tabBox}>
            <Box mt={5} mb={-3}>
              <Box ml={-2} mb={2}>
                <MobileTabs
                  darkMode={darkMode}
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                >
                  <MobileTab
                    sx={{ color: "gray", textTransform: "capitalize" }}
                    label={t("RECENT_BID")}
                  />
                  <MobileTab
                    sx={{ color: "gray", ml: 3, textTransform: "capitalize" }}
                    label={t("HISTORY")}
                  />
                </MobileTabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <div className="tabStylesMobile">
                  {biddingData[0].bidder.map((bd) => (
                    <Box pr={2} my={3} key={bd.id}>
                      <Typography
                        className={styles.tabBoxMobile}
                        variant="body2"
                      >
                        <Avatar
                          // sx={{
                          //   border: `2px solid ${darkMode ? "#ffffff" : "#02C7FE"
                          //     }`,
                          // }}
                          src={bd.avatar}
                          alt={bd.name}
                        />
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "12px", lineHeight: 2 }}
                          component="p"
                        >
                          {t("BIDDING_PRICE")}{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {bd.amount}
                          </span>{" "}
                          {t("BIDDING_BY")}{" "}
                          <span style={{ fontWeight: "bold" }}>{bd.name}</span>{" "}
                          {t("BIDDING_AT")} {bd.date} {bd.time}
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div className="tabStylesMobile">
                  {biddingData[0].acceptee.map((bd) => (
                    <Box pr={2} my={3} key={bd.id}>
                      <Typography
                        variant="body1"
                        className={styles.tabBoxMobile}
                      >
                        <Avatar
                          // sx={{
                          //   border: `2px solid ${darkMode ? "#ffffff" : "#02C7FE"
                          //     }`,
                          // }}
                          src={bd.avatar}
                          alt={bd.name}
                        />
                        <Typography
                          variant="body2"
                          component="p"
                          sx={{ fontSize: "12px", lineHeight: 2 }}
                        >
                          {t("BIDDING_PRICE")}{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {bd.amount}
                          </span>{" "}
                          {t("BIDDING_BY")}{" "}
                          <span style={{ fontWeight: "bold" }}>{bd.name}</span>{" "}
                          {t("BIDDING_AT")} {bd.date} {bd.time}
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </div>
              </TabPanel>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SingleAuctionCard;
