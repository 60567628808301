import React, { useEffect } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";

// Icons
import { AiFillCaretDown } from "react-icons/ai";
import { FiChevronLeft } from "react-icons/fi";

import { useTranslation } from "react-i18next";

import { useTheme } from "@emotion/react";

const FAQ = ({ darkMode }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {!isMobile ? (
        <div style={{ overflowY: "hidden" }}>
          <Box
            color={darkMode ? "#ffffff" : "#121212"}
            sx={{
              position: "relative",
              display: "flex",
              gap: 2,
              alignItems: "center",
              mt: 11.5,
              mb: 5,
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <FiChevronLeft fontSize={"1.5rem"} />
            </Box>
            <Typography
              component="div"
              sx={{
                // borderBottom: `2px solid ${darkMode ? '#ffffff' : '#121212'}`,
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="p"
                sx={{ zIndex: 2, cursor: "pointer" }}
              >
                {t("FOOTER_LINK_FAQS")}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                sx={{
                  // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                  color: `${darkMode ? "#ffffff" : "#121212"}`,
                }}
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  What is XNFT?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  XNFT stands for Cross-Chain Non-Fungible Token. It refers to a
                  type of non-fungible token (NFT) that can be utilized and
                  transferred across multiple blockchain networks. XNFTs aim to
                  overcome the limitations of traditional NFTs, which are
                  typically confined to a single blockchain platform.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  How do XNFTs work?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  XNFTs employ interoperability protocols and technologies to
                  enable seamless transfer and use across different blockchain
                  networks. They leverage cross-chain bridges or other solutions
                  to connect multiple blockchains, allowing XNFT holders to move
                  their tokens between compatible platforms.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  What are the advantages of XNFTs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  The key advantage of XNFTs is their ability to operate across
                  various blockchain networks, increasing their versatility and
                  potential user base. It enables XNFT owners to access
                  different ecosystems, marketplaces, and applications without
                  being restricted to a single blockchain's limitations.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  Can XNFTs be minted on any blockchain?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  XNFTs are typically minted on blockchain platforms that
                  support the necessary standards and protocols for cross-chain
                  compatibility. Examples include Ethereum, Polkadot, Cosmos,
                  and others. It's essential to ensure that the blockchain you
                  choose for minting supports cross-chain functionality.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  How can I transfer an XNFT between different blockchains?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  Transferring an XNFT between different blockchains requires
                  utilizing cross-chain bridges or interoperability protocols.
                  These mechanisms allow you to lock the XNFT on one blockchain
                  while minting an equivalent token on the target blockchain.
                  The locked token can then be unlocked and redeemed when
                  needed.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  Are XNFTs limited to digital art and collectibles?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  No, XNFTs are not limited to digital art and collectibles.
                  While these categories have gained significant attention in
                  the NFT space, XNFTs can represent a wide range of assets,
                  including virtual real estate, in-game items, music, videos,
                  virtual goods, and more. The potential applications of XNFTs
                  are diverse.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  How do I identify if an NFT is an XNFT?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  To identify an XNFT, you need to check whether the token is
                  compatible with multiple blockchain networks. Typically, XNFT
                  projects will specify the supported blockchains in their
                  documentation or project announcements. Look for information
                  about cross-chain compatibility or interoperability features.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  Can I use any wallet to store XNFTs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  The availability of wallets that support XNFTs may vary
                  depending on the blockchain networks involved. You'll need to
                  use wallets that are compatible with the specific
                  blockchain(s) hosting the XNFTs you own. It's recommended to
                  research and choose wallets that are compatible with the
                  relevant blockchains.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  Are XNFTs more expensive than regular NFTs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  The cost of an XNFT is typically determined by various
                  factors, including the underlying blockchain's transaction
                  fees, minting fees, and market demand for the specific NFT
                  project. While some XNFTs may have additional costs associated
                  with cross-chain functionality, it ultimately depends on the
                  project and its specific pricing structure.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES')} */}
                  Can I trade XNFTs across different marketplaces?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  The ability to trade XNFTs across different marketplaces
                  depends on the integration and support for cross-chain tokens
                  by those marketplaces. If a marketplace supports the
                  blockchain(s) on which your XNFT resides, it is possible to
                  trade the token on that platform. It's advisable to check with
                  each marketplace for their specific compatibility and listing
                  requirements.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </div>
      ) : (
        <div style={{ paddingBottom: "1rem" }}>
          <Box
            sx={{
              position: "fixed",
              top: "0%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10000",
              mt: 5,
            }}
          >
            <Typography
              variant="subtitle1"
              color="secondary"
              component="div"
              sx={{
                borderBottom: `${
                  darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                }`,
              }}
            >
              {t("FOOTER_LINK_FAQS")}
            </Typography>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Accordion
              defaultExpanded
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                sx={{
                  // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                  color: `${darkMode ? "#ffffff" : "#121212"}`,
                }}
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"What is XNFT?".slice(0, 50)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  XNFT stands for Cross-Chain Non-Fungible Token. It refers to a
                  type of non-fungible token (NFT) that can be utilized and
                  transferred across multiple blockchain networks. XNFTs aim to
                  overcome the limitations of traditional NFTs, which are
                  typically confined to a single blockchain platform.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"How do XNFTs work?".slice(0, 50)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  XNFTs employ interoperability protocols and technologies to
                  enable seamless transfer and use across different blockchain
                  networks. They leverage cross-chain bridges or other solutions
                  to connect multiple blockchains, allowing XNFT holders to move
                  their tokens between compatible platforms.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"What are the advantages of XNFTs?".slice(0, 50)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  The key advantage of XNFTs is their ability to operate across
                  various blockchain networks, increasing their versatility and
                  potential user base. It enables XNFT owners to access
                  different ecosystems, marketplaces, and applications without
                  being restricted to a single blockchain's limitations.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"Can XNFTs be minted on any blockchain?".slice(0, 50)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  XNFTs are typically minted on blockchain platforms that
                  support the necessary standards and protocols for cross-chain
                  compatibility. Examples include Ethereum, Polkadot, Cosmos,
                  and others. It's essential to ensure that the blockchain you
                  choose for minting supports cross-chain functionality.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"How can I transfer an XNFT between different blockchains?".slice(
                    0,
                    50
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  Transferring an XNFT between different blockchains requires
                  utilizing cross-chain bridges or interoperability protocols.
                  These mechanisms allow you to lock the XNFT on one blockchain
                  while minting an equivalent token on the target blockchain.
                  The locked token can then be unlocked and redeemed when
                  needed.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"Are XNFTs limited to digital art and collectibles?".slice(
                    0,
                    50
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  No, XNFTs are not limited to digital art and collectibles.
                  While these categories have gained significant attention in
                  the NFT space, XNFTs can represent a wide range of assets,
                  including virtual real estate, in-game items, music, videos,
                  virtual goods, and more. The potential applications of XNFTs
                  are diverse.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"How do I identify if an NFT is an XNFT?".slice(0, 50)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  To identify an XNFT, you need to check whether the token is
                  compatible with multiple blockchain networks. Typically, XNFT
                  projects will specify the supported blockchains in their
                  documentation or project announcements. Look for information
                  about cross-chain compatibility or interoperability features.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"Can I use any wallet to store XNFTs?".slice(0, 50)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  The availability of wallets that support XNFTs may vary
                  depending on the blockchain networks involved. You'll need to
                  use wallets that are compatible with the specific
                  blockchain(s) hosting the XNFTs you own. It's recommended to
                  research and choose wallets that are compatible with the
                  relevant blockchains.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"Are XNFTs more expensive than regular NFTs?".slice(0, 50)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  The cost of an XNFT is typically determined by various
                  factors, including the underlying blockchain's transaction
                  fees, minting fees, and market demand for the specific NFT
                  project. While some XNFTs may have additional costs associated
                  with cross-chain functionality, it ultimately depends on the
                  project and its specific pricing structure.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#EEEEEE"}`,
                color: `${darkMode ? "#ffffff" : "#121212"}`,
              }}
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={
                  <Typography component="span" color="secondary">
                    <AiFillCaretDown />
                  </Typography>
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1">
                  {/* {t('FAQ_QUES').slice(0, 50)} */}
                  {"Can I trade XNFTs across different marketplaces?".slice(
                    0,
                    50
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    // backgroundColor: `${darkMode ? '#040404' : '#EEEEEE'}`,
                    color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                    opacity: 0.9,
                    lineHeight: 1.8,
                    textAlign: "justify",
                    pr: 2,
                  }}
                >
                  {/* {t('FAQ_ANS')} */}
                  The ability to trade XNFTs across different marketplaces
                  depends on the integration and support for cross-chain tokens
                  by those marketplaces. If a marketplace supports the
                  blockchain(s) on which your XNFT resides, it is possible to
                  trade the token on that platform. It's advisable to check with
                  each marketplace for their specific compatibility and listing
                  requirements.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </div>
      )}
    </>
  );
};

export default FAQ;
