import { useTheme } from "@emotion/react";
import { Divider, Typography, useMediaQuery, Link } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronLeft } from "react-icons/fi";

const PrivacyPolicy = ({ darkMode }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!isMobile ? (
        <Box>
          <Box
            color={darkMode ? "#ffffff" : "#121212"}
            sx={{
              position: "relative",
              display: "flex",
              gap: 4,
              alignItems: "center",
              mt: 11,
              mb: 5,
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <FiChevronLeft fontSize={"1.5rem"} />
            </Box>
            <Typography
              component="div"
              sx={{
                // borderBottom: `2px solid ${darkMode ? "#ffffff" : "#121212"}`,
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="p"
                sx={{ zIndex: 2, cursor: "pointer" }}
              >
                {t("FOOTER_LINK_PRIVACY_POLICY")}
              </Typography>
            </Typography>
          </Box>

          <Box
            color={darkMode ? "#ffffff" : "#121212"}
            bgcolor={darkMode ? "#171c26" : "#EEEEEE"}
            sx={{
              borderRadius: "16px",
              p: 6,
            }}
          >
            <Typography variant="h6" component="h6" sx={{ mb: 3 }}>
              {t("LAST_UPDATED")}: 24 May 2023
            </Typography>
            <Divider sx={{ backgroundColor: "#8E8E8E", opacity: "0.7" }} />
            <Box>
              <Typography variant="h6" component="h6" sx={{ my: 2 }}>
                {t("OUR_PRIVACY_POLICY")}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                sx={{
                  lineHeight: 2,
                  textAlign: "justify",
                  color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                }}
              >
                {/* {t('PRIVACY_POLICY_DESC')} */}
                <p>
                  At XNFT, we are committed to protecting your privacy and
                  ensuring the security of your personal information. This
                  Privacy Policy outlines how we collect, use, disclose, and
                  safeguard your information when you access or use our services
                  and website. By using our services, you consent to the
                  practices described in this Privacy Policy.
                </p>

                <p>Information We Collect</p>

                <p>
                  1.1. Personal Information: We may collect personal information
                  that you provide directly to us when you interact with our
                  services, such as your name, email address, and other contact
                  details. You may choose to provide additional information,
                  such as your preferences or interests, but it is not
                  mandatory.
                </p>
                <p>
                  1.2. Non-Personal Information: We may automatically collect
                  certain non-personal information about your use of our
                  services, such as your IP address, device information, browser
                  type, and operating system. This information does not
                  personally identify you and is used to analyze trends,
                  administer the site, and track users' movements.
                </p>
                <p>Use of Information</p>
                <p>
                  2.1. We may use the information we collect for various
                  purposes, including: Providing and improving our services,
                  products, and user experience. Communicating with you and
                  responding to your inquiries. Sending you important
                  notifications, updates, and promotional materials. Customizing
                  and personalizing your experience with our services. Analyzing
                  and monitoring usage patterns and trends. Protecting the
                  security and integrity of our systems and preventing fraud.
                </p>
                <p>Sharing of Information</p>
                <p>
                  3.1. We may share your personal information with trusted third
                  parties who assist us in operating our services, conducting
                  business activities, or providing support. These third parties
                  are obligated to maintain the confidentiality and security of
                  your information.
                </p>
                <p>
                  3.2. We may disclose your information when required by law,
                  legal proceedings, or to protect our rights, safety, or the
                  rights, safety, or security of others.
                </p>
                <p>
                  3.3. In the event of a merger, acquisition, or sale of assets,
                  your personal information may be transferred to the acquiring
                  entity, subject to the applicable privacy laws.
                </p>
                <p>Data Security</p>
                <p>
                  4.1. We implement appropriate security measures to protect
                  against unauthorized access, alteration, disclosure, or
                  destruction of your personal information. However, no method
                  of transmission over the internet or electronic storage is
                  completely secure, and we cannot guarantee absolute security.
                </p>
                <p>Cookies and Tracking Technologies</p>
                <p>
                  5.1. We use cookies and similar tracking technologies to
                  enhance your experience on our website. These technologies
                  allow us to collect information about your browsing activities
                  and personalize your interactions. You have the option to
                  disable cookies through your browser settings, but this may
                  limit certain functionalities of our services.
                </p>
                <p>Third-Party Links</p>
                <p>
                  {" "}
                  6.1. Our services may contain links to third-party websites or
                  services that are not owned or controlled by us. We are not
                  responsible for the privacy practices or content of these
                  third-party sites. We encourage you to review the privacy
                  policies of any third-party sites you visit.
                </p>
                <p>Children's Privacy</p>
                <p>
                  7.1. Our services are not directed towards individuals under
                  the age of 13. We do not knowingly collect personal
                  information from children. If you believe we have
                  inadvertently collected information from a child, please
                  contact us, and we will promptly delete it.
                </p>
                <p>Changes to the Privacy Policy</p>
                <p>
                  8.1. We reserve the right to modify this Privacy Policy at any
                  time. Any changes will be effective upon posting the revised
                  Privacy Policy on our website. Your continued use of our
                  services after the effective date constitutes your acceptance
                  of the updated Privacy Policy.
                </p>
                <p>Contact Us</p>
                <p>
                  9.1. If you have any questions, concerns, or requests
                  regarding this Privacy Policy or the handling of your personal
                  information , please contact us at{" "}
                  <a href="https://indexx.ai"> www.indexx.ai </a>
                </p>
                <p>
                  Please note that this Privacy Policy applies only to the
                  information collected by XNFT and does not cover the practices
                  of third-party platforms or services that you may access
                  through our services.
                </p>
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              position: "fixed",
              top: "0%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10000",
              mt: 5,
            }}
          >
            <Typography
              variant="subtitle1"
              color="secondary"
              component="div"
              sx={{
                borderBottom: `${
                  darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                }`,
              }}
            >
              {t("FOOTER_LINK_PRIVACY_POLICY")}
            </Typography>
          </Box>
          <Box
            color={darkMode ? "#ffffff" : "#121212"}
            sx={{
              borderRadius: "16px",
              mt: 5,
            }}
          >
            <Box>
              <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                {t("OUR_PRIVACY_POLICY")}
              </Typography>
              <Divider sx={{ backgroundColor: "#8E8E8E", opacity: "0.4" }} />
              <Typography
                variant="body2"
                component="p"
                sx={{
                  lineHeight: 2,
                  textAlign: "justify",
                  mt: 2,
                  color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                }}
              >
                {/* {t('PRIVACY_POLICY_DESC')} */}
                <p>
                  At XNFT, we are committed to protecting your privacy and
                  ensuring the security of your personal information. This
                  Privacy Policy outlines how we collect, use, disclose, and
                  safeguard your information when you access or use our services
                  and website. By using our services, you consent to the
                  practices described in this Privacy Policy.
                </p>

                <p>Information We Collect</p>

                <p>
                  1.1. Personal Information: We may collect personal information
                  that you provide directly to us when you interact with our
                  services, such as your name, email address, and other contact
                  details. You may choose to provide additional information,
                  such as your preferences or interests, but it is not
                  mandatory.
                </p>
                <p>
                  1.2. Non-Personal Information: We may automatically collect
                  certain non-personal information about your use of our
                  services, such as your IP address, device information, browser
                  type, and operating system. This information does not
                  personally identify you and is used to analyze trends,
                  administer the site, and track users' movements.
                </p>
                <p>Use of Information</p>
                <p>
                  2.1. We may use the information we collect for various
                  purposes, including: Providing and improving our services,
                  products, and user experience. Communicating with you and
                  responding to your inquiries. Sending you important
                  notifications, updates, and promotional materials. Customizing
                  and personalizing your experience with our services. Analyzing
                  and monitoring usage patterns and trends. Protecting the
                  security and integrity of our systems and preventing fraud.
                </p>
                <p>Sharing of Information</p>
                <p>
                  3.1. We may share your personal information with trusted third
                  parties who assist us in operating our services, conducting
                  business activities, or providing support. These third parties
                  are obligated to maintain the confidentiality and security of
                  your information.
                </p>
                <p>
                  3.2. We may disclose your information when required by law,
                  legal proceedings, or to protect our rights, safety, or the
                  rights, safety, or security of others.
                </p>
                <p>
                  3.3. In the event of a merger, acquisition, or sale of assets,
                  your personal information may be transferred to the acquiring
                  entity, subject to the applicable privacy laws.
                </p>
                <p>Data Security</p>
                <p>
                  4.1. We implement appropriate security measures to protect
                  against unauthorized access, alteration, disclosure, or
                  destruction of your personal information. However, no method
                  of transmission over the internet or electronic storage is
                  completely secure, and we cannot guarantee absolute security.
                </p>
                <p>Cookies and Tracking Technologies</p>
                <p>
                  5.1. We use cookies and similar tracking technologies to
                  enhance your experience on our website. These technologies
                  allow us to collect information about your browsing activities
                  and personalize your interactions. You have the option to
                  disable cookies through your browser settings, but this may
                  limit certain functionalities of our services.
                </p>
                <p>Third-Party Links</p>
                <p>
                  {" "}
                  6.1. Our services may contain links to third-party websites or
                  services that are not owned or controlled by us. We are not
                  responsible for the privacy practices or content of these
                  third-party sites. We encourage you to review the privacy
                  policies of any third-party sites you visit.
                </p>
                <p>Children's Privacy</p>
                <p>
                  7.1. Our services are not directed towards individuals under
                  the age of 13. We do not knowingly collect personal
                  information from children. If you believe we have
                  inadvertently collected information from a child, please
                  contact us, and we will promptly delete it.
                </p>
                <p>Changes to the Privacy Policy</p>
                <p>
                  8.1. We reserve the right to modify this Privacy Policy at any
                  time. Any changes will be effective upon posting the revised
                  Privacy Policy on our website. Your continued use of our
                  services after the effective date constitutes your acceptance
                  of the updated Privacy Policy.
                </p>
                <p>Contact Us</p>
                <p>
                  9.1. If you have any questions, concerns, or requests
                  regarding this Privacy Policy or the handling of your personal
                  information, please contact us at{" "}
                  <a href="https://indexx.ai/" color="inherit">
                    {" "}
                    www.indexx.ai{" "}
                  </a>
                </p>
                <p>
                  Please note that this Privacy Policy applies only to the
                  information collected by XNFT and does not cover the practices
                  of third-party platforms or services that you may access
                  through our services.
                </p>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PrivacyPolicy;
