export const biddingData = [
  {
    bidder: [
      {
        id: "1",
        name: "0x4A30908F76b537d7D42537a8fA84Cc1b84b39a1B",
        avatar:
        "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/a.png",
        amount: "1890 iUSD+",
        date: "15 January, 2023",
        time: "5:40",
      },
      {
        id: "2",
        name: "0x73c62e51A47C6d99dF62D42a3F1e2A9126D2d0fC",
        avatar:
        "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/b.png",
        amount: "1000 iUSD+",
        date: "15 January, 2023",
        time: "5:40",
      },
      {
        id: "3",
        name: "0xD4eC1D6dC1F3BeD0d925D79bBc1eFd6A7B2c0dF7",
        avatar:
        "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/c.png",
        amount: "900 iUSD+",
        date: "15 January, 2023",
        time: "5:40",
      },
      {
        id: "4",
        name: "0x8e9d59bC87a876824FcBbEb2275f5a6037e9a940",
        avatar:
        "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/d.png",
        amount: "1500 iUSD+",
        date: "15 January, 2023",
        time: "5:40",
      },
      {
        id: "5",
        name: "0xA7c3d8dA6C3A596cd8621d17d6B687b5D70Db9B7",
        avatar:
        "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/e.png",
        amount: "2200 iUSD+",
        date: "15 January, 2023",
        time: "5:40",
      },
    ],
    acceptee: [
      {
        id: "1",
        name: "0xA7c3d8dA6C3A596cd8621d17d6B687b5D70Db9B7",
        avatar:
        "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/f.png",
        amount: "500 iUSD+",
        date: "15 January, 2023",
        time: "5:40",
      },
      {
        id: "2",
        name: "0x8e9d59bC87a876824FcBbEb2275f5a6037e9a940",
        avatar:
        "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/g.png",
        amount: "1500 iUSD+",
        date: "15 January, 2023",
        time: "5:40",
      },
      {
        id: "3",
        name: "0xD4eC1D6dC1F3BeD0d925D79bBc1eFd6A7B2c0dF7",
        avatar:
        "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/h.png",
        amount: "1000 iUSD+",
        date: "15 January, 2023",
        time: "5:40",
      },
      {
        id: "4",
        name: "0x73c62e51A47C6d99dF62D42a3F1e2A9126D2d0fC",
        avatar:
        "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/static_images_xnft/a.png",
        amount: "1100 iUSD+",
        date: "15 January, 2023",
        time: "5:40",
      },
    ],
  },
];
