/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Button,
  useMediaQuery,
  Stack,
  Input,
  InputAdornment,
  MenuItem,
  Grow,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import LiveAuctions from "../../components/LiveAuctions/LiveAuctions";
import { FiChevronLeft } from "react-icons/fi";
import PastAuctions from "../../components/PastAuctions/PastAuctions";
import styles from "./SingleArtWork.module.css";
import useQuery from "../../hooks/useQuery";


import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import SearchIconExplore from "../../assets/Icons/darkUIIcons/searchIconExplore.svg";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StyledMenu } from "../../components/StyledMenu/StyledMenu";

import HistoryIcon from "@mui/icons-material/History";
import UpdateIcon from "@mui/icons-material/Update";
import { BiSort } from "react-icons/bi";

import TuneIcon from "@mui/icons-material/Tune";
import FilterListIcon from "@mui/icons-material/FilterList";
import contractAddress from "../../contractData/contractsAddress.json";
import Navigation from "../../components/Navigation/Navigation";

const Auction = ({ darkMode, blockchain, setBlockchain, handleConnectIndexxWallet }) => {
  const { t } = useTranslation();
  const [sortOptions, setSorOptions] = useState(t("DATE_CREATED"));
  const [orderOptions, setOrderOptions] = useState(t("NEWEST"));
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);
  const navigationRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  let query = useQuery();

  const location = useLocation();
  const navigate = useNavigate();

  const handleDetails = (id) => {
    navigate(`/auction/${id}`);
  };
  const openSortMenu = Boolean(sortAnchorEl);
  const openOrderMenu = Boolean(orderAnchorEl);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Parse the query parameters from the URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCloseSortMenu = () => {
    setSortAnchorEl(null);
  };
  const handleOpenOrderMenu = (event) => {
    setOrderAnchorEl(event.currentTarget);
  };

  const handleCloseOrderMenu = () => {
    setOrderAnchorEl(null);
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
      color={darkMode ? "#ffffff" : "#121212"}
    >
      {/* <Navigation ref={navigationRef} darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} /> */}

      <Box sx={{ width: "100%", mb: 3 }} mt={isMobile ? 11 : 21.5}>
        {!isMobile ? (

          <Stack direction="column" alignItems="left">
            <Box className={styles.topNavigationBox}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} />
              </Box>
              <Typography
                className={styles.topNavigationTypo}
                component="div"

              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ cursor: "pointer" }}
                  zIndex={2}
                  color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                  onClick={() => window.history.back()}
                >
                  Auction
                </Typography>

              </Typography>
            </Box>





            <Stack direction="row" alignItems="center" gap={3}>
              <Box width="35%">
                <Input
                  disableUnderline
                  fullWidth
                  placeholder={t("SEARCH_IN_EXPLORE")}
                  sx={{
                    background: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                    border: "none",
                    py: 1.6,
                    px: 2,
                    borderRadius: "5px",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={SearchIconExplore} alt="Search" />
                    </InputAdornment>
                  }
                />
              </Box>
              <Box>
                <Button
                  onClick={handleOpenSortMenu}
                  sx={{
                    py: 0.7,
                    width: 350,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                  variant="contained"
                  color="accent"
                  fullWidth
                  endIcon={
                    <Box color="secondary" mt={1.5}>
                      {openSortMenu ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Box>
                  }
                >
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    variant="subtitle1"
                    color="secondary"
                  >
                    {t("SORT_BY")}
                  </Typography>
                  <Typography sx={{ color: "gray" }} variant="subtitle1">
                    {sortOptions}
                  </Typography>
                </Button>
                <StyledMenu
                  elevation={1}
                  PaperProps={{
                    style: {
                      borderRadius: "5px",
                      width: 330,
                    },
                  }}
                  TransitionComponent={Grow}
                  anchorEl={sortAnchorEl}
                  open={openSortMenu}
                  onClose={handleCloseSortMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setSorOptions(t("DATE_CREATED"));
                      handleCloseSortMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <HistoryIcon sx={{ height: 25, width: 25 }} />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("DATE_CREATED")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSorOptions(t("POPULARITY"));
                      handleCloseSortMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <BiSort style={{ height: 25, width: 25 }} />
                      <Typography ml={1.5} variant="subtitle1" fontWeight={400}>
                        {t("POPULARITY")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                </StyledMenu>
              </Box>
              {/* Order */}
              <Box>
                <Button
                  onClick={handleOpenOrderMenu}
                  sx={{
                    py: 0.7,
                    width: 350,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                  variant="contained"
                  color="accent"
                  fullWidth
                  endIcon={
                    <Box color="secondary" mt={1.5}>
                      {openOrderMenu ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Box>
                  }
                >
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    variant="subtitle1"
                    color="secondary"
                  >
                    {t("ORDER_BY")}
                  </Typography>
                  <Typography sx={{ color: "gray" }} variant="subtitle1">
                    {orderOptions}
                  </Typography>
                </Button>
                <StyledMenu
                  elevation={1}
                  PaperProps={{
                    style: {
                      borderRadius: "5px",
                      width: 330,
                    },
                  }}
                  TransitionComponent={Grow}
                  anchorEl={orderAnchorEl}
                  open={openOrderMenu}
                  onClose={handleCloseOrderMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setOrderOptions(t("NEWEST"));
                      handleCloseOrderMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <HistoryIcon sx={{ height: 25, width: 25 }} />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("NEWEST")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOrderOptions(t("OLDEST"));
                      handleCloseOrderMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <UpdateIcon sx={{ height: 25, width: 25 }} />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("OLDEST")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Stack>
          </Stack>

        ) : null}
      </Box>
      <Box>
        {!isMobile ? (
          <Box
            bgcolor={darkMode ? "#171c26" : "#EEEEEE"}
            sx={{
              borderRadius: "10px",
              pl: 5,
              py: 1,
              display: "flex",
              alignItems: "center",
              gap: 15,
            }}
          >
            <Button
              color="secondary"
              onClick={() => navigate("/auction?type=live")}
              sx={{ textTransform: "capitalize" }}
            >
              <Typography
                sx={
                  location.search === "?type=live"
                    ? {
                      borderBottom: `1px solid ${darkMode ? "#ffffff" : "#171C26"
                        }`,
                    }
                    : {}
                }
                variant="body2"
                component="span"
              >
                {t("LIVE_AUCTIONS")}
              </Typography>
            </Button>
            <Button
              color="secondary"
              onClick={() => navigate("/auction?type=past")}
              sx={{ textTransform: "capitalize" }}
            >
              <Typography
                sx={
                  location.search === "?type=past"
                    ? {
                      borderBottom: `1px solid ${darkMode ? "#ffffff" : "#171C26"
                        }`,
                    }
                    : {}
                }
                variant="body2"
                component="span"
              >
                {t("PAST_AUCTIONS")}
              </Typography>
            </Button>
          </Box>
        ) : (
          <Box sx={{ mt: -8 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                bgcolor={
                  location.search === "?type=live"
                    ? {
                      backgroundColor: `#0294FE`,
                    }
                    : {
                      backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                    }
                }
                onClick={() => navigate("/auction?type=live")}
                sx={{
                  color: `#ffffff`,
                  py: 1,
                  px: 3,
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{ fontSize: "12px" }}
                >
                  {t("LIVE_AUCTIONS")}
                </Typography>
              </Box>
              <Box
                bgcolor={
                  location.search === "?type=past"
                    ? {
                      backgroundColor: `#0294FE`,
                    }
                    : {
                      backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                    }
                }
                onClick={() => navigate("/auction?type=past")}
                sx={{
                  color: `#ffffff`,
                  py: 1,
                  px: 3,
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{ fontSize: "12px" }}
                >
                  {t("PAST_AUCTIONS")}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box>
          {isMobile && (
            <Stack
              direction="row"
              alignItems="center"
              gap={3}
              sx={{ mt: "3rem" }}
            >
              <Box>
                <OutlinedInput
                  size="small"
                  fullWidth
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        height={15}
                        width={15}
                        src={SearchIconExplore}
                        alt="Search"
                      />
                    </InputAdornment>
                  }
                />
              </Box>
              {/* Sort */}
              <Box>
                <IconButton
                  onClick={handleOpenSortMenu}
                  variant="contained"
                  fullWidth
                >
                  <TuneIcon />
                </IconButton>
                <StyledMenu
                  elevation={1}
                  PaperProps={{
                    style: {
                      borderRadius: "5px",
                      backgroundColor: `${darkMode ? "#040404" : "#fbfbfb"}`,
                    },
                  }}
                  anchorEl={sortAnchorEl}
                  open={openSortMenu}
                  onClose={handleCloseSortMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setSorOptions(t("DATE_CREATED"));
                      handleCloseSortMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <HistoryIcon sx={{ height: 25, width: 25 }} />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("DATE_CREATED")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSorOptions(t("POPULARITY"));
                      handleCloseSortMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <BiSort style={{ height: 25, width: 25 }} />
                      <Typography ml={1.5} variant="subtitle1" fontWeight={400}>
                        {t("POPULARITY")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                </StyledMenu>
              </Box>
              {/* Order */}
              <Box>
                <IconButton
                  onClick={handleOpenOrderMenu}
                  variant="contained"
                  fullWidth
                >
                  <FilterListIcon />
                </IconButton>
                <StyledMenu
                  elevation={1}
                  PaperProps={{
                    style: {
                      borderRadius: "5px",
                      backgroundColor: `${darkMode ? "#040404" : "#fbfbfb"}`,
                    },
                  }}
                  TransitionComponent={Grow}
                  anchorEl={orderAnchorEl}
                  open={openOrderMenu}
                  onClose={handleCloseOrderMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setOrderOptions(t("NEWEST"));
                      handleCloseOrderMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <HistoryIcon sx={{ height: 25, width: 25 }} />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("NEWEST")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOrderOptions(t("OLDEST"));
                      handleCloseOrderMenu();
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <UpdateIcon sx={{ height: 25, width: 25 }} />
                      <Typography variant="subtitle1" fontWeight={400}>
                        {t("OLDEST")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Stack>
          )}
        </Box>
        <Box sx={{ mt: "3rem" }}>
          <LiveAuctions
            handleDetails={handleDetails}
            darkMode={darkMode}
            queryName={query.get("type")}
          />
          <PastAuctions
            handleDetails={handleDetails}
            darkMode={darkMode}
            queryName={query.get("type")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Auction;
