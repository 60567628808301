/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid, Skeleton, Divider, Stack } from '@mui/material';
import { Box } from '@mui/system';

const CollectionCardFB = ({ darkMode }) => {
  return (
    <Grid item xs={1} md={4} sm={6}>
      <Box
        sx={{ borderRadius: '10px', p: 3 }}
        style={{
          boxShadow: `${!darkMode && '0px 4px 4px rgba(0, 0, 0, 0.25)'}`,
        }}
        bgcolor={darkMode ? '#121212' : '#fbfbfb'}
        color={darkMode ? '#ffffff' : '#121212'}
      >
        <Skeleton
          sx={{
            backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,

            width: '100%',
            height: '500px',
          }}
          animation='wave'
          variant='rectangular'
        />
        <Box sx={{ mt: 1 }}>
          <Divider
            style={{
              backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
              margin: '5px 0',
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Skeleton
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                width: '105px',
              }}
              animation='wave'
              variant='text'
            />
            <Skeleton
              sx={{
                backgroundColor: `${darkMode ? '#171C26' : '#EEEEEE'}`,
                width: '105px',
              }}
              animation='wave'
              variant='text'
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default CollectionCardFB;
