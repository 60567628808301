import React from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AtIcon from '../../../assets/Icons/atIcon.svg';
import LockIcon from '../../../assets/Icons/lockIcon.svg';
import { FcGoogle } from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useAuth1 from '../../../hooks/useAuth1';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import styles from "./SignInPage.module.css";


const SignInPage = ({ darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { handleSignInUser } = useAuth();
  const { signInWithGoogle } = useAuth1();
  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().email('Enter a valid email').required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      handleSignInUser(values.email, values.password, navigate, location);
    },
  });

  const handleLoginWithGoogle = (e) => {
    e.preventDefault();

    signInWithGoogle(
      location,
      navigate
    );
  };

  return (
    <React.Fragment>
      {!isMobile ? (
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          height='100vh'
          px={5}
        >
          <Container>
            <Box
              component='form'
              onSubmit={formik.handleSubmit}
              color={darkMode ? '#ffffff' : '#121212'}
              width='100%'
            >
              <Typography variant='h3' fontWeight={500} textAlign='center'>
                {t('WELCOME_TO_MINTO')}
              </Typography>
              <Stack mt={8} direction='column' gap={4}>
                <Box>
                  <TextField
                    autoComplete='off'
                    id='email'
                    name='email'
                    type='email'
                    variant='outlined'
                    placeholder='example@email.com'
                    label='Email'
                    color='pink'
                    autoFocus
                    fullWidth
                    InputProps={{
                      classes: {
                        input: `${darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                          }`,
                      },

                      endAdornment: (
                        <InputAdornment position='end'>
                          {<img src={AtIcon} alt='...' />}
                        </InputAdornment>
                      ),
                    }}
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <Typography
                      mt={1}
                      component='p'
                      color='error'
                      variant='caption'
                    >
                      {formik.errors.email}
                    </Typography>
                  ) : null}
                </Box>
                <Box>
                  <TextField
                    id='password'
                    name='password'
                    type='password'
                    variant='outlined'
                    placeholder='Enter your top secret password'
                    label='Password'
                    color='pink'
                    fullWidth
                    InputProps={{
                      classes: {
                        input: `${darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                          }`,
                      },

                      endAdornment: (
                        <InputAdornment position='end'>
                          {<img src={LockIcon} alt='...' />}
                        </InputAdornment>
                      ),
                    }}
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <Typography
                      mt={1}
                      component='p'
                      color='error'
                      variant='caption'
                    >
                      {formik.errors.password}
                    </Typography>
                  ) : null}
                </Box>
              </Stack>
              <Box mt={4}>
                <Button
                  type='submit'
                  variant='contained'
                  className={styles.buttonColor}
                  fullWidth
                  sx={{
                    py: 1.5,
                    fontSize: '1rem',
                    backgroundColor: '#0294FE',
                    '&:hover': {
                      backgroundColor: '#0057FF',
                    },
                  }}
                >
                  {t('LOGIN')}
                </Button>
                <Button
                  onClick={() => navigate('/authentication/verify-email')}
                  variant='text'
                  fullWidth
                  sx={{ mt: 1, py: 1.5, fontSize: '1rem' }}
                >
                  <Typography
                    color='secondary'
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {t('FORGOT_PASSWORD')}
                  </Typography>
                </Button>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  gap={3}
                >
                  <Box sx={{ width: '50%' }}>
                    <Divider />
                  </Box>
                  <Typography component='p' variant='body1'>
                    Or
                  </Typography>
                  <Box sx={{ width: '50%' }}>
                    <Divider />
                  </Box>
                </Stack>
                <Button
                  onClick={handleLoginWithGoogle}
                  color='secondary'
                  sx={{ mt: 2, py: 1.5, fontSize: '1rem' }}
                  fullWidth
                  variant='contained'
                  startIcon={<FcGoogle size={30} />}
                >
                  {t('SIGN_UP_WITH_GOOGLE')}
                </Button>
              </Box>
              <Box mt={5}>
                <Typography variant='body1' textAlign='center'>
                  Already have an account?{' '}
                  <Typography
                    onClick={() => navigate('/authentication/sign-up')}
                    color='blue'
                    component='span'
                    sx={{
                      textDecoration: 'underline',
                      color: '#02C7FE',
                      cursor: 'pointer',
                    }}
                  >
                    {t('SIGNUP')}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Container>
        </Stack>
      ) : (
        <Box px={3} py={3}>
          <Box
            component='form'
            onSubmit={formik.handleSubmit}
            color={darkMode ? '#ffffff' : '#121212'}
            width='100%'
          >
            <Typography variant='h4' fontWeight={500} textAlign='center'>
              {t('WELCOME_TO_MINTO')}
            </Typography>
            <Stack mt={5} direction='column' gap={4}>
              <Box>
                <TextField
                  autoComplete='off'
                  id='email'
                  name='email'
                  type='email'
                  variant='outlined'
                  placeholder='example@email.com'
                  label='Email'
                  color='pink'
                  autoFocus
                  fullWidth
                  InputProps={{
                    classes: {
                      input: `${darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                        }`,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        {<img src={AtIcon} alt='...' />}
                      </InputAdornment>
                    ),
                  }}
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <Typography
                    mt={1}
                    component='p'
                    color='error'
                    variant='caption'
                  >
                    {formik.errors.email}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <TextField
                  id='password'
                  name='password'
                  type='password'
                  variant='outlined'
                  placeholder='Enter your top secret password'
                  label='Password'
                  color='pink'
                  fullWidth
                  InputProps={{
                    classes: {
                      input: `${darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                        }`,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        {<img src={LockIcon} alt='...' />}
                      </InputAdornment>
                    ),
                  }}
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password ? (
                  <Typography
                    mt={1}
                    component='p'
                    color='error'
                    variant='caption'
                  >
                    {formik.errors.password}
                  </Typography>
                ) : null}
              </Box>
            </Stack>
            <Box mt={4}>
              <Button
                type='submit'
                variant='contained'
                color='blue'
                fullWidth
                sx={{ py: 1.5, fontSize: '1rem' }}
              >
                {t('LOGIN')}
              </Button>
              <Button
                onClick={() => navigate('/authentication/verify-email')}
                variant='text'
                fullWidth
                sx={{ mt: 1, py: 1.5, fontSize: '1rem' }}
              >
                <Typography
                  color='secondary'
                  sx={{ textTransform: 'capitalize' }}
                >
                  {t('FORGOT_PASSWORD')}
                </Typography>
              </Button>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                gap={3}
              >
                <Box sx={{ width: '50%' }}>
                  <Divider />
                </Box>
                <Typography component='p' variant='body1'>
                  Or
                </Typography>
                <Box sx={{ width: '50%' }}>
                  <Divider />
                </Box>
              </Stack>
              <Button
                color='secondary'
                sx={{ mt: 2, py: 1.5, fontSize: '1rem' }}
                fullWidth
                variant='contained'
                startIcon={<FcGoogle size={30} />}
              >
                {t('SIGN_UP_WITH_GOOGLE')}
              </Button>
            </Box>
            <Box mt={5}>
              <Typography variant='body1' textAlign='center'>
                Already have an account?{' '}
                <Typography
                  onClick={() => navigate('/authentication/sign-up')}
                  color='blue'
                  component='span'
                  sx={{
                    textDecoration: 'underline',
                    color: '#02C7FE',
                    cursor: 'pointer',
                  }}
                >
                  {t('SIGNUP')}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default SignInPage;
