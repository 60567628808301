import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArtCard from "../ArtCard/ArtCard";
import ArtCardFB from "../Skeletons/ArtCardFB";
import { Link } from "react-router-dom";

const ArtArtCards = ({ queryName, darkMode, view, searchText }) => {
  const [artWorks, setArtWorks] = useState([]);
  const [searchedart, setSearchedart] = useState([]);

  const navigate = useNavigate(); // navigation

  // Loading data from API
  useEffect(() => {
    async function fetchData() {
      const res = await axios.get("/artWorkData.json");
      const artWorkData = res.data;
      const filteredData = artWorkData.filter(
        (ac) => ac.tags === "collectibles"
      );
      setArtWorks(filteredData);
      setSearchedart(filteredData);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if(searchText === "" || searchText === undefined) {
      setSearchedart(artWorks);
    } else {
      //let res = CollectionData.includes(x => x.name === searchText);
      const filteredItems = artWorks.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );
      setSearchedart(filteredItems)
    }
  }, [searchText])

  // Handler for navigating to the details
  const handleDetails = (name, id) => {
    navigate(`/explore/${name}/${id}`);
  };

  return (
    <>
      {queryName === "collectibles" ? (
        <>
          {view === "container" ? (
            <Box>
              <Grid
                container
                spacing={{ xs: 6, md: 4 }}
                columns={{ xs: 1, sm: 12, md: 12 }}
              >
                {searchedart.length === 0 ? (
                  [1, 2, 3, 4, 5, 6].map((n) => (
                    <ArtCardFB darkMode={darkMode} key={n} />
                  ))
                ) : (
                  <>
                    {searchedart.map((artWork) => (
                      <ArtCard
                        darkMode={darkMode}
                        key={artWork.id}
                        artWork={artWork}
                        handleDetails={handleDetails}
                      />
                    ))}
                  </>
                )}
              </Grid>
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              style={{
                marginTop: 40,
                backgroundColor: `${darkMode ? "#040404" : "#fbfbfb"}`,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Title</TableCell>

                    <TableCell>Price</TableCell>
                    {/* <TableCell>Total NFT</TableCell> */}
                    <TableCell>Timestamp</TableCell>
                    <TableCell>Likes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchedart.map((collection) => (
                    <TableRow
                      key={collection.id}
                      component={Link}
                      to={`/explore/${collection.name}/${collection.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell>{collection.id}</TableCell>
                      <TableCell>
                        <Avatar
                          alt="Collection Image"
                          src={collection.artworkImage}
                          sx={{ width: 32, height: 32, borderRadius: "50%" }}
                        />
                      </TableCell>
                      <TableCell>{collection.artworkTitle}</TableCell>

                      <TableCell>{collection.artworkPrice}</TableCell>
                      {/* <TableCell>{collection.totalNFT}</TableCell> */}
                      <TableCell>{collection.uploaded}</TableCell>
                      <TableCell>{collection.likes}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      ) : null}
    </>
  );
};

export default ArtArtCards;
