import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const GradientButtonPrimary = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#9E00FF",
  border: "none",
  borderRadius: "2px",
  boxShadow: "none",
  padding: "8px 1.5rem",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "none",
    background: "#6B00AC",
  },
});

const GradientButtonGreen = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#1D6F46",
  border: "none",
  borderRadius: "2px",
  boxShadow: "none",
  padding: "8px 1.5rem",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "none",
    background: "#005F30",
  },
});

const GradientButtonBlue = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#0294FE",
  border: "none",
  borderRadius: "2px",
  boxShadow: "none",
  padding: "8px 1.5rem",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "none",
    background: "#0057FF",
  },

});

const GradientButtonOutlined = styled(Button)({
  color: "#ffffff",
  // borderColor: "#9E00FF",
  borderColor: "#0294FE",
  borderRadius: "2px",
  padding: "8px 1.5rem",
  textTransform: "capitalize",
  "&:hover": {
    // borderColor: "#6B00AC",
    borderColor: "#0057FF",
  },
});
const GradientButtonSecondary = styled(Button)({
  color: "#ffffff",
  border: "none",
  backgroundColor: "#8903DB",
  borderRadius: "2px",
  boxShadow: "none",
  padding: "8px 1.5rem",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "none",
    background: "#0057FF",
  }

});

export { GradientButtonPrimary, GradientButtonSecondary, GradientButtonOutlined, GradientButtonBlue, GradientButtonGreen };
