import React from "react";
import { Avatar, Grid, Typography, Grow } from "@mui/material";
import { Box } from "@mui/system";

import { useTranslation } from "react-i18next";

const BrandsCard = ({ br, handleBrandDetails, darkMode }) => {
  const { t } = useTranslation();
  const { id, brandName, brandLogo} = br;

  const parsedId = parseInt(id) * 100;

  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(true ? { timeout: 1000 + parsedId } : {})}
    >
      <Grid item xs={1} sm={6} md={3} zIndex={1000}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => handleBrandDetails(id)}
            sx={{
              p: 2,
              cursor: "pointer",
            }}
          >
            <Box
              bgcolor={darkMode ? "#171C26" : "#ffffff"}
              color={darkMode ? "#ffffff" : "#121212"}
              sx={{
                display: "flex",
                // gap: 1,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                width: "230px",
                overflow:"hidden",
                transition: 'transform 0.5s ease',
                '&:hover img': {
                  transform: 'scale(1.2)',
                },
              }}
            >
                <img
                  src={brandLogo}
                  alt="Brandlogo"
                  width={"210px"}
                  height={"220px"}
                />
            </Box>

            <Box
              color={darkMode ? "#ffffff" : "#121212"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "230px",
              }}
              my={1}
            >
              {/* <Typography
              color="secondary"
              variant="caption"
            //   component="h3"
            //   component="div"
              sx={{
                my: 3,
                fontSize: "10px",
                textAlign: "justify",
                
                fontWeight: "500" 
              }}
            > */}
              {brandName}
              {/* </Typography> */}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grow>
  );
};

export default BrandsCard;
