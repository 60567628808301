import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import useQuery from "../../../hooks/useQuery";

import { useTheme } from "@emotion/react";
import AllCollectionContainer from "./AllCollectionContainer";
import CryptoCollection from "./CryptoCollection";
import USDCollection from "./USDCollection";
import OtherCollection from "./OtherCollection";
import UtilityCollection from "./UtilityCollection";
import CollectibleCollection from "./CollectibleCollection";

const CollectionContainer = ({ darkMode, view, searchText }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  let query = useQuery();

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Box
        style={{
          position: "fixed",
          height: "100vh",
        }}
      >
      
      </Box>
      <div style={{ position: "fixed", left: "25%" }}>
       
     
      </div>
      <Box
        sx={{
          my: 1,
          width: "100%",
        }}
      >
        <AllCollectionContainer darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
        <CryptoCollection darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
        <USDCollection darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />    
        <UtilityCollection darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
        <CollectibleCollection darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
        <OtherCollection darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
      </Box>
    </div>
  );
};

export default CollectionContainer;
