import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
  Button,
  Grow,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from 'react-router-dom';
import axios from "axios";
import TrendingSellersFB from "../../components/Skeletons/TrendingSellersFB";
import { ImImage } from "react-icons/im";
import AddIcon from "@mui/icons-material/Add";
import styles from "./SingleArtWork.module.css";
import { FiChevronLeft } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import brandpage from "../../assets/brandImages/banner brand page.svg";
import BrandsCard from "../../components/BrandsCard/Brandscard";
import { brandData } from "./brandData";
const Allbrands = ({ darkMode, blockchain, handleConnectIndexxWallet}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigationRef = useRef(null);

  const [brands, setbrands] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // axios.get("/brandData.json").then((res) => {
    //   setbrands(res.data);
    // });
    setbrands(brandData);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Parse the query parameters from the URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  const handleBrandDetails = (id) => {
    navigate(`/brands/${id}`);
  };

  const addBrandDetails = () => {
    navigate(`/add-brand`);
  };

  return (
    <Box
      color={darkMode ? "#ffffff" : "#121212"}
      sx={{
        position: "relative",
      }}
      // mt={!isMobile ? 11.5 : 2}
    >
      <Box sx={!isMobile ? { mt: 11.5 } : { mt: 1 }}>
        {!isMobile ? (
          <Box className={styles.topNavigationBox}>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <FiChevronLeft fontSize={"1.5rem"} />
            </Box>
            <Typography className={styles.topNavigationTypo} component="div">
              <Typography
                variant="h6"
                component="p"
                sx={{ cursor: "pointer" }}
                zIndex={2}
                color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                onClick={() => window.history.back()}
              >
                Add Brand
              </Typography>
            </Typography>
            {/* <Button
            variant="contained"
            disableElevation
            onClick={addBrandDetails}
            sx={{
              fontSize: "16px",
              backgroundColor: "#9E00FF",
              textTransform: "none",
              width: "180px",
              padding: "8px",
              // display:"flex",
              // float:"right"
              right: "-61%",
            }}
            // sx={{bgcolor:"#9E00FF"}}
          >
            <Typography variant="p" fontWeight={500} color="secondary">
              <AddIcon sx={{ verticalAlign: "middle" }} />
              Add Brand
            </Typography>
          </Button> */}
          </Box>
        ) : null}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            // maxWidth: `${isMobile ? "100%" : "75%" }`,
            width: "100%",
            objectFit: "scale-down",
            // maxHeight: "auto"
            // my: 12,
            my: !isMobile ? 2 : 3,
            flexDirection: "column",
            gap:3,
          }}
        >
          <Box width={"62%"} sx={{textAlign:"center"}}>
                <Typography variant={isMobile ? "h4" : "h4"} fontWeight={400} color="secondary">
                Welcome!
                </Typography>
            <br/>
            <Typography variant="p" fontWeight={400} color="secondary">
                Introducing the hottest XNFT Market affiliates! These top-notch
            companies boast mind-blowing XNFTs. Hurry and explore their
            incredible creations before they vanish!
            </Typography>
            

          </Box>
          <Box width={"100%"} sx={{ display:"flex" ,justifyContent:"center",my:4}}>
            <img
              src={brandpage}
              alt="brand-page"
              width={isMobile ? "100%" : "75%"}
              // width={"75%"}
              height={"auto"}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 5, width: "100%" }}>
          <Grid
            container
            columns={{ xs: 1, sm: 12, md: 12 }}
            spacing={{ xs: 1, md: 2 }}
          >
            {brands.length === 0 ? (
              [1, 2, 3, 4, 5, 6, 7, 8].map((n) => (
                <TrendingSellersFB darkMode={darkMode} key={n} />
              ))
            ) : (
              <>
                {brands.map((br) => (
                  <BrandsCard
                    darkMode={darkMode}
                    key={br.id}
                    br={br}
                    handleBrandDetails={handleBrandDetails}
                  />
                ))}

                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(true ? { timeout: 1000 } : {})}
                >
                  <Grid item xs={1} sm={6} md={3} zIndex={1000}>
                    <Box>
                      <Box
                        // bgcolor={darkMode ? "#121212" : "#ffffff"}
                        onClick={addBrandDetails}
                        sx={{
                          p: 2,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          bgcolor={darkMode ? "#171C26" : "#ffffff"}
                          color={darkMode ? "#ffffff" : "#121212"}
                          sx={{
                            display: "flex",
                            gap: 1,
                            width: "230px",
                            height: "220px",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            borderRadius: "5px",
                          }}
                        >
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <Typography component="div" color="secondary">
                              <AddIcon />
                            </Typography>
                          </IconButton>
                          <Typography
                            component="div"
                            color="secondary"
                            sx={{ fontSize: "small" }}
                          >
                            Add Company Brand
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grow>
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Allbrands;
