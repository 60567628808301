/* eslint-disable no-unused-vars */
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AuctionCard from "../AuctionCard/AuctionCard";
import AuctionCardFB from "../Skeletons/AuctionCardFB";
import { useTheme } from "@emotion/react";

import Backdrop from "../../assets/exploreBackDropCircle.svg";
import BackdropMobile from "../../assets/backdropMobile.svg";
import { getAllNFTMinted, getAllPastNFTsMinted } from '../../service/api';
import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";

const PastAuctions = ({ queryName, darkMode, handleDetails }) => {
  const [auctionCards, setAuctionCards] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Loading data from API
  useEffect(() => {
    async function fetchData() {
      const res = await getAllPastNFTsMinted();
      setAuctionCards(res);
    }
    fetchData();
  }, []);


  const pastAuctions = auctionCards.filter((la) => la.auctionStatus === "past");

  return (
    <Box>
      {queryName === "past" ? (
        <>
          {pastAuctions.length === 0 ? (
            // <AuctionCardFB darkMode={darkMode} isPast={false} key={n} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              {darkMode ? (
                <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
              ) : (
                <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
              )}
            </div>
          ) : (
            <Grid
              container
              spacing={{ xs: 6, md: 6 }}
              columns={{ xs: 1, sm: 12, md: 12 }}
            >
              <>
                {pastAuctions.map((la) => (
                  <AuctionCard
                    handleDetails={handleDetails}
                    darkMode={darkMode}
                    key={la.id}
                    la={la}
                  />
                ))}
              </>
            </Grid>
          )}
        </>
      ) : null}
    </Box>
  );
};

export default PastAuctions;
