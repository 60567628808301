import { Alchemy, Network } from "alchemy-sdk";

export async function getAllNFTS(address, blockchain = "") {
    try {
        if (blockchain === "Ethereum") {
            const nfts = await getEthereumNftsForOwner(address);
            return nfts;
        } else if (blockchain === "Polygon") {
            const nfts = await getMaticNftsForOwner(address);
            return nfts;
        }
    } catch (err) {

    }
}

// create an async generator function that uses the getNftsForOwnerIterator method
async function getMaticNftsForOwner(owner) {
    try {
        const config = {
            apiKey: "0ht_aGipiqE8ADgcLaegCWlIKXhv8W0m",
            network: Network.MATIC_MUMBAI
        };
        const alchemy = new Alchemy(config);
        let nfts = [];
        // Get the async iterable for the owner's NFTs.
        const nftsIterable = alchemy.nft.getNftsForOwnerIterator(owner);

        // Iterate over the NFTs and add them to the nfts array.
        for await (const nft of nftsIterable) {
            nfts.push(nft);
        }
        return nfts;
    } catch (error) {
        console.log(error);
    }
}

async function getEthereumNftsForOwner(owner) {
    try {
        const config = {
            apiKey: "2DsflcbXsCueCqyNzHCOoMWEQgmgax6v",
            network: Network.ETH_SEPOLIA,
        };
        const alchemy = new Alchemy(config);
        let nfts = [];
        // Get the async iterable for the owner's NFTs.
        const nftsIterable = alchemy.nft.getNftsForOwnerIterator(owner);

        // Iterate over the NFTs and add them to the nfts array.
        for await (const nft of nftsIterable) {
            nfts.push(nft);
        }
        return nfts;
    } catch (error) {
        console.log(error);
    }
}
