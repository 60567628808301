import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import useQuery from "../../hooks/useQuery";
import AllArtCards from "./AllArtCards";
import ArtArtCards from "./ArtArtCards";
import MemesArtCards from "./MemesArtCards";
import MusicArtCards from "./MusicArtCards";

import Backdrop from "../../assets/exploreBackDropCircle.svg";
import BackdropMobile from "../../assets/backdropMobile.svg";

import { useTheme } from "@emotion/react";
import PosterArtCards from "./PosterArtCards";
import SignatureArtCards from "./SignatureArtCards";

const ArtCardContainer = ({ darkMode, view, searchText }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  let query = useQuery();

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Box
        style={{
          position: "fixed",
          height: "100vh",
        }}
      >
      
      </Box>
      <div style={{ position: "fixed", left: "25%" }}>
       
     
      </div>
      <Box
        sx={{
          my: 1,
          width: "100%",
        }}
      >
        <AllArtCards darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
        <ArtArtCards darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
        <MusicArtCards darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
        <PosterArtCards darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
        <SignatureArtCards darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
        <MemesArtCards darkMode={darkMode} queryName={query.get("type")} view={view} searchText={searchText} />
      </Box>
    </div>
  );
};

export default ArtCardContainer;
