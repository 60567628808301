import React, { useState, useEffect } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import SellersCard from "../../components/SellersCard/SellersCard";
import axios from "axios";
import { useNavigate } from "react-router-dom";


import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

import useAuthentication from "../../hooks/useAuth";
import BidCard from "./BidCard";
import ArtCardFB from "../../components/Skeletons/ArtCardFB";

const MyBidsContainer = ({ darkMode }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [myBidsData, setMyBidsData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const res = await axios.get("/auctionData.json");
      const artWorkData = res.data;
      setMyBidsData(artWorkData);
    }
    fetchData();
  }, []);

  console.log(myBidsData);
  const { user } = useAuthentication();

  return (
    <>
      <Box
        color={darkMode ? "#ffffff" : "#171c26"}
        sx={{
          position: "relative",
        }}
      >
        <Box
          style={{
            position: "fixed",
            height: "100vh",
          }}
        >
      
        </Box>
        <Box sx={!isMobile ? { mt: 11 } : { mt: 2 }}>
          {!isMobile ? (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                gap: 4,
                alignItems: "center",
              }}
            >
              <Typography
                component="div"
                sx={{
                
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ zIndex: 2, cursor: "pointer" }}
                >
                  {t("My Bids")}
                </Typography>
              
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "fixed",
                  top: "3%",
                  zIndex: "10000",
                  width: "75%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box pb={2} ml={6}>
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    component="div"
                    sx={{
                      borderBottom: `${
                        darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                      }`,
                    }}
                  >
                    {t("MY_BIDS")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 5, width: "100%" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 8 }}
              columns={{ xs: 1, sm: 12, md: 12 }}
            >
              {myBidsData && myBidsData.length === 0 ? (
                [1, 2, 3, 4, 5, 6].map((n) => (
                  <ArtCardFB darkMode={darkMode} key={n} />
                ))
              ) : (
                <>
                  {myBidsData &&
                    myBidsData.map((nft) => (
                      <BidCard
                        darkMode={darkMode}
                        key={nft.id}
                        nftDetails={nft}
                        bidAmount={"100"}
                        bidStatus={"BID"}
                      />
                    ))}
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MyBidsContainer;
