import axios from "axios";
import contractAddress from "../contractData/contractsAddress.json";

//import decode from "jwt-decode";
//import initialTokens from "../utilities/token.json";
const qs = require("qs");

let baseAPIURL = "";
export let baseCEXURL = "";
export let baseDEXURL = "";
export let baseURL = "";
export let etherscanTxURL = "";
export let etherscanURL = "https://goerli.etherscan.io/";
export let etherscanAddressURL = "";
export let bscscanTxURL = "";
export let bscscanURL = "https://testnet.bscscan.com/";
export let bscscanAddressURL = "";
export let maticscanTxURL = "https://testnet.bscscan.com/";
export let maticcanAddressURL = "";
export let BSC_HTTPS_URL = "";
export let BSC_WSS_URL = "";
export let ETH_HTTPS_URL = "";
export let ETH_WSS_URL = "";
export let btcTxURL = "";
export let btcAddressURL = "";
export let inexAddressEth = "";
export let inexAddressPolygon = "";
export let maticTokenUrl = "";
export let ethTokenUrl = "";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  baseAPIURL = "https://test.api.indexx.ai";
  baseCEXURL = "https://test.cex.indexx.ai";
  baseDEXURL = "https://test.dex.indexx.ai";
  baseURL = "https://test.indexx.ai";
  // baseAPIURL = "http://localhost:5000";
  etherscanTxURL = "https://sepolia.etherscan.io/tx/";
  etherscanAddressURL = "https://sepolia.etherscan.io/address/";
  bscscanTxURL = "https://testnet.bscscan.com/tx/";
  bscscanAddressURL = "https://testnet.bscscan.com/address/";
  maticscanTxURL = "https://mumbai.polygonscan.com/tx/";
  maticcanAddressURL = "https://mumbai.polygonscan.com/address/";
  BSC_HTTPS_URL =
    "https://dawn-twilight-model.bsc-testnet.discover.quiknode.pro/fa432b4f1daf53252e2b478f7064f5c67ddec17f/";
  BSC_WSS_URL =
    "wss://ws-nd-737-693-672.p2pify.com/8c59e69a9efcd44793f545715b427132";
  ETH_HTTPS_URL =
    "https://goerli.infura.io/v3/847e89cb852c43048f143456f82c32ec";
  ETH_WSS_URL = "wss://goerli.infura.io/ws/v3/847e89cb852c43048f143456f82c32ec";
  btcTxURL = "https://live.blockcypher.com/btc-testnet/tx/";
  btcAddressURL = "https://live.blockcypher.com/btc-testnet/address/";
  inexAddressEth = "0x674d7916e8B7ccD3Bc54eDf2a1274F683501413a";
  inexAddressPolygon = "0x98a5b2a9eB4F17a6718C89324D6654E87A2e57aF";
  maticTokenUrl = "https://mumbai.polygonscan.com/token/";
  ethTokenUrl = "https://sepolia.etherscan.io/token/";
} else {
  baseCEXURL = "https://test.cex.indexx.ai";
  baseDEXURL = "https://test.dex.indexx.ai";
  baseAPIURL = "https://test.api.indexx.ai";
  baseURL = "https://test.indexx.ai";
  etherscanTxURL = "https://goerli.etherscan.io/tx/";
  etherscanAddressURL = "https://goerli.etherscan.io/address/";
  bscscanTxURL = "https://testnet.bscscan.com/tx/";
  bscscanAddressURL = "https://testnet.bscscan.com/address/";
  BSC_HTTPS_URL =
    "https://dawn-twilight-model.bsc-testnet.discover.quiknode.pro/fa432b4f1daf53252e2b478f7064f5c67ddec17f/";
  BSC_WSS_URL =
    "wss://ws-nd-737-693-672.p2pify.com/8c59e69a9efcd44793f545715b427132";
  ETH_HTTPS_URL =
    "https://goerli.infura.io/v3/847e89cb852c43048f143456f82c32ec";
  ETH_WSS_URL = "wss://goerli.infura.io/ws/v3/847e89cb852c43048f143456f82c32ec";
  btcTxURL = "https://live.blockcypher.com/btc-testnet/tx/";
  btcAddressURL = "https://live.blockcypher.com/btc-testnet/address/";
  inexAddressEth = "0x674d7916e8B7ccD3Bc54eDf2a1274F683501413a";
  inexAddressPolygon = "0x98a5b2a9eB4F17a6718C89324D6654E87A2e57aF";
  maticTokenUrl = "https://mumbai.polygonscan.com/token/";
  ethTokenUrl = "https://sepolia.etherscan.io/token/";
}

const API = axios.create({
  baseURL: baseAPIURL,
});

export default baseAPIURL;

export const signupAPI = async (email, password, refferalCode) => {
  try {
    const result = await API.post("/api/v1/inex/user/register", {
      email,
      password,
      refferalCode,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const loginAPI = async (email, password) => {
  try {
    const result = await API.post("/api/v1/inex/user/login", {
      email,
      password,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const logoutAPI = async () => { };

export const getCountries = async () => {
  try {
    const result = await API.post("/api/v1/inex/getCountries");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getUserWallet = async () => { };

export const verifyPhoneCode = async (code) => {
  try {
    const result = await API.post("/api/v1/inex/user/verifyPhoneCode", {
      code,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getWalletBalance = async (email, coin) => {
  try {
    const result = await API.post(
      `/api/v1/inex/user/getBalance/${email}/${coin}`
    );
    if (result.status === 200) return result.data;
    else return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const validateAndConvert = async (nftData, walletAddress) => {
  try {
    const result = await API.post(
      `/api/v1/xnft/swap`, {
      userNFTData: nftData,
      address: walletAddress
    }
    );
    if (result.status === 200) return result.data;
    else return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const validateAndConvertInex = async (nftData, walletAddress) => {
  try {
    const result = await API.post(
      `/api/v1/xnft/swapinex`, {
      userNFTData: nftData,
      address: walletAddress
    }
    );
    if (result.status === 200) return result.data;
    else return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const validateInex = async (nftData, walletAddress) => {
  try {
    const result = await API.post(
      `/api/v1/xnft/swapinexValidate`, {
      userNFTData: nftData,
      address: walletAddress
    }
    );
    if (result.status === 200) return result.data;
    else return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getIndexxTokenPrices = async () => {
  try {
    const result = await API.post("/api/v1/inex/basic/indexxcoins");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getCryptoPrice = async (coin) => {
  try {
    const result = await API.post(`api/v1/inex/basic/getPriceByName`, {
      coin: coin,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getDecryptedKey = async (key) => {
  try {
    const result = await API.post(`api/v1/inex/basic/decryptPkey`, {
      privateKey: key,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

//https://api.coingecko.com/api/v3/coins/binancecoin/market_chart?vs_currency=USD&days=1&interval=hourly
export const getGraphicalCurrencyData = async (
  coinId,
  days,
  currency = "USD"
) => {
  let url = "";
  if (coinId === "IUSD+") {
    url = `https://api.coingecko.com/api/v3/coins/tether/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "INXC") {
    url = `https://api.coingecko.com/api/v3/coins/uma/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "IN500") {
    url = `https://api.coingecko.com/api/v3/coins/spdr-s-p-500-etf-trust-defichain/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "BTC") {
    url = `https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "BNB") {
    url = `https://api.coingecko.com/api/v3/coins/binancecoin/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "ETH") {
    url = `https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "LTC") {
    url = `https://api.coingecko.com/api/v3/coins/litecoin/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "BUSD") {
    url = `https://api.coingecko.com/api/v3/coins/binance-usd/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "INEX") {
    url = `https://api.coingecko.com/api/v3/coins/stellar/market_chart?vs_currency=${currency}&days=${days}`;
  }
  let res = await fetch(url);
  return res;
};

export function isLoggedIn() {
  return !!getJwtToken();
}

export function getJwtToken() {
  return localStorage.getItem("access_token");
}

export function getRefreshToken() {
  var token = localStorage.getItem("refresh_token");
  return token;
}

export function storeTokens(tokens) {
  //exact jwt and store in local store as user object
  localStorage.setItem("access_token", tokens.access_token);
  localStorage.setItem("refreshToken", tokens.refresh_token);
}

export function removeTokens() {
  //remove from local store the user object
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

export const getAllCountries = async (email, coin) => {
  try {
    const result = await API.post("/api/v1/inex/getCountries");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

// export function decodeJWT(access_token) {
//   let userObj = decode(access_token);
//   return userObj;
// }

export const getUserWallets = async (email) => {
  try {
    const result = await API.get(`/api/v1/wallet/getUserWallets/${email}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getJSONData = async (uri) => {
  try {
    const result = await API.get(uri);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};
export const checkAndUpdateDeposit = async (email, txHash, coin) => {
  try {
    const result = await API.post(`/api/v1/inex/transaction/createTx`, {
      email,
      txHash,
      coin,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createSellOrder1 = async (
  basecoin,
  quotecoin,
  amount,
  price,
  email
) => {
  try {
    const result = await API.post("/api/v1/inex/order/sell", {
      basecoin,
      quotecoin,
      amount,
      price,
      email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createConvertOrder1 = async (
  basecoin,
  quotecoin,
  amount,
  price,
  email
) => {
  try {
    const result = await API.post("/api/v1/inex/order/convert", {
      basecoin,
      quotecoin,
      amount,
      price,
      email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getAllTransactions = async (email) => {
  try {
    const result = await API.post(`/api/v1/inex/user/getTransactions/${email}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getUserRewardDetails = async (email) => {
  try {
    const result = await API.get(
      `/api/v1/inex/user/getUserRewardDetails/${email}`
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const updateRewardsWallet = async (email, walletAddr) => {
  try {
    const result = await API.post(`/api/v1/inex/user/updateRewardsWallet`, {
      email: email,
      rewardWalletAddress: walletAddr,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const changePassword = async (email, newPassword, oldPassword) => {
  try {
    const result = await API.post(`/api/v1/inex/user/changePassword`, {
      email: email,
      newPassword: newPassword,
      oldPassword: oldPassword,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const forgotPassword = async (email) => {
  try {
    const result = await API.post(`/api/v1/inex/user/forgotPassword`, {
      email: email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const resetPassword = async (email, password) => {
  try {
    const result = await API.post(`/api/v1/inex/user/resetPassword`, {
      email: email,
      password: password,
      code: "123",
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getUserDetails = async (email) => {
  try {
    const result = await API.post(`/api/v1/inex/user/getUserDetails/${email}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getCoinPriceByName = async (coin, type = "Buy") => {
  try {
    const result = await API.post(`/api/v1/inex/basic/getcoinprice/${coin}`, {
      type: type,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getAppSettings = async () => {
  try {
    const result = await API.post("/api/v1/inex/basic/appSettings");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const marketsData = async () => {
  try {
    const result = await API.get("/api/v1/inex/basic/marketPrice");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const updateFavCurrencies = async (email, currency) => {
  try {
    const result = await API.post("/api/v1/inex/basic/updateFavCurrencies", {
      email: email,
      currency: currency,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createBuyOrder = async (basecoin, quotecoin, amount, price) => {
  try {
    const result = await API.post("/api/v1/inex/order/createOrder", {
      currencyOut: basecoin,
      currencyIn: quotecoin,
      amount: amount,
      price: price,
      orderType: "Buy",
      email: localStorage.getItem("user"),
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createSellOrder = async (basecoin, quotecoin, amount, price) => {
  try {
    const result = await API.post("/api/v1/inex/order/createOrder", {
      currencyOut: quotecoin,
      currencyIn: basecoin,
      amount: amount,
      price: price,
      orderType: "Sell",
      email: localStorage.getItem("user"),
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createExchange = async (
  userWalletAddress,
  inCurr,
  inAmount,
  outCurr,
  outAmount,
  orderType,
  orderRate,
  USDValue,
  blockchain,
  userBankDetails = {}
) => {
  try {
    const userConnectedAddress = localStorage.getItem("userWalletAddress");
    const result = await API.post("/api/v1/dex/user/createExchange", {
      userWalletAddress: userWalletAddress,
      inCurr: inCurr,
      inAmount: inAmount,
      outCurr: outCurr,
      outAmount: outAmount,
      orderType: orderType,
      orderRate: orderRate,
      USDValue: USDValue,
      userBankDetails: userBankDetails,
      blockchain: blockchain,
      metaMaskAddress:
        userConnectedAddress === undefined ||
          userConnectedAddress === null ||
          userConnectedAddress === ""
          ? userWalletAddress
          : userConnectedAddress,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getDEXOrderDetails = async (orderId) => {
  try {
    const result = await API.get(`/api/v1/dex/user/orderById/${orderId}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getDEXUserRewards = async (userWalletAddress) => {
  try {
    const result = await API.get(
      `/api/v1/dex/user/gettradetoearnrewards/${userWalletAddress}`
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getConfirmStatus = async (orderId) => {
  try {
    const result = await API.get(`/api/v1/dex/user/confirmStatus/${orderId}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getDepositStatus = async (orderId) => {
  try {
    const result = await API.get(`/api/v1/dex/user/depositStatus/${orderId}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const dexCheckAndUpdateDeposit = async (depositHash, orderDetails) => {
  try {
    const result = await API.post("/api/v1/dex/user/checkAndUpdateDeposit", {
      txHash: depositHash,
      orderDetails: orderDetails,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const checkAndUpdateExchange = async (orderId) => {
  try {
    const result = await API.post("/api/v1/dex/user/checkAndUpdateExchange", {
      orderId: orderId,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getExchangeStatus = async (orderId) => {
  try {
    const result = await API.get(`/api/v1/dex/user/exchangeStatus/${orderId}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const confirmSellOrder = async (
  emal,
  orderId,
  orderStatus,
  basecoin
) => {
  try {
    const result = await API.post("/api/v1/inex/order/updateOrder", {
      email: emal,
      orderId: orderId,
      orderStatus: orderStatus,
      currencyIn: basecoin,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createConvertOrder = async (
  basecoin,
  quotecoin,
  amount,
  price
) => {
  try {
    const result = await API.post("/api/v1/inex/order/createOrder", {
      currencyOut: quotecoin,
      currencyIn: basecoin,
      amount: amount,
      price: price,
      orderType: "Convert",
      email: localStorage.getItem("user"),
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const confirmConvertOrder = async (
  emal,
  orderId,
  orderStatus = "Completed",
  basecoin = "IN500"
) => {
  try {
    const result = await API.post("/api/v1/inex/order/processCovert", {
      email: emal,
      orderId: orderId,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createStripePaymentIntent = async (amount, orderId, email) => {
  try {
    const result = await API.post("/api/v1/inex/stripe/createPaymentIntent", {
      amount: amount,
      orderId: orderId,
      email: email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createDEXStripePaymentIntent = async (
  amount,
  orderId,
  userWallerAddress
) => {
  try {
    const result = await API.post(
      "/api/v1/dex/user/stripe/createPaymentIntent",
      {
        amount: amount,
        orderId: orderId,
        userWallerAddress: userWallerAddress,
      }
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const oneUSDHelper = async (coinValue, coinType) => {
  try {
    let oneUSDValue = 0;
    if (coinType === "IN500") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "INXC") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "IUSD+") {
      oneUSDValue = 1;
    } else if (coinType === "BUSD") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "BTC") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "ETH") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "BNB") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "LTC") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "INEX") {
      oneUSDValue = 1 / coinValue;
    } else {
      oneUSDValue = 0.1;
    }
    return oneUSDValue;
  } catch (err) {
    return 0;
  }
};

export const getMinAndMaxOrderValues = async (coin, orderType) => {
  try {
    const result = await API.post("/api/v1/inex/basic/orderMinMax", {
      currency: coin,
      orderType: orderType,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const withdrawINEX = async (email, amount) => {
  try {
    const result = await API.post("/api/v1/inex/user/withdrawRewards", {
      email: email,
      amount: amount,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const transactionList = async (email, type = "FIAT") => {
  try {
    const result = await API.post(
      `/api/v1/inex/user/getTransactions/${email}`,
      {
        type: type,
      }
    );
    return result.data;
  } catch (err) {
    return err.response.data;
  }
};

export const createFiatWithdraw = async (
  email,
  amount,
  accountDetails,
  coin = "USD"
) => {
  try {
    const result = await API.post(
      "/api/v1/inex/transaction/createFiatWithdraw",
      {
        email: email,
        amount: amount,
        accountDetails: accountDetails,
        coin: coin,
      }
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createCryptoWithdraw = async (
  email,
  amount,
  address,
  coin = "USD"
) => {
  try {
    const result = await API.post(
      "/api/v1/inex/transaction/createCryptoWithdraw",
      {
        email: email,
        amount: amount,
        address: address,
        coin: coin,
      }
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createFiatDeposit = async (
  email,
  amount,
  txHash,
  coin = "USD"
) => {
  try {
    const result = await API.post(
      "/api/v1/inex/transaction/createFiatDeposit",
      {
        email: email,
        amount: amount,
        txHash: txHash,
        coin: coin,
      }
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const resendEmailCode = async (email) => {
  try {
    const result = await API.post("/api/v1/inex/user/resendEmailCode", {
      email: email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const validateEmail = async (email, code) => {
  try {
    const result = await API.post("/api/v1/inex/user/validateEmail", {
      email: email,
      code: code,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getOrderDetails = async (email, orderId) => {
  try {
    const result = await API.get(
      `/api/v1/inex/user/getUserOrder/${email}/${orderId}`
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getPaypalOrder = async (token) => {
  try {
    const result = await API.get(`/api/v1/inex/user/getPaypalOrder/${token}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const subscribeBlockchain = async (orderId) => {
  try {
    const result = await API.post(`/api/v1/dex/user/startSubcribe/`, {
      orderId: orderId,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const confirmAndExchange = async (txDetails, orderId) => {
  try {
    const result = await API.post(`/api/v1/dex/user/checkAndConfirmExchange`, {
      txDetails: txDetails,
      orderId: orderId,
    });
    return result.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getUniswapTokens = async () => {
  try {
    let getTokens = await axios.get(
      "https://tokens.coingecko.com/uniswap/all.json"
    );
    return getTokens.data.tokens;
  } catch (err) {
    return err.response.data;
  }
};

export const getPancakeTokens = async () => {
  try {
    let getTokens = await axios.get(
      "https://tokens.coingecko.com/binance-smart-chain/all.json"
    );
    return getTokens.data.tokens;
  } catch (err) {
    return err.response.data;
  }
};

// export const getETHBNBTokens = async () => {
//   try {
//     let getETHTokens = await getUniswapTokens();
//     let getBNBTokens = await getPancakeTokens();
//     let tokens = getETHTokens.concat(getBNBTokens, initialTokens);
//     tokens = tokens.sort((a, b) => (a.symbol > b.symbol) ? 1 : (a.symbol < b.symbol) ? -1 : 0);
//     return tokens;
//   } catch (err) {
//     return err.response.data;
//   }
// }

export const getTokenPrices = async (
  blockchainSymbol,
  sellToken,
  buyToken,
  sellAmount
) => {
  try {
    const baseURL =
      blockchainSymbol === "ETH"
        ? "https://api.0x.org"
        : "https://bsc.api.0x.org";

    const params = {
      sellToken: sellToken,
      buyToken: buyToken,
      sellAmount: sellAmount.toString(),
      feeRecipient: "0x35EAd16cbd74AB6380aB9ad91266dc407149652f",
      buyTokenPercentageFee: 0.1,
    };
    const params1 = {
      sellToken: sellToken,
      buyToken: buyToken,
      sellAmount: sellAmount.toString(),
      feeRecipient: "0x35EAd16cbd74AB6380aB9ad91266dc407149652f",
      buyTokenPercentageFee: 0.1,
    };
    let responseWithFees = await axios.get(
      `${baseURL}/swap/v1/price?${qs.stringify(params)}`
    );
    let responseWithOutFees = await axios.get(
      `${baseURL}/swap/v1/price?${qs.stringify(params1)}`
    );
    return {
      responseWithFees: responseWithFees.data,
      responseWithOutFees: responseWithOutFees.data,
    };
  } catch (err) {
    return err.response.data;
  }
};

export const getQuotes = async (
  blockchainSymbol,
  sellToken,
  buyToken,
  sellAmount,
  account
) => {
  try {
    const baseURL =
      blockchainSymbol === "ETH"
        ? "https://api.0x.org"
        : "https://bsc.api.0x.org";
    const params = {
      sellToken: sellToken,
      buyToken: buyToken,
      sellAmount: sellAmount.toString(),
      //takerAddress: account
    };
    let response = await axios.get(
      `${baseURL}/swap/v1/quote?${qs.stringify(params)}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getQuotesWithTaker = async (
  blockchainSymbol,
  sellToken,
  buyToken,
  sellAmount,
  account
) => {
  let response;
  try {
    const baseURL =
      blockchainSymbol === "ETH"
        ? "https://api.0x.org"
        : "https://bsc.api.0x.org";
    const params = {
      sellToken: sellToken,
      buyToken: buyToken,
      sellAmount: sellAmount.toString(),
      takerAddress: account,
      feeRecipient: "0x35EAd16cbd74AB6380aB9ad91266dc407149652f",
      buyTokenPercentageFee: 0.1,
    };
    response = await axios.get(
      `${baseURL}/swap/v1/quote?${qs.stringify(params)}`
    );
    return response.data;
  } catch (err) {
    //throw new Error("Couldn't proceed swap");
    return response;
  }
};

export const getMarketData = async (
  blockchainSymbol,
  contractAddress,
  days
) => {
  try {
    const blockchain =
      blockchainSymbol === "ETH" ? "ethereum" : "binance-smart-chain";
    let from, to;
    if (
      contractAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
      blockchainSymbol === "BNB"
    ) {
      contractAddress = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
    } else if (
      contractAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
      blockchainSymbol === "ETH"
    ) {
      contractAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
    }
    const getTimeStamps = await timeStamps(days);
    from = getTimeStamps.from;
    to = getTimeStamps.to;
    let response = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${blockchain}/contract/${contractAddress}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`
    );
    return response.data;
  } catch (err) {
    throw new Error("Couldn't fetch Graph");
  }
};

export const getPriceAndChangeData = async (
  blockchainSymbol,
  contractAddress
) => {
  try {
    const blockchain =
      blockchainSymbol === "ETH" ? "ethereum" : "binance-smart-chain";
    if (
      contractAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
      blockchainSymbol === "BNB"
    ) {
      contractAddress = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
    } else if (
      contractAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
      blockchainSymbol === "ETH"
    ) {
      contractAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
    }
    let response = await axios.get(
      `https://api.coingecko.com/api/v3/coins/${blockchain}/contract/${contractAddress}`
    );
    return response.data;
  } catch (err) {
    throw new Error("Couldn't fetch Graph", err);
  }
};

export const getGrahDataForTwoTokens = async (
  token1,
  token2,
  blockchain,
  days
) => {
  try {
    const token1Data = await getMarketData(blockchain, token1, days);
    const token2Data = await getMarketData(blockchain, token2, days);
    const token1Prices = token1Data.prices;
    const token2Prices = token2Data.prices;
    if (token1Prices.length === 0 || token2Prices.length === 0) {
      throw new Error("Insufficient Data");
    }
    const averagePrices = await calculateAverage(token1Prices, token2Prices);
    return averagePrices;
  } catch (err) {
    throw new Error("Couldn't fetch Graph");
  }
};

export const getPriceAndChangeDataForTwoTokens = async (
  token1,
  token2,
  blockchain,
  days
) => {
  try {
    const token1Data = await getPriceAndChangeData(blockchain, token1);
    const token2Data = await getPriceAndChangeData(blockchain, token2);
    const token1Price = token1Data.market_data.current_price["usd"];
    const token2Price = token2Data.market_data.current_price["usd"];
    let token1Change = 0;
    let token2Change = 0;
    if (days === 1) {
      token1Change = token1Data.market_data.price_change_percentage_24h;
      token2Change = token2Data.market_data.price_change_percentage_24h;
    } else if (days === 7) {
      token1Change = token1Data.market_data.price_change_percentage_7d;
      token2Change = token2Data.market_data.price_change_percentage_7d;
    } else if (days === 30) {
      token1Change = token1Data.market_data.price_change_percentage_30d;
      token2Change = token2Data.market_data.price_change_percentage_30d;
    } else if (days === 365) {
      token1Change = token1Data.market_data.price_change_percentage_1y;
      token2Change = token2Data.market_data.price_change_percentage_1y;
    } else if (days === 0.04) {
      token1Change =
        token1Data.market_data.price_change_percentage_1h_in_currency["usd"];
      token2Change =
        token2Data.market_data.price_change_percentage_1h_in_currency["usd"];
    }
    const averagePrice = token1Price / token2Price;
    const averageChange = token1Change / token2Change;
    return { averagePrice, averageChange };
  } catch (err) {
    throw new Error("Couldn't fetch Graph", err);
  }
};

export const createBitcoinWallet = async () => {
  try {
    const response = await API.get(`/api/v1/dex/user/generateBitcoinAddress`);
    return response;
  } catch (err) {
    throw new Error("Couldn't create wallet");
  }
};

export const getAllNFTMinted = async (contractAddress) => {
  try {
    const response = await API.post(`/api/v1/xnft/getAllnfts`, {
      address: contractAddress,
    });
    const filteredData = response.data.result.result.filter(item => item.token_uri !== null);
    let processData = await processAllData(filteredData);
    let extraItems = await addExtraItems(processData);
    return extraItems;
  } catch (err) {
    console.log('err', err)
    return err;
  }
};

async function processAllData(data) {
  const updatedItems = [];

  for (let item of data) {
    const updatedItem = await updateMetadataIfNeeded(item);
    if (updatedItem) {  // If the updated item is not null, add it to the list
      updatedItems.push(updatedItem);
    }
  }

  return updatedItems;
}

async function updateMetadataIfNeeded(item) {
  if (item.metadata === null) {
    try {
      const response = await axios.get(item.token_uri);
      item.metadata = JSON.stringify(response.data);
    } catch (error) {
      return null; // Return null to signify that this item had an issue and should be excluded
    }
  }
  return item;
}

async function addExtraItems(items) {
  try {
    // Extract editions from items and sort them
    let editions = items.map(item => JSON.parse(item.metadata).edition).sort((a, b) => a - b);

    // Set sold to true for all existing items
    items = items.map(item => ({ ...item, sold: true }));

    // Detect gaps in editions
    function detectGaps(editions) {
      let maxEdition = editions[editions.length - 1];
      let allEditions = Array.from({ length: maxEdition }, (_, i) => i + 1);
      return allEditions.filter(edition => !editions.includes(edition));
    }

    let gaps = detectGaps(editions);

    // Base item for creating new items
    const baseItem = { ...items[items.length - 1] };

    // This will be used to ensure unique token_id for new items
    let nextTokenId = Math.max(...items.map(item => parseInt(item.token_id))) + 1;

    // Generate new items for gaps
    let gapItems = gaps.map(gapEdition => {
      let updatedMetadata = JSON.parse(baseItem.metadata);
      updatedMetadata.name = updatedMetadata.name.replace(/#\d+/, `#${gapEdition}`);
      updatedMetadata.image = updatedMetadata.image.replace(/\d+\.png/, `${gapEdition}.png`);
      updatedMetadata.edition = gapEdition;
      updatedMetadata.date = Date.now();

      return {
        ...baseItem,
        minted: false,
        token_id: String(nextTokenId++),
        token_uri: baseItem.token_uri.replace(/\d+\.json/, `${gapEdition}.json`),
        metadata: JSON.stringify(updatedMetadata),
        sold: false
      };
    });

    // Calculate how many new editions we can generate after adding the gap items, ensuring we don't add more than 15 in total
    const remainingItemCount = 15 - gapItems.length;
    let nextEdition = Math.max(...editions) + 1;

    const newItems = Array.from({ length: remainingItemCount }, () => {
      let updatedMetadata = JSON.parse(baseItem.metadata);
      updatedMetadata.name = updatedMetadata.name.replace(/#\d+/, `#${nextEdition}`);
      updatedMetadata.image = updatedMetadata.image.replace(/\d+\.png/, `${nextEdition}.png`);
      updatedMetadata.edition = nextEdition++;
      updatedMetadata.date = Date.now();

      return {
        ...baseItem,
        minted: false,
        token_id: String(nextTokenId++),
        token_uri: baseItem.token_uri.replace(/\d+\.json/, `${updatedMetadata.edition}.json`),
        metadata: JSON.stringify(updatedMetadata),
        sold: false
      };
    });

    // Merge the three arrays: items, gap items, and new items
    const updatedItems = [...items, ...gapItems, ...newItems];

    return updatedItems;
  } catch (err) {
    console.log(err);
  }
}

export const getRedeemValue = async (voucherCode) => {
  try {
    const result = await API.post("/api/v1/xnft/giftcards/validateVoucher", {
      voucher: voucherCode
    });
    return { success: true, data: result };
  } catch (e) {
    if (e.response && e.response.data) {
      return { success: false, error: e.response.data };
    }
    return { success: false, error: e.message };
  }
};

export const updateUserImage = async (userAddress, profileImage) => {
  try {
    const result = await API.post("/api/v1/xnft/updateProfile", {
      address: userAddress,
      profileImageuRL: profileImage,
    });
    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getUserImage = async (userAddress) => {
  try {
    const result = await API.post("/api/v1/xnft/getProfile", {
      address: userAddress
    });
    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getRedeemXNFTValue = async (voucherCode) => {
  try {
    const result = await API.post("/api/v1/xnft/giftcards/validateXNFTVoucher", {
      voucher: voucherCode
    });
    return { success: true, data: result };
  } catch (e) {
    if (e.response && e.response.data) {
      return { success: false, error: e.response.data };
    }
    return { success: false, error: e.message };
  }
};


export const redeemValue = async (voucherCode, walletAddress) => {
  try {
    const result = await API.post("/api/v1/xnft/giftcards/redeemCoupon", {
      voucher: voucherCode,
      userWallerAddress: walletAddress
    });
    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const redeemXNFTValue = async (voucherCode, walletAddress) => {
  try {
    const result = await API.post("/api/v1/xnft/giftcards/redeemXNFTCoupon", {
      voucher: voucherCode,
      userWallerAddress: walletAddress
    });
    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};


export const getAllPastNFTsMinted = async () => {
  try {
    const allNFT = [];
    const fetchBitcoinBlackNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinBlack);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinblack/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchBitcoinBlueNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinBlue);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinblue/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchBitcoinPurpleNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinPurple);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinpurple/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchBitcoinPinkNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinPink);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinpink/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchBitcoinGreenNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinGreen);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoingreen/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchBitcoinOrangeNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinOrange);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinorange/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };

    const fetchEthereumBlackNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumBlack);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/black/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchEthereumBlueNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumBlue);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/blue/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchEthereumPurpleNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumPurple);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/purple/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchEthereumPinkNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumPink);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/pink/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchEthereumGreenNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumGreen);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/green/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    const fetchEthereumOrangeNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumOrange);
      let data = result;
      const parsedArray = data.map((obj) => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (
          parsedMetadata?.image !== null &&
          parsedMetadata?.image !== undefined
        ) {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/orange/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      allNFT.push(parsedArray);
    };
    fetchBitcoinBlackNFTData();
    fetchBitcoinBlueNFTData();
    fetchBitcoinGreenNFTData();
    fetchBitcoinOrangeNFTData();
    fetchBitcoinPurpleNFTData();
    fetchBitcoinPinkNFTData();
    fetchEthereumBlackNFTData();
    fetchEthereumBlueNFTData();
    fetchEthereumPinkNFTData();
    fetchEthereumGreenNFTData();
    fetchEthereumPurpleNFTData();
    fetchEthereumOrangeNFTData();
    return allNFT;
  } catch (err) {
    throw new Error("Couldn't create wallet");
  }
};
const calculateAverage = async (arr1, arr2) => {
  try {
    let length = Math.min(arr1.length, arr2.length);
    return Array.from({ length: length }, (_, i) => [
      arr1[i][0],
      arr1[i][1] / arr2[i][1],
    ]);
  } catch (err) {
    return err;
  }
};

const timeStamps = async (days) => {
  try {
    // Today
    var today = new Date();
    let to, from;
    to = today.getTime();
    if (days === 1) {
      // Yesterday
      today.setDate(today.getDate() - 1);
      from = today.getTime();
    } else if (days === 7) {
      // One week ago
      today.setDate(today.getDate() - 7);
      from = today.getTime();
    } else if (days === 31) {
      // One month ago
      today.setMonth(today.getMonth() - 1);
      from = today.getTime();
    } else if (days === 365) {
      // One year ago
      today.setFullYear(today.getFullYear() - 1);
      from = today.getTime();
    } else if (days === 0.04) {
      // One hour ago
      today.setHours(today.getHours() - 1);
      from = today.getTime();
    }
    return { to: Math.round(to / 1000), from: Math.round(from / 1000) };
  } catch (err) {
    throw new Error("Couldn't fetch Graph");
  }
};
