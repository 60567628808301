import React, { useEffect, useState, useRef } from 'react';

import { Container, Typography } from '@mui/material';

import axios from 'axios';

import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
// Single Artwork components
import SingleArtWork from './SingleArtWork';

// Styles
import styles from './ArtCardDetails.module.css';

import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";
import Navigation from '../Navigation/Navigation';

const ArtCardDetails = ({ darkMode, blockchain, setBlockchain, account, handleConnectIndexxWallet, indexxWalletEmail, indexxWalletObj, indexxWalletAddress, isIndexxWalletWeb, connectwall }) => {
  const { id } = useParams(); // Read from url
  const navigationRef = useRef(null);

  const [artWorks, setArtWorks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState('');
  useEffect(() => {
    setIsLoading(true);
    setLoaderMessage('Loading...');
    axios.get('/artWorkData.json').then((res) => {
      setArtWorks(res.data);
      console.log(res.data);
      setIsLoading(false);
    });
  }, [id]);

 // Parse the query parameters from the URL when the component mounts
 useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const returnedFrom = urlParams.get('returnFrom');
  const blockchainFromUrl = urlParams.get('blockchain');
  const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
  const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
  const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
  const lsreturnUrl = localStorage.getItem('returnUrl');
  const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
  if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
          handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
          handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
  }
}, [blockchain]);

  // Filtering artwork by IDs
  const filteredArtWork = artWorks.filter((artWork) => artWork.id === id);

  return (
    <>
      {isLoading && (<div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {/* <Navigation ref={navigationRef} darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain}/> */}

        {darkMode ? (
          <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
        ) : (
          <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
        )}
      </div>)
      }
      <Container className={styles.artCardDetailsContainer}>
        <Typography variant='h3' color='secondary.main'>
          {filteredArtWork.map((fa) => (
            <SingleArtWork darkMode={darkMode} key={fa.id} fa={fa} connectwall={connectwall} account={account} blockchain={blockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={indexxWalletAddress} isIndexxWalletWeb={isIndexxWalletWeb}/>
          ))}
        </Typography>
      </Container>
    </>
  );
};

export default ArtCardDetails;
