import React, { useEffect } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Outlet, useLocation } from "react-router-dom";
import ProfileSideBar from "../../components/ProfileSideBar/ProfileSideBar";

import { useTranslation } from "react-i18next";

import { useTheme } from "@emotion/react";

const UserProfile = ({ darkMode }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(location);
  return (
    <Box>
      <Box
        sx={
          !isMobile
            ? {
                color: `${darkMode ? "#ffffff" : "#040404"}`,
                mt: 10.5,
                mb: 10,
                position: "relative",
              }
            : { mt: 2 }
        }
      >
        {isMobile && location.pathname === "/profile/user-profile" && (
          <Box
            sx={{
              position: "fixed",
              top: "0%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "9999",
              mt: 5,
            }}
          >
            <Typography
              variant="subtitle1"
              color="secondary"
              component="div"
            
            >
              {"User Profile"}
            </Typography>
          </Box>
        )}
      
        {/* Navigation */}
        {!isMobile && (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              gap: 2,
              alignItems: "center",
              my: 5,
              ml: 2,
            }}
          >
            <Typography
              component="div"
              sx={{
     
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                onClick={handleBack}
                variant="h6"
                component="p"
                sx={{
                  zIndex: 2,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {t(
                  "SETTINGS_" +
                    location.pathname
                      .slice(9)
                      ?.split("-")
                      .join("_")
                      .toUpperCase()
                )}
              </Typography>
              
            </Typography>
          </Box>
        )}
        {!isMobile ? (
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
              borderRadius: "16px",
              display: "flex",
              p: 5,
              justifyContent: "center",
              alignItems: "center",
              width: "85%",
            }}
          >
            <Grid
              container
              spacing={{ xs: 1, md: 4 }}
              columns={{ xs: 1, md: 12 }}
            >
              <Grid zIndex={10000} item xs={1} md={3.5}>
                <ProfileSideBar darkMode={darkMode} />
              </Grid>
              <Grid zIndex={10000} item xs={1} md={8.5}>
                <Outlet />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              color: `${darkMode ? "#ffffff" : "#040404"}`,
              overflowY: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Grid
                container
                spacing={{ xs: 1, md: 4 }}
                columns={{ xs: 1, md: 12 }}
              >
                <Grid item xs={1} md={8.5}>
                  <Outlet />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserProfile;
