import collection1 from "../../assets/cardImages/bit_light.svg";
import collection1dark from "../../assets/cardImages/bit_dark.svg";

import collection2 from "../../assets/cardImages/eth_light.svg";
import collection2dark from "../../assets/cardImages/eth_dark.svg";

import collection3 from "../../assets/cardImages/usd_light.svg";
import collection3dark from "../../assets/cardImages/usd_dark.svg";

export const CollectionData = [
  {
    id: "1",
    title: "Bitcoin XNFT",
    name: "bitcoin-xnft",
    createdBy: "@Indexx",
    totalNFT: 100,
    timeStamp: "20 hours ago",
    likes: 40,
    image: collection1,
    imagedark : collection1dark,
    link:"/collections/bitcoin-xnft/1",
    tags:"cryptocurrency"
  },
  {
    id: "2",
    title: "Ethereum XNFT",
    name: "ethereum-xnft",
    createdBy: "@Indexx",
    totalNFT: 100,
    timeStamp: "24 hours ago",
    likes: 40,
    image: collection2,
    imagedark : collection2dark,
    link:"/collections/ethereum-xnft/2",
    tags:"cryptocurrency"
  },
  {
    id: "3",
    title: "XUSD",
    name: "xusd-nft",
    createdBy: "@Indexx",
    totalNFT: 100,
    timeStamp: "5 hours ago",
    likes: 2,
    image: collection3,
    imagedark : collection3dark,
    link:"/collections/xusd-nft/3",
    tags:"usd"
  },
];
