/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import xnftlogo from "./ftop.png";
import bottomlogo from "./bottomm.png";
import btccoin from "./btccoin.png";
import dummychart from "./dummychart.png";
import styles from "./SingleArtWork.module.css";
import tokentop from "./tokentop.svg";
import BTC from "../../assets/tokenImages/BTC.svg";
import ETH from "../../assets/tokenImages/ETH.svg";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  Select,
  TextField,
  useMediaQuery,
  MenuItem
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTheme } from "@emotion/react";

import { Box } from "@mui/system";
import { useState } from "react";

import { styled } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Popover } from "@mui/material";
import { ChromePicker } from "react-color";
// Icons
import { TiTimes } from "react-icons/ti";
import { MdAddToPhotos } from "react-icons/md";
import { RiAddBoxFill } from "react-icons/ri";
import { ImImage } from "react-icons/im";
// import { BiMenuAltLeft } from "react-icons/bi";
import sampleimage from "../../assets/collectionImages/BTClogo.png";

import AssetProperModal from "../../components/AssetPropertiesModal/AssetProperModal";

import { useTranslation } from "react-i18next";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";

import { uploadFile } from "react-s3";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'green', // Change this to the desired outline color when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'gray', // Change this to the desired outline color when focused
      },
      '&:not(.Mui-focused) fieldset': {
        borderColor: 'white', // Change this to the desired outline color when not focused
      },
      '& input': {
        color: 'white', // Change this to the desired color of the entered input
      },
    },
    '& .MuiInputLabel-root': {
      color: 'white', // Change this to the desired color of the label
    },
  },
});

const SelectTokenDropdown = ({ selectedToken, handleTokenChange }) => {


  return (
    <div className={styles.selectContainer}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center">
          <Typography
            color="secondary"
            sx={{ textAlign: 'center', marginRight: 10 }}
            variant="body2"
            component="p"
            fontSize={14}
          >
            <br />
            Select Token:
          </Typography>
          <Select
            value={selectedToken}
            onChange={handleTokenChange}
            variant="outlined"
            width="100%"
            sx={{ marginLeft: 'auto', minWidth: "150px" }}
          >
            <MenuItem value="btc">Bitcoin</MenuItem>
            <MenuItem value="eth">Ethereum</MenuItem>

          </Select>
        </Stack>
      </Stack>
    </div>
  );
};

const SelectTypeDropdown = ({ selectedType, handleTypeChange }) => {



  return (
    <div className={styles.selectContainer}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center">
          <Typography
            color="secondary"
            sx={{ textAlign: 'center', marginRight: 10 }}
            variant="body2"
            component="p"
            fontSize={14}
          >
            <br />
            Select Type:
          </Typography>
          <Select
            value={selectedType}
            onChange={handleTypeChange}
            variant="outlined"
            width="100%"
            sx={{ marginLeft: 'auto', minWidth: "150px" }}
          >
            <MenuItem value="token">Token</MenuItem>
            <MenuItem value="merch">Merch</MenuItem>

          </Select>
        </Stack>
      </Stack>
    </div>
  );
};

const TitleTextField = ({ titleName, handleTitleNameChange }) => {

  return (
    <div className={styles.textFieldContainer}>

      <Stack direction="row">

        <Typography
          color="secondary"
          sx={{ textAlign: 'left' }}
          variant="body2"
          component="p"
          fontSize={14}

        >  <br />
          Title:
        </Typography>
        <TextField
          value={titleName}
          onChange={handleTitleNameChange}
          variant="outlined"
          sx={{ marginLeft: 'auto' }}
        />

      </Stack></div>

  );
};

// const SerialNumberTextField = ({serialNumber,handleSerialNumberChange}) => {

//   return (
//     <div className={styles.textFieldContainer}>
//       <Stack direction="row">
//       <Typography
//         color="secondary"
//         sx={{ textAlign: 'left' }}
//         variant="body2"
//         component="p"
//         fontSize={14}
//       >  <br/>
//         Serial Number: 
//       </Typography>
//       <TextField
//         value={serialNumber}
//         onChange={handleSerialNumberChange}
//         variant="outlined"
//         sx={{marginLeft: 'auto'}}
//         type="number"
//       />
//       </Stack>
//     </div>
//   );
// };






const Input = styled("input")({
  display: "none",
});


const CustomTokenCombo = ({ count, selectedToken, handleTokenChange, handlePriceChange, companyName, websiteName, serialNumber, parentImage }) => {
  const [selectedType, setSelectedType] = useState('');
  const [image, setImage] = useState(null);
  const [tokenColor, setTokenColor] = useState('#5A5A5A'); // Initial color state
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [fixedButtonToggler, setFixedButtonToggler] = useState(true);
  const [openButtonToggler, setOpenButtonToggler] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dateValueFrom, setDateValueFrom] = useState(new Date());
  const [dateValueTo, setDateValueTo] = useState(new Date());

  const [xnftName, setxnftName] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [propertiesDataState, setPropertiesDataState] = useState([{ key: '', value: '' }]);
  const [savedProperties, setSavedProperties] = useState([]);
  const [nftImage, setNftImage] = useState('');
  const [price, setPrice] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [generatedImageURL, setGeneratedImageURL] = useState(Array(10).fill(sampleimage));

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;
  const [titleName, setTitleName] = useState('Enter Title Name');
  const classes = useStyles();


  const handlePriceChangeInternal = (event) => {
    const enteredPrice = event.target.value;
    handlePriceChange(enteredPrice); // Call the handlePriceChange function with the entered price
  };
  const handleTitleNameChange = (event) => {
    setTitleName(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeTokenColor = (newColor) => {
    setTokenColor(newColor.hex);
  };

  const handleImageUpload = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setNftImage(e.target.files[0]);
  };

  const handleTokenChangeInternal = (event) => {
    const newSelectedToken = event.target.value;
    handleTokenChange(newSelectedToken);
  };



  return (
    <>
      <Stack
        direction="row" spacing={6}
        sx={{ width: `${!isMobile ? 'auto' : "326px" }`,}}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column" }}
          // direction="column"
        >
          <Box
            sx={{
              width: `${!isMobile ? "360px" : "326px"}`,
              height: "600px",
              backgroundColor: tokenColor,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "30px",
            }}
          >
            <br />
            <br />
            <div
              style={{
                // backgroundImage: `url(${tokentop})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: `${!isMobile ? "360px" : "326px"}`,
                height: "60px",
                position: "relative",
                marginTop: "-120px",
                textAlign: "center",
                paddingBottom: "10px",
                paddingTop: "20px",
                borderRadius: "30px 30px 0px 0",
              }}
            >
              <img
                style={{
                  width: "70px",

                  objectFit: "contain", // Ensure the image stays within the boundaries
                  padding: "5px",
                }}
                src={parentImage}
                alt="Uploaded"
              />
            </div>

            <Box style={{ marginTop: -100 }}>
              <Box>
                {!image ? (
                  <Box
                    sx={{
                      my: 2,
                      border: "1px solid #c4c4c4",
                      borderStyle: "dashed",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "4px",
                      p: 3,
                      width: "140px", // Adjust the width and height to make it square
                      height: "140px",
                      mt: 32,
                    }}
                  >
                    <Typography
                      color="secondary"
                      sx={{ mt: 0 }}
                      variant="body2"
                      component="p"
                      fontSize={8}
                    >
                      UPLOAD IMAGE (PNG, JPEG/SVG)
                    </Typography>
                    <label htmlFor="icon-button-file-front">
                      <Input
                        accept="image/*"
                        id="icon-button-file-front"
                        type="file"
                        onChange={handleImageUpload}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <Typography component="span" color="secondary">
                          <ImImage />
                        </Typography>
                      </IconButton>
                    </label>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      width: "200px", // Adjust the width and height to make it square
                      height: "200px",
                      mt: 32,
                    }}
                  >
                    <Box sx={{ position: "absolute", right: "-8%", top: "-12%" }}>
                      <IconButton
                        // sx={{
                          // backgroundColor: "#171c26",
                        // }}
                        onClick={() => setImage(null)}
                      >
                        <Typography component="span" color="secondary">
                          <TiTimes />
                        </Typography>
                      </IconButton>
                    </Box>
                    <img
                      style={{
                        color: "white",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain", // Ensure the image stays within the boundaries
                        padding: "5px",
                        marginTop: "-40px",
                      }}
                      src={image}
                      alt="Uploaded"
                    />
                  </Box>
                )}
              </Box>
            </Box>

            {/* 

            <div style={{ marginTop: -100 }}>

              <Box>
                {!image ? (
                  <Box
                    sx={{
                      my: 2,
                      border: "1px solid #c4c4c4",
                      borderStyle: "dashed",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "4px",
                      p: 3,
                      width: "140px", // Adjust the width and height to make it square
                      height: "140px",
                      mt: 32
                    }}
                  >
                    <Typography color="secondary" sx={{ mt: 0 }} variant="body2" component="p" fontSize={8}>
                      UPLOAD IMAGE (PNG, JPEG/SVG)
                    </Typography>
                    <label htmlFor="icon-button-file-front">
                      <Input
                        accept="image/*"
                        id="icon-button-file-front"
                        type="file"
                        onChange={handleImageUpload}
                      />
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <Typography component="span" color="secondary">
                          <ImImage />
                        </Typography>
                      </IconButton>
                    </label>
                  </Box>

                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      width: "200px", // Adjust the width and height to make it square
                      height: "200px",
                      mt: 32
                    }}
                  >
                    <Box sx={{ position: "absolute", left: "1%", top: "4%" }}>
                      <IconButton

                        onClick={() => setImage(null)}
                      >
                        <Typography component="span" color="secondary">
                          <TiTimes />
                        </Typography>
                      </IconButton>
                    </Box>
                    <img
                      style={{

                        width: "100%",
                        height: "100%",
                        objectFit: "contain", // Ensure the image stays within the boundaries
                        padding: "5px",
                        marginTop: "-40px"
                      }}
                      src={image}
                      alt="Uploaded"
                    />
                  </Box>
                )}
              </Box>
            </div> */}

            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                textAlign: "center",
                color: "white",
              }}
            >
              {titleName}
            </Typography>

            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 30,
                textAlign: "center",
                color: "white",
                marginBottom: -3,
              }}
            >
              {`${serialNumber}`.padStart(7, "0")}
            </Typography>

            <br />
            <Typography
              sx={{
                fontWeight: "light",
                fontSize: 14,
                textAlign: "center",
                color: "white",
                marginTop: "50px",
                marginBottom: "-40px",
              }}
            >
              {websiteName}
            </Typography>
          </Box>

          <br />
{isMobile ? 
  <Box >
              <Typography
                color="secondary"
                variant="h6"
                component="h2"
                sx={{ mb: 2, fontSize: "14px" }} // Decrease the font size
              >
                Combo {count + 1}
              </Typography>

              <Typography
                color="secondary"
                variant="h6"
                component="h2"
                sx={{ mb: 2, fontSize: "14px" }} // Decrease the font size
              >
                Description:
              </Typography>
              <TextField
                label="Description"
                variant="outlined"
                color="secondary"
                width="100%"
                sx={{ mb: 2, width: "100%" }}
                rows={4}
                // className={classes.root}
                size="small" // Make the input box smaller
              />
              <Typography
                color="secondary"
                variant="h6"
                component="h2"
                sx={{ mb: 2, fontSize: "14px" }} // Decrease the font size
              >
                Item Price:
              </Typography>
              <TextField
                label="Token Price in MATIC"
                variant="outlined"
                color="secondary"
                rows={1}
                type="number"
                sx={{ mb: 2, width: "100%" }}
                size="small"
                value={price} // Set the value of the TextField to the price state
                onChange={handlePriceChangeInternal} // Update the price state when the user enters a value
                // className={classes.root}
              />
            </Box>
:null}

          <Box>
            <br />
            <TitleTextField
              titleName={titleName}
              handleTitleNameChange={handleTitleNameChange}
            />
            <br />
            {/* <SerialNumberTextField serialNumber={serialNumber} handleSerialNumberChange={handleSerialNumberChange}/> */}
            <br />
            <div>
              <Button
                variant="contained"
                onClick={handleClick}
                // variant={fixedButtonToggler ? "contained" : "outlined"}
                style={{
                  backgroundColor: "#040404",
                  border: "1px solid #c4c4c4",
                }}
                fullWidth={true}
              >
                <Typography color="white">Select Background Color</Typography>
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    backgroundColor: tokenColor,
                    marginLeft: 5,
                    display: "inline-block",
                  }}
                />
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Box p={2}>
                  <ChromePicker
                    color={tokenColor}
                    onChange={handleChangeTokenColor}
                  />
                </Box>
              </Popover>
            </div>
          </Box>
        </Box>

        {!isMobile ? (
          <Box
            sx={{
              width: "430px",
              height: "270px",
              backgroundColor: "#171C26",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", // Align to the left
              justifyContent: "center",
              borderRadius: "15px",
              padding: "20px", // Add some padding for better spacing
              paddingBottom: "500px",
            }}
          >
            <Box>
              <Typography
                color="white"
                variant="h6"
                component="h2"
                sx={{ mb: 2, fontSize: "14px" }} // Decrease the font size
              >
                Combo {count + 1}
              </Typography>

              <Typography
                color="white"
                variant="h6"
                component="h2"
                sx={{ mb: 2, fontSize: "14px" }} // Decrease the font size
              >
                Description:
              </Typography>
              <TextField
                label="Description"
                variant="outlined"
                color="secondary"
                minWith="400px"
                sx={{ mb: 2, minWidth: "420px" }}
                rows={4}
                className={classes.root}
                size="small" // Make the input box smaller
              />
              <Typography
                color="white"
                variant="h6"
                component="h2"
                sx={{ mb: 2, fontSize: "14px" }} // Decrease the font size
              >
                Item Price:
              </Typography>
              <TextField
                label="Token Price in MATIC"
                variant="outlined"
                // color="secondary"
                rows={1}
                type="number"
                sx={{ mb: 2, width: "100%" }}
                size="small"
                value={price} // Set the value of the TextField to the price state
                onChange={handlePriceChangeInternal} // Update the price state when the user enters a value
                className={classes.root}
              />
            </Box>
          </Box>
        ) : null}
      </Stack>
    </>
  );
};


export default CustomTokenCombo;
