/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react';
import xnftlogo from './ftop.png';
import bottomlogo from './bottomm.png';
import btccoin from './btccoin.png';
import dummychart from './dummychart.png';
import styles from './SingleArtWork.module.css';
import circle from '../../assets/circle.svg';
import BTC from '../../assets/tokenImages/BTC.svg';
import ETH from '../../assets/tokenImages/ETH.svg';
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  Select,
  TextField,
  useMediaQuery,
  MenuItem,
} from '@mui/material';
// import { useRef } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@emotion/react';
// import { exportComponentAsPNG } from 'react-component-export-image';

import { Box } from '@mui/system';
import { useState } from 'react';

import { styled } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Popover } from '@mui/material';
import { ChromePicker } from 'react-color';
// Icons
import { TiTimes } from 'react-icons/ti';
import { MdAddToPhotos } from 'react-icons/md';
import { RiAddBoxFill } from 'react-icons/ri';
import { ImImage } from 'react-icons/im';
// import { BiMenuAltLeft } from "react-icons/bi";
import sampleimage from '../../assets/collectionImages/BTClogo.png';

import AssetProperModal from '../../components/AssetPropertiesModal/AssetProperModal';

import { useTranslation } from 'react-i18next';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';

import { uploadFile } from 'react-s3';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'green', // Change this to the desired outline color when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'gray', // Change this to the desired outline color when focused
      },
      '&:not(.Mui-focused) fieldset': {
        borderColor: 'white', // Change this to the desired outline color when not focused
      },
      '& input': {
        color: 'white', // Change this to the desired color of the entered input
      },
    },
    '& .MuiInputLabel-root': {
      color: 'white', // Change this to the desired color of the label
    },
  },
});

const SelectBlockchainDropdown = () => {
  const [selectedBlockchain, setSelectedBlockchain] = useState('Select');

  const handleBlockchainChange = (event) => {
    setSelectedBlockchain(event.target.value);
  };



  return (
    <div className={styles.selectContainer}>
      <Stack direction="column">
        <Stack direction="row">
          <Typography
            color="secondary"
            sx={{ textAlign: 'center', marginRight: 10 }}
            variant="body2"
            component="p"
            fontSize={14}
          >
            <br />
            Select Blockchain:
          </Typography>

          <Select
            value={selectedBlockchain}
            onChange={handleBlockchainChange}
            variant="outlined"
            width="100%"
            sx={{ marginLeft: 'auto', minWidth: '150px' }}
          >
            {/* <MenuItem value="Bitcoin">Bitcoin</MenuItem> */}
            <MenuItem value="Ethereum">Ethereum</MenuItem>
            <MenuItem value="Polygon">Polygon</MenuItem>
          </Select>
        </Stack>
      </Stack>
    </div>
  );
};

const SelectCategoryDropdown = () => {
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className={styles.selectContainer}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center">
          <Typography
            color="secondary"
            sx={{ textAlign: 'center', marginRight: 10 }}
            variant="body2"
            component="p"
            fontSize={14}
          >
            <br />
            Select Category:
          </Typography>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            variant="outlined"
            width="100%"
            sx={{ marginLeft: 'auto', minWidth: '150px' }}
          >
            <MenuItem value="category1">Utility</MenuItem>
            <MenuItem value="category2">Merch</MenuItem>
            <MenuItem value="category3">Token</MenuItem>
          </Select>
        </Stack>
      </Stack>
    </div>
  );
};

const SelectTokenDropdown = ({ selectedToken, handleTokenChange }) => {
  return (
    <div className={styles.selectContainer}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center">
          <Typography
            color="secondary"
            sx={{ textAlign: 'center', marginRight: 10 }}
            variant="body2"
            component="p"
            fontSize={14}
          >
            <br />
            Select Token:
          </Typography>
          <Select
            value={selectedToken}
            onChange={handleTokenChange}
            variant="outlined"
            width="100%"
            sx={{ marginLeft: 'auto', minWidth: '150px' }}
          >
            <MenuItem value="btc">Bitcoin</MenuItem>
            <MenuItem value="eth">Ethereum</MenuItem>
          </Select>
        </Stack>
      </Stack>
    </div>
  );
};

const SelectTypeDropdown = () => {
  const [selectedType, setSelectedType] = useState('');

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <div className={styles.selectContainer}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center">
          <Typography
            color="secondary"
            sx={{ textAlign: 'center', marginRight: 10 }}
            variant="body2"
            component="p"
            fontSize={14}
          >
            <br />
            Select Type:
          </Typography>
          <Select
            value={selectedType}
            onChange={handleTypeChange}
            variant="outlined"
            width="100%"
            sx={{ marginLeft: 'auto', minWidth: '150px' }}
          >
            <MenuItem value="type1">Type 1</MenuItem>
            <MenuItem value="type2">Type 2</MenuItem>
            <MenuItem value="type3">Type 3</MenuItem>
          </Select>
        </Stack>
      </Stack>
    </div>
  );
};

const WebsiteNameTextField = ({ websiteName, handleWebsiteNameChange }) => {
  return (
    <div className={styles.textFieldContainer}>
      <Stack direction="row">
        <Typography
          color="secondary"
          sx={{ textAlign: 'left' }}
          variant="body2"
          component="p"
          fontSize={14}
        >
          <br />
          Website Name:
        </Typography>
        <TextField
          value={websiteName}
          onChange={handleWebsiteNameChange}
          variant="outlined"
          sx={{ marginLeft: 'auto' }}
        />
      </Stack>
    </div>
  );
};

// const CompanyNameTextField = ({ companyName, handleCompanyNameChange }) => {
//   const products = [
//     {
//       company: 'Apple',
//       productName: 'iPhone 12',
//       imageUrl: 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/company_logos/apple.png'
//     },
//     {
//       company: 'Samsung',
//       productName: 'Galaxy S21',
//       imageUrl: 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/company_logos/samsung.png'
//     },
//     {
//       company: 'Sony',
//       productName: 'Playstation 5',
//       imageUrl: 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/company_logos/sony.png'
//     },
//     {
//       company: 'Microsoft',
//       productName: 'Xbox Series X',
//       imageUrl: 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/company_logos/microsoft.png'
//     },
//     {
//       company: 'Amazon',
//       productName: 'Echo Dot (4th Gen)',
//       imageUrl: 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/company_logos/amazon.png'
//     },
//     {
//       company: 'Google',
//       productName: 'Pixel 5',
//       imageUrl: 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/company_logos/google.png'
//     }
//   ];

//   // Convert the products into the format needed for react-select
//   const productOptions = products.map((product, index) => ({
//     value: index,
//     label: product.productName,
//     image: product.imageUrl,
//   }));

//   return (
//     <div className={styles.textFieldContainer}>
//       <Stack direction="row">
//         <Typography
//           color="secondary"
//           sx={{ textAlign: 'left' }}
//           variant="body2"
//           component="p"
//           fontSize={14}
//         >
//           {' '}
//           <br />
//           Company Name:
//         </Typography>
//         <TextField
//           value={companyName}
//           onChange={handleCompanyNameChange}
//           variant="outlined"
//           sx={{ marginLeft: 'auto' }}
//         />
//         {/* <>
//           <Select
//             value={companyName}
//             onChange={handleCompanyNameChange}
//             variant="outlined"
//             fullWidth
//             sx={{ marginLeft: 'auto', minWidth: '150px' }}
//           >
//             {products.map((product, index) => (
//               <MenuItem value={product.imageUrl} key={index}>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                   <img src={product.imageUrl} alt={product.productName} width="50" style={{ marginRight: '10px' }} />
//                   <span>{product.company}</span>
//                 </div>
//               </MenuItem>
//             ))}
//           </Select>
//         </> */}
//       </Stack>
//     </div>
//   );
// };

const SerialNumberTextField = ({ serialNumber, handleSerialNumberChange }) => {
  return (
    <div className={styles.textFieldContainer}>
      <Stack direction="row">
        <Typography
          color="secondary"
          sx={{ textAlign: 'left' }}
          variant="body2"
          component="p"
          fontSize={14}
        >
          {' '}
          <br />
          Serial Number:
        </Typography>
        <TextField
          value={serialNumber}
          onChange={handleSerialNumberChange}
          variant="outlined"
          type="number"
          sx={{ marginLeft: 'auto' }}
        />
      </Stack>
    </div>
  );
};

const Input = styled('input')({
  display: 'none',
});

const CustomCover = ({
  tokenPrice,
  selectedToken,
  prices,
  setTitleImage,
  handleTokenChange,
  companyName,
  handleCompanyNameChange,
  websiteName,
  serialNumber,
  handleWebsiteNameChange,
  handleCompanyNameChangeParent,
  handleChangeCoverColor,
  handleChangeCoverRoyaltyFee,
  handleChangeCoverNameParent,
  handleChangeCoverDescriptionParent,
  handleParentSerialNumber,
  handleParentImage,
  setTopImage,
  topImage,
  setCompanyImage,
  companyImage,
}) => {
  const [image, setImage] = useState(null);
  //const [topImage,setTopImage]= useState(null);
  const [royaltyFee, setRoyaltyFee] = useState(0);
  const [coverDescription, setCoverDescription] = useState('');
  const [coverName, setCoverName] = useState('');
  const [color, setColor] = useState('#5A5A5A'); // Initial color state
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [fixedButtonToggler, setFixedButtonToggler] = useState(true);
  const [openButtonToggler, setOpenButtonToggler] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [internalSerialNumber, setInternalSerialNumber] = useState('');
  const [internalCompanyName, setInternalCompanyName] = useState('');
  const [dateValueFrom, setDateValueFrom] = useState(new Date());
  const [dateValueTo, setDateValueTo] = useState(new Date());
  const [internalWebsiteName, setInternalWebsiteName] = useState('');
  const [xnftName, setxnftName] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();

  const [propertiesDataState, setPropertiesDataState] = useState([
    { key: '', value: '' },
  ]);

  const [savedProperties, setSavedProperties] = useState([]);

  const [nftImage, setNftImage] = useState('');
  const [price, setPrice] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [generatedImageURL, setGeneratedImageURL] = useState(
    Array(10).fill(sampleimage)
  );

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;
 

  console.log('selectedToken:', selectedToken);
  console.log('prices', prices);
  // const handleCompanyNameChange = (event) => {
  //   setCompanyName(event.target.value);
  // };

  // const handleCompanyNameChangeInternal = (event) => {
  //   const newCompanyName = event.target.value;
  //   setInternalCompanyName(newCompanyName);
  //   handleCompanyNameChange(newCompanyName);
  // };
  const handleWebsiteNameChangeInternal = (event) => {
    const newWebsiteName = event.target.value;
    setInternalWebsiteName(newWebsiteName);
    handleWebsiteNameChange(newWebsiteName);
  };

  const handleSerialNumberChangeInternal = (event) => {
    const newSerialNumber = event.target.value;
    setInternalSerialNumber(newSerialNumber);
    handleParentSerialNumber(newSerialNumber);
  };


  const handleImageUploadInternal = (e)=> {
    const newImage = (URL.createObjectURL(e.target.files[0]));
    setCompanyImage(e.target.files[0]);
    setTopImage(newImage);
    console.log('uploaded', e.target.files[0]);
    handleParentImage(newImage);
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = (newColor) => {
    setColor(newColor.hex);
    console.log(color);
    handleChangeCoverColor(newColor);
  };

  const handleImageUpload = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setTitleImage(e.target.files[0]);
    console.log('uploaded', e.target.files[0]);
  };

 
  const handleChangeRoyaltyFee = (e) => {
    setRoyaltyFee(e.target.value);
    handleChangeCoverRoyaltyFee(e);
  };
  const handleChangeCoverName = (e) => {
    setCoverName(e.target.value);
    handleChangeCoverNameParent(e);
  };
  const handleChangeDescription = (e) => {
    setCoverDescription(e.target.value);
    console.log('desc', e.target.value);
    handleChangeCoverDescriptionParent(e);
  };
  const handleTokenChangeInternal = (event) => {
    const newSelectedToken = event.target.value;
    handleTokenChange(newSelectedToken);
  };

  const CustomTokenSummary = ({ prices, tokenPrice }) => {

    if (prices.length === 0) {
      prices[0] = 0;
    }
    const sum = prices.reduce(
      (total, price) => total + parseInt(price) + parseInt(tokenPrice),
      0
    ); // Convert prices to integers before adding

    return (
      <Typography
        color="gray"
        component="h2"
        sx={{ mb: 2, fontSize: '23px' }} // Decrease the font size
      >
        {`${sum} Matic    (${tokenPrice} + ${prices.join(' + ')})`}{' '}
        {/* Display the sum and individual values */}
      </Typography>
    );
  };
  // const componentCoverRef1 = useRef(null);

  return (
    <>
      <Stack direction="row" spacing={6}>
        <div>
          <Stack direction="column">
            <Box
              sx={{
                width: `${!isMobile ? "360px" : "326px"}`,
                height: '600px',
                backgroundColor: color,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '30px',
              }}
            >

        <div style={{ marginTop: 0 }}>
                <Box>
                  {!topImage ? (
                    <Box
                      sx={{
                        my: 2,
                        border: '1px solid #c4c4c4',
                        borderStyle: 'dashed',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        p: 3,
                        width: '140px', // Adjust the width and height to make it square
                        height: '50px',
                      }}
                    >
                      <Typography
                        color="secondary"
                        sx={{ mt: 0 }}
                        variant="body2"
                        component="p"
                        fontSize={8}
                      >
                       Upload Company Logo (PNG, JPEG/SVG)
                      </Typography>
                      <label htmlFor="icon-button-file-front">
                        <Input
                          accept="image/*"
                          id="icon-button-file-front"
                          type="file"
                          onChange={handleImageUploadInternal}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Typography component="span" color="secondary">
                            <ImImage />
                          </Typography>
                        </IconButton>
                      </label>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100px', // Adjust the width and height to make it square
                        height: '100px',
                      }}
                    >
                      <Box sx={{ position: 'absolute', right: '-26%', top: '-5%',marginBottom:"-20px"}}>
                        <IconButton onClick={() => setTopImage(null)}>
                          <Typography component="span" color="secondary">
                            <TiTimes />
                          </Typography>
                        </IconButton>
                      </Box>
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain', // Ensure the image stays within the boundaries
                          padding: '5px',
                          marginTop:'-20px'
                        }}
                        src={topImage}
                        alt="Uploaded"
                      />
                    </Box>
                  )}
                </Box>
              </div>













              {/* <img
                src={internalCompanyName}
                alt="IndexXNFT Logo"
                style={{ width: '200px' }}
              /> */}

              {/* <Typography
                color="secondary"
                sx={{ mt: -5 }}
                variant="body2"
                component="p"
                fontSize={30}
              >
                {internalCompanyName}
              </Typography> */}

              <img
                src={
                  selectedToken === ''
                    ? circle
                    : selectedToken === 'btc'
                      ? BTC
                      : ETH
                }
                alt="IndexXNFT Logo"
                style={{ width: '200px', marginTop: 20, marginBottom: 100 }}
              />

              <div style={{ marginTop: -100 }}>
                <Box>
                  {!image ? (
                    <Box
                      sx={{
                        my: 2,
                        border: '1px solid #c4c4c4',
                        borderStyle: 'dashed',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        p: 3,
                        width: '140px', // Adjust the width and height to make it square
                        height: '140px',
                      }}
                    >
                      <Typography
                        color="secondary"
                        sx={{ mt: 0 }}
                        variant="body2"
                        component="p"
                        fontSize={8}
                      >
                        UPLOAD IMAGE (PNG, JPEG/SVG)
                      </Typography>
                      <label htmlFor="icon-button-file-front">
                        <Input
                          accept="image/*"
                          id="icon-button-file-front"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Typography component="span" color="secondary">
                            <ImImage />
                          </Typography>
                        </IconButton>
                      </label>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position: 'relative',
                        width: '200px', // Adjust the width and height to make it square
                        height: '200px',
                      }}
                    >
                      <Box sx={{ position: 'absolute', right: '-6%', top: '4%' }}>
                        <IconButton onClick={() => setImage(null)}>
                          <Typography component="span" color="secondary">
                            <TiTimes />
                          </Typography>
                        </IconButton>
                      </Box>
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain', // Ensure the image stays within the boundaries
                          padding: '5px',
                          
                        }}
                        src={image}
                        alt="Uploaded"
                      />
                    </Box>
                  )}
                </Box>
              </div>

              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                  marginTop:'-10px'
                }}
              >
                {`${internalSerialNumber}`.padStart(7, '0')}
              </Typography>

              <Typography
                sx={{
                  fontWeight: 'light',
                  fontSize: 14,
                  textAlign: 'center',
                  color: "secondary.main",
                  marginTop: '20px',

                }}
              >
                {websiteName}
              </Typography>

            </Box>

            <br />

            {isMobile ? 
            <div>
            <Typography
              color="secondary"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Cover Name:
            </Typography>
            <TextField
              label="Cover Name"
              variant="outlined"
              color="secondary"
              sx={{ mb: 2, width: '100%'}}
              size="small" // Make the input box smaller
              onChange={handleChangeCoverName}
              // className={classes.root}
            />
            <Typography
              color="secondary"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Description:
            </Typography>
            <TextField
              label="Description"
              variant="outlined"
              InputProps={{ style:{  color:"white"}}}
              multiline
              color="secondary"
              rows={4}
              sx={{ mb: 2, width: '100%' }}
              size="small" // Make the input box smaller
              onChange={handleChangeDescription}
              // className={classes.root}
            />
            <Typography
              color="secondary"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Total Price:
            </Typography>

            <CustomTokenSummary prices={prices} tokenPrice={tokenPrice} />
            <Typography
              color="secondary"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Matic Current Value:
            </Typography>
            <Typography
              color="secondary"
              component="h2"
              sx={{ mb: 2, fontSize: '23px' }} // Decrease the font size
            >
              0.65 USD
            </Typography>
            </div>
            :null}

            <div>
              <SelectTokenDropdown
                selectedToken={selectedToken}
                handleTokenChange={handleTokenChangeInternal}
              />
              <br />
              <SelectBlockchainDropdown />
              <br />
              <SelectCategoryDropdown />
              <br />
              <SelectTypeDropdown></SelectTypeDropdown>
              <br />
              <WebsiteNameTextField
                websiteName={websiteName}
                handleWebsiteNameChange={handleWebsiteNameChangeInternal}
              />

              {/* <br />
              <CompanyNameTextField
                companyName={companyName}
                handleCompanyNameChange={handleCompanyNameChangeInternal}
              /> */}
              <br />
              <SerialNumberTextField
                serialNumber={serialNumber}
                handleSerialNumberChange={handleSerialNumberChangeInternal}
              />
              <br />
              <div>
                <Button
                  variant="contained"
                  onClick={handleClick}
                  // variant={fixedButtonToggler ? "contained" : "outlined"}
                  style={{
                    backgroundColor: '#040404',
                    border: '1px solid #c4c4c4',
                  }}
                  fullWidth={true}
                >
                <Typography color="white">
                  Select Background Color
                </Typography>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '50%',
                      backgroundColor: color,
                      marginLeft: 5,
                      display: 'inline-block',
                    }}
                  />
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box p={2}>
                    <ChromePicker color={color} onChange={handleChangeColor} />
                  </Box>
                </Popover>
              </div>
            </div>
          </Stack>
        </div>
        {!isMobile ? 
        <Box
          sx={{
            width: '430px',
            height: '1000px',
            backgroundColor: '#171C26',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start', // Align to the left
            justifyContent: 'flex-start',
            borderRadius: '15px',
            padding: '20px',
            paddingBottom: '123px', // Add some padding for better spacing
          }}
        >
          <div style={{width:"100%"}}>
            <Typography
              color="white"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Cover Name:
            </Typography>
            <TextField
              label="Cover Name"
              variant="outlined"
              sx={{ mb: 2, width: '100%'}}
              size="small" // Make the input box smaller
              onChange={handleChangeCoverName}
              className={classes.root}
            />
            <Typography
              color="white"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Description:
            </Typography>
            <TextField
              label="Description"
              variant="outlined"
              InputProps={{ style:{  color:"white"}}}
              multiline
              rows={4}
              sx={{ mb: 2, width: '100%' }}
              size="small" // Make the input box smaller
              onChange={handleChangeDescription}
              className={classes.root}
            />
            <Typography
              color="white"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Total Price:
            </Typography>

            <CustomTokenSummary prices={prices} tokenPrice={tokenPrice} />
            <Typography
              color="white"
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontSize: '14px' }} // Decrease the font size
            >
              Matic Current Value:
            </Typography>
            <Typography
              color="white"
              component="h2"
              sx={{ mb: 2, fontSize: '23px' }} // Decrease the font size
            >
              0.65 USD
            </Typography>

            {/* <img
              src={dummychart} // Replace with your actual image source
              alt="Dummy Image"
              style={{
                width: '100%',
                borderRadius: '10px',
                alignSelf: 'flex-start',
              }} // Align the image to the left
            /> */}
          </div>
          <Box
            className={styles.artDisplayDetailsMobile}
            bgcolor="#171C26"
            sx={{
              mt: 3,
            }}
          >
            <Box
              sx={{
                // p: 3,
              }}
            >
              <Box gap={1}>
                <Box className={styles.artDisplayDetailsContent}>
                  <Typography variant="subtitle1" gutterBottom color="gray">
                    Contract Address
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="p"
                      color="#FFFFFF"
                      mb={2}
                      fontWeight={350}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      0x010123F1232323F
                    </Typography>
                    <Typography
                      component="span"
                      color="#FFFFFF"
                      mb={2}
                      ml={1}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <ContentCopyIcon />
                    </Typography>
                  </div>
                </Box>
                <Box className={styles.artDisplayDetailsContent}>
                  <Typography variant="subtitle1" gutterBottom color="gray">
                    Token ID:
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    color="#FFFFFF"
                    mb={2}
                    fontWeight={500}
                  >
                    {21}
                  </Typography>
                </Box>
                <Box className={styles.artDisplayDetailsContent}>
                  <Typography variant="subtitle1" gutterBottom color="gray">
                    Token Standard:
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    color="#FFFFFF"
                    mb={2}
                    fontWeight={500}
                  >
                    ERC721
                  </Typography>
                </Box>
                <Box className={styles.artDisplayDetailsContent}>
                  <Typography variant="subtitle1" gutterBottom color="gray">
                    Blockchain:
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    color="#FFFFFF"
                    mb={2}
                    fontWeight={500}
                  >
                    Polygon
                  </Typography>
                </Box>
                <Box className={styles.artDisplayDetailsContent}>
                  <Typography variant="subtitle1" gutterBottom color="gray">
                    Royalty Fee
                  </Typography>
                  <TextField
                    label="1-10%"
                    variant="outlined"
                    rows={1}
                    textAlign="right"
                    sx={{ mb: 2, width: '20%' }}
                    size="small" // Make the input box smaller
                    onChange={handleChangeRoyaltyFee}
                    className={classes.root}
                  />
                </Box>
                <Box className={styles.artDisplayDetailsContent}>
                  <Typography variant="subtitle1" gutterBottom color="gray">
                    Transaction Fee
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    color="#FFFFFF"
                    fontWeight={500}
                  >
                    3.5%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        :null }
      </Stack>
    </>
  );
};

export default CustomCover;
