import { Container, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import styles from './AuctionCardDetails.module.css';
import SingleAuctionCard from './SingleAuctionCard';
import { getAllNFTsCount } from '../../service/nftServices';
import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";
import Navigation from '../Navigation/Navigation';

const AuctionCardDetails = ({ darkMode, blockchain, setBlockchain, handleConnectIndexxWallet }) => {
  const { id } = useParams(); // Read from url
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState('');
  const navigationRef = useRef(null);

  const [auctionArtWorks, setAuctionArtWorks] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setLoaderMessage('Loading...');
    getAllNFTsCount().then((res) => {
      setAuctionArtWorks(res);
      setIsLoading(false);
    })
  }, [id]);


   // Parse the query parameters from the URL when the component mounts
   useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  // Filtering artwork by IDs
  const filteredArtWork = auctionArtWorks.filter(
    (artWork) => artWork.contractAddress === id
  );

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Navigation ref={navigationRef} darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain}/> */}

          {darkMode ? (
          <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
        ) : (
          <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
        )}
        </div>
      ) : ""}
      <Container className={styles.auctionCardContainer}>
        <Typography variant='h3' color='secondary.main'>
          {filteredArtWork.map((fa) => (
            <SingleAuctionCard darkMode={darkMode} key={fa.id} fa={fa} />
          ))}
        </Typography>
      </Container>
    </>
  );
};

export default AuctionCardDetails;
