/* eslint-disable no-unused-vars */
import React from "react";

// Material UI components
import {
  AppBar,
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from '@mui/system';
// Icons
import {

  BsGearFill,
  BsTwitch,
} from "react-icons/bs";

import bulb from "../../assets/sidebaricons/bulb.png";
import gear from "../../assets/sidebaricons/gear.png";
import star from "../../assets/sidebaricons/star.png";
import tag from "../../assets/sidebaricons/tag.png";
import sbulb from "../../assets/sidebaricons/sidebarselected/bulb.png";
import sgear from "../../assets/sidebaricons/sidebarselected/gear.png";
import sstar from "../../assets/sidebaricons/sidebarselected/star.png";
import stag from "../../assets/sidebaricons/sidebarselected/tag.png";


import dbulb from "../../assets/sidebaricons/sidebarlight/bulb01.png"
import dgear from "../../assets/sidebaricons/sidebarlight/gear01.png"
import dstar from "../../assets/sidebaricons/sidebarlight/star01.png"


import { HiCubeTransparent } from "react-icons/hi";
import { MdGavel } from "react-icons/md";
import { CgMenuRight } from "react-icons/cg";
import { FiPackage } from "react-icons/fi";

// Main logo
import MainLogo from "../../assets/x.png"
import MainLogoLight from "../../assets/x.png";

import { useLocation, useNavigate } from "react-router-dom";

// Side Navigation Shadow
// Theme

import { useTheme } from "@emotion/react";

import { useTranslation } from "react-i18next";
import SideDrawer from "./SideDrawer";

const HoverIconButton = styled(IconButton)({
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.5)',
    backgroundColor: 'transparent', 
    outline: 'none', // Remove outline on hover
  },
  '&:focus': {
    outline: 'none', // Remove outline on focus
  },
});;

const topBarContent = [
  {
    path: "/home",
    name: "NAV_HOME",
    // darkIcon: <BsTwitch />,
  },
  {
    path: "/explore",
    name: "NAV_EXPLORE",
    // darkIcon: <HiCubeTransparent />,
  },
  {
    path: "/auction",
    name: "NAV_AUCTION",
    // darkIcon: <MdGavel />,
  },
  {
    path: "/collections",
    name: "Collection",
    // darkIcon: <FiPackage />,
  },
  {
    path: "/create-asset",
    name: "Create Asset",
    // darkIcon: <FiPackage />,
  },
  {
    path: "/brands",
    name: "Brands",
    // darkIcon: <FiPackage />,
  },
  {
    path: "/add-brand",
    name: "Add Brand",
    // darkIcon: <FiPackage />,
  },
  {
    path: "/redeem-gift",
    name: "Redeem Gift Card",
    // darkIcon: <FiPackage />,
  },
  {
    path: "/store-gift",
    name: "Store Gift Card",
    // darkIcon: <FiPackage />,
  },
  {
    path: "/redeem-greeting",
    name: "Redeem Greeting Card",
    // darkIcon: <FiPackage />,
  },
  {
    path: "/store-greeting",
    name: "Store Greeting Card",
    // darkIcon: <FiPackage />,
  },
];

const SideBar = ({
  darkMode,
  handleConnectMetamaskWallet,
  marketplace,
  account,
  blockchain,
  setBlockchain,
  setIndexxWalletEmail,
  indexxWalletEmail,
  indexxWalletObj,
  indexxWalletAddress,
  isIndexxWalletWeb,
  handleConnectIndexxWallet,
  connectwall,
},
  ref) => {
  // For mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const isLightMode = theme.palette.mode === "light";
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // Mobile drawer toggler
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleLogoClick = () => {
    window.location.href = "https://test.indexx.ai";
  };

  return (
    <>
      {isMobile ? (
        <AppBar
          sx={{
            pt: 3,
            px: 4,
            zIndex: "3000"
          }}
          position="fixed"
          elevation={0}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >




            <Box>
              {darkMode ? (

                <img
                  style={{ width: "40px", height: "40px" }}
                  src={MainLogo}
                  alt="MainLogo"
                  onClick={handleLogoClick}
                />
              ) : (

                <img
                  style={{ width: "40px", height: "40px" }}
                  src={MainLogoLight}
                  alt="MainLogoLight"
                  onClick={handleLogoClick}
                />
              )}
            </Box>



            <Box mt={-1.5}>
              <Typography
                color="secondary"
                variant="subtitle1"
                component="div"
                sx={{
                  ml: -2,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {topBarContent.map(
                  (tbc) =>
                    tbc.path === location.pathname && (
                      <React.Fragment key={tbc.name}>
                        <Typography
                          component="span"
                          color="secondary"
                          mt={1}
                          fontSize={20}
                        >
                          {tbc.darkIcon}
                        </Typography>
                        <Typography
                          color="secondary"
                          component="p"
                          variant="subtitle1"
                          sx={{
                            borderBottom: `2px solid ${darkMode ? "#ffffff" : "#040404"
                              }`,
                          }}
                        >
                          {t(`${tbc.name}`)}
                        </Typography>
                      </React.Fragment>
                    )
                )}
              </Typography>
            </Box>
            <Box>
              <HoverIconButton onClick={handleDrawerToggle}>
                <Typography component="span" color="secondary">
                  <CgMenuRight fontSize={20} />
                </Typography>
              </HoverIconButton>
            </Box>
          </Box>
        </AppBar>
      ) : null}
      {!isMobile ? (
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "fixed",

              marginLeft: "-1rem",
              width: "300px",
              minHeight: "100%",
            }}
          >

          </div>
          <div
            style={{
              position: "fixed",
              zIndex: "1000",
              marginTop: "-5rem",
              marginLeft: "-6rem",
            }}
          >
            <div
              style={{
                overflow: "hidden",
              }}
            >



              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  ml: -6.5,
                  mt: 1.8,
                  width: "200px",
                  mr: -5,
                  cursor: 'pointer'
                }}
              ><br />
                {darkMode ? (
                  <HoverIconButton onClick={handleLogoClick}>
                    <img
                      style={{ display: "block", width: '40px' }}
                      src={MainLogo}
                      alt="Minto"
                    // onClick={handleLogoClick}
                    />
                  </HoverIconButton>
                ) : (
                  <HoverIconButton onClick={handleLogoClick}>
                    <img
                      style={{ display: "block", width: '40px' }}
                      src={MainLogoLight}
                      alt="Minto"
                    // onClick={handleLogoClick}
                    />
                  </HoverIconButton>
                )}
              </Box>

              <Box

                style={{
                  marginTop: "2rem",
                  borderRadius: "20px",
                }}
              >
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <ListItem
                    disablePadding
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {location.pathname ===
                      `/trending-sellers${location.pathname.slice(17)}` ? (
                      <div
                        style={{

                          borderRadius: "4px",
                          width: "100px",
                          height: "40px",
                          position: "absolute",
                          marginRight: "3rem",
                        }}
                      ></div>
                    ) : null}

                    <HoverIconButton onClick={() => navigate("/trending-sellers")}>

                      {isLightMode ? (
                        <img src={location.pathname === "/trending-sellers" ? sstar : dstar} alt="star" width="25px" />
                      ) : (
                        <img src={location.pathname === "/trending-sellers" ? sstar : star} alt="star" width="25px" />
                      )}

                    </HoverIconButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {location.pathname === "/trending-creators" ? (
                      <div
                        style={{

                          borderRadius: "4px",
                          width: "100px",
                          height: "40px",
                          position: "absolute",
                          marginRight: "3rem",
                        }}
                      ></div>
                    ) : null}
                    <HoverIconButton
                      onClick={() => navigate(`${"/trending-creators"}`)}
                    >

                      {isLightMode ? (
                        <img src={location.pathname === "/trending-creators" ? sbulb : dbulb} alt="star" width="25px" />
                      ) : (
                        <img src={location.pathname === "/trending-creators" ? sbulb : bulb} alt="star" width="25px" />
                      )}

                    </HoverIconButton>
                  </ListItem>
                  {/* <ListItem
                    disablePadding
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {location.pathname === "/favourites" ? (
                      <div
                        style={{
                          
                          borderRadius: "4px",
                          width: "100px",
                          height: "40px",
                          position: "absolute",
                          marginRight: "3rem",
                        }}
                      ></div>
                    ) : null}



                    <HoverIconButton
                      onClick={() =>
                        navigate(`${"/favourites?type=nft-bookmark"}`)
                      }
                    >
                      {location.pathname === "/favourites" ? (
                       <img src={stag} alt="star" width="25px">
                        
                       </img>
                      ) : (
                        <img src={tag} alt="star" width="25px">
                        
                        </img>
                      )}
                    </HoverIconButton>
                  </ListItem> */}

                  <ListItem
                    disablePadding
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >

                    <HoverIconButton
                      onClick={() => navigate(`${"/profile/language"}`)}
                    >
                      {isLightMode ? (
                        <img src={location.pathname === `/profile${location.pathname?.slice(8)}` ? sgear : dgear} alt="star" width="25px" />
                      ) : (
                        <img src={location.pathname === `/profile${location.pathname?.slice(8)}` ? sgear : gear} alt="star" width="25px" />
                      )}
                    </HoverIconButton>

                  </ListItem>
                </List>
              </Box>
            </div>
          </div>
        </div>
      ) : null}
      {/* Drawer will render only for mobile devices */}
      {isMobile ? (
        <>
          <SideDrawer
            darkMode={darkMode}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            handleConnectMetamaskWallet={handleConnectMetamaskWallet}
            marketplace={marketplace}
            account={account}
            blockchain={blockchain}
            setBlockchain={setBlockchain}
            setIndexxWalletEmail={setIndexxWalletEmail}
            indexxWalletEmail={indexxWalletEmail}
            indexxWalletObj={indexxWalletObj}
            indexxWalletAddress={indexxWalletAddress}
            isIndexxWalletWeb={isIndexxWalletWeb}
            handleConnectIndexxWallet={handleConnectIndexxWallet}
            connectwall={connectwall}
          />
        </>
      ) : null}
    </>
  );
};

export default SideBar;
