
// import axios from 'axios';
// import { ethers } from "ethers";
import contractAddress from "../contractData/contractsAddress.json";
import bitcoinBlack from "../contractData/BitcoinBlackXNFT.json";
import bitcoinGreen from "../contractData/BitcoinGreenXNFT.json";
import bitcoinPink from "../contractData/BitcoinPinkXNFT.json";
import bitcoinPurple from "../contractData/BitcoinPurpleXNFT.json";
import bitcoinOrange from "../contractData/BitcoinOrangeXNFT.json";
import bitcoinBlue from "../contractData/BitcoinBlueXNFT.json";
import ethereumBlack from "../contractData/EthereumBlackXNFT.json";
import ethereumGreen from "../contractData/EthereumGreenXNFT.json";
import ethereumPink from "../contractData/EthereumPinkXNFT.json";
import ethereumPurple from "../contractData/EthereumPurpleXNFT.json";
import ethereumOrange from "../contractData/EthereumOrangeXNFT.json";
import ethereumBlue from "../contractData/EthereumBlueXNFT.json";
import e_bitcoinBlack from "../contractData/e_BitcoinBlackXNFT.json";
import e_bitcoinGreen from "../contractData/e_BitcoinGreenXNFT.json";
import e_bitcoinPink from "../contractData/e_BitcoinPinkXNFT.json";
import e_bitcoinPurple from "../contractData/e_BitcoinPurpleXNFT.json";
import e_bitcoinOrange from "../contractData/e_BitcoinOrangeXNFT.json";
import e_bitcoinBlue from "../contractData/e_BitcoinBlueXNFT.json";
import e_ethereumBlack from "../contractData/e_EthereumBlackXNFT.json";
import e_ethereumGreen from "../contractData/e_EthereumGreenXNFT.json";
import e_ethereumPink from "../contractData/e_EthereumPinkXNFT.json";
import e_ethereumPurple from "../contractData/e_EthereumPurpleXNFT.json";
import e_ethereumOrange from "../contractData/e_EthereumOrangeXNFT.json";
import e_ethereumBlue from "../contractData/e_EthereumBlueXNFT.json";
import usdNFT from "../contractData/XUSDNFT.json";
import e_usdnft from "../contractData/e_XUSDNFT.json";
import ipfsData from "../contractData/ipfsData.json";
const ethers = require("ethers")

export async function getCurrentMintNumberByAddress(address, abiValue, type, blockchain = "") {
    try {
        if (blockchain === "Polygon") {
            let rpcProvider = new ethers.providers.JsonRpcProvider(
                "https://polygon-mumbai.g.alchemy.com/v2/UhcrnitjAWQ4J2KhQI7atNDxAx0-TMlK"
            );

            const spFeed = new ethers.Contract(address, abiValue, rpcProvider);
            let res = await spFeed.totalSupply();
            let cost;
            if (type === "BTC") {
                cost = await spFeed.convertBitcoinToMatic();
            } else if (type === "ETH") {
                cost = await spFeed.convertEthereumToMatic();
            } else if (type === "USD") {
                cost = await spFeed.getMaticValueForOneUSD();
            }
            const mintCount = parseInt(res);
            const costValue = parseInt(cost);
            const uriP = await spFeed.uriPrefix();
            const uriS = await spFeed.uriSuffix();
            const tokenUri = uriP + (mintCount + 1) + uriS;

            return {
                mintCount,
                costValue,
                tokenUri
            };
        } else if (blockchain === "Ethereum") {
            let rpcProvider = new ethers.providers.JsonRpcProvider(
                "https://eth-sepolia.g.alchemy.com/v2/2DsflcbXsCueCqyNzHCOoMWEQgmgax6v"
            );

            const spFeed = new ethers.Contract(address, abiValue, rpcProvider);
            let res = await spFeed.totalSupply();
            let cost;
            if (type === "BTC") {
                cost = await spFeed.cost();
            } else if (type === "ETH") {
                cost = await spFeed.cost();
            } else if (type === "USD") {
                cost = await spFeed.cost();
            }
            const mintCount = parseInt(res);
            const costValue = parseInt(cost);
            const uriP = await spFeed.uriPrefix();
            const uriS = await spFeed.uriSuffix();
            const tokenUri = uriP + (mintCount + 1) + uriS;

            return {
                mintCount,
                costValue,
                tokenUri
            };
        }
    } catch (err) {
        return {
            mintCount: 0,
            costValue: 0,
            tokenUri: ""
        };
    }
}

export async function getAllNFTsCount() {
    try {
        let contracts = [
            {
                "address": contractAddress.bitcoinBlack,
                "abi": bitcoinBlack.abi
            },
            {
                "address": contractAddress.bitcoinBlue,
                "abi": bitcoinBlue.abi
            },
            {
                "address": contractAddress.bitcoinGreen,
                "abi": bitcoinGreen.abi
            },
            {
                "address": contractAddress.bitcoinPurple,
                "abi": bitcoinPurple.abi
            },
            {
                "address": contractAddress.bitcoinPink,
                "abi": bitcoinPink.abi
            },
            {
                "address": contractAddress.bitcoinOrange,
                "abi": bitcoinOrange.abi
            },
            {
                "address": contractAddress.ethereumBlack,
                "abi": ethereumBlack.abi
            },
            {
                "address": contractAddress.ethereumBlue,
                "abi": ethereumBlue.abi
            },
            {
                "address": contractAddress.ethereumGreen,
                "abi": ethereumGreen.abi
            },
            {
                "address": contractAddress.ethereumPurple,
                "abi": ethereumPurple.abi
            },
            {
                "address": contractAddress.ethereumPink,
                "abi": ethereumPink.abi
            },
            {
                "address": contractAddress.ethereumOrange,
                "abi": ethereumOrange.abi
            },
            {
                "address": contractAddress.e_bitcoinBlack,
                "abi": e_bitcoinBlack.abi
            },
            {
                "address": contractAddress.e_bitcoinBlue,
                "abi": e_bitcoinBlue.abi
            },
            {
                "address": contractAddress.e_bitcoinGreen,
                "abi": e_bitcoinGreen.abi
            },
            {
                "address": contractAddress.e_bitcoinPurple,
                "abi": e_bitcoinPurple.abi
            },
            {
                "address": contractAddress.e_bitcoinPink,
                "abi": e_bitcoinPink.abi
            },
            {
                "address": contractAddress.e_bitcoinOrange,
                "abi": e_bitcoinOrange.abi
            },
            {
                "address": contractAddress.e_ethereumBlack,
                "abi": e_ethereumBlack.abi
            },
            {
                "address": contractAddress.e_ethereumBlue,
                "abi": e_ethereumBlue.abi
            },
            {
                "address": contractAddress.e_ethereumGreen,
                "abi": e_ethereumGreen.abi
            },
            {
                "address": contractAddress.e_ethereumPurple,
                "abi": e_ethereumPurple.abi
            },
            {
                "address": contractAddress.e_ethereumPink,
                "abi": e_ethereumPink.abi
            },
            {
                "address": contractAddress.e_ethereumOrange,
                "abi": e_ethereumOrange.abi
            },
            {
                "address": contractAddress.XUSDNFT,
                "abi": usdNFT.abi
            },
            {
                "address": contractAddress.e_XUSDNFT,
                "abi": e_usdnft.abi
            },
        ]
        const tempData = {
            "id": "1",
            "artworkImage": "https://res.cloudinary.com/djisilfwk/image/upload/v1642268556/Minto%20NFT%20Marketplace/NFT%20Images/debug_mode_on_pgrgao.jpg",
            "artworkTitle": "Debug Mode On PG Rao",
            "artworkDetails": "Introducing IndexXNFTs: where art meets innovation in stunning masterpieces. Explore breathtaking digital artworks that redefine creativity and captivate with their beauty. Join the revolution and experience the magic of IndexXNFTs today",
            "artworkPrice": 0,
            "highestBid": " (141 USD)",
            "currentBid": " (141 USD)",
            "basePrice": " (141 USD)",
            "boughtAt": " (141 USD)",
            "creator": "@willie",
            "creatorImage": "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/willie.jpg",
            "owner": "@brian",
            "ownerImage": "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/brian.jpg",
            "uploaded": "12",
            "likes": 40,
            "auctionStatus": "live",
            "currentMint": 0,
            "contractAddress": "",
            "tokenUrl": "",
            "abi": {}
        };

        let contractData = [];
        for (let index = 0; index < contracts.length; index++) {
            const element = contracts[index];
            const newData = { ...tempData };
            if (element.address === "0x8c034b0346a68aBE5e9Ca07B5b5dA129bE92E881") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "BTC");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Black IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Black IndexXNFT with supply around 10000 and value equality to one Bitcoin Value.";
                newData.artworkImage = ipfsData.bitcoinBlackImageIPFS + (res.mintCount + 1) + '.png';
                newData.basePrice = "1 BTC";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0xaE8F8C78d51DFaFb2c246877819C2Ad38594bB2c") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "BTC");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Bitcoin Purple IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Bitcoin Purple IndexXNFT with supply around 80000 and value equality to half Bitcoin Value.";
                newData.artworkImage = ipfsData.bitcoinPurpleImageIPFS + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.5 BTC";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0x1c01F64882E60EFDeC659b6F7c2B35F3434d8F3F") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "BTC");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Bitcoin Blue IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Bitcoin Blue IndexXNFT with supply around 80000 and value equality to one fourth Bitcoin Value.";
                newData.artworkImage = ipfsData.bitcoinBlueImageIPFS + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.25 BTC";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0xBE4cAcF5b2132578587d454B856184dDA0D5a6dc") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "BTC");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Bitcoin Green IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Bitcoin Green IndexXNFT with supply around 80000 and value equality to one tenth Bitcoin Value.";
                newData.artworkImage = ipfsData.bitcoinGreenImageIPFS + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.1 BTC";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0x2f394FaA89C807C0251A17709540426c40A1507A") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "BTC");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Bitcoin Pink IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Bitcoin Pink IndexXNFT with supply around 160000 and value equality to one hundredth Bitcoin Value.";
                newData.artworkImage = ipfsData.bitcoinPinkImageIPFS + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.01 BTC";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0x732799Bf3ba77a5a6C774cc76FA479B3098e6a99") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "BTC");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Bitcoin Orange IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Bitcoin Orange IndexXNFT with supply around 320000 and value equality to one thousandth Bitcoin Value.";
                newData.artworkImage = ipfsData.bitcoinOrangeImageIPFS + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.001 BTC";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0xD96031e03ee3C57a47C3d4785958E912a56c2c69") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "ETH");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Ethereum IndexXNFT #" + newData.currentMint;
                newData.artworkDetails = "Ethereum IndexXNFT with supply around 10000 and value equality to one Ethereum Value.";
                //newData.artworkImage = ipfsData.ethereumBlackImageIPFS + (res.mintCount + 1) +  '.png';
                newData.artworkImage = "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/black/output/" + (res.mintCount + 1) + '.png';
                newData.basePrice = "1 ETH";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0x6681F1e93CAA90ec684b34582481f70bc6dE65AF") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "ETH");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Ethereum Purple IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Ethereum Purple IndexXNFT with supply around 20000 and value equality to half Ethereum Value.";
                //newData.artworkImage = ipfsData.ethereumPurpleImageIPFS + (res.mintCount + 1) +  '.png';
                newData.artworkImage = "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/purple/output/" + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.5 ETH";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0xED57FC4CDd710F95e6900D5e19fBD1569eb4602A") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "ETH");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Ethereum Blue IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Ethereum Blue IndexXNFT with supply around 40000 and value equality to one fourth Ethereum Value.";
                //newData.artworkImage = ipfsData.ethereumBlueImageIPFS + (res.mintCount + 1) +  '.png';
                newData.artworkImage = "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/blue/output/" + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.25 ETH";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0x415D03cc37242Fc7f533573580065Ca1aE24ED62") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "ETH");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Ethereum Green IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Ethereum Green IndexXNFT with supply around 80000 and value equality to one tenth Ethereum Value.";
                //newData.artworkImage = ipfsData.ethereumGreenImageIPFS + (res.mintCount + 1) +  '.png';
                newData.artworkImage = "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/green/output/" + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.1 ETH";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0x8688CE3A94e25c743a85c8D2C0B006a31B5F3839") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "ETH");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Ethereum Pink IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Ethereum Pink IndexXNFT with supply around 160000 and value equality to one hundredth Ethereum Value.";
                //newData.artworkImage = ipfsData.ethereumPinkImageIPFS + (res.mintCount + 1) +  '.png';
                newData.artworkImage = "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/pink/output/" + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.01 ETH";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0x20627adF2ecA7B8fdE0f9c4533dea121FcE1A4e5") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "ETH");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Ethereum Orange IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Ethereum Orange IndexXNFT with supply around 320000 and value equality to one thousandth Ethereum Value.";
                //newData.artworkImage = ipfsData.ethereumOrangeImageIPFS + (res.mintCount + 1) +  '.png';
                newData.artworkImage = "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/orange/output/" + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.001 ETH";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0xb58F2EafE63C9ac7a47B6Dc729c125A9321492A6") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "USD", "Matic");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Ethereum Orange IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Ethereum Orange IndexXNFT with supply around 320000 and value equality to one thousandth Ethereum Value.";
                //newData.artworkImage = ipfsData.ethereumOrangeImageIPFS + (res.mintCount + 1) +  '.png';
                newData.artworkImage = "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/orange/output/" + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.001 ETH";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            } else if (element.address === "0x6681F1e93CAA90ec684b34582481f70bc6dE65AF") {
                const res = await getCurrentMintNumberByAddress(element.address, element.abi, "USD", "Blockchain");
                newData.currentMint = res.mintCount + 1;
                newData.artworkTitle = "Ethereum Orange IndexXNFT #" + newData.currentMint
                newData.artworkDetails = "Ethereum Orange IndexXNFT with supply around 320000 and value equality to one thousandth Ethereum Value.";
                //newData.artworkImage = ipfsData.ethereumOrangeImageIPFS + (res.mintCount + 1) +  '.png';
                newData.artworkImage = "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/orange/output/" + (res.mintCount + 1) + '.png';
                newData.basePrice = "0.001 ETH";
                newData.artworkPrice = res.costValue;
                newData.contractAddress = element.address;
                newData.abi = element.abi;
                newData.tokenUrl = res.tokenUri;
            }

            contractData.push(newData);

        }
        return contractData;
    } catch (err) {
        return err;
    }
}