/* eslint-disable no-unused-vars */
import { useTheme } from "@emotion/react";
import { Grid, Stack, Typography, useMediaQuery, } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from '../DataContext';
import sold from "../../../assets/sold_.png";

const CollectionCard = ({
  darkMode,
  collection,
  sellerCollection,
  mode,
  id,
}) => {
  const navigate = useNavigate();
  const { setData } = useContext(DataContext);
  const [sendData, setSendData] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const handleNavigate = () => {
    const largeData = sellerCollection;
    setData(largeData);
    setSendData(largeData)
    navigate(`${sellerCollection?.name}/${id}`)
    setIsClicked(true)
  };

  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // console.log(sellerCollection, "sellercollection");

  return (
    <>
      <Grid sx={{ zIndex: 10 }} item xs={1} sm={6} md={collection?.image ? 3 : 3}>
        <Box
          elevation={1}
          sx={{ borderRadius: "10px", p:2 }}
          bgcolor={darkMode ? "#121212" : "#fbfbfb"}
          color={darkMode ? "#ffffff" : "#121212"}
          style={{
            boxShadow: `${!darkMode && "0px 4px 4px rgba(0, 0, 0, 0.25)"}`,
          }}
        >
          {collection?.image ? (
            <React.Fragment>
              
                <Box
                  onClick={(e) => {
                    navigate(`${collection?.name}/${id}`);
                  }}
                  sx={{
                    width: "100%",
                    height:"260px",
                    // height: String(collection?.image).includes('USD') ? "250px" : "500px",
                    backgroundImage: `linear-gradient(0deg, #121212 0%, rgba(28, 28, 28, 0) 100%) ,url(${darkMode ? collection?.imagedark : collection?.image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize:"contain",
                    backgroundPosition: "top",
                    position: "relative",
                    cursor: "pointer",
                    borderRadius:"10px"
                    // marginTop: String(collection?.image).includes('USD') ? "125px": "",
                    // marginBottom: String(collection?.image).includes('USD')? "125px": "",
                  }}
                ></Box>
              
            </React.Fragment>
          ) : (
            <React.Fragment>
              {darkMode ? (
                <Box
                  onClick={handleNavigate}
                  sx={{
                    width: String(sellerCollection?.name).includes("xUSDNFT") ? "100%" : "100%",
                    height:`${isMobile ? "500px" : "420px"}`,
                    // height: String(sellerCollection?.name).includes("xUSDNFT") ? "250px" : "500px",
                    backgroundImage: `linear-gradient(0deg, #121212 0%, rgba(28, 28, 28, 0) 100%) ,url(${sellerCollection?.metadata?.image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  {sellerCollection.sold === true ?
                    <img alt="sold" src={sold} 
                     style={{
                      width: "100%",
                      height:`${isMobile ? "500px" : "401px"}`,
                      }}/>
                    :null
                  }
                </Box>
              ) : (
                <Box
                  onClick={handleNavigate}
                  sx={{
                    width: "100%",
                    height:`${isMobile ? "500px" : "420px"}`,
                    backgroundImage: `linear-gradient(0deg, #121212 0%, rgba(28, 28, 28, 0) 100%) ,url(${sellerCollection?.metadata?.image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  {sellerCollection.sold === true ?
                    <img alt="sold" src={sold} 
                    style={{
                      width: "100%",
                      height:`${isMobile ? "500px" : "401px"}`,
                      }}/>
                    :null
                  }
                </Box>
              )}
            </React.Fragment>
          )}
          <Box position="relative" px={1.5}>
            <Box sx={{ position: "absolute", mt: -8, width: "90%" }}>
              <Typography variant="h6" color="#fff">
                {collection?.title}
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" color="#fff">
                  {collection?.createdBy
                    ? collection?.createdBy
                    : sellerCollection?.creator}
                </Typography>
                <Typography variant="body2" color="#fff">
                  {collection?.totalNFT ? "" : (String(sellerCollection?.metadata?.name) === undefined || String(sellerCollection?.metadata?.name) === null) ? "" :
                    String(sellerCollection?.metadata?.name).includes("Bitcoin Black")
                      ? `${String(sellerCollection?.metadata?.name).replace("Bitcoin", "")}`
                      : `${sellerCollection?.metadata?.name}`}
                </Typography>
              </Stack>
            </Box>
          </Box>

        </Box>
      </Grid>
    </>
  );
};

export default CollectionCard;
